import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
// core components
import FormHelperText from '@material-ui/core/FormHelperText';
import customInputStyle from "styles/jss/material-dashboard-react/components/customInputStyle";

function CustomTextArea({ ...props }) {
  const {
    input,
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    requiredLabel,
    meta: {touched, error, warning}
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: Boolean(touched && (error || warning)),
    [" " + classes.labelRootSuccess]: Boolean(touched && !(error || warning))
  });
  const underlineClasses = classNames({
    [classes.underlineError]: Boolean(touched && (error || warning)),
    [classes.underlineSuccess]: Boolean(touched && !(error || warning)),
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });
  return (
    <FormControl error={Boolean(touched && (error || warning))} aria-describedby="name-error-text"
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          required={requiredLabel}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <textarea
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        id={id}
        {...inputProps}
        {...input}
        //value={defaultValue}
        //endAdornment={children}
      />
      {touched && (error || warning) && <FormHelperText id="name-error-text">{error || warning}</FormHelperText>}
    </FormControl>
  );
}

CustomTextArea.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool
};

export default withStyles(customInputStyle)(CustomTextArea);
