import {
    defaultFont,
    primaryColor,
    /*defaultBoxShadow,
    infoColor,
    successColor,
    warningColor,
    dangerColor*/
  } from "styles/jss/material-dashboard-react.jsx";
const documentoStyle = theme => ({
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "20px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    fontWeight: "400",
    
  },
  titleIcon: {
    paddingTop: "3px",
    marginRight: "10px"
  },
  dialog: {
    width: 'calc(100% - 16px)',
  },
  lookupEditCell: {
    paddingTop: theme.spacing.unit * 0.875,
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
  },
  inputRoot: {
    width: '100%',
  },
  distribucionGrid: {
    ...defaultFont,
    fontSize: "0.8125rem",
    display: "inherit"
  },
  distribucionGrpCanal: {
    backgroundColor: primaryColor + " !important",
    color: "white !important",
    fontWeight: "bold"
  },
  distribucionTitulo: {
    paddingRight: "5px !important",
    paddingLeft: "5px !important",
    backgroundColor: "rgb(89,89,89) !important",
    color: "white !important",
    fontWeight: "400"
  },
  distribucionGrpProd: {
    paddingLeft: "2px !important",
    color: "black !important",
    backgroundColor: "white !important",
    fontWeight: "400",
    textAlign: "left !important"
  },
  distribucionProd: {
    paddingRight: "3px !important",
    paddingLeft: "8px !important",
    backgroundColor: "white !important",
    color: "black !important",
    textAlign: "left !important",
    "& > span": {
      width: "220px",
    }
  },
  nuevo: {
    color: primaryColor + " !important"
  },
  distribucionProdPorc: {
    paddingRight: "3px !important",
    color: "blue !important",
    textAlign: "right !important"
  },
  distribucionCanalPorc: {
    paddingRight: "3px !important",
    color: "black !important",
    textAlign: "right !important"
  },
  distribucionCellCheckbox: {
    paddingRight: "5px !important",
    paddingLeft: "5px !important",
  },
  distribucionCheckbox: {
    width: "0",
    height: "0"
  },
  distribucionDialog: {
    overflow: "hidden"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
})
export default documentoStyle;