import * as React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom"; 
import * as notificationActions from "actions/NotificationActions"; 
import * as loadActions from "actions/LoadActions";
import * as domicilioActions from "actions/DomicilioActions";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
//import PublicacionGrid from "components/Grid/PublicacionGrid";
import {TIPO_MENSAJE, GRID_TEXT_ES, MENSAJE, ESTADO} from 'utils/Constants';
import { DATA_DISTRITOS, DATA_TIPO_DOCUMENTO, DATA_LINEAS, DATA_RECEPCIONISTA, DATA_AREA, DATA_ARTEFACTO, DATA_MARCA, DATA_TIPO_SERVICIO , DATA_TIPO_COMPROBANTE} from 'utils/GeneralData';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import {
  CustomPaging, SortingState, EditingState, PagingState, DataTypeProvider, SummaryState, IntegratedSummary
} from '@devexpress/dx-react-grid';
import {
  Grid, TableEditColumn, Table,   TableColumnVisibility,
  VirtualTable, TableEditRow, TableHeaderRow, PagingPanel, 
  TableColumnResizing, TableSummaryRow
} from '@devexpress/dx-react-grid-material-ui';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import CustomInput from "components/CustomInput/CustomInput";
import { change } from 'redux-form'; 
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { CurrencyTypeProvider } from 'theme-sources/material-ui/components/currency-type-provider';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';


const DeleteButton = ({ onExecute }) => (
  <IconButton
    onClick={() => {
      // eslint-disable-next-line
      if (window.confirm('Estas seguro de eliminar el registro?')) {
        onExecute();
      }
    }}
    title="Eliminar"
  >
    <DeleteIcon />
  </IconButton>
);

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Editar" style={{padding: "2px"}}>
    <EditIcon />
  </IconButton>
);

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Guardar" style={{padding: "2px"}}>
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancelar" style={{padding: "2px"}}>
    <CancelIcon />
  </IconButton>
);

const AddButton = ({ onExecute }) => (
  <div style={{ textAlign: 'center' }}>
    <Button
      color="primary"
      onClick={onExecute}
      title="Nuevo"
    >
      ADD
    </Button>
  </div>
);

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton
};

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return (
    <CommandButton style={{ padding: 0 }}
      onExecute={onExecute}
    />
  );
};

const Row = (props) => {
  const { row } = props;
  let style = {}
  if (row.flag === 1) {
    style = { backgroundColor: "lightcyan" }
  }
  return <VirtualTable.Row {...props} style={style} />;
};

const EditCell = (props) => {
  return <TableEditRow.Cell {...props} />;
};

const getRowId = row => row.id;

//Artefacto
const ArtefactoTypeProvider = props => {
  const BooleanEditor = ({ value, onValueChange }) => {
    return  <Select
              input={<Input />}
              value={value}
              onChange={event => onValueChange(event.target.value)}
              style={{ width: '100%' }}
            >
            {props.estados.map(({id,valor}) => (
            <MenuItem  value={id} >
              {valor }
            </MenuItem>
            ))}           
          </Select>
         
  };

  const BooleanFormatter = ({ value }) => {
    console.log("value del objeto", value);
    var dscstsusuario = 'PARAMETRO INVALIDO';
    for (var key in props.todosestados) {
      const obj = props.todosestados[key];
      if (obj.id == value) {
        dscstsusuario = obj.valor;
      }
    }  
    return <Chip label={dscstsusuario} />      
  };

  return <DataTypeProvider  formatterComponent={BooleanFormatter}
                            editorComponent={BooleanEditor}
                            {...props}
  />
};

//fin de Artefacto
//Marca
  //Artefacto
const MarcaTypeProvider = props => {
  const BooleanEditor = ({ value, onValueChange }) => {
    return  <Select
              input={<Input />}
              value={value}
              onChange={event => onValueChange(event.target.value)}
              style={{ width: '100%' }}
            >
            {props.estados.map(({id,valor}) => (
            <MenuItem  value={id} >
              {valor }
            </MenuItem>
            ))}           
          </Select>
         
  };

  const BooleanFormatter = ({ value }) => {
    console.log("value del objeto", value);
    var dscstsusuario = 'PARAMETRO INVALIDO';
    for (var key in props.todosestados) {
      const obj = props.todosestados[key];
      if (obj.id == value) {
        dscstsusuario = obj.valor;
      }
    }  
    return <Chip label={dscstsusuario} />      
  };

  return <DataTypeProvider  formatterComponent={BooleanFormatter}
                            editorComponent={BooleanEditor}
                            {...props}
  />
};
 
//Fin Marca


class ConsultaFichasDomicilioGrid extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      columns: [
        { name: 'id', title: 'Nro.' },      
        { name: 'artefacto', title: 'Artefacto' },
        { name: 'marca', title: 'Marca' },
        { name: 'modelo', title: 'Modelo' },
        { name: 'serie', title: 'Serie' },
        { name: 'fcompra', title: 'F/Compra' },
        { name: 'estado', title: 'Condición' },
        { name: 'accion', title: 'Acción' },
      ],
      tableColumnExtensions: [
        { columnName: 'id', width: 70 },    
        { columnName: 'artefacto', width: 140 },  
        { columnName: 'marca', width: 140 },
        { columnName: 'modelo', width: 100 },
        { columnName: 'serie', width: 100 },
        { columnName: 'fcompra', width: 100 },
        { columnName: 'estado', width: 100 },
        { columnName: 'accion', width: 100 }
      ],

      editingStateColumnExtensions: [
        { columnName: 'artefacto', editingEnabled: true },
        { columnName: 'marca', editingEnabled: true },
        { columnName: 'modelo', editingEnabled: true },
        { columnName: 'serie', editingEnabled: true },
        { columnName: 'fcompra', editingEnabled: true },
        { columnName: 'estado', editingEnabled: false },
        { columnName: 'accion', editingEnabled: false }
      ],

      hiddenColumnNames: ['ideusuario', 'id'],
      rows: [ ],
      sorting: [],
      currentPage: 0,
      totalCount: 0,
      pageSize: 100,
      pageSizes: [10, 20, 50, 100],
      editingRowIds: [], 
      marcaColumn: ['marca'],
      artefactoColumn: ['artefacto'], 
      todosestados:[],
      tipocostos:[],
      artefactos: [],
      marcas: []
    };

    this.hiddenColumnNamesChange = (hiddenColumnNames) => {
      this.setState({ hiddenColumnNames });
    };

    this.changeSorting = sorting => this.setState({ sorting });
    this.changeEditingRowIds = editingRowIds => this.setState({ editingRowIds });
    this.changeRowChanges = rowChanges => this.setState({ rowChanges });    
    this.commitChanges = ({ added, changed, deleted }) => {
      let { rows } = this.state;
      let changedRows;
      if (changed) {
           changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      }
      
      if (deleted) {  
          console.log("rowAEliminar", deleted);
          let deletedIds = deleted;
          const rowsForDelete = rows.slice();
          deletedIds.forEach((rowId) => {
            const index = rowsForDelete.findIndex(row => row.id === rowId);
            if (index > -1) {
              rowsForDelete.splice(index, 1);
            }
          });
          console.log("nuevos rows::", rowsForDelete);
          // this.setState({ rows: rowsForDelete});
           //          return rowsForDelete;
           changedRows = rowsForDelete;
      }

      if (added){
        console.log(added);
        const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
         changedRows = [
          ...rows,
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...row,
          })),
        ];
    
      }  
     for (let i = 0; i < changedRows.length; i++) {
        changedRows[i].total = changedRows[i].cantidad * changedRows[i].precio;
     }
      this.setState({ rows: changedRows});
    };  
  }

  
  changeCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage })
    this.buscarUsuario(currentPage);
  }

  changePageSize = (pageSize) => {
    const { totalCount, currentPage: stateCurrentPage } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(stateCurrentPage, totalPages - 1);

    this.setState({
      pageSize: pageSize,
      currentPage: currentPage
    });
    this.buscarUsuario(currentPage, pageSize);
  }

  componentDidMount() {
    this.props.onRef(this);
    let  { repuestos } = this.props;
    this.state.artefactos = JSON.parse(localStorage.getItem("productos_serfac")); //DATA_ARTEFACTO;
    this.state.marcas = JSON.parse(localStorage.getItem("marcas_serfac")); //DATA_MARCA;  
    this.state.rows = [
      {
        "id": "1",
        "artefacto" : "1",
        "marca": "1",
        "modelo": "mq",
        "serie": "SER.01",
        "fcompra": "09/01/20202",
        "estado": "ACTIVADO"
      } 
    ]
  } 

  buscarFicha = (data) => {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    const { currentPage, pageSize } = this.state;
    add();
    
    console.log("hola vengo desde la vista principal", data);
      remove(); 
  }  

  extraerRows = ( ) => {
   
    const { rows  } = this.state;
    console.log("rows extraidos");
    return rows;
  }  


  descargarArchivo(documento, tipo) {
      console.log("vengo desde el evento de la grilla, ", documento);
      console.log("vengo desde el evento de la grilla, tipo ", tipo);
  }

  Cell = (props) => {
    const { column, row} = props;

    if (column.name === 'edit') {
      return (
        <TableCell style={{ padding: 0 }}>
          <IconButton color="primary" aria-label="editIcon" style={{ display: 'flex', float: 'left' }}  title="Editar" >
            <EditIcon />            
          </IconButton>
        </TableCell>
      );
    }


    if (column.name === 'accion') {
      return (
        <TableCell style={{ padding: 0 }}>
          <IconButton color="primary" aria-label="row.cdr" style={{ display: 'flex', float: 'left' }}
            onClick={this.descargarArchivo.bind(this, row, 'MAIL')} title="Enviar Ficha por Correo" > 
            <MailOutlineOutlinedIcon>{row.cdr}</MailOutlineOutlinedIcon>
          </IconButton>
        </TableCell>
      );
    }

     

    return <VirtualTable.Cell {...props} />;
  };

  render() {

    
    const {
      rows,
      columns,
      tableColumnExtensions,
      editingStateColumnExtensions,
      sorting,
      editingRowIds,
      rowChanges,
      currentPage,
      pageSize,
      pageSizes,
      totalCount,
      hiddenColumnNames,
      artefactoColumn,
      marcaColumn,
      todosestados, 
      artefactos,
      marcas
    } = this.state;  
    const {estados, repuestos, perfiles} = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ width: "100%",  height: "300px"  }}>
            <Grid
              rows={rows}
              columns={columns}
              style={{ height: "10px" }}
              getRowId={getRowId}
              className={"ps-child"}
            >
              <SortingState
                sorting={sorting}
                onSortingChange={this.changeSorting}
              />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={this.changeCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={this.changePageSize}
              />

              <EditingState
                editingRowIds={editingRowIds}
                onEditingRowIdsChange={this.changeEditingRowIds}
                rowChanges={rowChanges}
                onRowChangesChange={this.changeRowChanges}
                onCommitChanges={this.commitChanges}
                columnExtensions={editingStateColumnExtensions}
               />
 
              
               
              <VirtualTable
                columnExtensions={tableColumnExtensions}
                cellComponent={this.Cell}
                messages={GRID_TEXT_ES}
              />
 

              <ArtefactoTypeProvider
                  for={artefactoColumn}
                  estados={artefactos}
                  todosestados={artefactos}
              />

              <MarcaTypeProvider
                  for={marcaColumn}
                  estados={marcas}
                  todosestados={marcas}
              />

              

              <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
              <TableHeaderRow showSortingControls />
              <TableEditRow
                cellComponent={EditCell}
              />

              <TableEditColumn
                width={85}                
                showEditCommand                      
                showDeleteCommand
                showAddCommand
                commandComponent={Command}
              /> 
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
              /> 
            </Grid>
          </Paper>

           
        </GridItem>
      </GridContainer>
    );
  }
}

const mapState = (state) => ({
  loading: state.load.loading,  
  user: state.session.user,
});


const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(domicilioActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

export default withRouter(connect(mapState, mapDispatch)(ConsultaFichasDomicilioGrid));

