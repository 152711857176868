import { Auth } from "aws-amplify";
let userCognito;
export default class SessionApi {

  static login = (paramUser) => {
    return new Promise((resolve, reject) =>
      Auth.signIn(paramUser.user, paramUser.password)
        .then(user => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            userCognito = user;
          }
          resolve(user);
        })
        .catch(err => {
          reject(err);
        })
    );
  };

  static newPassword = (paramUser) => {
    return new Promise((resolve, reject) =>
      Auth.completeNewPassword(paramUser.user, paramUser.password)
        .then(user => { 
          resolve(user);
        })
        .catch(err => {
          reject(err);
        })
    );
  };

  static async loginAD() {
      let session = await Auth.currentSession();
      return session;
  }

  static async logout() {
    return Auth.signOut()
      .then((response) => {
        return response;
      }).catch((error) => {
        return error;
      })
  }
}