import { sessionService } from 'redux-react-session';
import sessionApi from 'services/api/SessionApi';
import usuarioApi from 'services/api/UsuarioApi';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';

export function loginAD() {
  return function () {
    var respuesta = {};
    return sessionApi.loginAD().then(data => {
      localStorage.setItem('tkn', data.idToken.jwtToken);
      let usuario = data.idToken.payload;
      return usuarioApi.obtenerUsuario(usuario).then(dataUsuario => {
        respuesta = dataUsuario;
        if (respuesta.tipo === TIPO_MENSAJE.SUCCESS) {
          let usersaved = Object.assign(data.idToken.payload, dataUsuario.data);
          return sessionService.saveUser(usersaved)
            .then(() => {
              return sessionService.saveSession(data.idToken.jwtToken)
                .then(() => {
                  localStorage.setItem('tkn', data.idToken.jwtToken);
                  respuesta.tipo = TIPO_MENSAJE.SUCCESS;
                  respuesta.indMostrarMensaje = MENSAJE.IND_MOSTRAR_MENSAJE;
                  respuesta.mensaje = "Bienvenido " + data.idToken.payload.name + " " + data.idToken.payload.family_name;
                  return respuesta;
                }).catch(err => console.error(err));
            }).catch(err => console.error(err));
        }
        return respuesta;
      }).catch(err => {
        respuesta.tipo = TIPO_MENSAJE.DANGER;
        respuesta.mensaje = MENSAJE.ERROR_GENERAL;
        return respuesta;
      });
    }).catch(error => {
      respuesta.tipo = TIPO_MENSAJE.DANGER;
      respuesta.mensaje = "Lo sentimos no pudo ingresar al sistema, por favor inténtelo nuevamente.";
      return respuesta;
    });
  }
}


export function loginTecnico(usuario, clave) {
  return function () {
    var respuesta = {}; 
      return usuarioApi.obtenerUsuario(usuario, clave).then(dataUsuario => {
        respuesta.tipo = TIPO_MENSAJE.DANGER;
        if (dataUsuario.data.length >= 1){
          respuesta =   dataUsuario.data[0];
          respuesta.tipo = TIPO_MENSAJE.SUCCESS;
        }
       console.log(dataUsuario);
        if (respuesta.tipo === TIPO_MENSAJE.SUCCESS) { 
                  localStorage.setItem('tkn', dataUsuario.idToken);
                  respuesta.tipo = TIPO_MENSAJE.SUCCESS; 
                  respuesta.indMostrarMensaje = MENSAJE.IND_MOSTRAR_MENSAJE;
                  respuesta.mensaje = "Bienvenido " + dataUsuario.name + " " + dataUsuario.apellidos ;
                  respuesta.productos = dataUsuario.productos;
                  respuesta.marcas = dataUsuario.marcas;
                  respuesta.tecnicos = dataUsuario.tecnicos;

                  respuesta.lineas = dataUsuario.lineas;
                  respuesta.recepcionistas = dataUsuario.recepcionistas;
                  respuesta.areas = dataUsuario.areas;
                  respuesta.tiposervicios = dataUsuario.tiposervicios;
                  respuesta.tipocostos = dataUsuario.tipocostos;
                  respuesta.tipotrabajos = dataUsuario.tipotrabajos;
                  respuesta.tiendas = dataUsuario.tiendas;
                  respuesta.ubigeos = dataUsuario.ubigeos;
                  return respuesta;
        }
        return respuesta;
      }).catch(err => {
        console.log(err);
        respuesta.tipo = TIPO_MENSAJE.DANGER;
        respuesta.mensaje = MENSAJE.ERROR_GENERAL;
        return respuesta;
      });
     
  }
}



export function login(paramUser) {
  return function () {
    var respuesta = {};
    return sessionApi.login(paramUser).then(data => {  
      console.log("dataCognito:::" , data);
      respuesta.tipo = TIPO_MENSAJE.SUCCESS;
      respuesta.data = data
      respuesta.indMostrarMensaje = MENSAJE.IND_MOSTRAR_MENSAJE;
      respuesta.mensaje = "Bienvenido " + data.username;
      if (data.signInUserSession){
        localStorage.setItem('aws_token', data.signInUserSession.idToken.jwtToken);
      } 
      return respuesta; 
    }).catch(error => {
      respuesta.tipo = TIPO_MENSAJE.DANGER;
      respuesta.data = {}
      respuesta.mensaje = "Lo sentimos no pudo ingresar al sistema, por favor inténtelo nuevamente.";
      return respuesta;
    });
  }
}

export function newPassword(paramUser) {
  return function () {
    var respuesta = {};
    return sessionApi.newPassword(paramUser).then(data => {  
      return data;
    }).catch(error => {
      respuesta.tipo = TIPO_MENSAJE.DANGER;
      respuesta.mensaje = "Lo sentimos no pudo ingresar al sistema, por favor inténtelo nuevamente.";
      return respuesta;
    });
  }
} 

export const logout = (history) => {
  return (dispatch) => {
    return sessionApi.logout().then(() => {
      localStorage.clear();
      sessionService.deleteUser();
      sessionService.deleteSession();
    }).catch(err => {
      throw (err);
    });
  };
};

export const logoutAD = () => {
  return (dispatch) => {
    return sessionApi.logout().then(() => {
      localStorage.clear();
      sessionService.deleteUser();
      sessionService.deleteSession();
    }).catch(err => {
      throw (err);
    });
  };
};