import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";

import CustomAutocomplete from "components/CustomInput/CustomAutocomplete";
import CustomTextArea from "components/CustomInput/CustomTextArea";

import CustomSelect from "components/CustomInput/CustomSelect";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';
import { DATA_DISTRITOS, DATA_TIPO_TRABAJO, DATA_TIPO_DOCUMENTO, DATA_LINEAS, DATA_MARCA_ILUMI, DATA_AREA_ILUMI, DATA_AREA_INDURAMA, DATA_MARCA_INDURAMA, DATA_TIENDAS, DATA_TIPO_SERVICIO , DATA_TIPO_COMPROBANTE} from 'utils/GeneralData';

import * as domicilioActions from "actions/DomicilioActions";
import * as loadActions from "actions/LoadActions";
import * as usuarioActions from "actions/UsuarioActions";
import * as notificationActions from "actions/NotificationActions";
import { s2ab } from 'utils/Functions';
import { getFormatDate, getRestarDias } from 'utils/Functions';
import { change } from 'redux-form'; 

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Dialog from '@material-ui/core/Dialog';
import ClienteDialog from "components/Dialog/ClienteDialog";

import Grid from "components/Grid/DomicilioArtefactoGrid"; 
import GridCosto from "components/Grid/CostosDomicilioGrid"; 

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';

/********new */
const required = value => value ? undefined : 'Valor Requerido'
const requiredTienda = value => value || value=='0' ? undefined : 'Valor Requerido'
const maxLength = max => value =>
  value && value.length > max ? `debe ser ${max} caracteres or menos` : undefined
const maxLength11 = maxLength(11)
const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
const minValueFecha = minValue(18)


const validate = values => {
  const errors = {}
  var fechaActual = getFormatDate(new Date(),  'ymd', '-')
  if (!values.txtruc) {
  }  

  if (values.txtserie && !values.txtnumdoc) {
    errors.txtnumdoc = 'debe ingresar el numero de documento' 
  }

  if (!values.txtserie) {
    errors.txtnumdoc = 'debe ingresar la serie' 
  }
  return errors
}

/****end new */

class RegDomicilioForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showBusquedaCliente: 'None',
      showNameLocal: 'None',
      showComboLocal: '',
      tipoComprobantes: [],
      tipDocuments: [], 
      tipsTrabajo: [],
      tiendas:[],
      lineas: [],
      recepcionistas: [],
      areas: [], 
      artefactos:[],
      marcas: [],
      tipoServicios: [],
      lstCdr: [],
      lstTypeDocument: [],
      distritos: [], 
      showClienteDialog: false,
      localesGuardados: [],
      documento: {
        clieId: 0,
        clieNombre: '', //razon Social
        ubicacion: '',
        clieTipDoc: '',
        clieDireccion:'',
        clieNumero: '',  //es ruc
        clieUbigeo: '',
        motivo: '',
        otro_motivo: '',
        artefacto: '',
        tipoServicio: '',
        modelo: '',
        serie: '',
        marca: '',
        observacion:'',
        telefono: '',
        email:'',
        fechaVenta: '',
        docuNumero: '',
        docuNumeroTo: '',
        tipoDocumento: [],
        docuFechaIni: '',
        docuFechaVenta: '',
        estado: '',
        clieNombre: '', //razon Social
        cdr: '',
        documentTypeClient: '',
        compania: '',
        numeroservicio: '',
        area:'',
        facbolguia: '', 
        doccaja: '',
        recepciona: '',
        linea: '',
        distritoComprobante: '',
        razonSocialComprobante: '',
        tipoComprobante:'',
        rucComprobante:'',
        tipoTrabajo: '',
        tienda: '',
        local: '000000',
        nombreLocal: '',
        nombreTemporalLocal: ''
      },
      repuestos: [],
      locales: [
          {
            id: "000000",
            valor: "NUEVO LOCAL"
          }
      ],
      fecdefault: getFormatDate(new Date(),  'ymd', '-'),
      errorFecha: '',
      errorFechaFin: '',
    };  
  }

  updateName = (e) => { 
    var {documento} = this.state;
    documento[e.target.name] = e.target.value
    this.setState({
      documento: documento
   }); 
  }

  componentDidMount() {
    this.props.onRef(this);
    this.state.distritos = JSON.parse(localStorage.getItem("ubigeos_serfac")); //DATA_DISTRITOS;
    this.state.tipDocuments = DATA_TIPO_DOCUMENTO;
    this.state.lineas = JSON.parse(localStorage.getItem("lineas_serfac")); //DATA_LINEAS;
    this.state.recepcionistas = JSON.parse(localStorage.getItem("recepcionistas_serfac")); //DATA_RECEPCIONISTA;
    this.state.areas = JSON.parse(localStorage.getItem("areas_serfac"));  //DATA_AREA;
    this.state.artefactos = JSON.parse(localStorage.getItem("productos_serfac")); //DATA_ARTEFACTO;
    this.state.marcas = JSON.parse(localStorage.getItem("marcas_serfac")); //DATA_MARCA;

    if (localStorage.getItem("tkn") == "serfac-ilumi" ){
      this.state.marcas = DATA_MARCA_ILUMI; // solo para ILUMI login
      this.state.areas = DATA_AREA_ILUMI; 

    }

    if ( localStorage.getItem("tkn") == "serfac-indurama"){
      this.state.marcas = DATA_MARCA_INDURAMA; // solo para ILUMI login
      this.state.areas = DATA_AREA_INDURAMA; 

    }
    

    this.state.tipoServicios = JSON.parse(localStorage.getItem("tiposervicios_serfac")); // DATA_TIPO_SERVICIO; 
    this.state.tipoComprobantes = DATA_TIPO_COMPROBANTE;
    this.state.tipsTrabajo = JSON.parse(localStorage.getItem("tipotrabajos_serfac")); //DATA_TIPO_TRABAJO;
    this.state.tiendas = JSON.parse(localStorage.getItem("tiendas_serfac")); //DATA_TIENDAS;
    

    let fechaActual = new Date();

   
    this.props.change('hFechaInicio', getFormatDate(new Date(), 'ymd', '-'))
    this.props.change('hFechaFin', getFormatDate(new Date(), 'ymd', '-'))
    this.state.documento.docuFechaIni = getFormatDate(new Date(), 'ymd', '-')
    this.state.documento.docuFechaVenta = getFormatDate(new Date(fechaActual.getFullYear() + '/01/01'), 'ymd', '-')
    //para filtros exportar excel
    this.props.documento.docuFechaIni = getFormatDate(new Date(), 'ymd', '-')
    this.props.documento.docuFechaVenta = getFormatDate(new Date(fechaActual.getFullYear() + '/01/01'), 'ymd', '-')


  }

  guardarCliente = () => {
    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    
    if (this.state.showBusquedaCliente == 'None' || this.state.documento.clieNombre == '' || 
     this.state.documento.direccion == '' || this.state.documento.celular == ''){
      show(MENSAJE.IND_MOSTRAR_MENSAJE, "Ingrese Datos a actualizar o registrar", TIPO_MENSAJE.WARNING);
    }else{
      add();
      this.props.actions.updateCliente(this.state.documento).then(response => {
        if (response.status !== 200) {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "Ocurrio un error al guardar los datos", TIPO_MENSAJE.WARNING);
        } else {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "Registro  Guardado con Exito", TIPO_MENSAJE.SUCCESS);
          this.mostrarCliente();
        }
        remove();
      });
    }
  }

  mostrarCliente(){ 
    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    this.state.localesGuardados = []
    add();
    let documentoRepuesto = {
      operacion: "domGetCliente",
      numDOc: this.state.documento.clieNumero,
      tipoDoc: this.state.documento.clieTipDoc
    }
    
      this.props.actions.consultarDocumentoPorOtrosCampos(documentoRepuesto).then(response => {
        if (response.status !== 200) {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "Ocurrio un error al ejecutar a Query", TIPO_MENSAJE.WARNING);
        } else {
          if (response.data.length === 0) {
            this.props.change('clieNombre',  '')
            this.props.change('telefono',  '')
            this.props.change('celular', '')
            this.props.change('email', '')
            this.props.change('direccion',  '')
            this.props.change('referencia',  '')
            this.props.change('clieUbigeo',  '') 
            this.props.change('nombreLocal',  '') 
            this.state.documento.nombreLocal =  ''

  
            //this.state.documento.clieUbigeo = '';
            this.state.documento.clieId = 0;
            this.state.showBusquedaCliente = '';
            this.state.locales = [{
              id: "000000",
              valor: "NUEVO LOCAL"
            }];
            this.state.documento.local = "000000"; 
            this.state.showNameLocal = '';
            this.state.showComboLocal = 'None';
            show(MENSAJE.IND_MOSTRAR_MENSAJE, "No se encontro al cliente, por favor proceda a registrarlo", TIPO_MENSAJE.WARNING);
          } else {
            // obtenemos los locales y procedemos a mostrar el primero
            let dataCliente = response.data[0];
            let dataLocal = response.locales[0];
            this.props.change('clieNombre',  dataCliente.nombre)
            this.props.change('telefono',  dataLocal.telefono)
            this.props.change('celular',  dataLocal.celular)
            this.props.change('email',  dataLocal.email)
            this.props.change('direccion',  dataLocal.direccion)
            this.props.change('referencia',  dataLocal.referencia)
            this.props.change('clieUbigeo',  dataLocal.ubigeo)
            this.props.change('nombreLocal',  dataLocal.nombre)
            

  
            this.state.documento.clieNombre =  dataCliente.nombre;
            this.state.documento.telefono =  dataLocal.telefono;
            this.state.documento.celular =  dataLocal.celular;
            this.state.documento.email =  dataLocal.email;
            this.state.documento.direccion =  dataLocal.direccion;
            this.state.documento.referencia =  dataLocal.referencia;
            this.state.documento.clieUbigeo =  dataLocal.ubigeo;
            this.state.documento.nombreLocal =  dataLocal.nombre;
  
            this.state.documento.clieUbigeo = dataLocal.ubigeo;
            this.state.documento.clieId = dataCliente.id;
            this.state.localesGuardados = response.locales;
            let localesBd = [];
            for (var i=0; i < response.locales.length; i++ ){
              localesBd.push({
                id: response.locales[i].id,
                valor: response.locales[i].nombre
              });
            }
  
            localesBd.push({
              id: "000000",
              valor: "NUEVO LOCAL"
            });
            console.log("localesBd", localesBd);
            this.state.showBusquedaCliente = '';
            this.state.locales = localesBd;
            this.state.showNameLocal = 'None';
            this.state.showComboLocal = '';
            this.props.change('local',  dataLocal.id)
            this.state.documento.local = dataLocal.id;
  
          }
        }
        remove();
      }) 
    
  }

  getSelectValue = (valor, name) => {
    var documento = this.state.documento
    documento[name] = valor;
    if (name == 'clieUbigeo'){
      for (var i=0; i < this.state.distritos.length ; i++ ){
        if (valor ==  this.state.distritos[i].id){
          this.state.documento.nombreTemporalLocal =  this.state.distritos[i].valor;
        }
      }
    }
    this.setState({ documento: documento });
  }

  getSelectValueLocal = (valor, name) => {
    var documento = this.state.documento
    documento[name] = valor;
    console.log("valor del select " + valor);
    if (valor == '000000'){
      //this.props.change('clieNombre',  '')
      this.state.documento.clieUbigeo = '';
      this.state.documento.referencia = '';
      this.state.documento.telefono =  '';
      this.state.documento.celular =  '';
      this.state.documento.email =  '';
      this.state.documento.direccion = '';
      this.state.documento.referencia =  '';
      this.state.documento.clieUbigeo =  '';
      this.state.documento.nombreLocal =  '';

      this.props.change('nombreLocal',  '')
      this.props.change('telefono',  '')
      this.props.change('celular',  '')
      this.props.change('email',  '')
      this.props.change('direccion',  '')
      this.props.change('referencia', '')
      this.props.change('clieUbigeo',  '') 
      this.state.showNameLocal = '';
      this.state.showComboLocal = 'None';

    }else{
     
      if (valor != ''){
        for (var i=0; i < this.state.localesGuardados.length ; i++ ){
          console.log("valoridstate" + this.state.localesGuardados[i].id)
          if (valor == this.state.localesGuardados[i].id){
            console.log("localesGuardados", this.state.localesGuardados);
            this.props.change('telefono',  this.state.localesGuardados[i].telefono)
            this.props.change('celular',  this.state.localesGuardados[i].celular)
            this.props.change('email',  this.state.localesGuardados[i].email)
            this.props.change('direccion',  this.state.localesGuardados[i].direccion)
            this.props.change('referencia',  this.state.localesGuardados[i].referencia)
            this.props.change('clieUbigeo',  this.state.localesGuardados[i].ubigeo)
            this.props.change('nombreLocal',  this.state.localesGuardados[i].nombre)

            this.state.documento.telefono =  this.state.localesGuardados[i].telefono;
            this.state.documento.celular =  this.state.localesGuardados[i].celular;
            this.state.documento.email =  this.state.localesGuardados[i].email;
            this.state.documento.direccion =  this.state.localesGuardados[i].direccion;
            this.state.documento.referencia =  this.state.localesGuardados[i].referencia;
            this.state.documento.clieUbigeo =  this.state.localesGuardados[i].ubigeo;
            this.state.documento.nombreLocal =  this.state.localesGuardados[i].nombre;
          }
        }
      }
    }
    this.setState({ documento: documento });
  }

  limpiarForm = () => {
    this.props.reset();
    this.state.localesGuardados = [];
    var documento = {
      clieId: 0,
      docuNumero: '',
      tipoDocumento: [],
      clieNumero: '',  //es ruc
      docuFechaIni: getFormatDate(new Date(), 'ymd', '-'),
      docuFechaVenta: getFormatDate(new Date(), 'ymd', '-'),
      estado: '',
      clieNombre: '', //razon Social
      cdr: '',
      clieUbigeo: '',
      documentTypeClient: '',
      artefacto: '',
      marca: '',
      area: '',
      clieTipDoc: '',
      tipoServicio: '',
      linea: '',
      recepciona: '',
      nombreTemporalLocal: ''
    };
    this.setState({ documento: documento });
    this.props.change('hFechaInicio', getFormatDate(new Date(), 'ymd', '-'))
    this.props.change('hFechaFin', getFormatDate(new Date(), 'ymd', '-'))
    this.props.change('clieNombre',  '')
    this.props.change('txtnumdocto',  '')
    this.props.change('clieTipDoc',  '')
    this.props.change('clieNumero',  '')
    //  this.props.change('clieUbigeo',  '')
    this.props.change('telefono',  '') 
    this.props.change('motivo',  '')
    this.props.change('tipoServicio',  '')
    //this.props.change('artefacto',  '')
    this.props.change('modelo',  '')
    this.props.change('serie',  '')
    //   this.props.change('marca',  '')
    this.props.change('ubicacion',  '')
    this.props.change('observacion',  '')

    this.state.showNameLocal = 'None';
    this.state.showComboLocal = '';
    this.setState({ errorFecha: false });
    this.setState({ errorFechaFin: false });
    this.state.showBusquedaCliente = 'None';
    this.limpiarRows();
    this.state.locales = [{
      id: "000000",
      valor: "NUEVO LOCAL"
    }];

  }

  setFechaIni = (event) => {
    let fecha = event.target.value;
    this.setState({ errorFechaFin: false });
    this.setState({ errorFecha: false });
    this.state.documento.docuFechaIni = fecha;
    this.props.change('hFechaInicio', fecha) 
  }
 

  setFechaVenta = (event) => {
    let fecha = event.target.value;
     
      this.setState({ errorFecha: false });
      this.setState({ errorFechaFin: false });
      this.state.documento.docuFechaVenta = fecha;
      this.props.change('hFechaFin', fecha) 
  }

  toggleDialogCliente = (documento) => {
    console.log("valor seleccionado" , this.state.documento) 
    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
   // if (this.state.documento.clieNumero  != null){ 
      add();
      let documentoRepuesto = {
        operacion: "repuesto" 
      }
      // this.props.actions.consultarDocumentoPorOtrosCampos(documentoRepuesto).then(response => {
        //  console.log("respuesta de los repuestos", response); 
      //  if (response.status == 200) {
        //  console.log("datos de los repuestos", response.data );
          //this.setState({ documento: documento})
          //this.setState({ repuestos: response.data})
          this.setState({ showClienteDialog: !this.state.showClienteDialog })
        //} else{
          //  show("1", "No se encontraron datos a mostrar.", TIPO_MENSAJE.WARNING);
        //}
        remove();
    // })
    //}else{
    //  this.setState({ showClienteDialog: !this.state.showClienteDialog })
   // }

  };

  toggleDialogClienteRegistro = (currentPage, page) => {
     //this.buscarDocumentos(currentPage, page);
     this.setState({ showClienteDialog: !this.state.showClienteDialog }) 
  }; 


  extraerRows = ( ) => {
    let rowsRepuestos = this.GridCosto.extraerRows(); 
    let response = {
      costos: rowsRepuestos,
      clieId: this.state.documento.clieId,
      idlocal: this.state.documento.local 
    }
    return response;
  } 

  limpiarRows = ( ) => {
    this.GridCosto.limpiarRows();  
  }

  render() {
    const { handleSubmit, classes } = this.props;
    const { documento, fecdefault, errorFecha, errorFechaFin, lineas, showClienteDialog, repuestos } = this.state;
    return (
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <ExpansionPanel defaultExpanded Expanded>
            <ExpansionPanelSummary>
                <Typography className={classes.title} variant="h1"> Cliente</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2}>
                          <Field labelText="Tipo Documento"
                            formControlProps={{
                              fullWidth: true,
                              margin: "normal",
                              style: { marginTop: "0px" }
                            }}
                            inputProps={{
                              autoComplete: "clieTipDoc",
                              name: "clieTipDoc"
                            }}
                            multiple={false}
                            data={this.state.tipDocuments}
                            component={CustomSelect}
                            valor={documento.clieTipDoc}
                            getValue={this.getSelectValue}
                            placeholder="Placeholder"
                            label="With placeholder"
                            val="id"
                            txt="valor"
                            name="clieTipDoc"
                            id="clieTipDoc" />
                          {}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                         <Field labelText="Número Documento"
                            formControlProps={{
                              fullWidth: true,
                              margin: "normal",
                              style: { marginTop: "0px" }
                            }}
                            onChange={(event) => {
                              this.updateName(event);
                            }}
                            inputProps={{
                              type: "tezt",
                              name: "clieNumero",
                              min: "1"
                            }}
                            component={CustomInput}
                            validate={[required]}
                            value={documento.clieNumero}
                            name="clieNumero"
                            id="clieNumero" />    
                        </GridItem>
                        <GridItem xs={12} sm={12} md={1}>
                          <IconButton color="primary" style={{ display: 'flex', float: 'left' }}
                             title="Buscar Cliente" onClick={() => this.mostrarCliente()}  >
                              <SearchIcon> </SearchIcon> 
                          </IconButton>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                         <Field labelText="Nombres y/o Razón social"
                            formControlProps={{
                              fullWidth: true,
                              maxLength: "10",
                              margin: "normal",
                              style: { marginTop: "0px" }
                            }}
                            onChange={(event) => {
                              this.updateName(event);
                            }}
                            inputProps={{
                              type: "text",
                              name: "clieNombre",
                              min: "1",
                              maxLength: "250"
                            }} 
                            component={CustomInput}
                            validate={[required]}
                            value={documento.clieNombre}
                            maxLength="10"
                            name="clieNombre"
                            id="clieNombre" />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                         <Field labelText="Local"
                                formControlProps={{
                                  fullWidth: true,
                                  margin: "normal",
                                  style: { marginTop: "0px", display: this.state.showComboLocal  }
                                }}
                                inputProps={{
                                  autoComplete: "local",
                                  name: "local"
                                }}
                                multiple={false}
                                data={this.state.locales}
                                component={CustomSelect}
                                validate={[required]}
                                valor={documento.local}
                                getValue={this.getSelectValueLocal}
                                val="id"
                                txt="valor"
                                name="local"
                                id="local" />

                          <Field labelText="Nombre Local"
                            formControlProps={{
                              fullWidth: true,
                              maxLength: "10",
                              margin: "normal",
                              style: { marginTop: "0px", display: this.state.showNameLocal }
                            }}
                            onChange={(event) => {
                              this.updateName(event);
                            }}
                            inputProps={{
                              type: "text",
                              name: "nombreLocal",
                              min: "1",
                              maxLength: "250",
                              Visible: false,
                            }} 
                            component={CustomInput}
                            value={documento.nombreLocal}
                            maxLength="10"
                            visible={false}
                            name="nombreLocal"
                            id="nombreLocal" /> 
                          </GridItem>
                         <ClienteDialog 
                            toggleDialogCliente={this.toggleDialogCliente} 
                            toggleDialogClienteRegistro={this.toggleDialogClienteRegistro} 
                            showClienteDialog={showClienteDialog} 
                            classes={classes} 
                            documento={documento}  />  
                      </GridContainer>
                    </GridItem>

                   
                      <GridItem xs={12} sm={12} md={12} style={{ display: this.state.showBusquedaCliente }}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                          <Field labelText="Celular"
                              formControlProps={{
                                fullWidth: true,
                                margin: "normal",
                                style: { marginTop: "0px" }
                              }}
                              onChange={(event) => {
                                this.updateName(event);
                              }}
                              inputProps={{
                                name: "celular"
                              }}
                              validate={[required]}
                              component={CustomInput}
                              value={documento.celular} 
                              name="celular"
                              id="celular" />
                            
                          </GridItem> 
                          <GridItem xs={12} sm={12} md={4}>
                          <Field labelText="Telefono"
                              formControlProps={{
                                fullWidth: true,
                                disabled: false,
                                margin: "normal",
                                style: { marginTop: "0px" }
                              }}
                              onChange={(event) => {
                                this.updateName(event);
                              }}
                              inputProps={{
                                name: "telefono"
                              }}
                              
                              component={CustomInput}
                              value={documento.telefono} 
                              name="telefono"
                              id="telefono" />
                          </GridItem>   
                          <GridItem xs={12} sm={12} md={4}>
                             <Field labelText="Email"
                              formControlProps={{
                                fullWidth: true,
                                margin: "normal",
                                style: { marginTop: "0px" }
                              }}
                              onChange={(event) => {
                                this.updateName(event);
                              }}
                              inputProps={{
                                type: "email",
                                name: "email",
                                min: "1"
                              }}
                              
                              component={CustomInput}
                              value={documento.email}
                              name="email"
                              id="email" />
                          </GridItem> 
                        </GridContainer>
                      </GridItem>


                      <GridItem xs={12} sm={12} md={12} style={{ display: this.state.showBusquedaCliente }}>
                        <GridContainer>

                          <GridItem xs={12} sm={12} md={4}>
                         
                          <Field labelText="Distrito"
                                formControlProps={{
                                  fullWidth: true,
                                  margin: "normal",
                                  style: { marginTop: "0px" }
                                }}
                                
                                inputProps={{
                                  autoComplete: "clieUbigeo",
                                  name: "clieUbigeo"
                                }}
                                multiple={false}
                                data={this.state.distritos}
                                component={CustomSelect}
                                validate={[required]}
                                valor={documento.clieUbigeo}
                                getValue={this.getSelectValue}
                                val="id"
                                txt="valor"
                                name="clieUbigeo"
                                id="clieUbigeo" /> 
                          </GridItem>
                          
                          
                          <GridItem xs={12} sm={12} md={4}>
                            <Field labelText="Dirección"
                              formControlProps={{
                                fullWidth: true,
                                margin: "normal",
                                style: { marginTop: "0px" }
                              }}
                              onChange={(event) => {
                                this.updateName(event);
                              }}
                              inputProps={{
                                name: "direccion"
                              }}
                              validate={[required]}
                              component={CustomInput}
                              value={documento.direccion} 
                              name="direccion"
                              id="direccion" />
             
                          </GridItem>

                          <GridItem xs={12} sm={12} md={4}>
                            <Field labelText="Referencia"
                              formControlProps={{
                                fullWidth: true,
                                margin: "normal",
                                style: { marginTop: "0px" }
                              }}
                              onChange={(event) => {
                                this.updateName(event);
                              }}
                              inputProps={{
                                name: "referencia"
                              }} 
                              component={CustomInput}
                              value={documento.referencia} 
                              name="referencia"
                              id="referencia" /> 
                          </GridItem> 
                        </GridContainer>
                      </GridItem> 

                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={this.guardarCliente}
                          >Guardar</Button>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={this.limpiarForm}
                          >Limpiar</Button>

                        </GridItem>
                      </GridContainer>
                    </GridItem>

              </GridContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {/* <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary>
            <Typography className={classes.title} variant="h1"> Equipos</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} >
                  <GridContainer> 
                    <GridItem xs={12} sm={12} md={12}>
                      <Grid onRef={ref => (this.Grid = ref)}  usuario={this.state.usuario} estados={this.state.estados} repuestos={repuestos}/> 
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel> */}

        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary>
            <Typography className={classes.title} variant="h1"> Detalle de Servicio</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} >
                  <GridContainer> 
                    <GridItem xs={12} sm={12} md={3}>
                      <Field labelText="Artefacto"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          autoComplete: "artefacto",
                          name: "artefacto"
                        }}
                        multiple={false}
                        data={this.state.artefactos}
                        component={CustomAutocomplete}
                        valor={documento.artefacto}
                        getValue={this.getSelectValue}
                        val="id"
                        validate={[required]}
                        txt="valor"
                        name="artefacto"
                        id="artefacto" />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
             
                         <Field labelText="Modelo"
                            formControlProps={{
                              fullWidth: true,
                              margin: "normal",
                              style: { marginTop: "0px" }
                            }}
                            inputProps={{
                              type: "text",
                              name: "modelo",
                              min: "1"
                            }} 
                            component={CustomInput}
                            value={documento.modelo}
                            name="modelo"
                            id="modelo" />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
             
                         <Field labelText="Serie"
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            style: { marginTop: "0px" }
                          }}
                          inputProps={{
                            type: "text",
                            name: "serie",
                            min: "1"
                          }} 
                          component={CustomInput}
                          value={documento.serie}
                          name="serie"
                          id="serie" />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                         <Field labelText="Marca"
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            style: { marginTop: "0px" }
                          }}
                          inputProps={{
                            autoComplete: "marca",
                            name: "marca"
                          }}
                          multiple={false}
                          data={this.state.marcas}
                          component={CustomAutocomplete}
                          valor={documento.marca}
                          getValue={this.getSelectValue}
                          validate={[required]}
                          val="id"
                          txt="valor"
                          name="marca"
                          id="marca" />
                    </GridItem>

                  </GridContainer>
                </GridItem>



                <GridItem xs={12} sm={12} md={12} >
                  <GridContainer> 
                    <GridItem xs={12} sm={12} md={3}>
                      <Field labelText="Tipo de Servicio"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          autoComplete: "tipoServicio",
                          name: "tipoServicio"
                        }}
                        multiple={false}
                        data={this.state.tipoServicios}
                        component={CustomSelect}
                        valor={documento.tipoServicio}
                        getValue={this.getSelectValue}
                        validate={[required]}
                        val="id"
                        txt="valor"
                        name="tipoServicio"
                        id="tipoServicio" />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
             
                         <Field labelText="Defecto"
                            formControlProps={{
                              fullWidth: true,
                              margin: "normal",
                              style: { marginTop: "0px" }
                            }}
                            inputProps={{
                              type: "motivo",
                              name: "motivo",
                              min: "1"
                            }}
                            validate={[required]}
                            component={CustomInput}
                            value={documento.motivo}
                            name="motivo"
                            id="motivo" /> 
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
             
                         <Field labelText="Observación"
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            style: { marginTop: "0px" }
                          }}
                          inputProps={{
                            type: "observacion",
                            name: "observacion",
                            rows: 9,
                            min: "1"
                          }} 
                          component={CustomInput}
                          value={documento.observacion}
                          name="observacion"
                          id="observacion" /> 
                    </GridItem> 
                  </GridContainer>
                </GridItem> 

                <GridItem xs={12} sm={12} md={12} >
                  <GridContainer> 
                    <GridItem xs={12} sm={12} md={3}>
                      <Field labelText="Número de O/S"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          type: "text",
                          name: "numeroservicio" 
                        }}                    
                        component={CustomInput}
                        value={documento.numeroservicio}
                        name="numeroservicio"
                        id="numeroservicio" />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                         <CustomInput labelText="Fecha Atención"
                                formControlProps={{
                                  fullWidth: true,
                                  margin: "normal",
                                  style: { marginTop: "0px" }
                                }}
                                inputProps={{
                                  autoComplete: "docuFechaIni",
                                  name: "docuFechaIni",
                                  type: "date",
                                  //defaultValue: fecdefault,
                                  onChange: this.setFechaIni,
                                  value:  documento.docuFechaIni,
                                  format: 'DD-MM-YYYY'
                                }}
                               // validate={[ required ]}
                                meta={{ touched: true, error: errorFecha, warning: false }}
                                //value={documento.docuFechaIni}
                                //name="docuFechaIni"
                               // id="docuFechaIni"
                                 />
                                 <Field
                      formControlProps={{
                        fullWidth: false,
                        margin: "normal",
                        style: { marginTop: "0px" }
                      }}
                      inputProps={{
                        type: "hidden",
                        name: "hFechaInicio",
                        min: "1"
                      }}
                      component={CustomInput}
                      value={documento.docuFechaIni}
                      name="hFechaInicio"
                      id="hFechaInicio" /> 
                    </GridItem>  


                    <GridItem xs={12} sm={12} md={3}>
                      <Field labelText="Tienda"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          autoComplete: "tienda",
                          name: "tienda"
                        }}
                        multiple={false}
                        data={this.state.tiendas}
                        component={CustomSelect}
                        valor={documento.tienda}
                        getValue={this.getSelectValue}
                        validate={[requiredTienda]}
                        val="id"
                        txt="valor"
                        name="tienda"
                        id="tienda" />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <Field labelText="Tipo de Trabajo"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          autoComplete: "tipoTrabajo",
                          name: "tipoTrabajo"
                        }}
                        multiple={false}
                        data={this.state.tipsTrabajo}
                        component={CustomSelect}
                        valor={documento.tipoTrabajo}
                        getValue={this.getSelectValue}
                        validate={[required]}
                        val="id"
                        txt="valor"
                        name="tipoTrabajo"
                        id="tipoTrabajo" />
                    </GridItem>


                    
                  </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} >
                  <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <Field labelText="Area Asignada"
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            style: { marginTop: "0px" }
                          }}
                          inputProps={{
                            autoComplete: "area",
                            name: "area"
                          }}
                          multiple={false}
                          data={this.state.areas}
                          component={CustomAutocomplete}
                          valor={documento.area}
                          getValue={this.getSelectValue}
                          validate={[required]}
                          val="id"
                          txt="valor"
                          name="area"
                          id="area" />
                          
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <Field labelText="Documentos Caja"
                            formControlProps={{
                              fullWidth: true,
                              margin: "normal",
                              style: { marginTop: "0px" }
                            }}
                            inputProps={{
                              type: "text",
                              name: "doccaja" 
                            }}                    
                            component={CustomInput}
                            value={documento.doccaja}
                            name="doccaja"
                            id="doccaja" />
                        </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput labelText="Fecha de Compra"
                                  formControlProps={{
                                    fullWidth: true,
                                    margin: "normal",
                                    style: { marginTop: "0px" }
                                  }}
                                  inputProps={{
                                    autoComplete: "docuFechaVenta",
                                    name: "docuFechaVenta",
                                    type: "date",
                                    //defaultValue: fecdefault,
                                    onChange: this.setFechaVenta,
                                    value:  documento.docuFechaVenta,
                                    format: 'DD-MM-YYYY'
                                  }}
                                 // validate={[ required ]}
                                  meta={{ touched: true, error: errorFecha, warning: false }}
                                  //value={documento.docuFechaIni}
                                  //name="docuFechaIni"
                                 // id="docuFechaIni"
                                   />
                                <Field
                                formControlProps={{
                                  fullWidth: false,
                                  margin: "normal",
                                  style: { marginTop: "0px" }
                                }}
                                inputProps={{
                                  type: "hidden",
                                  name: "hFechaFin",
                                  min: "1"
                                }}
                                component={CustomInput}
                                value={documento.docuFechaVenta}
                                name="hFechaFin"
                                id="hFechaFin" />
                    </GridItem>

                  </GridContainer>
                </GridItem>


              </GridContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel>


        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary>
            <Typography className={classes.title} variant="h1"> Costos</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} >
                  <GridContainer> 
                      <GridCosto onRef={ref => (this.GridCosto = ref)}  usuario={this.state.usuario} estados={this.state.estados} repuestos={repuestos}/> 
                  </GridContainer>
                </GridItem> 
              </GridContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel> 

        <div style={{ textAlign: "right" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >REGISTRAR </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={this.limpiarForm}
          >Limpiar</Button>
        </div>
      </form>
    );
  }
}
RegDomicilioForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
RegDomicilioForm = reduxForm({
  form: 'regDomicilioForm',
  validate
})(RegDomicilioForm)

const selector = formValueSelector('regDomicilioForm')
const mapState = (state) => ({
  loading: state.load.loading,
  dscversion: selector(state, 'dscversion')
});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(domicilioActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    usuarioActions: bindActionCreators(usuarioActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(RegDomicilioForm));