import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 
import * as notificationActions from "actions/NotificationActions";
import * as loadActions from "actions/LoadActions";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab'; 

import tabStyle from "styles/jss/app/components/tabStyle.jsx";
import Form from "components/Form/MntPasajeForm";
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants'; 
import * as documentoActions from "actions/DocumentoActions";
function TabContainer(props) {
  return (
    <div style={{ padding: 8 * 3 }}>
      {props.children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class PasajeDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueTab: 0,
      gth: {},
      showRepuestoDialog: '',

    };
  }

  componentDidMount() { 
    this.setState({ usuario: this.props.usuario });
  }
  handleChangeTab = (event, valueTab) => {
    this.setState({ valueTab });
  };

  guardaRepuesto = (data) => {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions; 
    console.log("data a guardar de pasajes", data ); 
    data.operacion = "updatePasajeAlm"
    add();
    this.props.actions.guardarPasajeById(data).then(response => {
    if (response.status === 200) {
        show(MENSAJE.IND_MOSTRAR_MENSAJE, response.message, TIPO_MENSAJE.SUCCESS);
        this.closeDialogRegistro();
    } else{
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
    }
    remove();
    })

  }

  closeDialogRegistro = () => {
    this.setState({ valueTab: 0 });
    this.props.toggleDialogRepuestoRegistro(this.props.currentPage,this.props.pageSize );
  }

  closeDialog = () => {
    this.setState({ valueTab: 0 });
    this.props.toggleDialogRepuesto();
  }

  render() {
    const { classes, showRepuestoDialog, estados, perfiles, repuestos, documento } = this.props;
    const { valueTab, gth, usuario} = this.state;

    return (
      <Dialog
        open={showRepuestoDialog}
        onClose={this.closeDialog}
        fullWidth={false}
        maxWidth={false}
      >
        <DialogContent>
          <AppBar position="static" color="default">
            <Tabs
              value={valueTab}
              onChange={this.handleChangeTab}
              indicatorColor="secondary"
              classes={{ root: classes.tabsRoot }}
              fullWidth
            >
              <Tab
                classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                label={"Detalle de la Ficha Domicilio:   "+documento.idficha + " - Creado Por: " + documento.user} /> 
            </Tabs>
          </AppBar>
          <Form onRef={ref => (this.Form = ref)} onSubmit={this.guardaRepuesto} classes={classes} usuario={usuario} estados={estados} perfiles={perfiles} repuestos={repuestos} documento={documento} toggleDialogRepuesto={this.closeDialog} />
        </DialogContent>
      </Dialog>
    )
  }
}

const mapState = (state) => ({
  user: state.session.user,
  loading: state.load.loading
});

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(documentoActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

PasajeDialog = withStyles(tabStyle)(PasajeDialog);
export default withRouter(connect(mapState, mapDispatch)(PasajeDialog));