import React from "react";
// @material-ui/core components
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';

import documentoStyle from "styles/jss/app/views/documentoStyle.jsx";
import * as documentoActions from "actions/DocumentoActions";
import * as notificationActions from "actions/NotificationActions";
//import * as sessionActions from "actions/SessionActions";
import * as loadActions from "actions/LoadActions";
import Form from "components/Form/RepDocumentoForm";
import Grid from "components/Grid/BsqDocumentoGrid"; 

class ReporteDocumento extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.reporteDocumento = this.reporteDocumento.bind(this);
    this.state = {
      documento: {
        docuNumero: '',
        tipoDocumento: '',
        clieNumero: '',  //es ruc
        docuFechaIni: '',
        docuFechaFin: '',
        estado: '',
        clieNombre: '', //razon Social
        cdr: '',
        compania: '',
        documentType: '',
        typeGraph: '',
        tipReporte: '',
        producto: ''
      }
    }
  }

  componentDidMount() {
  }

  reporteDocumento = (data) => {
   // console.log("datos enviados");
   // console.log(data);
    var {documento} = this.state;
    documento.docuNumero = data.txtnumdoc?data.txtnumdoc:'';
    documento.tipoDocumento = data.tipoDocumento?data.tipoDocumento:[];
    documento.clieNumero = data.txtruc?data.txtruc:'';
    documento.clieNombre = data.txtrazsoc?data.txtrazsoc:'';
    documento.estado = data.estado?data.estado:'';
    documento.cdr = data.cdr?data.cdr:'';
    documento.docuFechaIni = data.hFechaInicio?data.hFechaInicio:'';
    documento.docuFechaFin = data.hFechaFin?data.hFechaFin:'';
    documento.documentTypeClient = data.documentTypeClient?data.documentTypeClient:'';
    documento.compania = data.compania?data.compania:'';
    documento.producto = data.producto?data.producto:'';
    documento.tipReporte = data.tipReporte?data.tipReporte:'';
    documento.modelo = data.modelo?data.modelo:'';
    documento.serie = data.serie?data.serie:'';
    documento.marca = data.marca?data.marca:'';
    documento.area = data.area?data.area:'';
    documento.tipoServicio = data.tipoServicio?data.tipoServicio:'';

    documento.clieTipDoc = data.clieTipDoc?data.clieTipDoc:'';
    documento.clieNumero = data.clieNumero?data.clieNumero:'';
    documento.numeroservicio = data.numeroservicio?data.numeroservicio:'';
     documento.numeroFicha = data.numeroFicha?data.numeroFicha:'';
      documento.telefono = data.telefono?data.telefono:'';
    
    this.setState({documento: documento});    

    if (localStorage.getItem("tkn") == "serfac-ilumi"){ 
      documento.area = 15; // siempre para ilumi 15
    }

    if (localStorage.getItem("tkn") == "serfac-indurama"){ 
      console.log(data.area?data.area:"18,19");
      documento.area = data.area?data.area:"18,19";
      console.log("datos enviados documento", documento ); 

    }
    
    this.Grid.buscarDocumentos();
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <SearchIcon className={classes.titleIcon} />
            <Typography className={classes.title} variant="h4"> Criterio de Búsqueda</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Form onSubmit={this.reporteDocumento} classes={classes} reporteDocumento={this.reporteDocumento} documento={this.state.documento} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <ListIcon className={classes.titleIcon} />
            <Typography className={classes.title} variant="h4"> Lista de Fichas</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid documento={this.state.documento} onRef={ref => (this.Grid = ref)}/>
          </ExpansionPanelDetails>
        </ExpansionPanel>

      </div>
    )
  }
}
const mapState = (state) => ({
  loading: state.load.loading
});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(documentoActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

ReporteDocumento = withStyles(documentoStyle)(ReporteDocumento);
export default withRouter(connect(mapState, mapDispatch)(ReporteDocumento));