import React, { Component } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Typography, Button, ListItem, withStyles } from '@material-ui/core';
import AWS from 'aws-sdk'
import FileAction from "actions/FileAction";
import config from "commons/config/AWSConfig";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

export default class UploadImages extends Component {
  constructor(props) {
    super(props);
    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);

    this.state = {
      currentFile: undefined,
      previewImage: undefined,
      progress: 0,

      message: "",
      isError: false,
      imageInfos: [],
      imagesUpload:[]
    };
  }

  componentDidMount() {
    this.props.onRef(this); 
    
  }

  listarImagenesPorId(documento){
    console.log("aaaaa");
    if (documento.id > 0){
      FileAction.getFiles( documento, (event) => { }) 
        .then((files) => { 
          this.setState({
            imageInfos: files.data.data,
          });
        })
        .catch((err) => {
          console.log("Error al listar las imagenes", err);
          this.setState({
            progress: 0,
            message: "No se han cargado Imagenes",
            currentFile: undefined,
            isError: true
          });
        });
    }
  }

  selectFile(event) {
    try{ 
      console.log("event filessss:",event.target.files);
      this.setState({
        currentFile: event.target.files[0],
        previewImage: URL.createObjectURL(event.target.files[0]),
        progress: 0,
        message: ""
      });
      var lstImagenes = [];
      for(var i= 0; i < event.target.files.length ; i++) {
        lstImagenes.push(
          {
            currentFile: event.target.files[i],
            previewImage: URL.createObjectURL(event.target.files[i]),
            progress: 0,
            message: ""
          }
        );
      }
      this.setState({imagesUpload: lstImagenes});

    }catch(err){
      console.log("error", err);
    } 
  }

  upload() {
    const { 
      imagesUpload 
    } = this.state;
    for(var i= 0; i < imagesUpload.length ; i++) { 
        this.setState({
          progress: 0
        });

        let documento = this.props.documento
       // let file = this.state.currentFile
        let file = imagesUpload[i].currentFile
        
        AWS.config.update({
          accessKeyId: config.accessKey,
          secretAccessKey: config.secretKey,
        })
      
        const myBucket = new AWS.S3({
            params: { Bucket: config.bucket},
          region: config.REGION,
        })

        const params = {
          ACL: 'public-read',
          Body: file, 
          Bucket: config.bucket,
          Key: "IMAGES/"+documento.fechaIngreso.substr(0,10).replace(/-/g,"/") + "/"+ documento.id + "/" + file.name
        };

        myBucket.putObject(params)
        .on('httpUploadProgress', (evt) => {
          this.setState({
            progress: Math.round((100 * evt.loaded) / evt.total),
          });
        })
        .send((err) => {
            if (err){
              console.log(err) 
              this.setState({
                message: "Ocurrio un error al subir",
                isError: true
              });
            } else{
              this.setState({
                message: "Subido Correctamente",
                isError: false
              });
              this.state.currentFile = null;
      
              FileAction.getFiles( documento, (event) => { }) 
              .then((files) => { 
                this.setState({
                  imageInfos: files.data.data,
                });
              })
              .catch((err) => {
                console.log("Error al listar las imagenes", err);
                this.setState({
                  progress: 0,
                  message: "No se han cargado Imagenes",
                  currentFile: undefined,
                  isError: true
                });
              }); 
            }
            
        }) 
    }
    this.state.imagesUpload = [];



    /*
    console.log("subiendo imaen ", this.state.currentFile);
    FileAction.upload(this.state.currentFile, this.props.documento, (event) => {
      this.setState({
        progress: Math.round((100 * event.loaded) / event.total),
      });
    })
      .then((response) => {
        this.setState({
          message: response.data.message,
          isError: false
        });
        return FileAction.getFiles( this.props.documento);
      })
      .then((files) => { 
        this.setState({
          imageInfos: files.data.data,
        });
      })
      .catch((err) => {
        console.log("no se pudo cargar la iamgen ", err);
        this.setState({
          progress: 0,
          message: "No se pudo cargar el archivo, muy pesado, Maximo 4.5MB!",
          currentFile: undefined,
          isError: true
        });
      });*/
  }

  deleteImage = (image) => {
    let documento = this.props.documento
    console.log("eliminando Imagen", image);

    FileAction.deteleFile( image.key, (event) => { })  .then((files) => { 
          FileAction.getFiles( documento, (event) => { }) 
          .then((files) => { 
            this.setState({
              imageInfos: files.data.data,
            });
          })
          .catch((err) => {
            console.log("Error al listar las imagenes", err);
            this.setState({
              progress: 0,
              message: "No se han cargado Imagenes",
              currentFile: undefined,
              isError: true
            });
          }); 
        })
    .catch((err) => {
          console.log("Error al listar las imagenes", err);
          this.setState({
            progress: 0,
            message: "No se han cargado Imagenes",
            currentFile: undefined,
            isError: true
          });
    }); 
  }


  render() {
    const {
      currentFile,
      previewImage,
      progress,
      message,
      imageInfos,
      imagesUpload,
      isError
    } = this.state;
    
    return (
      <div className="mg20">
        {currentFile && (
          
              <BorderLinearProgress variant="determinate" value={progress} /> 
 
         )
        }
        <label htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: 'none' }}
            type="file"
            multiple
            accept="image/*"
            onChange={this.selectFile} />
          <Button
            className="btn-choose"
            variant="outlined"
            component="span" >
                 Cargar Imagen
          </Button>
        </label> 
        <Button
          className="btn-upload"
          color="primary"
          variant="contained"
          component="span"
          disabled={!currentFile}
          onClick={this.upload}>
          Subir
        </Button>
        {imagesUpload &&
            imagesUpload.map((image, index) => ( 
              <ListItem 
              key={index}>
              <img id={index} className="preview my20" src={image.previewImage} alt="" height="50px" width="50" /> {image.currentFile.name}
              </ListItem>
        ))}
        {message && (
          <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
            {message}
          </Typography>
        )}

        <Typography variant="h6" className="list-header">
          Imagenes Cargadas
          </Typography>
        <ul className="list-group">
          {imageInfos &&
            imageInfos.map((image, index) => (
              <ListItem
                divider
                key={index}>
                <img src={image.url} alt={image.name} target="_blank" height="80px" className="mr20" width="100" />
                <a href={image.url}>{image.name}</a>
                <IconButton color="primary" aria-label="Eliminar Imagen"   onClick={this.deleteImage.bind(this, image)} title="Eliminar Imagen" >
                      <DeleteIcon/>
                 </IconButton>
              </ListItem>
            ))}
        </ul>
      </div >
    );
  }
}