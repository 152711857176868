import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Snack from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import snackbarContentStyle from "styles/jss/material-dashboard-react/components/snackbarContentStyle.jsx";
import InfoIcon from "@material-ui/icons/Info";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

function SnackbarAlert({ ...props }) {
  const { classes, message, color, place, open } = props;
  var action = [];
  const icons = {
    "info": <InfoIcon className={classes.icon} />,
    "danger": <ErrorIcon className={classes.icon} />,
    "warning": <WarningIcon className={classes.icon} />,
    "success": <CheckCircleIcon className={classes.icon} />,
  }
  action = [
    <IconButton
      className={classes.iconButton}
      key="close"
      aria-label="Close"
      color="inherit"
      onClick={() => props.closeNotification()}
    >
      <Close className={classes.close} />
    </IconButton>
  ];
  return (
    <Snack
      anchorOrigin={{
        vertical: place.indexOf("t") === -1 ? "bottom" : "top",
        horizontal:
        place.indexOf("l") !== -1
          ? "left"
          : place.indexOf("c") !== -1 ? "center" : "right"
      }}
      open={open}
      message={
        <div>
          {icons[color]}<span className={classes.iconMessage} dangerouslySetInnerHTML={{ __html: message }}></span>
        </div>
      }
      action={action}
      onClose={props.closeNotification}
      autoHideDuration={color === "danger" ? null : 4000}
      ContentProps={{
        classes: {
          root: classes.root + " " + classes[color],
          message: classes.message
        }
      }}
    />
  );
}

SnackbarAlert.propTypes = {
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf(["info", "success", "warning", "danger"]),
  place: PropTypes.oneOf(["tl", "tr", "tc", "br", "bl", "bc"]),
  open: PropTypes.bool
};

export default withStyles(snackbarContentStyle)(SnackbarAlert);
