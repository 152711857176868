export const PATH_APP = {    
    BASE: '/factelec',
    LOGIN: '/login',
    HOME1: '/',
    HOME2: '/home',
    CUESTIONARIO: '/documento',
    //DOCUMENTO_CONSULTA: '/consulta',
}

export const PATH_USUARIO_API = {
    URL_METHOD_GET: '/usuario'
}

export const PATH_OPCION_API = {
    URL_METHOD_GET: '/opcion'
}

export const PATH_DOCUMENTO_API = {
    URL_METHOD: '/documento',
    URL_METHOD_PARAMETRO: '/parametro',
    URL_METHOD_SEGUIMIENTO: '/documento/seguimiento',
    URL_METHOD_DESCARGA: '/documento/descarga',
    URL_METHOD_DESCARGA_EXCEL: '/documento/descarga/excel',
    URL_METHOD_REPROCESAR: '/documento/reprocesar',
    URL_METHOD_REPROCESAR_ERP: '/documento/reprocesarerp',
    URL_METHOD_REPORTE_INDICADOR: '/documento/reporte/indicador',
}

export const PATH_PARAMETRO_API = {
    URL_METHOD: '/parametro',
    URL_LISTA: '/parametro/listar'
}

export const PATH_SEGURIDAD_API = {    
    URL_METHOD: '/seguridad',
    URL_METHOD_OPCION: '/seguridad/opcion',
    URL_METHOD_PERFIL: '/seguridad/perfil',
    URL_METHOD_USUARIO: '/usuario'
}

export const PATH_SERFAC_API = {
    URL_METHOD: '/serfac',
    URL_METHOD_FICHA: '/serfac/ficha',
    URL_METHOD_DESCARGA_EXCEL: '/serfac/ficha/excel',
    URL_METHOD_REPORTE: '/serfac/ficha/reporte',
    URL_METHOD_LOGIN: '/serfac/auth',
    URL_METHOD_UPLOAD: '/serfac/upload',
}