import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CustomAutocomplete from "components/CustomInput/CustomAutocomplete";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm } from 'redux-form';

import * as loadActions from "actions/LoadActions";
import * as notificationActions from "actions/NotificationActions";

import { integer } from 'utils/FormValidation';
import { email, required } from 'utils/FormValidation';

import UsuarioDialog from "components/Dialog/UsuarioDialog";

class UsuarioForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
       usuario: {
           ideusuario: '',
           dscnomape:'',
           stsresponsable:'',
           email:'',
           ideperfil: '',
           vertodogth: '',
           stsusuario: ''
       },
       perfiles: [],
       showUsuarioDialog: false,
    }
  }
  componentDidMount() {
  }

  getSelectValue = (valor, name) => {
    var usuario = this.state.usuario
    usuario[name] = valor;
    this.setState({ usuario: usuario });
  }
  limpiarForm = () => {
    this.props.reset();
    var usuario = { 
      ideusuario: '',
      dscnomape:'',
      stsresponsable:'',
      email:'',
      ideperfil: '',
      vertodogth: '',
      stsusuario: '' };

    this.setState({ usuario: usuario });
  }


  toggleDialogUsuario = (usuarioVal) => {
    var { usuario } = this.state;
    if (typeof usuarioVal !== "undefined") {
      usuario = Object.assign(usuario, usuarioVal);
    } else {
      usuario.ideusuario = '';
      usuario.dscnomape = '';
      usuario.stsresponsable = '';
      usuario.email = '';
      usuario.ideperfil = '';
      usuario.vertodogth = '';
      usuario.stsusuario = '';
    }
    this.setState({ showUsuarioDialog: !this.state.showUsuarioDialog })
  };

  render() {
    const { handleSubmit, classes, estados, perfiles } = this.props;
    const { usuario, showUsuarioDialog } = this.state;

    return (
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Field labelText="Nombres y Apellidos"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "dscnomape",
                    type: "text"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 100)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={usuario.dscnomape}
                  name="dscnomape"
                  id="dscnomape" />
              </GridItem>            
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Field labelText="Correo"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "email"
                  }}
                  component={CustomInput}
                  value={usuario.email}
                  validate={[email]}
                  name="email"
                  id="email" />
              </GridItem>                
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Field labelText="Perfil"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "ideperfil",
                    name: "ideperfil"
                  }}
                  multiple={false}
                  data={perfiles}
                  component={CustomAutocomplete}
                  valor={usuario.ideperfil}
                  getValue={this.getSelectValue}
                  val="ideperfil"
                  txt="nomperfil"
                  name="ideperfil"
                  id="ideperfil" />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>

                <Field labelText="Estado"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "stsusuario",
                    name: "stsusuario"
                  }}
                  multiple={false}
                  data={estados}
                  component={CustomAutocomplete}
                  valor={usuario.stsusuario}
                  getValue={this.getSelectValue}
                  val="stsusuario"
                  txt="dscstsusuario"
                  name="stsusuario"
                  id="stsusuario" />
              </GridItem>
            </GridContainer>
            <UsuarioDialog toggleDialogUsuario={this.toggleDialogUsuario} showUsuarioDialog={showUsuarioDialog} usuario={usuario} classes={classes}
              estados={estados} perfiles={perfiles} />
          </GridItem>
        </GridContainer>
        <div style={{ textAlign: "right" }}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => this.toggleDialogUsuario()}
          >Nuevo</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >Buscar</Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={this.limpiarForm}
          >Limpiar</Button>
        </div>
      </form>
    );
  }
}

UsuarioForm = reduxForm({
  form: 'usuarioForm'
})(UsuarioForm)
const mapState = (state) => ({
  loading: state.load.loading
});
const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(UsuarioForm));