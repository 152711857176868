import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CustomAutocomplete from "components/CustomInput/CustomAutocomplete";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm } from 'redux-form';

import * as loadActions from "actions/LoadActions";
import * as notificationActions from "actions/NotificationActions";
import * as formActions from "actions/FormActions";
import { email, required} from 'utils/FormValidation';

class MntPerfilForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      perfil:  { 
        nomperfil:'',
        dscperfil:'',
        stsperfil:'' }
    }
  }

  componentDidMount() {
  }

  getSelectValue = (valor, name) => {
    var perfil = this.state.perfil
    perfil[name] = valor;
    this.setState({ perfil: perfil });
  }

  render() {
    const { handleSubmit, classes, estados, toggleDialogPerfil } = this.props;
    const { perfil } = this.state;
    return (
      <form onSubmit={handleSubmit} style={{ width: "100%", margin: 8 * 3 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <Field labelText="Nombre de perfil"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "nomperfil"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 20)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={perfil.nomperfil}
                  validate={[required]}
                  name="nomperfil"
                  id="nomperfil" />
              </GridItem>   
              <GridItem xs={12} sm={12} md={5}>
                <Field labelText="Descripción del perfil"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "dscperfil"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 250)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={perfil.dscperfil}
                  validate={[required]}
                  name="dscperfil"
                  id="dscperfil" />
              </GridItem>   
              <GridItem xs={12} sm={12} md={2}>
                <Field labelText="Estado"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "stsusuario",
                    name: "stsusuario"
                  }}
                  multiple={false}
                  data={estados}
                  component={CustomSelect}
                  valor={perfil.stsperfil}
                  getValue={this.getSelectValue}
                  validate={[required]}
                  val="stsperfil"
                  txt="dscstsperfil"
                  name="stsperfil"
                  id="stsperfil" />
              </GridItem>                                    
            </GridContainer>
          </GridItem>
        </GridContainer>        
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
              {/* <div style={{ textAlign: "right" }}> */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >Guardar</Button>
              <Button onClick={() => toggleDialogPerfil()}>Cerrar</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </form>
    );
  }
}

MntPerfilForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

MntPerfilForm = reduxForm({
  form: 'mntPerfilForm',
  enableReinitialize: true
})(MntPerfilForm)

const mapState = (state) => ({
  loading: state.load.loading,
  initialValues: state.formApp.data
});
const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(MntPerfilForm));
