import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CustomAutocomplete from "components/CustomInput/CustomAutocomplete";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm } from 'redux-form';
import CustomTextArea from "components/CustomInput/CustomTextArea";
import * as loadActions from "actions/LoadActions";
import * as notificationActions from "actions/NotificationActions";
import * as formActions from "actions/FormActions";
import { email, required} from 'utils/FormValidation';
import { ESTADO } from 'utils/Constants';
import { DATA_DISTRITOS, DATA_TIPO_DOCUMENTO } from 'utils/GeneralData';


class MntClienteForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
         documento:  { 
            vertodogth: '',
            stsusuario: '',
            nombre: '',
            id: '',
            clieId: '', 
            clieNumero: '',
            email: '',
            telefono: '',
            clieUbigeo: '',
            referencia: '',
            celular: '',
            clieTipDoc: '' 
          },
           
          distritos: [],
          tipDocuments: [],
      }
      
  }

  componentDidMount() {
      this.props.onRef(this);
      this.state.distritos = JSON.parse(localStorage.getItem("ubigeos_serfac")); //DATA_DISTRITOS;
      this.state.tipDocuments = DATA_TIPO_DOCUMENTO;
      const {   documento } = this.props;
      console.log("datos que llegaron", documento);
       
      this.props.change('clieNumero', documento.numdoccliente)
      this.props.change('clieId', documento.clienteid) 
       
      
      this.state.documento.clieUbigeo = documento.ubigeo;
       
  }

 
  getSelectValue = (valor, name) => {
    var documento = this.state.documento
    documento[name] = valor;
    this.setState({ documento: documento });
  }

  render() {
    const { handleSubmit, classes, estados, toggleDialogCliente } = this.props;
    const { documento , distritos} = this.state;

    var perfilesactivos = [];
    

    return (
      <form onSubmit={handleSubmit} style={{ width: "100%", margin: 8 * 3 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>

              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Tipo Documento"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "clieTipDoc",
                    name: "clieTipDoc"
                  }}
                  multiple={false}
                  data={this.state.tipDocuments}
                  component={CustomSelect}
                  valor={documento.clieTipDoc}
                  getValue={this.getSelectValue}
                  placeholder="Placeholder"
                  label="With placeholder"
                  val="id"
                  txt="valor"
                  name="clieTipDoc"
                  id="clieTipDoc" />
                {}
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
               <Field labelText="Número Documento"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "clieNumero",
                    name: "clieNumero",
                    min: "1"
                  }}
                  component={CustomInput}
                  validate={[required]}
                  value={documento.clieNumero}
                  name="clieNumero"
                  id="clieNumero" /> 
              </GridItem> 

              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Nombre del Cliente"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "nombre"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 50)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={documento.nombre} 
                  name="nombre"
                  id="nombre" />
              </GridItem> 
            </GridContainer>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Telefono"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "telefono"
                  }}
                  
                  component={CustomInput}
                  value={documento.telefono} 
                  name="telefono"
                  id="telefono" />
              </GridItem> 
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Celular"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "celular"
                  }}
                  
                  component={CustomInput}
                  value={documento.celular} 
                  name="celular"
                  id="celular" />
              </GridItem>   
              <GridItem xs={12} sm={12} md={4}>
                 <Field labelText="Email"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "email",
                    name: "email",
                    min: "1"
                  }}
                  
                  component={CustomInput}
                  value={documento.email}
                  name="email"
                  id="email" />
              </GridItem> 
            </GridContainer>
          </GridItem>


          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>

              <GridItem xs={12} sm={12} md={4}>
             
                   <Field labelText="Distrito"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      autoComplete: "clieUbigeo",
                      name: "clieUbigeo"
                    }}
                    multiple={false}
                    data={this.state.distritos}
                    component={CustomSelect}
                    validate={[required]}
                    valor={documento.clieUbigeo}
                    getValue={this.getSelectValue}
                    val="id"
                    txt="valor"
                    name="clieUbigeo"
                    id="clieUbigeo" /> 
              </GridItem>
              
              
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Dirección"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "direccion"
                  }}
                 
                  component={CustomInput}
                  value={documento.direccion} 
                  name="direccion"
                  id="direccion" />
 
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Referencia"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "referencia"
                  }}
                 
                  component={CustomInput}
                  value={documento.referencia} 
                  name="referencia"
                  id="referencia" /> 
              </GridItem>

                
            </GridContainer>
          </GridItem>
 

          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                {/* <div style={{ textAlign: "right" }}> */}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >Guardar</Button>
                <Button onClick={() => toggleDialogCliente()}>Cerrar</Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

MntClienteForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
MntClienteForm = reduxForm({
  form: 'mntClienteForm',
  enableReinitialize: true
})(MntClienteForm)
const mapState = (state) => ({
  loading: state.load.loading,
  initialValues: state.formApp.data
});
const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(MntClienteForm));
