import { getFormatDate, getRestarDias } from 'utils/Functions';
export const validateCriterioBusqueda = values => {
  const errors = {}
  var fechaActual = getFormatDate(new Date(),  'ymd', '-') 

 
  if (!values.hFechaInicio) {
    errors.hFechaInicio = 'Valor Requerido'
  } else if (values.hFechaInicio > values.hFechaFin) {
    errors.hFechaInicio = ' '
  } 

  if (!values.hFechaFin) {
    errors.hFechaFin = 'Valor Requerido'
  } else if (values.hFechaFin < values.hFechaInicio) {    
    errors.hFechaFin = ' '
  }else if (values.hFechaFin > fechaActual) {    
    errors.hFechaFin = 'La fecha fin no puede ser mayor a la fecha actual'
  }else if (values.hFechaInicio > fechaActual) {    
    errors.hFechaFin = 'La fecha inicio no puede ser mayor a la fecha actual'
  } 

  return errors
};

export const required = value => value ? undefined : 'Valor Requerido';