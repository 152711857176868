import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
//import MDSpinner from "react-md-spinner";
export default class Loader extends Component {

    timer = null;

    state = {
        completed: 0,
    };

    componentDidMount() {
        this.timer = setInterval(this.progress, 500);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    progress = () => {
        const { completed } = this.state;
        if (completed === 100) {
            this.setState({ completed: 0 });
        } else {
            const diff = Math.random() * 10;
            this.setState({ completed: Math.min(completed + diff, 100) });
        }
    };

    render() {
        var { loading } = this.props;
        return (
            /*<div id="myLoading" className="modalLoad" style={{ display: loading ? 'block' : 'none' }}>
                 <div className="logo-loader"><img className="preloader-img" src={imgLoader} alt="Rimac" /></div>
                 <div id="status" className="preloader-status"></div>
            </div>*/
            <div id="myLoading" className="modalLoad" style={{ display: loading ? 'block' : 'none' }}>
                <LinearProgress variant="determinate" color="secondary" value={this.state.completed} />
                {/* <MDSpinner size={100} duration={1500} borderSize={10} color1="#ff8cb3"
                    color2="#FF7C7C" color3="#FF5252" color4="#FC1919" className="spinerModalLoad" /> */}
            </div>
        );
    }
}