import React, { Component } from "react";
import { Router, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";

import PropTypes from "prop-types";
/*import moment from "moment";
import "moment/locale/es";*/
import { Auth } from "aws-amplify";

import { PATH_APP } from "commons/config/Path";
import PrivateRoute from 'services/routes/PrivateRoute';
import PublicRoute from 'services/routes/PublicRoute';
import Home from "containers/Home";
import Login from "containers/Login";

//moment.locale('es');

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }
  /*
  componentDidMount() {
    this.setState({ isAuthenticating: false });
  } */

  async componentDidMount() {
    console.log("aaaaaaaaaaaaaaaa");
    try {
      //if (await authUser()) {
      //if (await Auth.currentSession()) {
       // this.userHasAuthenticated(true);
      //}
      console.log("valor del token", localStorage.tkn)
      if (localStorage.tkn!=null &&  localStorage.tkn == "serfac-usuarios" ){
        this.userHasAuthenticated(true);
      }


    }
    catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = async authenticated => {
    //await authUser();
    this.setState({ isAuthenticated: true });
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };
    var { authenticated, checked } = this.props;
    
    console.log("valor del token", localStorage.tkn)
      if (localStorage.tkn!=null &&  ( localStorage.tkn == "serfac-usuarios" || localStorage.tkn == 'serfac-tecnicos' 
      || localStorage.tkn == 'serfac-ilumi' || localStorage.tkn == 'serfac-indurama' || localStorage.tkn == 'serfac-coordinadora') ){
        authenticated = true;
      }else{
        authenticated = false;
      }
    console.log("valor de authenticated" + authenticated);
    return (
      <Router history={this.props.hist}>
          {(checked && !this.state.isAuthenticating) &&
            <Switch> 
               <PublicRoute path={PATH_APP.LOGIN} component={Login} authenticated={authenticated} props={childProps} />
              <PrivateRoute path={PATH_APP.HOME1} component={Home} authenticated={authenticated} props={childProps} />
              <Redirect push to={authenticated ? PATH_APP.HOME1 : PATH_APP.LOGIN} />} 
 

          </Switch>
          }
      </Router>
    )
  }
}

const { bool } = PropTypes;

App.propTypes = {
  authenticated: bool.isRequired,
  checked: bool.isRequired
};

const mapState = ({ session }) => ({
  checked: session.checked,
  authenticated: session.authenticated,
  checkAuth: session.checkAuth
});

export default connect(mapState)(App);