import React from "react";
// @material-ui/core components
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';

import cuestionarioStyle from "styles/jss/app/views/cuestionarioStyle.jsx";
import * as usuarioActions from "actions/UsuarioActions";
import * as perfilActions from "actions/PerfilActions";
import * as notificationActions from "actions/NotificationActions";
import * as parametroActions from "actions/ParametroActions";
import * as loadActions from "actions/LoadActions";
import * as documentoActions from "actions/DocumentoActions";
import { TIPO_MENSAJE, MENSAJE, ESTADO } from 'utils/Constants';

import Form from "components/Form/UsuarioForm";
import Grid from "components/Grid/UsuarioGrid";

class Usuario extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.buscarUsuario = this.buscarUsuario.bind(this);
    this.state = {
      estados: [],
      perfiles: [],
      usuario: {
        ideusuario: '',
        dscnomape: '',
        email: '',
        ideperfil: '',
        vertodogth:'',
        stsusuario:''

      }
    }
  }

  componentDidMount() {
    this.buscarEstados();
    this.listarPerfil();
  }

  buscarEstados() {
    const { show } = this.props.notificationActions;
    this.props.documentoActions.listarParametrosFiltroDocumento('listparamconsult').then(response => {
      if (response.tipo !== TIPO_MENSAJE.SUCCESS) {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
      } else {
        if (response.data.length === 0) {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "No se encontraron los estados a mostrar.", TIPO_MENSAJE.WARNING);
        } else {
          console.log('estados');
          var todosestados = [];
          
          for (var i = 0; i < response.data.length; i++) {
            var estado;
            if (response.data[i].cod_grup_parm == '6') {
              estado = {
                stsusuario: response.data[i].val_parm_nume,
                dscstsusuario: response.data[i].nom_parm
              };
              todosestados.push(estado);
            }
          }
          this.setState({ estados: todosestados})         
        }
      }
    })
  }

  listarPerfil() {
    const { show } = this.props.notificationActions;
    this.props.perfilActions.listarPerfil().then(response => {
      if (response.tipo !== TIPO_MENSAJE.SUCCESS) {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
      } else {
        if (response.data.length === 0) {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "No se encontraron Responasbles a mostrar.", TIPO_MENSAJE.WARNING);
        } else {
          this.setState({ perfiles: response.data })
        }
      }
    })
  }

  buscarUsuario = (data) => {
    var { usuario } = this.state;
    usuario.dscnomape = data.dscnomape ? data.dscnomape : '';
    usuario.email = data.email ? data.email : '';
    usuario.stsusuario = data.stsusuario ? data.stsusuario : '';
    usuario.ideperfil = data.ideperfil ? data.ideperfil : '';

    this.setState({ usuario: usuario });
    this.Grid.buscarUsuario();
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <SearchIcon className={classes.titleIcon} />
            <Typography className={classes.title} variant="h4"> Criterio de Búsqueda</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Form onSubmit={this.buscarUsuario} classes={classes} buscarUsuario={this.buscarUsuario} usuario={this.state.usuario} 
               estados={this.state.estados} perfiles={this.state.perfiles}  />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <ListIcon className={classes.titleIcon} />
            <Typography className={classes.title} variant="h4"> Lista de Usuarios</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid onRef={ref => (this.Grid = ref)}  usuario={this.state.usuario} estados={this.state.estados} perfiles={this.state.perfiles}/> 
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }
}
const mapState = (state) => ({
  loading: state.load.loading
});
const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    usuarioActions: bindActionCreators(usuarioActions, dispatch),
    perfilActions: bindActionCreators(perfilActions, dispatch),
    documentoActions: bindActionCreators(documentoActions, dispatch),
    parametroActions: bindActionCreators(parametroActions, dispatch)
  };
};

Usuario = withStyles(cuestionarioStyle)(Usuario);
export default withRouter(connect(mapState, mapDispatch)(Usuario));