import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import customInputStyle from "styles/jss/material-dashboard-react/components/customInputStyle";
import FormHelperText from '@material-ui/core/FormHelperText';

class CustomSelect extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }
  componentDidMount() {
  }
  componentDidUpdate() {
  }
  handleChange = event => {
    this.props.getValue(event.target.value, this.props.id)
  }
  getTextSelect = selected => {
    const { data, multiple, val, txt } = this.props;
    if (multiple) {
      var params = [];
      for (var i = 0; i < selected.length; i++) {
        for (var j = 0; j < data.length; j++) {
          if (selected[i] == data[j][val]) {
            params.push(data[j][txt])
          }
        }
      }
      return params.join(', ');
    } else {
      var param = data.find(o => o[val] == selected);
      return param[txt];
    }
  }
  render() {
    const {
      input,
      classes,
      formControlProps,
      labelText,
      id,
      labelProps,
      inputProps,
      requiredLabel,
      meta: { touched, error, warning },
      multiple,
      data,
      val,
      txt,
      forceSelect
    } = this.props;
    const labelClasses = classNames({
      [" " + classes.labelRootError]: Boolean(touched && (error || warning)),
      [" " + classes.labelRootSuccess]: Boolean(touched && !(error || warning))
    });
    const underlineClasses = classNames({
      [classes.underlineError]: Boolean(touched && (error || warning)),
      [classes.underlineSuccess]: Boolean(touched && !(error || warning)),
      [classes.underline]: true
    });
    const marginTop = classNames({
      [classes.marginTop]: labelText === undefined
    });
    return (
      <FormControl error={Boolean(touched && (error || warning))} aria-describedby="name-error-text"
        {...formControlProps}
        className={formControlProps.className + " " + classes.formControl}>
        {labelText !== undefined ? (
          <InputLabel
            className={classes.labelRoot + labelClasses}
            htmlFor={id}
            required={requiredLabel}
            {...labelProps}
          >
            {labelText}
          </InputLabel>
        ) : null}
        <Select
          multiple={multiple}
          value={this.props.valor}
          onChange={this.handleChange}
          input={<Input id={id} classes={{ underline: underlineClasses }} {...inputProps} {...input} />}
          classes={{
            root: marginTop,
            disabled: classes.disabled
          }}
          renderValue={this.getTextSelect}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250
              }
            }
          }}
        >
          {!multiple && !forceSelect ? (
            <MenuItem value="" className={classes.itemSelect}>
              <em>SELECCIONAR</em>
            </MenuItem>
          ) : ("")}
          {multiple ? data.map(item => (
            <MenuItem key={item[val]} value={item[val]} className={classes.menuItemCheck}>
              <Checkbox checked={this.props.valor.indexOf(item[val]) > -1} className={classes.sinMargen}/>
              <ListItemText primary={item[txt]} primaryTypographyProps={{ variant: "subtitle1", className: classes.itemSelect }} />
            </MenuItem>
          )) : data.map(item => (
            <MenuItem key={item[val]} value={item[val]} className={classes.itemSelect}>
              {item[txt]}
            </MenuItem>
          ))}
        </Select>
        {touched && (error || warning) && <FormHelperText id="name-error-text">{error || warning}</FormHelperText>}
      </FormControl>
    );
  }
}
CustomSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool
};
export default withStyles(customInputStyle)(CustomSelect);