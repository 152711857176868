import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CustomAutocomplete from "components/CustomInput/CustomAutocomplete";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm } from 'redux-form';

import * as loadActions from "actions/LoadActions";
import * as notificationActions from "actions/NotificationActions";

import { integer } from 'utils/FormValidation';
import OpcionDialog from "components/Dialog/OpcionDialog";

class OpcionForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
       opcion: {
                  nomopcion : '',
                  dscopcion : '',
                  dsctitopcion : '',
                  dscruta : '',
                  dscvista : '',
                  ideopcionpadre : '',
                  stssegopcion : ''
       },
       showOpcionDialog: false,
    }
  }
  componentDidMount() {
  }

  getSelectValue = (valor, name) => {
    var opcion = this.state.opcion
    opcion[name] = valor;
    this.setState({ opcion: opcion });
  }

  limpiarForm = () => {
    this.props.reset();
    var  opcion = {
                  nomopcion : '',
                  dscopcion : '',
                  dsctitopcion : '',
                  dscruta : '',
                  dscvista : '',
                  ideopcionpadre : '',
                  stssegopcion : ''
       };

    this.setState({ opcion: opcion });
  }


  toggleDialogOpcion = (opcionVal) => {
    var { opcion } = this.state;
    if (typeof opcionVal !== "undefined") {
        opcion = Object.assign(opcion, opcionVal);
    } else {
      opcion.nomopcion = '';
      opcion.dscopcion = '';
      opcion.dsctitopcion = '';
      opcion.dscruta = '';
      opcion.dscvista = '';
      opcion.ideopcionpadre = '';
      opcion.stssegopcion = '';
    }
    this.setState({ showOpcionDialog: !this.state.showOpcionDialog })
  };

  render() {
    const { handleSubmit, classes, estados, buscarOpcion,lstOpcionPadre } = this.props;
    const { showOpcionDialog, opcion} = this.state;

    return (
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Field labelText="Nombre de la Opción"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "nomopcion"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 30)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={opcion.nomopcion}
                  name="nomopcion"
                  id="nomopcion" />
              </GridItem>  
              <GridItem xs={12} sm={12} md={6}>
                <Field labelText="Descripción de la Opción"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "dscopcion"
                  }}
                  normalize={(val, prevVal) => {                   
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={opcion.dscopcion}
                  name="dscopcion"
                  id="dscopcion" />
              </GridItem>                            
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
                <Field labelText="Título de la Opción"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "dsctitopcion"
                  }}
                  normalize={(val, prevVal) => {                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={opcion.dsctitopcion}
                  name="dsctitopcion"
                  id="dsctitopcion" />
              </GridItem>    
              <GridItem xs={12} sm={12} md={6}>
                <Field labelText="Ruta de la Opción"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "dscruta"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 50)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-Z0-9/]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={opcion.dscruta}
                  name="dscruta"
                  id="dscruta" />
              </GridItem>                            
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Nombre de Objeto"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      name: "dscvista"
                    }}
                    normalize={(val, prevVal) => {
                      if (val.length > 40)
                        return prevVal;
                      
                      if (val) {
                        return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                      }
                      return val;
                    }}
                    component={CustomInput}
                    value={opcion.dscvista}
                    name="dscvista"
                    id="dscvista" />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Opción Padre"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "ideopcionpadre",
                    name: "ideopcionpadre"
                  }}
                  multiple={false}
                  data={lstOpcionPadre}
                  component={CustomAutocomplete}
                  valor={opcion.ideopcionpadre}
                  getValue={this.getSelectValue}
                  val="ideopcionpadre"
                  txt="dscideopcionpadre"
                  name="ideopcionpadre"
                  id="ideopcionpadre" />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Estado"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "stssegopcion",
                    name: "stssegopcion"
                  }}
                  multiple={false}
                  data={estados}
                  component={CustomAutocomplete}
                  valor={opcion.stssegopcion}
                  getValue={this.getSelectValue}
                  val="stssegopcion"
                  txt="dscstssegopcion"
                  name="stssegopcion"
                  id="stssegopcion" />
              </GridItem>              
            </GridContainer>
            <OpcionDialog toggleDialogOpcion={this.toggleDialogOpcion} 
                                  showOpcionDialog={showOpcionDialog} 
                                  opcion={opcion} 
                                  classes={classes}
                                  estados={estados} 
                                  lstOpcionPadre={lstOpcionPadre} 
                                  buscarOpcion={buscarOpcion}
                                  titulo = {"Registro de Opciones del Sistema"}
                                  />
          </GridItem>
        </GridContainer>
        <div style={{ textAlign: "right" }}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => this.toggleDialogOpcion()}
          >Nuevo</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >Buscar</Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={this.limpiarForm}
          >Limpiar</Button>
        </div>
      </form>
    );
  }
}

OpcionForm = reduxForm({
  form: 'opcionForm'
})(OpcionForm)
const mapState = (state) => ({
  loading: state.load.loading
});
const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(OpcionForm));