import { APIRestAWS } from "services/common/APIRestAWS";
import {PATH_SEGURIDAD_API, PATH_OPCION_API } from "commons/config/Path";
import config from "commons/config/AWSConfig";
import {OPC_SEGURIDAD } from 'utils/Constants';

export default class OpcionApi {

    static async listarOpcionesXPerfil(usuario) {
            var varQueryParams = {
                ideperfil : usuario.ideperfil
            };
            var apiRestAWS = new APIRestAWS();
            return apiRestAWS.get(config.apiGateway.NAME, PATH_OPCION_API.URL_METHOD_GET, varQueryParams)
                .then(async (response) => {
                    return response;
                }); 
    }


    static async listarOpcion() {
        var varQueryParams = {
            opc : OPC_SEGURIDAD.LISTAR_OPCION
        };
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_OPCION, varQueryParams)
            .then(async (response) => {
                return response;
            }); 
    }

    static async insertarOpcion(opcion, user) {
        var varQueryParams = {
            opc : OPC_SEGURIDAD.INSERTAR_OPCION,
            opcion : {  nomopcion : opcion.nomopcion,
                        dscopcion : opcion.dscopcion,
                        dsctitopcion : opcion.dsctitopcion,
                        dscruta : opcion.dscruta,
                        dscvista : opcion.dscvista,
                        dscicono : opcion.dscicono,
                        ideopcionpadre :  opcion.ideopcionpadre,
                        stssegopcion : opcion.stssegopcion,
                        numorden : opcion.numorden,
                        valdescendencia : opcion.valdescendencia,
                        usucreacion : user.ideusuario,
                        usumodificacion : user.ideusuario
            }
        };
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.post(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_OPCION, varQueryParams)
            .then(async (response) => {
                return response;
            }); 
    }

    static async paginarOpcion(opcion, page, pagesize) {
        var varQueryParams = {
            opc: OPC_SEGURIDAD.PAGINAR_OPCION,
            nomopcion:  opcion.nomopcion,
            dscopcion: opcion.dscopcion,
            dsctitopcion: opcion.dsctitopcion,
            dscruta:opcion.dscruta,
            dscvista:opcion.dscvista,
            ideopcionpadre:opcion.ideopcionpadre,
            stssegopcion:opcion.stssegopcion,
            page: page,
            pagesize: pagesize
        }
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_OPCION, varQueryParams)
            .then(async (response) => {
                return response;
            });
    } 
    
    static async actualizarOpcion(opcion, user) {

        var varQueryParams = {
            opc : OPC_SEGURIDAD.ACTUALIZAR_OPCION,
            opcion : {  ideopcion : opcion.ideopcion === undefined ? '' : opcion.ideopcion ,
                        nomopcion : opcion.nomopcion === undefined ? '' : opcion.nomopcion ,
                        dscopcion : opcion.dscopcion === undefined ? '' : opcion.dscopcion ,
                        dsctitopcion : opcion.dsctitopcion === undefined ? '' : opcion.dsctitopcion ,
                        dscruta : opcion.dscruta === undefined ? '' : opcion.dscruta ,
                        dscvista : opcion.dscvista === undefined ? '' : opcion.dscvista ,
                        dscicono : opcion.dscicono === undefined ? '' : opcion.dscicono ,
                        ideopcionpadre :  opcion.ideopcionpadre === undefined ? '' : opcion.ideopcionpadre ,
                        stssegopcion : opcion.stssegopcion === undefined ? '' : opcion.stssegopcion ,
                        numorden : opcion.numorden === undefined ? '' : opcion.numorden ,
                        valdescendencia : opcion.valdescendencia === undefined ? '' : opcion.valdescendencia ,
                        usumodificacion : user.ideusuario
            }
        };
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.put(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_OPCION, varQueryParams)
            .then(async (response) => {
                return response;
            }); 
    }

    
    static async buscarOpcion(opcion) {
        var varQueryParams = {
            opc: OPC_SEGURIDAD.BUSCAR_OPCION,
            nomopcion:  opcion.nomopcion,
            dscopcion: opcion.dscopcion,
            dsctitopcion: opcion.dsctitopcion,
            dscruta:opcion.dscruta,
            dscvista:opcion.dscvista,
            ideopcionpadre:opcion.ideopcionpadre,
            stssegopcion:opcion.stssegopcion
        }
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_OPCION, varQueryParams)
            .then(async (response) => {
                return response;
            });
    } 

    static async buscarPadre(opcion) {
        var varQueryParams = {
            opc: OPC_SEGURIDAD.BUSCAR_PADRE,
            stssegopcion:opcion.stssegopcion
        }
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_OPCION, varQueryParams)
            .then(async (response) => {
                return response;
            });
    } 
}