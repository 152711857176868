import React from "react";
// @material-ui/core components
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';

import cuestionarioStyle from "styles/jss/app/views/cuestionarioStyle.jsx";
import * as opcionActions from "actions/OpcionActions";
import * as notificationActions from "actions/NotificationActions";
import * as loadActions from "actions/LoadActions";
import * as parametroActions from "actions/ParametroActions";
import * as documentoActions from "actions/DocumentoActions";
import { MENSAJE, TIPO_MENSAJE } from 'utils/Constants';

import Form from "components/Form/OpcionForm";
import Grid from "components/Grid/OpcionGrid";

class Opcion extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.buscarOpcion = this.buscarOpcion.bind(this);
    this.state = {
      estados: [],
      lstOpcionPadre: [],
      opcion: {
        nomopcion : '',
        dscopcion:'',
        dsctitopcion:'',
        dscruta:'',
        dscvista: '',
        ideopcionpadre:'',
        stssegopcion:''
      },
    }
  }

  componentDidMount() {
    this.listarEstados();
    this.listarOpcionesPadre();
  }


  listarEstados() {
    const { show } = this.props.notificationActions;
    this.props.documentoActions.listarParametrosFiltroDocumento('listparamconsult').then(response => {
      if (response.tipo !== TIPO_MENSAJE.SUCCESS) {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
      } else {
        if (response.data.length === 0) {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "No se encontraron los estados a mostrar.", TIPO_MENSAJE.WARNING);
        } else {
          var estados = [];
          var estado;
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].cod_grup_parm == '6') {
              estado = {};
              estado.dscstssegopcion = response.data[i].nom_parm;
              estado.stssegopcion = response.data[i].val_parm_nume;
              estados.push(estado);
            }
          }
          this.setState({ estados: estados})
        }
      }
    })    
  }


  listarOpcionesPadre() {
    const { show } = this.props.notificationActions;
    var opcion = {
      stssegopcion : ''           
    };
    this.props.opcionActions.buscarPadre(opcion).then(response => {
      if (response.tipo !== TIPO_MENSAJE.SUCCESS) {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
      } else {
        if (response.data.length === 0) {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "No se encontraron las opciones padre", TIPO_MENSAJE.WARNING);
        } else {
          var lstOpcionPadre = [];
          var opcpadre;
          for (var key in response.data) {
            opcpadre = {};
            opcpadre.ideopcionpadre = response.data[key].ideopcion;
            opcpadre.dscideopcionpadre = response.data[key].nomopcion;
            lstOpcionPadre.push(opcpadre);
          }
          this.setState({ lstOpcionPadre: lstOpcionPadre})
        }
      }
    })    
  }

  buscarOpcion = (data) => {
    var { opcion } = this.state

    opcion.nomopcion = data.nomopcion ? data.nomopcion : '';
    opcion.dscopcion  = data.dscopcion ? data.dscopcion : '';
    opcion.dsctitopcion  = data.dsctitopcion ? data.dsctitopcion : '';
    opcion.dscruta  = data.dscruta ? data.dscruta : '';
    opcion.dscvista = data.dscvista ? data.dscvista : '';
    opcion.ideopcionpadre  = data.ideopcionpadre ? data.ideopcionpadre : '';
    opcion.stssegopcion  = data.stssegopcion ? data.stssegopcion : '';    

    this.setState({ opcion: opcion });
    this.Grid.buscarOpcion();
  }  

  render() {
    const { classes } = this.props;
    return (
      <div>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <SearchIcon className={classes.titleIcon} />
            <Typography className={classes.title} variant="h4"> Criterio de Búsqueda</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Form onSubmit={this.buscarOpcion} 
                  classes={classes} 
                  buscarOpcion={this.buscarOpcion} 
                  opcion={this.state.opcion} 
                  estados={this.state.estados} 
                  lstOpcionPadre={this.state.lstOpcionPadre}                 
                  />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <ListIcon className={classes.titleIcon} />
            <Typography className={classes.title} variant="h4"> Lista de Opciones del Sistema</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid onRef={ref => (this.Grid = ref)}  
                         opcion={this.state.opcion} 
                         estados={this.state.estados}
                         lstOpcionPadre={this.state.lstOpcionPadre} 
                         /> 
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }
}
const mapState = (state) => ({
  loading: state.load.loading
});
const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    opcionActions: bindActionCreators(opcionActions, dispatch),
    parametroActions: bindActionCreators(parametroActions, dispatch),
    documentoActions: bindActionCreators(documentoActions, dispatch)
  };
};

Opcion = withStyles(cuestionarioStyle)(Opcion);
export default withRouter(connect(mapState, mapDispatch)(Opcion));