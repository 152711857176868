import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import MenuIconClose from "@material-ui/icons/MoreVert";
import MenuIconOpen from "@material-ui/icons/Menu";
// core components
import HeaderLinks from "./HeaderLinks";
import Button from "components/CustomButtons/Button";

import headerStyle from "styles/jss/app/components/headerStyle.jsx";

function Header({ ...props }) {
  function makeBrand() {
    var name ="";
    props.routes.map((prop, key) => {
      if (prop.DSCRUTA === props.location.pathname) {
        name = prop.DSCTITOPCION;
      }
      if (prop.CHILDREN.length > 0) {
        prop.CHILDREN.map((childProp, childkey) => {
          if (childProp.DSCRUTA === props.location.pathname) {
            name = childProp.DSCTITOPCION;
          }
        return null
        })
      }
      return null
    });
    if (name === "") name = "Sistema de Recepción de Artefactos"
    return name;
  }
  const { classes, color, open } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
      <Hidden mdUp implementation="css">
          <Button color="primary" aria-label="open" justIcon round 
            onClick={props.handleDrawerToggle}>
            <MenuIconOpen />
          </Button>
        </Hidden>
        <Hidden smDown implementation="css">
          <Button color="primary" aria-label="opwn" justIcon round
            onClick={props.handleMiniDrawerToggle}>
            {open ? <MenuIconOpen /> : <MenuIconClose />}
          </Button>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button color="transparent" className={classes.title}>
            {makeBrand()}
          </Button>
        </div>
        <Hidden smDown implementation="css">
          <HeaderLinks mobileOpen={props.mobileOpen}/>
        </Hidden>
        
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(headerStyle)(Header);
