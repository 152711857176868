import * as React from 'react';
import { reduxForm } from 'redux-form'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ListIcon from '@material-ui/icons/List';
import Paper from '@material-ui/core/Paper';
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import TableCell from '@material-ui/core/TableCell';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import {
  TIPO_MENSAJE, MENSAJE, GRID_TEXT_ES
} from 'utils/Constants';
import * as notificationActions from "actions/NotificationActions";
import * as loadActions from "actions/LoadActions";
import * as usuarioActions from "actions/UsuarioActions";
import * as perfilActions from "actions/PerfilActions";

import cuestionarioStyle from "styles/jss/app/views/cuestionarioStyle.jsx";

import {
  SelectionState,
  IntegratedSelection,
  FilteringState, SortingState, IntegratedFiltering, DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable, Table,
  TableHeaderRow,
  TableSelection, TableFilterRow,  TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';

import { getFormatDate } from 'utils/Functions';

class OpcionesxPerfilForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'ideopcion', title: 'id' },         
        { name: 'nomopcion', title: 'Nombre de la opción del sistema' },
        { name: 'dscopcion', title: 'Descripción de la opción del sistema' },
        { name: 'stssegopperfil', title: 'Estado' },
        { name: 'usumodificacion', title: 'Usuario modificación' },
        { name: 'fecmodificacion', title: 'Fecha modificación' }
      ],
      tableColumnExtensions: [
        { columnName: 'ideopcion', width: 100, align: 'left' },
        { columnName: 'nomopcion', width: 300, align: 'left' },   
        { columnName: 'dscopcion', width: 500, align: 'left' },                  
        { columnName: 'stssegopperfil', width: 150, align: 'left' },
        { columnName: 'usumodificacion', width: 150, align: 'left' },
        { columnName: 'fecmodificacion', width: 150, align: 'center' }
      ],
      filteringStateColumnExtensions: [
        { columnName: 'ideopcion', filteringEnabled: false },
        { columnName: 'nomopcion', filteringEnabled: true },
        { columnName: 'stssegopperfil', filteringEnabled: false },
        { columnName: 'usumodificacion', filteringEnabled: false },  
        { columnName: 'fecmodificacion', filteringEnabled: false },                         
      ],
      dateColumns: ['fecmodificacion'],
      rows: [],
      listaOpcionesxPerfil: [],
      selection: [],
      showObservaciones: false,
      observacion: '',
      currentPage: 1,
      pageSize: 20,
      pageSizes: [20, 40, 60],
      rowsRespuesta: [],
      showRespuesta: false,
      titleRespuesta: '',
      ideusuario: '',
      showReasignar: false,
      responsables: [],
      showDevolver: false,
      motivodevolucion: '',
      showPublicar: false,
      gthXPublicar: [],
      feclimite: '',
      showCambiarFecha: false,
      fecdefault: getFormatDate(new Date(), 'ymd', '-'),
      errorFecha: '',
      perfil:'',
      hiddenColumnNames: ['ideopcion','stssegopperfil'],
    };

    this.hiddenColumnNamesChange = (hiddenColumnNames) => {
        this.setState({ hiddenColumnNames });
      };

    this.changeSelection = selection => {
      this.setState({ selection });
    }
  }

  AsignarOpcionesxPerfil = () => {
    const { add , remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    const { selection, perfil } = this.state;
    const { user } = this.props;
    var lstideopcion = selection.join(',');

    if (selection.length != 0) {
      add();
      this.props.actions.AsignarOpcionesxPerfil(lstideopcion, perfil, user).then(response => {
        if (response.tipo !== TIPO_MENSAJE.SUCCESS) {
          show(response.indMostrarMensaje, response.mensaje, response.tipo);
        } else {
          if (response.data.length === 0) {
            show(MENSAJE.IND_MOSTRAR_MENSAJE, "No se actualizó las opciones del perfil.", TIPO_MENSAJE.WARNING);
          } else {
            show(response.indMostrarMensaje, response.mensaje, response.tipo);
          }
        }
      remove();
      this.props.listarOpcionesxPerfil(perfil);
      })
    } else {
      show(MENSAJE.IND_MOSTRAR_MENSAJE, "Es necesario seleccionar al menos una opción", TIPO_MENSAJE.WARNING);
    }

  }

  componentDidMount() {
    this.setState({ perfil: this.props.perfil });
    this.setState({ listaOpcionesxPerfil: this.props.listaOpcionesxPerfil });
  }

  componentWillReceiveProps() {
    this.setState({ selection: this.props.selection,
                    perfil: this.props.perfil,
                    listaOpcionesxPerfil: this.props.listaOpcionesxPerfil});
  }

  Cell = (props) => {
    const { column } = props;

    if (column.name === 'info') {
      return (
        <TableCell style={{ padding: 0 }}>
          <IconButton color="primary" aria-label="observacion" style={{ display: 'flex', float: 'left' }}
            onClick={this.toggleDialogObservaciones.bind(this, props.row.dscobservaciones)} title="Observación" >
            <InfoIcon />
          </IconButton>
        </TableCell>
      );
    }
    return <VirtualTable.Cell {...props} />;
  };


  render() {
    const { selection,
            columns, 
            tableColumnExtensions, 
            dateColumns,
            hiddenColumnNames} = this.state;

    const { handleSubmit, classes, toggleDialogOpcionesxPerfil, listaOpcionesxPerfil} = this.props;
    return (
      <div>
        <form className={classes.root} onSubmit={handleSubmit}>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <ListIcon className={classes.titleIcon} />
              <Typography variant="h4" className={classes.title}> Lista de Opciones por perfil</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Paper>
                    <Grid
                      rows={listaOpcionesxPerfil}
                      columns={columns}
                      style={{ height: "50%" }}
                      className={"ps-child"}
                      getRowId={row => row.ideopcion}
                    >
                      <DataTypeProvider
                        for={dateColumns}
                      />
                      <FilteringState
                        columnExtensions={this.state.filteringStateColumnExtensions} />
                      <IntegratedFiltering />
                      <SortingState />
                      <SelectionState
                        selection={selection}
                        onSelectionChange={this.changeSelection}
                      />
                      <IntegratedSelection />
                      <VirtualTable
                        columnExtensions={tableColumnExtensions}
                        cellComponent={this.Cell}
                        messages={GRID_TEXT_ES}
                      />
                      <TableHeaderRow />
                      <TableSelection showSelectAll />
                      <TableFilterRow messages={GRID_TEXT_ES} />
                      <TableColumnVisibility
                        hiddenColumnNames={hiddenColumnNames}
                        onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
                      />
                    </Grid>
                  </Paper>
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <div style={{ textAlign: "right" }}>
            <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => this.AsignarOpcionesxPerfil()}
            >Aplicar</Button>
            <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => toggleDialogOpcionesxPerfil()}
            >Cerrar</Button>
           </div>
        </form>
      </div>

    );
  }
}

OpcionesxPerfilForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

OpcionesxPerfilForm = reduxForm({
  form: 'opcionesxPerfilForm'
})(OpcionesxPerfilForm)

const mapState = (state) => ({
  user: state.session.user,
  loading: state.load.loading
});

const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    usuarioActions: bindActionCreators(usuarioActions, dispatch),
    actions: bindActionCreators(perfilActions, dispatch),
  };
};

OpcionesxPerfilForm = withStyles(cuestionarioStyle)(OpcionesxPerfilForm);
export default withRouter(connect(mapState, mapDispatch)(OpcionesxPerfilForm));





