import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import * as opcionActions from "actions/OpcionActions";
import * as notificationActions from "actions/NotificationActions";
import * as loadActions from "actions/LoadActions";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import tabStyle from "styles/jss/app/components/tabStyle.jsx";
import Form from "components/Form/MntOpcionForm";
import { TIPO_MENSAJE, OPC_SEGURIDAD } from 'utils/Constants';

function TabContainer(props) {
  return (
    <div style={{ padding: 8 * 3 }}>
      {props.children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class OpcionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueTab: 0,
      opcion: {},
      showOpcionDialog: '',

    };
  }

  componentDidMount() {
    this.setState({ opcion: this.props.opcion });
  }
  handleChangeTab = (event, valueTab) => {
    this.setState({ valueTab });
  };

  guardaOpcion = (data) => {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    add();
    this.props.actions.insertarOpcion(data, this.props.user).then(response => {
    if (response.tipo === TIPO_MENSAJE.SUCCESS) {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
        this.props.buscarOpcion(this.props.opcion);
        this.closeDialog();
    } else{
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
    }
    remove();
    })

  }

  closeDialog = () => {
    this.setState({ valueTab: 0 });
    this.props.toggleDialogOpcion();
  }

  render() {
    const { classes, showOpcionDialog,  estados, lstOpcionPadre, titulo, accion } = this.props;
    const { valueTab, opcion} = this.state;

    return (
      <Dialog
        open={showOpcionDialog}
        onClose={this.closeDialog}
        //fullWidth={true}
        maxWidth={false}
      >
        <DialogContent>
          <AppBar position="static" color="default">
            <Tabs
              value={valueTab}
              onChange={this.handleChangeTab}
              indicatorColor="secondary"
              classes={{ root: classes.tabsRoot }}
              //fullWidth
            >
              <Tab
                classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                label={titulo} />
            </Tabs>
          </AppBar>
          <Form onSubmit={accion === OPC_SEGURIDAD.ACTUALIZAR_OPCION ? this.props.handleSubmit : this.guardaOpcion} 
                classes={classes} 
                opcion={opcion} 
                estados={estados} 
                lstOpcionPadre={lstOpcionPadre} 
                toggleDialogOpcion={this.closeDialog}
           />
        </DialogContent>
      </Dialog>
    )
  }
}

const mapState = (state) => ({
  user: state.session.user,
  loading: state.load.loading
});

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(opcionActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

OpcionDialog = withStyles(tabStyle)(OpcionDialog);
export default withRouter(connect(mapState, mapDispatch)(OpcionDialog));