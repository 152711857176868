import { APIRestAWS } from "services/common/APIRestAWS";
import { PATH_DOCUMENTO_API, PATH_SERFAC_API } from "commons/config/Path";
import config from "commons/config/AWSConfig";
import axios from 'axios';
import { validarResponse } from 'utils/Functions';
import queryString from 'query-string'
export default class ClienteApi {
     

    //serfac registrar documentos
    static async registrarCliente(  documento )  {
        var apiRestAWS = new APIRestAWS();
        documento.clieNombre = documento.clieNombre.replace(/\\n/g, "\\n")  
                           .replace(/\\'/g, "\\'")
                           .replace(/\\"/g, '\\"')
                           .replace(/\\&/g, "\\&")
                           .replace(/\\r/g, "\\r")
                           .replace(/\\t/g, "\\t")
                           .replace(/\\b/g, "\\b")
                           .replace(/\\f/g, "\\f");
       
        var data = { 
            nombre: documento.clieNombre?documento.clieNombre : "",
            apellidos: '',
            tipoDoc: documento.clieTipDoc?documento.clieTipDoc : "",
            numDoc: documento.clieNumero?documento.clieNumero : "",
            direccion: documento.clieDireccion?documento.clieDireccion:'',
            ubigeo: documento.clieUbigeo?documento.clieUbigeo : "",
            email: documento.clieEmail?documento.clieEmail : "",
            telefono: documento.clieTelefono?documento.clieTelefono : "" 
        };
       /* return apiRestAWS.post(config.apiGateway.NAME, PATH_SERFAC_API.URL_METHOD_FICHA, varQueryParams)
            .then(async (response) => {
                return response;
        });*/
        const httpClient = axios.create();
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.post(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });
    }

     


}