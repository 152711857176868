import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
// core components
import HeaderLinks from "components/Header/HeaderLinks";

import sidebarStyle from "styles/jss/app/components/sidebarMenuStyle";
import {isMobile} from 'react-device-detect';


const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  const { classes, color, logo, image, logoText, routes, logoMini, open } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.redirect) return null;
        var activePro = " ";
        var listItemClasses;
        var listSubItemClasses;
        var isSubItemActive = false;
        listItemClasses = classNames({
          [" " + classes[color + "Link"]]: activeRoute(prop.DSCRUTA)
        });
        if (prop.CHILDREN.length > 0) {
          var subLinks = (
            <List className={classes.listSubMenu}>
              {prop.CHILDREN.map((subItem, keySI) => {
                isSubItemActive = activeRoute(subItem.DSCRUTA) ? true : isSubItemActive;
                listSubItemClasses = classNames({
                  [" " + classes[color + "SubLink"]]: activeRoute(subItem.DSCRUTA)
                },{[" " + classes.nested]: open });
                return (
                  <NavLink
                    to={subItem.DSCRUTA}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={subItem.IDEOPCION}
                  >
                    <ListItem button className={classes.itemLinkSubMenu + listSubItemClasses}>
                      <ListItemIcon className={classes.itemIconSubMenu}>
                        <Icon>{subItem.DSCICONO}</Icon>
                      </ListItemIcon>
                      <ListItemText
                        primary={subItem.NOMOPCION} disableTypography={true}
                        className={classNames(classes.itemText, classes.itemTextSubMenu, { [classes.itemTextHidden]: !props.open })}
                      />
                    </ListItem>
                  </NavLink>
                );
              })}
            </List>
          )
          return (
            <div key={prop.IDEOPCION}>
              <ListItem button className={classes.itemLink + listItemClasses} onClick={props.handleItemListCollapse.bind(this, prop.DSCRUTA)}>
                <ListItemIcon className={classes.itemIcon}>
                  {typeof prop.DSCICONO === "string" ? (
                    <Icon>{prop.DSCICONO}</Icon>
                  ) : (
                      <prop.DSCICONO />
                    )}
                </ListItemIcon>
                <ListItemText
                  primary={prop.NOMOPCION}
                  className={classNames(classes.itemText, { [classes.itemTextHidden]: !props.open })}
                  disableTypography={true}
                />
                {props.states[prop.DSCRUTA] || isSubItemActive ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={props.states[prop.DSCRUTA] || isSubItemActive} timeout="auto" unmountOnExit>
                {subLinks}
              </Collapse>
            </div>
          );
        } else {
          return (
            <NavLink
              to={prop.DSCRUTA}
              className={activePro + classes.item}
              activeClassName="active"
              key={prop.IDEOPCION}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                <ListItemIcon className={classes.itemIcon}>
                  {typeof prop.DSCICONO === "string" ? (
                    <Icon>{prop.DSCICONO}</Icon>
                  ) : (
                      <prop.DSCICONO />
                    )}
                </ListItemIcon>
                <ListItemText
                  primary={prop.NOMOPCION}
                  className={classNames(classes.itemText, { [classes.itemTextHidden]: !props.open })}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          );

        }

      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a href="/" className={classNames(classes.logoLinkFactElec, { [classes.logoLinkHidden]: !props.open })}>
        <div className={classes.logoImageFactElec}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
      <a href="/" className={classNames(classes.logoLinkFactElec, classes.logoLinkMini, { [classes.logoLinkMiniHidden]: props.open })}>
        <div className={classes.logoImage}>
          <img src={logoMini} alt="logo" className={classNames(classes.img, classes.imgMini)} />
        </div>
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarProfile}>
            <HeaderLinks />
          </div>
          <div className={classNames(classes.sidebarWrapper, {[classes.overflowHidden]: !isMobile})} id="sidebarWrapperMobile">
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            //paper: classes.drawerPaper 
            paper: classNames(classes.drawerPaper, !props.open && classes.drawerPaperClose)
          }}
        >
          {brand}
          <div className={classNames(classes.sidebarWrapper, {[classes.overflowHidden]: !isMobile})} id="sidebarWrapperWeb">{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
