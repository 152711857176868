import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import ParametroSelect from "components/CustomInput/ParametroSelect";
import CustomSelect from "components/CustomInput/CustomSelect";
import CustomAutocomplete from "components/CustomInput/CustomAutocomplete";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';

import * as documentoActions from "actions/DocumentoActions";
import * as loadActions from "actions/LoadActions";
import * as usuarioActions from "actions/UsuarioActions";
import * as notificationActions from "actions/NotificationActions";
import { s2ab } from 'utils/Functions';
import { getFormatDate, getRestarDias } from 'utils/Functions';
import { change } from 'redux-form'

const required = value => value ? undefined : 'Valor Requerido'

const validate = values => {
  const errors = {}
  var fechaActual = getFormatDate(new Date(),  'ymd', '-')

  if (!values.tipReporte) {
    errors.tipReporte = 'Seleccione el tipo de reporte'
  }

  if (!values.typeGraph) {
    errors.typeGraph = 'Seleccione el tipo de grafico'
  }

  

  if (!values.hFechaInicio) {
    errors.hFechaInicio = 'Valor Requerido'
  } else if (values.hFechaInicio > values.hFechaFin) {
    errors.hFechaInicio = ' '
  } 

  if (!values.hFechaFin) {
    errors.hFechaFin = 'Valor Requerido'
  } else if (values.hFechaFin < values.hFechaInicio) {    
    errors.hFechaFin = ' '
  }else if (values.hFechaFin > fechaActual) {    
    errors.hFechaFin = 'La fecha fin no puede ser mayor a la fecha actual'
  }else if (values.hFechaInicio > fechaActual) {    
    errors.hFechaFin = 'La fecha inicio no puede ser mayor a la fecha actual'
  }

  if (values.typeGraph && values.typeGraph == 'PIE' && !values.compania) {
    errors.compania = 'Valor Requerido para este tipo de consulta'
  }
  
  if (values.hFechaInicio && values.hFechaFin) {
    var fechaInicio = new Date(values.hFechaInicio).getTime();
    var fechaFin    = new Date(values.hFechaFin).getTime();
    var diff = fechaFin - fechaInicio;
    var difDias = diff/(1000*60*60*24);
    //if (difDias > 30 ){
      // errors.hFechaFin = 'La diferencia de fechas no puede ser mayor a 30 dias'
    //}
  }

  return errors
}

/****end new */

class ConTecnicoForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tipDocuments: [{
                id: "1",
                valor: "DNI"
              },
              {
                id: "2",
                valor: "RUC"
              },
              {
                id: "3",
                valor: "CE"
              }
          ],
      statesSunat: [],
      lstCdr: [],
      lstTypeDocument: [],
      graficos: [],
      tipReporte: [],
      companias: [],
      documento: {
        docuNumero: '',
        tipoDocumento: [],
        clieNumero: '',  //es ruc
        docuFechaIni: '',
        docuFechaFin: '',
        estado: [],
        clieNombre: '', //razon Social
        cdr: '',
        documentTypeClient: '',
        compania: '',
        producto: '',
        estado: '',
        marca: '',
        tipoServicio: '',
        area: '',
        modelo: '',
        serie: '',
        clieTipDoc: '',
        numeroservicio: '',
        numeroFicha: '',
        telefono: ''
      },
      estadoArtefactos: [
                {
                    id: "0",
                    valor: "REGISTRADO"
                  },
                  {
                    id: "11",
                    valor: "REVISADO POR TECNICO"
                  },{
                    id: "12",
                    valor: "EN REPARACIÓN"
                  },{
                    id: "13",
                    valor: "REPARADO"
                  }
                  ,{
                    id: "14",
                    valor: "NO SE PUDO REPARAR"
                  },
                  {
                    id: "1",
                    valor: "PND COTIZACIÓN"
                  },
                  {
                    id: "2",
                    valor: "COTIZADO"
                  },
                  {
                    id: "3",
                    valor: "ACEPTO"
                  },
                  {
                    id: "4",
                    valor: "NO ACEPTO"
                  } ,
                  {
                    id: "5",
                    valor: "ENTREGAR SIN REPARAR"
                  } ,
                  {
                    id: "6",
                    valor: "ENTREGADO SIN REPARAR"
                  } ,
                  {
                    id: "7",
                    valor: "PND DESPACHO RPTOS"
                  } , ,
                  {
                    id: "8",
                    valor: "PND X IMPORTACIÓN"
                  } ,
                  {
                    id: "9",
                    valor: "DESPACHADO"
                  },
                  {
                    id: "10",
                    valor: "ENTREGADO OK"
                  },
                  {
                    id: "19",
                    valor: "EN REVISIÓN"
                  },
                  {
                    id: "17",
                    valor: "EN PRUEBA"
                  },
                  {
                    id: "18",
                    valor: "DESCARTE"
                  }

      ],
      artefactos:[
              {
                id: "1",
                valor: "AIRE ACONDICIONADO"
              },
              {
                id: "2",
                valor: "ASPIRADORA"
              },
              {
                id: "3",
                valor: "BATIDORA"
              },
              {
                id: "4",
                valor: "BATIDORA DE MANO"
              },
              {
                id: "5",
                valor: "CAFETERA"
              },
              {
                id: "6",
                valor: "CALEFACTOR"
              },
              {
                id: "7",
                valor: "CAMPANA"
              },
              {
                id: "8",
                valor: "CENTRO DE LAVADO"
              },
              {
                id: "9",
                valor: "COCINA"
              },
              {
                id: "10",
                valor: "CONGELADOR"
              },
              {
                id: "11",
                valor: "DESHUMEDECEDOR"
              },
              {
                id: "12",
                valor: "DISPENSADOR DE AGUA"
              },
              {
                id: "13",
                valor: "EXHIBIDORAS"
              },
              {
                id: "14",
                valor: "EXPRIMIDOR DE CITRICOS"
              },
              {
                id: "15",
                valor: "FABRICADOR DE HIELO"
              },
              {
                id: "16",
                valor: "FREIDORA"
              },
              {
                id: "17",
                valor: "FRIGOBAR"
              },
              {
                id: "18",
                valor: "HERVIDOR"
              },
              {
                id: "19",
                valor: "HORNO"
              },
              {
                id: "20",
                valor: "LAVADORA"
              },
              {
                id: "21",
                valor: "LAVAPLATOS"
              },
              {
                id: "22",
                valor: "LAVASECA"
              },
              {
                id: "23",
                valor: "LICUADORA"
              },
              {
                id: "24",
                valor: "LUSTRADORA"
              },
              {
                id: "25",
                valor: "MICROONDAS"
              },
              {
                id: "26",
                valor: "OLLA ARROCERA"
              },
              {
                id: "27",
                valor: "PARRILLA"
              },
              {
                id: "28",
                valor: "PLANCHA"
              },
              {
                id: "29",
                valor: "PROCESADOR DE ALIMENTOS"
              },
              {
                id: "30",
                valor: "REFRIGERADORA"
              },
              {
                id: "31",
                valor: "SANDWICHERA"
              },
              {
                id: "32",
                valor: "SECADORA"
              },
              {
                id: "33",
                valor: "TERMA"
              },
              {
                id: "34",
                valor: "TORRE DE SECADO"
              },
              {
                id: "35",
                valor: "TOSTADORA"
              },
              {
                id: "36",
                valor: "VENTILADOR"
              },
              {
                id: "37",
                valor: "VINERA"
              },
              {
                id: "38",
                valor: "ASPIRADORA INDUSTRIAL"
              },
              {
                id: "39",
                valor: "LUSTRADORA INDUSTRIAL"
              },
              {
                id: "40",
                valor: "LAVAALFOMBRAS INDUSTRIAL"
              },
              {
                id: "41",
                valor: "LAVADORA INDUSTRIAL"
              },
              {
                id: "42",
                valor: "SECADORA INDUSTRIAL"
              },
              {
                id: "43",
                valor: "MESA DE PLANCHAR"
              },
              {
                id: "44",
                valor: "PLANCHA INDUSTRIAL"
              },
              {
                id: "45",
                valor: "CALANDRA"
              },
              {
                id: "46",
                valor: "LAVADORA COMERCIAL"
              },
              {
                id: "47",
                valor: "SECADORA COMERCIAL"
              },
              {
                id: "48",
                valor: "CENTRO DE LAVADO COMERCIAL"
              }
      ],
      marcas: [
        {
                id: "1",
                valor: "BOSCH"
              },
              {
                id: "2",
                valor: "COLDEX"
              },
              {
                id: "3",
                valor: "ELECTROLUX"
              },
              {
                id: "4",
                valor: "FRIGIDAIRE"
              },
              {
                id: "5",
                valor: "GENERAL ELECTRIC"
              },
              {
                id: "6",
                valor: "IMACO"
              },
              {
                id: "7",
                valor: "INDURAMA"
              },
              {
                id: "8",
                valor: "KENMORE"
              },
              {
                id: "9",
                valor: "KITCHENAID"
              } ,
              {
                id: "10",
                valor: "LG"
              } ,
              {
                id: "11",
                valor: "MABE"
              } ,
              {
                id: "12",
                valor: "MAYTAG"
              } ,
              {
                id: "13",
                valor: "OSTER"
              } ,
              {
                id: "14",
                valor: "PHILIPS"
              } ,
              {
                id: "15",
                valor: "PRACTIKA"
              } ,
              {
                id: "16",
                valor: "SAMSUNG"
              } ,
              {
                id: "17",
                valor: "THOMAS"
              } ,
              {
                id: "18",
                valor: "UNIVERSAL"
              } ,
              {
                id: "19",
                valor: "WHIRLPOOL"
              } ,
              {
                id: "20",
                valor: "WHITE WESTINGHOUSE"
              } ,
              {
                id: "21",
                valor: "OTRO"
              },
              {
                id: "22",
                valor: "GHIBLI"
              },
              {
                id: "23",
                valor: "NILFISK ADVANCE"
              },
              {
                id: "24",
                valor: "CHASQUY"
              }
      ],
      tipoServicios: [
              {
                id: "1",
                valor: "GARANTIA"
              },
              {
                id: "2",
                valor: "COBRABLE"
              }, 
              {
                id: "3",
                valor: "GARANTIA TALLER"
              } 
      ],
      areas: [{
                id: "1",
                valor: "COBRABLES PRODUCTO PEQUEÑO"
              },
              {
                id: "2",
                valor: "GARANTÍA KITCHENAID"
              },
              {
                id: "3",
                valor: "GARANTIA/COBRABLE LIMPIEZA INDUSTRIAL"
              },
              {
                id: "4",
                valor: "GARANTÍA ELECTROLUX DOMÉSTICO"
              },
              {
                id: "5",
                valor: "GARANTÍA ELECTROLUX ICON"
              },
              {
                id: "6",
                valor: "GARANTÍA WHIRLPOOL LINEA BLANCA"
              },
              {
                id: "7",
                valor: "GARANTÍA UNIVERSAL"
              },
              {
                id: "8",
                valor: "GARANTÍA OSTER"
              },
              {
                id: "9",
                valor: "COBRABLE LINEA BLANCA"
              },
              {
                id: "10",
                valor: "GARANTÍA EXTENDIDA MOK"
              },
              {
                id: "11",
                valor: "GARANTIA WHIRLPOOL COMERCIAL"
              },
              {
                id: "12",
                valor: "GARANTIA / COBRABLE LAVANDERIA INDUSTRIAL"
              }
          ],
      fecdefault: getFormatDate(new Date(),  'ymd', '-'),
      errorFecha: '',
      errorFechaFin: '',
    }
  }
  componentDidMount() {
    //this.buscarParametroFiltro();
    this.props.change('hFechaInicio', getFormatDate(new Date(), 'ymd', '-'))
    this.props.change('hFechaFin', getFormatDate(new Date(), 'ymd', '-'))
    this.state.documento.docuFechaIni = getFormatDate(new Date(), 'ymd', '-')
    this.state.documento.docuFechaFin = getFormatDate(new Date(), 'ymd', '-')
    //para filtros exportar excel
    this.props.documento.docuFechaIni = getFormatDate(new Date(), 'ymd', '-')
    this.props.documento.docuFechaFin = getFormatDate(new Date(), 'ymd', '-')

  }
  buscarParametroFiltro() {
    const { show } = this.props.notificationActions;
    this.props.actions.listarParametrosFiltroDocumento('listparamconsult').then(response => {
      if (response.tipo !== TIPO_MENSAJE.SUCCESS) {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
      } else {
        if (response.data.length === 0) {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "No se encontraron documentos a mostrar.", TIPO_MENSAJE.WARNING);
        } else {
          var lstTipDoc = [];
          var lstCdr = [];
          var lstSendSunar = [];
          var lstTypeDocument = [];
          var graficos = [];
          var tipReporte = [];
          var companias = [];
         // lstTipDoc.push({id:' ', valor: 'TODOS'});
          lstCdr.push({id:' ', valor: 'TODOS'});
         // lstSendSunar.push({id:' ', valor: 'TODOS'});
          for (var i = 0; i < response.data.length; i++) {
            var item = {};
            if (response.data[i].cod_grup_parm == '1') {
              item = {
                id: "'" + response.data[i].val_parm_nume + "'",
                valor: response.data[i].nom_parm
              };
              lstTipDoc.push(item);
            }
            if (response.data[i].cod_grup_parm == '2') {
              item = {
                id: response.data[i].val_parm_nume,
                valor: response.data[i].nom_parm
              };
              lstSendSunar.push(item);
            }
            if (response.data[i].cod_grup_parm == '5') {
              item = {
                id: response.data[i].val_parm_nume,
                valor: response.data[i].nom_parm
              };
              lstTypeDocument.push(item);
            }
            if (response.data[i].cod_grup_parm == '7') {
              item = {
                id: response.data[i].val_parm_nume,
                valor: response.data[i].nom_parm
              };
              companias.push(item);
            }

            
          }          
          graficos.push({ id: 'PIE', valor: 'CIRCULAR' });
          graficos.push({ id: 'BAR', valor: 'BARRA' });
          graficos.push({ id: 'LINEA', valor: 'LINEAS' });

          tipReporte.push({ id: 'C', valor: 'Cantidad de Documentos' });
          tipReporte.push({ id: 'M', valor: 'Monto de Transacciones' });
          tipReporte.push({ id: 'E', valor: 'Estados SUNAT' });

          this.setState({ tipDocuments: lstTipDoc })
          this.setState({ statesSunat: lstSendSunar })
          this.setState({ lstCdr: lstCdr })
          this.setState({ lstTypeDocument: lstTypeDocument })
          this.setState({ graficos: graficos })
          this.setState({ tipReporte: tipReporte })
          this.setState({ companias: companias })
        }
      }
    })
  }

  getSelectValue = (valor, name) => {
    var documento = this.state.documento
    documento[name] = valor;
    this.setState({ documento: documento });
  }
  limpiarForm = () => {
    this.props.reset();
    var documento = {
      docuNumero: '',
      tipoDocumento: [],
      clieNumero: '',  //es ruc
      docuFechaIni: getFormatDate(new Date(), 'ymd', '-'),
      docuFechaFin: getFormatDate(new Date(), 'ymd', '-'),
      estado: '',
      clieNombre: '', //razon Social
      cdr: '',
      documentTypeClient: '',
      typeGraph: '',
      producto: '',
      tipoServicio: '',
      marca: '',
      area: '',
      modelo: '',
      serie: '',
      clieTipDoc: '',
      numeroservicio: '',
      numeroFicha: '',
      telefono: ''
    };
    this.setState({ documento: documento });
    this.props.change('hFechaInicio', getFormatDate(new Date(), 'ymd', '-'))
    this.props.change('hFechaFin', getFormatDate(new Date(), 'ymd', '-'))
    this.setState({ errorFecha: false });
    this.setState({ errorFechaFin: false });

  }

  setFechaIni = (event) => {
    let fecha = event.target.value;
    //console.log("la fecha de fin es: " + this.state.documento.docuFechaFin);
    if ((this.state.documento.docuFechaFin && fecha) && (fecha > this.state.documento.docuFechaFin)) {
      this.state.documento.docuFechaIni = fecha;
      this.props.change('hFechaInicio', fecha)
      this.setState({ errorFecha: "Es mayor a la fecha fin." });
    } else {
      this.setState({ errorFechaFin: false });
      this.setState({ errorFecha: false });
      this.state.documento.docuFechaIni = fecha;
      this.props.change('hFechaInicio', fecha)
    }
  }

  setFechaFin = (event) => {
    let fecha = event.target.value;
    if ((this.state.documento.docuFechaIni && fecha) && (fecha < this.state.documento.docuFechaIni)) {
      this.state.documento.docuFechaFin = fecha;
      this.props.change('hFechaFin', fecha)
      this.setState({ errorFechaFin: "Es menor a la fecha inicio." });
    } else {
      this.setState({ errorFecha: false });
      this.setState({ errorFechaFin: false });
      this.state.documento.docuFechaFin = fecha;
      this.props.change('hFechaFin', fecha)
    }
  }

  render() {
    const { handleSubmit, classes } = this.props;
    const { documento, fecdefault, errorFecha, errorFechaFin } = this.state;
    return (
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <GridContainer> 
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput labelText="Fecha Inicio"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "docuFechaIni",
                    name: "docuFechaIni",
                    type: "date",
                    //defaultValue: fecdefault,
                    onChange: this.setFechaIni,
                    value:  documento.docuFechaIni,
                    format: 'DD-MM-YYYY'
                  }}
                 // validate={[ required ]}
                  meta={{ touched: true, error: errorFecha, warning: false }}
                  //value={documento.docuFechaIni}
                  //name="docuFechaIni"
                 // id="docuFechaIni"
                   />
                  <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "hFechaInicio",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={documento.docuFechaIni}
                  name="hFechaInicio"
                  id="hFechaInicio" />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput labelText="Fecha Fin"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "date",
                    name: "txtfechafin",
                    //defaultValue: fecdefault,
                    onChange: this.setFechaFin,
                    value:  documento.docuFechaFin
                  }}
                 // validate={[ required ]}
                  meta={{ touched: true, error: errorFechaFin, warning: false }}
                  value={documento.docuFechaFin}
                  name="txtfechafin"
                  id="txtfechafin" />
                  <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "hFechaFin",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={documento.docuFechaFin}
                  name="hFechaFin"
                  id="hFechaFin" />
              </GridItem>
            </GridContainer>
          </GridItem>
          
        </GridContainer>
        <div style={{ textAlign: "right" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >Buscar </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={this.limpiarForm}
          >Limpiar</Button>
        </div>
      </form>
    );
  }
}
ConTecnicoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
ConTecnicoForm = reduxForm({
  form: 'conTecnicoForm',
  validate
})(ConTecnicoForm)

const selector = formValueSelector('conTecnicoForm')
const mapState = (state) => ({
  loading: state.load.loading,
  dscversion: selector(state, 'dscversion')
});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(documentoActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    usuarioActions: bindActionCreators(usuarioActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(ConTecnicoForm));