import {
    transition,
    container,
    defaultFont,
    primaryColor
} from "styles/jss/material-dashboard-react.jsx";
const loginStyle = theme => ({
    mainPanel: {
        [theme.breakpoints.up(960)]: {
            width: `calc(100% - 550px)`
        },
        [theme.breakpoints.up(1050)]: {
            width: `calc(100% - 650px)`
        },
        [theme.breakpoints.up(1180)]: {
            width: `calc(100% - 775px)`
        },
        overflow: "hidden !important",
        position: "relative",
        float: "right",
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    content: {
        marginTop: "70px",
        padding: "30px 15px",
        minHeight: "calc(100vh - 185px)",
        ...transition
    },
    container,

    paper: {
        [theme.breakpoints.up("xs")]: {
            width: "70%"
        },
        [theme.breakpoints.up("sm")]: {
            width: "45%"
        },
        [theme.breakpoints.up("md")]: {
            width: "80%"
        },
        [theme.breakpoints.up("lg")]: {
            width: "60%"
        },
        [theme.breakpoints.up(1450)]: {
            width: "50%"
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 auto",
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.error.main,
    },
    bold: {
        fontWeight : 'bold'
    },
    form: {
        width: '100%', // Fix IE11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    loginBar: {
        backgroundColor: "hsla(0,0%,100%,.0)",
        boxShadow: "none",
        borderBottom: "0",
        marginBottom: "0",
        position: "absolute",
        width: "100%",
        zIndex: "1029",
        color: "#555555",
        border: "0",
        borderRadius: "3px",
        minHeight: "50px",
        display: "block"
    },
    logo: {
        position: "relative",
        padding: "15px 15px 11px",
        zIndex: "4",
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: "0",

            height: "1px",
            right: "15px",
            width: "calc(100% - 30px)",
            backgroundColor: "rgba(180, 180, 180, 0.3)"
        },
        maxHeight: "40px"
    },
    logoLink: {
        ...defaultFont,
        textTransform: "uppercase",
        display: "block",
        fontSize: "19px",
        textAlign: "left",
        fontWeight: "400",
        lineHeight: "40px",
        textDecoration: "none",
        backgroundColor: "transparent",
        "&,&:hover": {
            color: "#FFFFFF"
        },
        transform: "translateZ(0)",
        opacity: "1"
    },
    img: {
        width: "100px",
        position: "relative",
        verticalAlign: "baseline",
        border: "0"
    },
    logoImage: {
        width: "90px",
        display: "inline-block",
        maxHeight: "30px",
        marginLeft: "10px",
        marginRight: "15px"
    },
    passwordContainter :{
        position: "relative"
    },
    visibilityIcon: {
        cursor: "pointer",
        position: "absolute",
        right: "0",
        top: "50px",
        transition: "all .3s ease-in",
        "&:hover": {
            color: primaryColor,
        },
    },
    fadeInPassword: {
        animation: "fadein 0.3s ease-in alternate",
    }
})

export default loginStyle;