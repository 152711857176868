import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as documentoActions from "actions/DocumentoActions";
import * as notificationActions from "actions/NotificationActions";
//import * as sessionActions from "actions/SessionActions";
import * as loadActions from "actions/LoadActions";

import {
  TIPO_MENSAJE, GRID_TEXT_ES, MENSAJE, MENSAJE_GENERAL
} from 'utils/Constants';
import { Doughnut } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import {Line} from 'react-chartjs-2';

function SelectChart(props) {
  const data = props.data;
  const document = props.document;
  let typeGraph = document.typeGraph?document.typeGraph:'';  
  if (typeGraph == 'PIE') {
    return <Pie data={data}
    height={350}
    options={{
      maintainAspectRatio: false
    }} />;
  }
  if (typeGraph == 'LINEA') {
    /*data.datasets[0].fill = false;
    data.datasets[0].lineTension =  0.1;
    data.datasets[0].backgroundColor= 'rgba(75,192,192,0.4)'; 
    data.datasets[0].borderColor = 'rgba(75,192,192,1)';
    data.datasets[0].borderCapStyle = 'butt';
    data.datasets[0].borderDash = [];
    data.datasets[0].borderDashOffset = 0.0;
    data.datasets[0].borderJoinStyle = 'miter';
    data.datasets[0].pointBorderColor = 'rgba(75,192,192,1)';
    data.datasets[0].pointBackgroundColor = '#fff';
    data.datasets[0].pointBorderWidth = 1;
    data.datasets[0].pointHoverRadius = 5;
    data.datasets[0].pointHoverBackgroundColor = 'rgba(75,192,192,1)';
    data.datasets[0].pointHoverBorderColor = 'rgba(220,220,220,1)';
    data.datasets[0].pointHoverBorderWidth= 2;
    data.datasets[0].pointRadius = 1;
    data.datasets[0].pointHitRadius = 10;*/
  
    return <Line data={data}
    height={350}
    options={{
      maintainAspectRatio: false
    }} />;
  }
  if (typeGraph == 'BAR') {
    return <Bar data={data}
    height={350}
    options={{
      maintainAspectRatio: false
    }} />;
  }
  return <div>Resultados de Busqueda</div>;
}

class CantDocumentoGraph extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [      ],
        datasets: [    ]
      }
    };


  }

  buscarDocumentos() {
    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    add();
    let data =  {
      labels: [],
      datasets: []
    };
    let tipGraficoSel = this.props.documento.tipReporte;
    let typeGraph = this.props.documento.typeGraph?this.props.documento.typeGraph:'';  
    this.props.actions.reporteGraficoDocumento(this.props.documento).then(response => {
      if (response.tipo !== TIPO_MENSAJE.SUCCESS) {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
      }else{
        if (response.data[0].length === 0) {
          this.setState({ data: data })
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "No se encontraron documentos a mostrar.", TIPO_MENSAJE.WARNING);
        } else {
          let dataGraph;
          if (tipGraficoSel == 'E'){
            dataGraph  =  this.generarDatosGraficoEstadoSunat(response.data[0], tipGraficoSel, typeGraph);
          }else{
            dataGraph  =  this.generarDatosGrafico(response.data[0], tipGraficoSel, typeGraph);
          }          

          let keysDataGraph = Object.keys(dataGraph);
          data.labels = dataGraph[keysDataGraph[0]].labels;
            for(var y=0; y<=keysDataGraph.length -1; y++){
              let dataSet = {};
              dataSet = {  label: dataGraph[keysDataGraph[y]].compania,
              // borderColor: 'rgba(255,99,132,1)',
                data: dataGraph[keysDataGraph[y]].data,
                backgroundColor: dataGraph[keysDataGraph[y]].colors,
                hoverBackgroundColor:  dataGraph[keysDataGraph[y]].colors
              };

              if (typeGraph == 'LINEA') {
                dataSet = {
                  label: dataGraph[keysDataGraph[y]].compania,
                  data: dataGraph[keysDataGraph[y]].data,
                  backgroundColor:  dataGraph[keysDataGraph[y]].colors,
                  hoverBackgroundColor:  dataGraph[keysDataGraph[y]].colors,
                  fill : false,
                  lineTension :  0.1,
                  backgroundColor : dataGraph[keysDataGraph[y]].color,
                  borderColor : dataGraph[keysDataGraph[y]].color,
                  borderCapStyle : 'butt',
                  borderDash : [],
                  borderDashOffset : 0.0,
                  borderJoinStyle : 'miter',
                  pointBorderColor : dataGraph[keysDataGraph[y]].color,
                  pointBackgroundColor : '#fff',
                  pointBorderWidth : 1,
                  pointHoverRadius : 5,
                  pointHoverBackgroundColor : dataGraph[keysDataGraph[y]].color,
                  pointHoverBorderColor : 'rgba(220,220,220,1)',
                  pointHoverBorderWidth : 2,
                  pointRadius : 1,
                  pointHitRadius : 10
                }
            }
            data.datasets.push(dataSet);
            this.setState({ data: data })
          }
        }
      }
      remove();
    })
  }

  generarDatosGrafico(data,rep, typeGraph ){
    let labels = [];
    let tipDocs= [];
    let companias=[];
    let colors = ["#f7261b", "#f9f216", "#f9ab18","#13cff9", "#979899"];
    
    for (var i = 0; i < data.length; i++) {
      labels[data[i].compania] = []; 
      tipDocs[data[i].tipodocumento] = [];      
      companias[data[i].compania]  =  data[i].nomb_compania; 
    }

    let keysCom = Object.keys(labels);
    let keysTipDoc = Object.keys(tipDocs);
    for(var x=0; x<=keysCom.length -1; x++){
      for(y=0; y<=keysTipDoc.length -1; y++){     
        labels[keysCom[x]][keysTipDoc[y]] = 0
      }
    }    
    for (var i = 0; i < data.length; i++) {
      let codCia = data[i].compania;
      let cantidad = data[i].cantidad;
      let monto = data[i].monto;
      let tipDoc = data[i].tipodocumento;
      labels[codCia][tipDoc] = monto;
      if (rep == "C"){
        labels[codCia][tipDoc] = cantidad;
      }
    }
    let keysLabels = Object.keys(labels);
    let dataEnd = [];
    for(var x=0; x<=keysLabels.length -1; x++){
      dataEnd[keysCom[x]] = [];
      let arrTipDoc = labels[keysCom[x]];
      let keysTipDocs = Object.keys(arrTipDoc);      
      let labelsPush = [];
      let dataComp = [];
      let colorsEnd = [];
      
      for( var y=0; y<=keysTipDocs.length -1; y++){
        labelsPush.push(keysTipDocs[y]);
        if (keysLabels.length <= 1){
          colorsEnd.push(colors[y]);
        }else{
          colorsEnd.push(colors[x]);
        }

        dataComp.push(labels[keysCom[x]][keysTipDocs[y]]);
      }
      dataEnd[keysCom[x]]['labels'] = labelsPush;
      dataEnd[keysCom[x]]['colors'] = colorsEnd;
      dataEnd[keysCom[x]]['color'] = colors[x];
      dataEnd[keysCom[x]]['data'] = dataComp;
      dataEnd[keysCom[x]]['compania'] = companias[keysCom[x]];
    }    
    return dataEnd;
  }

  generarDatosGraficoEstadoSunat(data,rep ){
    let labels = [];
    let tipDocs= [];
    let companias=[];
    let colors = ["#f7261b", "#f9f216", "#f9ab18","#13cff9", "#979899"];
    
    for (var i = 0; i < data.length; i++) {
      labels[data[i].compania] = []; 
      tipDocs[data[i].estado] = [];      
      companias[data[i].compania]  =  data[i].nomb_compania; 
    }

    let keysCom = Object.keys(labels);
    let keysTipDoc = Object.keys(tipDocs);
    for(var x=0; x<=keysCom.length -1; x++){
      for(y=0; y<=keysTipDoc.length -1; y++){     
        labels[keysCom[x]][keysTipDoc[y]] = 0
      }
    }    
    for (var i = 0; i < data.length; i++) {
      let codCia = data[i].compania;
      let cantidad = data[i].cantidad;
      let estado = data[i].estado;
      labels[codCia][estado] = cantidad;
    }
    let keysLabels = Object.keys(labels);
    let dataEnd = [];
    for(var x=0; x<=keysLabels.length -1; x++){
      dataEnd[keysCom[x]] = [];
      let arrTipDoc = labels[keysCom[x]];
      let keysTipDocs = Object.keys(arrTipDoc);      
      let labelsPush = [];
      let dataComp = [];
      let colorsEnd = [];
      
      for( var y=0; y<=keysTipDocs.length -1; y++){
        labelsPush.push(keysTipDocs[y]);
        if (keysLabels.length <= 1){
          colorsEnd.push(colors[y]);
        }else{
          colorsEnd.push(colors[x]);
        }

        dataComp.push(labels[keysCom[x]][keysTipDocs[y]]);
      }
      dataEnd[keysCom[x]]['labels'] = labelsPush;
      dataEnd[keysCom[x]]['colors'] = colorsEnd;
      dataEnd[keysCom[x]]['color'] = colors[x];
      dataEnd[keysCom[x]]['data'] = dataComp;
      dataEnd[keysCom[x]]['compania'] = companias[keysCom[x]];
    }    
    return dataEnd;
  }

  componentDidMount() {    
    this.props.onRef(this);
  }

  render() {
    const {
      data
    } = this.state;
    const { documento } = this.props;
    return (
      <SelectChart data={data} document={documento}/>
    );
  }
}

const mapState = (state) => ({
  loading: state.load.loading,
  user: state.session.user
});

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(documentoActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

export default withRouter(connect(mapState, mapDispatch)(CantDocumentoGraph));