import opcionApi from 'services/api/OpcionApi';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';

export function listarOpcionesXPerfil(usuario) {
    return function (dispatch) {
        return opcionApi.listarOpcionesXPerfil(usuario).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function insertarOpcion(opcion, user) {
    return function (dispatch) {

        return opcionApi.insertarOpcion(opcion, user).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}    

export function paginarOpcion(opcion, page, pagesize) {
    return function (dispatch) {
        return opcionApi.paginarOpcion(opcion, page, pagesize).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function actualizarOpcion(opcion, user) {
    return function (dispatch) {
        return opcionApi.actualizarOpcion(opcion, user).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function buscarOpcion(opcion, user) {
    return function (dispatch) {
        return opcionApi.buscarOpcion(opcion, user).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function buscarPadre(opcion, user) {
    return function (dispatch) {
        return opcionApi.buscarPadre(opcion, user).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}