import { APIRestAWS } from "services/common/APIRestAWS";
import { PATH_PARAMETRO_API } from "commons/config/Path";
import { OPC_PARAMETROS } from "utils/Constants";
import config from "commons/config/AWSConfig";

export default class ParametroApi {
    static async listarParametro(parametro) {
        var varQueryParams = {
            idetipparametro: parametro.idetipparametro,
            opc: OPC_PARAMETROS.LISTAR
        }
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_PARAMETRO_API.URL_METHOD, varQueryParams)
            .then(async (response) => {
                return response;
            });
    }

    static async seleccionarParametro(parametro) {
        var varQueryParams = {
            idetipparametro: parametro.idetipparametro,
            stsparametro: parametro.stsparametro,
            codparametro: parametro.codparametro,
            opc: OPC_PARAMETROS.SELECCIONAR
        }
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_PARAMETRO_API.URL_METHOD, varQueryParams)
            .then(async (response) => {
                return response;
            });
    }

    static async listarTipParametro(operacion) {
        var varQueryParams = {
            operacion
        };
        //console.log("listarTipParametro ::: " + JSON.stringify(varQueryParams));
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_PARAMETRO_API.URL_LISTA, varQueryParams)
            .then(async (response) => {
                return response;
            });
    }

    static async listarParametroDet(operacion, idetipparametro) {
        var varQueryParams = {
            operacion,
            idetipparametro
        };
        //console.log("listarParametroDet ::: " + JSON.stringify(varQueryParams));
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_PARAMETRO_API.URL_LISTA, varQueryParams)
            .then(async (response) => {
                return response;
            });
    }

    static async insertarTipParametro(parametroCab, user, operacion) {
        var varQueryParams = {
            operacion: operacion,
            idetipparametro: parametroCab.idetipparametro,
            dsctipparametro: parametroCab.dsctipparametro, 
            ststipparametro: parametroCab.ststipparametro,
            usuparametro: user.ideusuario 
        }
        //console.log("insertarTipParametro ::: " + JSON.stringify(varQueryParams));        
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.post(config.apiGateway.NAME, PATH_PARAMETRO_API.URL_METHOD, varQueryParams)
            .then(async (response) => {
                return response;
            });
    }

    static async insertarParametro(parametro, user, operacion) {
        var varQueryParams = {
            operacion: operacion,
            idetipparametro: parametro.idetipparametro,
            codparametro: parametro.codparametro,
            dscparametro: parametro.dscparametro,
            txtparametro: parametro.txtparametro,
            stsparametro: parametro.stsparametro,
            usuparametro: user.ideusuario
        }
        //console.log("insertarParametro ::: " + JSON.stringify(varQueryParams));        
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.post(config.apiGateway.NAME, PATH_PARAMETRO_API.URL_METHOD, varQueryParams)
            .then(async (response) => {
                return response;
            });
    }

    static async actualizarTipParametro(parametroCab, user, operacion) {
        var dsctipparametro = typeof parametroCab.dsctipparametro === "undefined" ? '' : parametroCab.dsctipparametro;

        var varBodyParam = {
            operacion: operacion,
            idetipparametro: parametroCab.idetipparametro,
            dsctipparametro: dsctipparametro,
            ststipparametro: parametroCab.ststipparametro,
            usuparametro: user.ideusuario
        }
        //console.log("ParametroApi ::: actualizarTipParametro ::: " + JSON.stringify(varBodyParam));
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.put(config.apiGateway.NAME, PATH_PARAMETRO_API.URL_METHOD + "/" + parametroCab.idetipparametro, varBodyParam)
            .then(async (response) => {
                return response;
            });
    }  
     
    static async actualizarParametro(parametro, codparametroOld, user, operacion) {       
        var codparametro = typeof parametro.codparametro === "undefined" ? '' : parametro.codparametro;
        var codparametroOld = typeof codparametroOld === "undefined" ? '' : codparametroOld;
        var dscparametro = typeof parametro.dscparametro === "undefined" ? '' : parametro.dscparametro;
        var txtparametro = typeof parametro.txtparametro === "undefined" ? '' : parametro.txtparametro;

        var varBodyParam = {
            operacion: operacion,
            idetipparametro: parametro.idetipparametro,
            codparametro: codparametro,
            codparametroOld: codparametroOld,
            dscparametro: dscparametro,
            txtparametro: txtparametro,
            stsparametro: parametro.stsparametro,
            usuparametro: user.ideusuario
        }
        //console.log("ParametroApi ::: actualizarParametro ::: " + JSON.stringify(varBodyParam));
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.put(config.apiGateway.NAME, PATH_PARAMETRO_API.URL_METHOD + "/" + parametro.idetipparametro, varBodyParam)
            .then(async (response) => {
                return response;
            });
    } 
    



}

