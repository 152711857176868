import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Button from "components/CustomButtons/Button";
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Details from '@material-ui/icons/Details';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Settings from '@material-ui/icons/Settings';
import List from '@material-ui/icons/List';
import TableCell from '@material-ui/core/TableCell';
import RootRef from '@material-ui/core/RootRef';
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import PropTypes from 'prop-types';
import {
  TIPO_MENSAJE, GRID_TEXT_ES, MENSAJE
} from 'utils/Constants';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import {
  SelectionState,
  IntegratedSelection,
  FilteringState, SortingState, IntegratedFiltering,
  PagingState, IntegratedPaging, DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable, Table,
  TableHeaderRow,
  TableSelection, TableFilterRow, PagingPanel
} from '@devexpress/dx-react-grid-material-ui';


import * as usuarioActions from "actions/UsuarioActions";
import * as parametroActions from "actions/ParametroActions";
import * as notificationActions from "actions/NotificationActions";
import * as loadActions from "actions/LoadActions";
//import ParametroCabGrid from "components/Grid/ParametroCabGrid";

import withStyles from "@material-ui/core/styles/withStyles";
import cuestionarioStyle from "styles/jss/app/views/cuestionarioStyle.jsx";
import ParametroDetMantForm from "components/Form/ParametroDetMantForm";

const getRowId = (row) => {
    return row.idetipparametro+"-"+row.codparametro;
}

class ParametroDetGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                { name: 'mnt', title: ' ' },
                { name: 'idetipparametro', title: 'Código' },
                { name: 'codparametro', title: 'Código det.' },
                { name: 'dscparametro', title: 'Descripción' },
                { name: 'txtparametro', title: 'Texto' },
                { name: 'dscstsparametro', title: 'Estado' }
            ],
            tableColumnExtensions: [
                { columnName: 'mnt', width: 60, align: 'right', wordWrapEnabled: true },
                { columnName: 'idetipparametro', width: 180, align: 'left', wordWrapEnabled: true },
                { columnName: 'codparametro', width: 180, align: 'left', wordWrapEnabled: true },
                { columnName: 'dscparametro', width: 250, align: 'left', wordWrapEnabled: true },
                { columnName: 'txtparametro', width: 250, align: 'left', wordWrapEnabled: true },
                { columnName: 'dscstsparametro', width: 250, align: 'left', wordWrapEnabled: true }
            ],
            filteringStateColumnExtensions: [],
            rows: [],
            currentPage: 0,
            pageSize: 10, 
            pageSizes: [10, 20, 0],
            parametro : {
                idetipparametro: '',
                codparametro: '',
                dscparametro: '',
                txtparametro: '',
                stsparametro: '',
                usuparametro: ''
            },
            metodo: null,
            showParametroActual: false,
            descIdetipparametro: '',
            disabledBoton: true,
            codparametroOld: ''
        };

        this.changeCurrentPage = currentPage => this.setState({ currentPage });
        this.changePageSize = pageSize => this.setState({ pageSize });
    }

    Cell = (props) => {
        const { column } = props;

        if (column.name === 'mnt') {
            return (
                <TableCell style={{ padding: 0 }}>
                <IconButton color="primary" aria-label="observacion" style={{ display: 'flex', float: 'left' }}
                    onClick={this.toggleDialogMantParamDet.bind(this, props.row)} title="Mantenimiento" >
                    <EditIcon />
                </IconButton>
                </TableCell>
            );
        }

        return <VirtualTable.Cell {...props} />;
    }; 
    
    componentDidMount() {
      this.props.onRef(this);
    }


    listarParametroDet = (idetipparametro) => {
        const { add, remove } = this.props.loadActions;
        const { show } = this.props.notificationActions;
        add();
        this.props.actions.listarParametroDet(idetipparametro).then(response => {

        if (response.tipo === TIPO_MENSAJE.SUCCESS) {
            this.setState({ rows: response.data, descIdetipparametro : idetipparametro });
            this.setState({disabledBoton : false }); 
        } else {
            this.setState({ rows: [] })
            show(response.indMostrarMensaje, response.mensaje, response.tipo);
        }
        remove();
        })
    }

    toggleDialogMantParamDet = (data) => {
        var { parametro, descIdetipparametro } = this.state; 
        if (typeof data !== "undefined") {          
          parametro = Object.assign(parametro, data);            
          this.setState({metodo : 0});
          let codparametroOld = parametro.codparametro;
          this.setState({codparametroOld : codparametroOld});
        } else {      
            parametro.idetipparametro = descIdetipparametro;
            parametro.codparametro = '';
            parametro.dscparametro = '';
            parametro.txtparametro = '';
            parametro.stsparametro = '';
            this.setState({parametro : parametro});   
            this.setState({metodo : 1});
        }
    
      this.setState({ showParametroActual: !this.state.showParametroActual })
    };

    enviarMetodo = (data) =>{
        if(this.state.metodo === 1){
            this.crearParametro(data);
        }else{
            this.actualizarParametro(data);
        }
    }

    crearParametro = (data) => {
      const { add, remove } = this.props.loadActions;
      const { show } = this.props.notificationActions;             
        add();    
        this.props.actions.insertarParametro(data, this.props.user).then(response => {       
            if (response.tipo === TIPO_MENSAJE.SUCCESS) {        
                show(response.indMostrarMensaje, response.mensaje, response.tipo);          
                this.listarParametroDet(this.state.descIdetipparametro);
                this.toggleDialogMantParamDet();        
            }else {
                show(response.indMostrarMensaje, response.mensaje, response.tipo);      
            }        
            remove();
        });
    }

    actualizarParametro = (data) =>{    
      const { add, remove } = this.props.loadActions;
      const { show } = this.props.notificationActions;                 
        add();  
        this.props.actions.actualizarParametro(data, this.state.codparametroOld, this.props.user).then(response => {       
        if (response.tipo === TIPO_MENSAJE.SUCCESS) {        
            show(response.indMostrarMensaje, response.mensaje, response.tipo);          
            this.listarParametroDet(this.state.descIdetipparametro);      
        }else{
            show(response.indMostrarMensaje, response.mensaje, response.tipo);
        }        
        remove();
        });
    }

    render() {
        const {rows, columns, currentPage, pageSize, 
            tableColumnExtensions, 
            pageSizes,
            showParametroActual, parametro, 
            descIdetipparametro, 
            disabledBoton } = this.state;
        const { handleSubmit, classes, estadosDet, pristine } = this.props;
        let descIdetipparametro1 = descIdetipparametro !== '' ? ' : ' + descIdetipparametro : '';
        return (
        <div>
            <Button
                    type="button"
                    variant="contained"
                    color="primary"                      
                    onClick={() => this.toggleDialogMantParamDet()}
                    disabled={disabledBoton}
            >Nuevo {descIdetipparametro1} </Button>
            <br/>
            <form className={classes.root} onSubmit={handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <Paper>
                            <Grid
                            rows={rows}
                            columns={columns}
                            style={{ height: "550px" }}
                            className={"ps-child"}
                            getRowId={getRowId}
                            >

                            <FilteringState
                                columnExtensions={this.state.filteringStateColumnExtensions} />
                            <IntegratedFiltering />
                            <SortingState />
                            <PagingState
                                currentPage={currentPage}
                                onCurrentPageChange={this.changeCurrentPage}
                                pageSize={pageSize}
                                onPageSizeChange={this.changePageSize}
                            />
                            <IntegratedPaging />
                            
                            <VirtualTable
                                columnExtensions={tableColumnExtensions}
                                cellComponent={this.Cell}
                                messages={GRID_TEXT_ES}
                            />
                            <TableHeaderRow />
                            <TableFilterRow messages={GRID_TEXT_ES} />
                            <PagingPanel
                                pageSizes={pageSizes}
                                messages={GRID_TEXT_ES}
                            />
                            </Grid>
                        </Paper>
                        </GridItem>
                    </GridContainer>
                    <Dialog                                
                        open={showParametroActual}
                        onClose={this.toggleDialogMantParamDet}
                        >                        
                        <DialogTitle>Mantenimiento de Parametro Detalle</DialogTitle>
                        <DialogContent>
                        {/* */}
                        <ParametroDetMantForm classes={classes} 
                            toggleDialogMantParamDet={this.toggleDialogMantParamDet}
                            estadosDet={estadosDet} parametroDet={parametro}
                            onSubmit={this.enviarMetodo} />
                        </DialogContent>               
                        <DialogActions>
                        {/*  <Button onClick={this.toggleDialogMantDriver}>Cerrar</Button> */}
                        </DialogActions>            
                    </Dialog>
            </form>
        </div>

        );
    }

}

const mapState = (state) => ({
  user: state.session.user,
  loading: state.load.loading
});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(parametroActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    usuarioActions: bindActionCreators(usuarioActions, dispatch)
  };
};

ParametroDetGrid = withStyles(cuestionarioStyle)(ParametroDetGrid);
export default withRouter(connect(mapState, mapDispatch)(ParametroDetGrid));