import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomAutocomplete from "components/CustomInput/CustomAutocomplete";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';
import * as domicilioActions from "actions/DomicilioActions";
import * as loadActions from "actions/LoadActions";
import * as usuarioActions from "actions/UsuarioActions";
import * as notificationActions from "actions/NotificationActions";
import { s2ab } from 'utils/Functions';
import { getFormatDate, getRestarDias } from 'utils/Functions';
import { DATA_DISTRITOS, DATA_TECNICOS, DATA_ESTADOS } from 'utils/GeneralData';

import { validateCriterioBusqueda, required } from 'utils/validators/ValidateCriterioBusqueda';
import { change } from 'redux-form'


class CriterioBusquedaForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {  
      documento: {  
        clieNumero: '',   
        docuFechaIni: '',
        docuFechaFin: '',
        estado: [],  
        estado: '', 
        numeroFicha: '',
        telefono: '' 
      },
      distritos: [],
      fecdefault: getFormatDate(new Date(),  'ymd', '-'),
      errorFecha: '',
      errorFechaFin: '',
      estadoArtefactos: []
    }
  }
  componentDidMount() {
    this.props.change('hFechaInicio', getFormatDate(new Date(), 'ymd', '-'))
    this.props.change('hFechaFin', getFormatDate(new Date(), 'ymd', '-'))
    this.state.documento.docuFechaIni = getFormatDate(new Date(), 'ymd', '-')
    this.state.documento.docuFechaFin = getFormatDate(new Date(), 'ymd', '-')
    //para filtros exportar excel
    this.props.documento.docuFechaIni = getFormatDate(new Date(), 'ymd', '-')
    this.props.documento.docuFechaFin = getFormatDate(new Date(), 'ymd', '-')
    this.state.estadoArtefactos = DATA_ESTADOS;

  }

  getSelectValue = (valor, name) => {
    var documento = this.state.documento
    documento[name] = valor;
    this.setState({ documento: documento });
  }

  limpiarForm = () => {
    this.props.reset();
    var documento = {
      clieNumero: '',  //es ruc
      docuFechaIni: getFormatDate(new Date(), 'ymd', '-'),
      docuFechaFin: getFormatDate(new Date(), 'ymd', '-'),
      estado: '',
      numeroFicha: '',
      telefono: ''      
    };
    this.setState({ documento: documento });
    this.props.change('hFechaInicio', getFormatDate(new Date(), 'ymd', '-'))
    this.props.change('hFechaFin', getFormatDate(new Date(), 'ymd', '-'))
    this.setState({ errorFecha: false });
    this.setState({ errorFechaFin: false });

  }

  setFechaIni = (event) => {
    let fecha = event.target.value;
    //console.log("la fecha de fin es: " + this.state.documento.docuFechaFin);
    if ((this.state.documento.docuFechaFin && fecha) && (fecha > this.state.documento.docuFechaFin)) {
      this.state.documento.docuFechaIni = fecha;
      this.props.change('hFechaInicio', fecha)
      this.setState({ errorFecha: "Es mayor a la fecha fin." });
    } else {
      this.setState({ errorFechaFin: false });
      this.setState({ errorFecha: false });
      this.state.documento.docuFechaIni = fecha;
      this.props.change('hFechaInicio', fecha)
    }
  }

  setFechaFin = (event) => {
    let fecha = event.target.value;
    if ((this.state.documento.docuFechaIni && fecha) && (fecha < this.state.documento.docuFechaIni)) {
      this.state.documento.docuFechaFin = fecha;
      this.props.change('hFechaFin', fecha)
      this.setState({ errorFechaFin: "Es menor a la fecha inicio." });
    } else {
      this.setState({ errorFecha: false });
      this.setState({ errorFechaFin: false });
      this.state.documento.docuFechaFin = fecha;
      this.props.change('hFechaFin', fecha)
    }
  }

  render() {
    const { handleSubmit, classes } = this.props;
    const { documento, fecdefault, errorFecha, errorFechaFin } = this.state;
    return (
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Número Documento"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "text",
                    name: "clieNumero",
                    min: "1"
                  }}
                  component={CustomInput}
                  validate={[required]}
                  value={documento.clieNumero}
                  name="clieNumero"
                  id="clieNumero" />
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Telefono"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "text",
                    name: "telefono",
                    min: "1"
                  }}
                  component={CustomInput}
                  validate={[required]}
                  value={documento.telefono}
                  name="telefono"
                  id="telefono" />
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                 <Field labelText="Ficha"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "text",
                    name: "numeroFicha",
                    min: "1"
                  }}
                  component={CustomInput}
                  validate={[required]}
                  value={documento.numeroFicha}
                  name="numeroFicha"
                  id="numeroFicha" />
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                 <Field labelText="Estado"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "estado",
                    name: "estado"
                  }}
                  multiple={true}
                  data={this.state.estadoArtefactos}
                  component={CustomAutocomplete}
                  valor={documento.estado}
                  //validate={[ required ]}
                  getValue={this.getSelectValue}
                  val="id"
                  txt="valor"
                  name="estado"
                  id="estado" />
              </GridItem>

            </GridContainer>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput labelText="Fecha Inicio"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "docuFechaIni",
                    name: "docuFechaIni",
                    type: "date",
                    //defaultValue: fecdefault,
                    onChange: this.setFechaIni,
                    value:  documento.docuFechaIni,
                    format: 'DD-MM-YYYY'
                  }} 
                  meta={{ touched: true, error: errorFecha, warning: false }}
                
                   />
                  <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "hFechaInicio",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={documento.docuFechaIni}
                  name="hFechaInicio"
                  id="hFechaInicio" />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput labelText="Fecha Fin"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "date",
                    name: "txtfechafin", 
                    onChange: this.setFechaFin,
                    value:  documento.docuFechaFin
                  }}
                 // validate={[ required ]}
                  meta={{ touched: true, error: errorFechaFin, warning: false }}
                  value={documento.docuFechaFin}
                  name="txtfechafin"
                  id="txtfechafin" />
                  <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "hFechaFin",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={documento.docuFechaFin}
                  name="hFechaFin"
                  id="hFechaFin" />
              </GridItem>
            </GridContainer>
          </GridItem>
          
        </GridContainer>
        <div style={{ textAlign: "right" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >Buscar </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={this.limpiarForm}
          >Limpiar</Button>
        </div>
      </form>
    );
  }
}
CriterioBusquedaForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
CriterioBusquedaForm = reduxForm({
  form: 'criterioBusquedaForm',
  validateCriterioBusqueda
})(CriterioBusquedaForm)

const selector = formValueSelector('criterioBusquedaForm')
const mapState = (state) => ({
  loading: state.load.loading,
  dscversion: selector(state, 'dscversion')
});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(domicilioActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    usuarioActions: bindActionCreators(usuarioActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(CriterioBusquedaForm));