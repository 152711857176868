import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import customInputStyle from "styles/jss/material-dashboard-react/components/customInputStyle";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


function NoOptionsMessage(props) {
  return (
    <div
      className={props.selectProps.classes.noOptionsMessageAutocomplete}
      {...props.innerProps}
    >
      {"Sin opciones"}
    </div>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      className={props.selectProps.classes.itemSelect}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <div
      className={props.selectProps.classes.placeholderAutocomplete}
      {...props.innerProps}
    >
      {"Seleccionar"}
    </div>
  );
}

function SingleValue(props) {
  return (
    <div className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </div>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainerAutocomplete}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}
function Menu(props) {
  return (
    <Paper square elevation={8} className={props.selectProps.classes.paperAutocomplete} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}
function DropdownIndicator(props) {
  return <ArrowDropDownIcon />
}
function ClearIndicator(props) {
  return <CancelIcon onClick={props.clearValue} />
}

class CustomAutocomplete extends React.Component {

  handleChange = name => value => {
    const { multiple, val } = this.props;
    if (multiple) {
      var arrayRspta=[]
      if (value.length>0) {
        value.forEach(element => {
          arrayRspta.push(element[val]);
        });
      }
      this.props.input.onChange(arrayRspta.join(', '));
    } else {
      this.props.input.onChange(value ? value[val] : "");
    }
    this.props.getValue(value, this.props.id)
    
  };

  render() {
    const {
      classes,
      formControlProps,
      labelText,
      id,
      labelProps,
      inputProps,
      requiredLabel,
      meta: { touched, error, warning },
      multiple,
      data,
      val,
      txt,
      valor } = this.props;
    const selectStyles = {
      input: base => ({
        ...base,
        '& input': {
          font: 'inherit',
        },
      }),
    };
    const labelClasses = classNames({
      [" " + classes.labelRootError]: Boolean(touched && (error || warning)),
      [" " + classes.labelRootSuccess]: Boolean(touched && !(error || warning))
    });
    const underlineClasses = classNames({
      [classes.underlineError]: Boolean(touched && (error || warning)),
      [classes.underlineSuccess]: Boolean(touched && !(error || warning)),
      [classes.underline]: true
    });
    const marginTop = classNames({
      [classes.marginTop]: labelText === undefined
    });
    //console.log("valor de autocomplete", valor);
    var shrinkValue = valor !== null && valor.length !== 0 ? true : false;
    function handleFocus(a) {
      shrinkValue = true;
    }
    function handleBlur(a) {
      shrinkValue = false;
    }
    return (

      <Select
        classes={classes}
        options={data}
        styles={selectStyles}
        isMulti={multiple}
        components={{
          Menu,
          Option,
          SingleValue,
          ValueContainer,
          Placeholder,
          MultiValue,
          DropdownIndicator,
          ClearIndicator,
          NoOptionsMessage,
          Control: function (propss) {
            return (
              <TextField
                error={Boolean(touched && (error || warning))}
                label={labelText}
                className={classes.formControl}
                fullWidth
                InputLabelProps={{
                  className: classes.labelRoot + labelClasses,
                  required: requiredLabel,
                  htmlFor: id,
                  shrink: shrinkValue,
                  ...labelProps
                }}
                InputProps={{
                  inputComponent,
                  classes: {
                    root: marginTop,
                    disabled: classes.disabled,
                    underline: underlineClasses
                  },
                  inputProps: {
                    className: propss.selectProps.classes.inputAutocomplete,
                    inputRef: propss.innerRef,
                    children: propss.children,

                    ...propss.innerProps,
                  },
                }}
                {...propss.selectProps.textFieldProps}
                helperText={touched && (error) ? error : ""}
              />
            )
          },
          className: formControlProps.className + " " + classes.formControl
        }}
        onChange={this.handleChange("single")}
        value={valor}
        getOptionValue={(option) => (option[val])}
        getOptionLabel={(option) => (option[txt])}
        onFocus={handleFocus}
        onBlur={handleBlur}
        maxMenuHeight={125}
        isClearable={true}
        {...inputProps}
      />
    );
  }
}
CustomAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(customInputStyle)(CustomAutocomplete);