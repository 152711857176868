import {
  primaryColor,
  dangerColor,
  successColor,
  defaultFont
} from "styles/jss/material-dashboard-react.jsx";
import { emphasize } from '@material-ui/core/styles/colorManipulator';
const customInputStyle = theme => ({
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#D2D2D2 !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: primaryColor
    }
  },
  underlineError: {
    "&:after": {
      borderColor: dangerColor
    }
  },
  underlineSuccess: {
    "&:after": {
      borderColor: successColor
    }
  },
  labelRoot: {
    ...defaultFont,
    color: "#AAAAAA !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857"
  },
  labelRootError: {
    color: dangerColor
  },
  labelRootSuccess: {
    color: successColor
  },
  feedback: {
    position: "absolute",
    top: "18px",
    right: "0",
    zIndex: "2",
    display: "block",
    width: "24px",
    height: "24px",
    textAlign: "center",
    pointerEvents: "none"
  },
  marginTop: {
    marginTop: "16px"
  },
  formControl: {
    paddingBottom: "10px",
    margin: "0",
    position: "relative"
  },
  itemSelect: {
    fontSize: '0.8125rem',
    height: 'auto',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  menuItemCheck: {
    paddingBottom: "0px"
  },
  sinMargen: {
    padding: "0px", 
    margin: "0px"
  },
  noOptionsMessageAutocomplete: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },

  inputAutocomplete: {
    display: 'flex',
    padding: 0
  },

  placeholderAutocomplete: {
    display: 'none',
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },

  paperAutocomplete: {
    position: 'absolute',
    zIndex: 2,
    marginTop: `${theme.spacing.unit}px - 0px`,
    left: 0,
    right: 0,
    top: 50
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  input: base => ({
    ...base,
    color: theme.palette.text.primary,
    '& input': {
      font: 'inherit',
    },
  }),
  valueContainerAutocomplete: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    paddingTop: '5px'
  }
});

export default customInputStyle;
