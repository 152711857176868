export const FORM_VALIDATION_MESSAGE = {
    REQUIRED: "Campo Requerido",
    EMAIL: "Email no válido",
    INTEGER: "Valor no es entero",
    MAXNUMBER: "Debe ser menor o igual a ",
    MINNUMBER: "Debe ser mayor o igual a ",
    MAXLENGTH: "Debe tener %s caracteres o menos"
}

export const LOGIN_TITLE_MESSAGE = {
    USER: "Usuario",
    PASS: "Contraseña",
    LOGIN: "Ingresar",
    LOGININVISIBLE: "Ver contraseña",
    WELCOME: "Bienvenido ",
    FORGOTPASS: "Olvidé mi contraseña",
    ERROR_NO_PROFILE: "No puedes acceder ya que no cuentas con el perfil requerido"
}

export const API_MESSAGE = {
    ERROR_NO_SERVICE: "Ocurrió un error, por favor intenta nuevamente",
    ERROR: "error",
    SUCCESS: "success"
}

export const FORGOT_PASSWORD_MESSAGE = {
    TITLE: "Usuario: ",
    SUBTITLE: "Genera una nueva contraseña de inspección virtual",
    VALIDATE_FORM: "Verifica los datos del formulario",
    FORGOT_PASSWORD: "Se ha enviado un código a tu correo electrónico, ingresalo para que puedas generar una nueva contraseña",
    FORGOT_PASSWORD_OK: "Tu contraseña ha sido cambiada",
    BTN_FORGOTPASS: "Generar contraseña",
    PHOLDER_INPASSWORD: "Ingresa tu nueva contraseña",
    PHOLDER_INNEWPASSWORD: "Repite tu nueva contraseña",
    PHOLDER_INVERIFICATIONCODE: "Ingresa el código de verificación",
    VALID_INPASSWORD: "Las contraseñas deben ser iguales",
}

export const COGNITO_AUTH_MESSAGE = [
    { 
        code: "NotAuthorizedException",
        message: "Usuario y/o contraseña inválidos"
    },
    {
        code: "UserNotFoundException",
        message: "Usuario no se encuentra registrado"
    },
    {   code: "LimitExceededException",
        message: "Límite de cantidad de intentos excedido, por favor intente después de unos minutos"
    },
    {   code: "InvalidParameterException",
        message: "Verifica los datos ingresados"
    },
    {   code: "CodeMismatchException",
        message: "El código de verificación no es válido, por favor intenta nuevamente"
    }
]

export const CHANGE_PASSWORD_MESSAGE = {
    TITLE: "Usuario: ",
    SUBTITLE: "Genera una nueva contraseña de inspección virtual",
    VALIDATE_FORM: "Verifica los datos del formulario.",
    CHANGE_PASSWORD: "Estas ingresando por primera vez, por favor cambia la contraseña",
    CHANGE_PASSWORD_OK: "Tu contraseña ha sido cambiada. Por favor vuelve a iniciar sesión",
    BTN_FORGOTPASS: "Generar contraseña",
    PHOLDER_INPASSWORD: "Ingresa tu nueva contraseña",
    PHOLDER_INNEWPASSWORD: "Repite tu nueva contraseña",
    VALID_INPASSWORD: "Las contraseñas deben ser iguales",
    VALID_PASWORD_VAC: "Ingresa tu nueva contraseña",
}
