import axios from "axios";
import { PATH_DOCUMENTO_API, PATH_SERFAC_API } from "commons/config/Path";
import config from "commons/config/AWSConfig";
import S3 from "react-aws-s3";
import AWS from 'aws-sdk'
export default class ClienteApi {
  
    static async upload2(  file, documento, onUploadProgress )  { 
        let formData = new FormData(); 
        
        formData.append("file", file);
        let httpClient = axios.create({headers: {
          'Content-Type': 'application/json', 
          'Authorization': localStorage.getItem('aws_token')    
        }});
        
        
        const toBase64 = file =>  new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.onerror = () => { reader.abort(); reject(new Error("Error parsing file"));}
            reader.onload = function () {

                //This will result in an array that will be recognized by C#.NET WebApi as a byte[]
                let bytes = Array.from(new Uint8Array(this.result));

                //if you want the base64encoded file you would use the below line:
                let base64StringFile = btoa(bytes.map((item) => String.fromCharCode(item)).join(""));

                //Resolve the promise with your custom file structure
                resolve({ 
                    //bytes: bytes,
                    base64StringFile: base64StringFile,
                    fileName: file.name, 
                    fileType: file.type
                });
            }
            reader.readAsArrayBuffer(file);
        });

           
        const data = {
            title: file.name,
            file: await toBase64(file),
            desc: "desc",
            id: documento.id,
            fechaIngresoPath: documento.fechaIngreso.substr(0,10).replace(/-/g,"/"),
            operacion: "upload"
        }

        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.post(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA, data, {
            headers: {
              "Content-Type": "application/json",
            },
            onUploadProgress,
          });

    }

    static async upload(  file, documento, onUploadProgress )  {
       
      AWS.config.update({
          accessKeyId: 'AKIASLT7MSG2RHZYGL6I',
          secretAccessKey: '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'
      })
      
      const myBucket = new AWS.S3({
          params: { Bucket: "ue2stgdesaserart001"},
        region: "us-east-1",
      })

      const params = {
        ACL: 'public-read',
        Body: file, 
        Bucket: "ue2stgdesaserart001",
        Key: "IMAGEN2/"+documento.fechaIngreso.substr(0,10).replace(/-/g,"/") + "/" + file.name
    };

    return myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                 console.log("subiii")
            })
            .send((err) => {
                if (err) console.log(err)
                console.log("termino de cargar el archivo")
            })

      /*
      const config = {
        bucketName: "ue2stgdesaserart001",
        dirName: "IMAGEN2/"+documento.fechaIngreso.substr(0,10).replace(/-/g,"/") 
        region: "us-east-1",
        accessKeyId: "AKIASLT7MSG2RHZYGL6I",
        secretAccessKey: "XS/yvAVfne0I4PIYjZ+a5Xh82eVyftWiuio6vJnD",
      };
      const ReactS3Client = new S3(config);
      return ReactS3Client.uploadFile(file, file.name);


      */
    }

    static async getFiles(  documento )  { 
  
      let httpClient = axios.create({headers: {
        'Content-Type': 'application/json', 
        'Authorization': localStorage.getItem('aws_token')    
      }});  
      const data = {
          id: documento.id,
          fechaIngresoPath: documento.fechaIngreso.substr(0,10).replace(/-/g,"/"),
          operacion: "listImagen"
      }

      httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
      return httpClient.post(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA, data, {
          headers: {
            "Content-Type": "application/json",
          }
        });

    }


    static async deteleFile(  keyImage )  { 
  
      let httpClient = axios.create({headers: {
        'Content-Type': 'application/json', 
        'Authorization': localStorage.getItem('aws_token')    
      }});  
      const data = {
          key: keyImage,
          operacion: "deleteImagen"
      }

      httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
      return httpClient.post(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA, data, {
          headers: {
            "Content-Type": "application/json",
          }
        });

    }


}