import * as React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as usuarioActions from "actions/UsuarioActions";
import * as notificationActions from "actions/NotificationActions";
import * as parametroActions from "actions/ParametroActions";
import * as loadActions from "actions/LoadActions";
import * as domicilioActions from "actions/DomicilioActions";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
//import PublicacionGrid from "components/Grid/PublicacionGrid";
import {TIPO_MENSAJE, GRID_TEXT_ES, MENSAJE, ESTADO} from 'utils/Constants';
import { email, required} from 'utils/FormValidation';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import {
  CustomPaging, SortingState, EditingState, PagingState, DataTypeProvider, SummaryState, IntegratedSummary
} from '@devexpress/dx-react-grid';
import {
  Grid, TableEditColumn, Table,   TableColumnVisibility,
  VirtualTable, TableEditRow, TableHeaderRow, PagingPanel, 
  TableColumnResizing, TableSummaryRow
} from '@devexpress/dx-react-grid-material-ui';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import CustomInput from "components/CustomInput/CustomInput";
import { change } from 'redux-form'; 
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { DATA_TIPO_COSTOS, DATA_ESTADO_REPUESTO} from 'utils/GeneralData';
import { CurrencyTypeProvider } from 'theme-sources/material-ui/components/currency-type-provider';
const DeleteButton = ({ onExecute }) => (
  <IconButton
    onClick={() => {
      // eslint-disable-next-line
      if (window.confirm('Estas seguro de eliminar el registro?')) {
        onExecute();
      }
    }}
    title="Eliminar"
  >
    <DeleteIcon />
  </IconButton>
);

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Editar" style={{padding: "2px"}}>
    <EditIcon />
  </IconButton>
);

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Guardar" style={{padding: "2px"}}>
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancelar" style={{padding: "2px"}}>
    <CancelIcon />
  </IconButton>
);

const AddButton = ({ onExecute }) => (
  <div style={{ textAlign: 'center' }}>
    <Button
      color="primary"
      onClick={onExecute}
      title="Nuevo"
    >
      ADD
    </Button>
  </div>
);

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton
};

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return (
    <CommandButton style={{ padding: 0 }}
      onExecute={onExecute}
    />
  );
};

const Row = (props) => {
  const { row } = props;
  let style = {}
  if (row.flag === 1) {
    style = { backgroundColor: "lightcyan" }
  }
  return <VirtualTable.Row {...props} style={style} />;
};

const EditCell = (props) => {
  return <TableEditRow.Cell {...props} />;
};

const getRowId = row => row.id;

//Estado de Usuario
const EstadoTypeProvider = props => {
  const BooleanEditor = ({ value, onValueChange }) => {
    return  <Select
              input={<Input />}
              value={value}
              onChange={event => onValueChange(event.target.value)}
              style={{ width: '100%', height: "150px" }}
            >
            {props.estados.map(({stsusuario,dscstsusuario}) => (
            <MenuItem  value={stsusuario} >
              {dscstsusuario }
            </MenuItem>
            ))}           
          </Select>
         
  };
  const BooleanFormatter = ({ value }) => {
    var dscstsusuario = 'PARAMETRO INVALIDO';
    for (var key in props.todosestados) {
      const obj = props.todosestados[key];
      if (obj.stsusuario == value) {
        dscstsusuario = obj.dscstsusuario;
      }
    }  
    return <Chip label={dscstsusuario} />      
  };

  return <DataTypeProvider  formatterComponent={BooleanFormatter}
                            editorComponent={BooleanEditor}
                            {...props}
  />
};
//Fin Estado Usuario

//tipo de costo 
const CostoTypeProvider = props => {
  const BooleanEditor = ({ value, onValueChange }) => {
    return  <Select
              input={<Input />}
              value={value}
              onChange={event => onValueChange(event.target.value)}
              style={{ width: '100%' }}
            >
            {props.estados.map(({id,name}) => (
            <MenuItem  value={id} >
              {name }
            </MenuItem>
            ))}           
          </Select>
         
  };
  const BooleanFormatter = ({ value }) => {
    console.log("value del objeto", value);
    var dscstsusuario = 'PARAMETRO INVALIDO';
    for (var key in props.todosestados) {
      const obj = props.todosestados[key];
      if (obj.id == value) {
        dscstsusuario = obj.name;
      }
    }  
    return <Chip label={dscstsusuario} />      
  };

  return <DataTypeProvider  formatterComponent={BooleanFormatter}
                            editorComponent={BooleanEditor}
                            {...props}
  />
};

// final de tipo de costo
//Perfil de Usuario
const PerfilTypeProvider = props => {
  var perfilesactivos = [];

 
  const PerfilEditor = ({ value, onValueChange }) => { 
    return <Select
              input={<Input />}
              value={value}
              onChange={event => onValueChange(event.target.value)}
              style={{ width: '100%' }}
            >
              {perfilesactivos.map(({ideperfil,nomperfil}) => (
              <MenuItem  value={ideperfil} >
                {nomperfil}
              </MenuItem>
              ))}  
          </Select>
  };

  const PerfilFormatter = ({ value }) => {
    var nomperfil = 'PERFIL INVALIDO';
    for (var key in props.perfiles) {
      var obj = props.perfiles[key];
      if (obj.ideperfil == value) {
        nomperfil = obj.nomperfil;
      }
    }    
    return <Chip label={nomperfil} />
  };

  return <DataTypeProvider
          formatterComponent={PerfilFormatter}
          editorComponent={PerfilEditor}
          {...props}
        />
};
//Fin Perfil Usuario


//Correo de Usuario
const CorreoEditor = ({ value, onValueChange }) => { 

  var label = required(value) !== undefined ? required(value) : (email(value) !== undefined ? email(value) : '');
  var error = required(value) ? true : (email(value) ? true : false);

  return   <TextField            
            id="standard-name"
            label={label}
            value={value}
            onChange={event => onValueChange(event.target.value)}
            type="email"
            error={error}
            />
};

const CorreoTypeProvider = props => {
  return <DataTypeProvider
          editorComponent={CorreoEditor}
          {...props}
        />
};
//Fin Correo Usuario

//Nombre y Apellido de Usuario
const dscNomApeEditor = ({ value, onValueChange }) => { 

  var label = required(value) !== undefined ? required(value) : '';
  var error = required(value) ? true : false;

  return   <TextField            
            id="standard-name"
            label={label}
            value={value}
            onChange={event => onValueChange(event.target.value)}
            type="text"
            error={error}
            />
};

const DscNomApeTypeProvider = props => {
  return <DataTypeProvider
          editorComponent={dscNomApeEditor}
          {...props}
        />
};
//Fin Nombre y Apellido Usuario

class CostosDomicilioGrid extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      columns: [
        { name: 'id', title: 'Nro.' },      
        { name: 'tipcosto', title: 'Tipo Costo' },
        { name: 'codigo', title: 'Código *' },
        { name: 'nombre', title: 'Nombre *'},
        { name: 'cantidad', title: 'Cantidad *' },
        { name: 'stock', title: 'En Stock ?' },
        { name: 'precio', title: 'Precio *' },
        { name: 'total', title: 'Total *' }, 
      ],
      tableColumnExtensions: [
        { columnName: 'id', width: 70 },      
        { columnName: 'tipcosto', width: 120 },  
        { columnName: 'codigo', width: 120 },
        { columnName: 'nombre', width: 150 },
        { columnName: 'cantidad', width: 100 },
        { columnName: 'stock', width: 1 },
        { columnName: 'precio', width: 100 },
        { columnName: 'total', width: 100 } 
      ],

      editingStateColumnExtensions: [
        { columnName: 'tipcosto', editingEnabled: true },
        { columnName: 'codigo', editingEnabled: true },
        { columnName: 'nombre', editingEnabled: true },
        { columnName: 'cantidad', editingEnabled: true },
         { columnName: 'stock', editingEnabled: false },
        { columnName: 'precio', editingEnabled: true },
        { columnName: 'total', editingEnabled: true }
      ],

      hiddenColumnNames: ['ideusuario', 'id'],
      rows: [ ],
      sorting: [],
      currentPage: 0,
      totalCount: 0,
      pageSize: 100,
      pageSizes: [10, 20, 50, 100],
      currencyColumns: ['precio'],
      editingRowIds: [],
      usuario: {
        ideusuario: '',
        dscnomape: '',
        email: '',
        ideperfil: '',
        vertodogth:'',
        stsusuario:'',
        respuestos: '',
      },
      estadoColumn: ['stsusuario'],
      perfilColumn: ['ideperfil'],
      correoColumn:['email'],
      costoColumn: ['tipcosto'],
      dscNomApeColumn:['dscnomape'],
      totalSummaryItems: [ 
        { columnName: 'total', type: 'sum' },
      ],
      todosestados:[],
      tipocostos:[]
    };

    this.hiddenColumnNamesChange = (hiddenColumnNames) => {
      this.setState({ hiddenColumnNames });
    };

    this.changeSorting = sorting => this.setState({ sorting });
    this.changeEditingRowIds = editingRowIds => this.setState({ editingRowIds });
    this.changeRowChanges = rowChanges => this.setState({ rowChanges });    
    this.commitChanges = ({ added, changed, deleted }) => {
      let { rows } = this.state;
      let changedRows;
      if (changed) {
           changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      }
      
      if (deleted) {
          //changedRows = deleteRows(deleted);

          console.log("rowAEliminar", deleted);
          let deletedIds = deleted;
          const rowsForDelete = rows.slice();
          deletedIds.forEach((rowId) => {
            const index = rowsForDelete.findIndex(row => row.id === rowId);
            if (index > -1) {
              rowsForDelete.splice(index, 1);
            }
          });
          console.log("nuevos rows::", rowsForDelete);
          // this.setState({ rows: rowsForDelete});
           //          return rowsForDelete;
           changedRows = rowsForDelete;
      }

      if (added){
        console.log(added);
        const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
         changedRows = [
          ...rows,
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...row,
          })),
        ];
       // this.setState({ rows: changedRows});
      } 

      // actualizamos el total  
     for (let i = 0; i < changedRows.length; i++) {
      changedRows[i].total = changedRows[i].cantidad * changedRows[i].precio;
   }

     // this.props.change('hRepuestos', JSON.stringify(changedRows))
      this.setState({ rows: changedRows});
    };  
  }

  
  changeCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage })
    this.buscarUsuario(currentPage);
  }

  changePageSize = (pageSize) => {
    const { totalCount, currentPage: stateCurrentPage } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(stateCurrentPage, totalPages - 1);

    this.setState({
      pageSize: pageSize,
      currentPage: currentPage
    });
    this.buscarUsuario(currentPage, pageSize);
  }

  componentDidMount() {
    this.props.onRef(this);
    this.state.tipocostos = JSON.parse(localStorage.getItem("tipocostos_serfac")); //DATA_TIPO_COSTOS;
    this.state.todosestados = DATA_ESTADO_REPUESTO; 
 //   this.buscarUsuario();
   // this.listarTodosEstados();
  } 

  buscarUsuario = (page, size) => {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    const { currentPage, pageSize } = this.state;
    add();
    page = page ? page : currentPage;
    size = size ? size : pageSize;

    if (page === -1) {
      page = 0;
      this.limpiarForm();
    }
    if (page === currentPage) { page = 0; }
    this.props.actions.paginarUsuario(this.props.usuario, page + 1, size).then(response => {
      if (response.tipo === TIPO_MENSAJE.SUCCESS) {
        this.setState({ rows: response.data.rows, totalCount: response.data.totalCount })
      } else {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
      }
      remove();
    })
  }  

  extraerRows = ( ) => {
   
    const { rows  } = this.state;
    console.log("rows extraidos");
    return rows;
  }  

  limpiarRows = ( ) => {
    this.state.rows = [];
  }  

  asignarRows = ( rows ) => {
    this.state.rows = rows;
  }  


  Cell = (props) => {
    const { column } = props;

    if (column.name === 'edit') {
      return (
        <TableCell style={{ padding: 0 }}>
          <IconButton color="primary" aria-label="editIcon" style={{ display: 'flex', float: 'left' }}  title="Editar" >
            <EditIcon />            
          </IconButton>
        </TableCell>
      );
    }

    return <VirtualTable.Cell {...props} />;
  };

  render() {
    const {
      rows,
      columns,
      tableColumnExtensions,
      editingStateColumnExtensions,
      sorting,
      editingRowIds,
      rowChanges,
      currentPage,
      pageSize,
      pageSizes,
      totalCount,
      hiddenColumnNames,
      estadoColumn,
      perfilColumn,
      correoColumn,
      dscNomApeColumn,
      costoColumn,
      tipocostos,
      todosestados,
      currencyColumns,
      totalSummaryItems
    } = this.state;
    const {estados, perfiles} = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ width: "100%" }}>
            <Grid
              rows={rows}
              columns={columns}
              style={{ height: "500px" }}
              getRowId={getRowId}
              className={"ps-child"}
            >
              <SortingState
                sorting={sorting}
                onSortingChange={this.changeSorting}
              />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={this.changeCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={this.changePageSize}
              />

              <EditingState
                editingRowIds={editingRowIds}
                onEditingRowIdsChange={this.changeEditingRowIds}
                rowChanges={rowChanges}
                onRowChangesChange={this.changeRowChanges}
                onCommitChanges={this.commitChanges}
                columnExtensions={editingStateColumnExtensions}
               />
              <SummaryState
                totalItems={totalSummaryItems}
              />
               <IntegratedSummary />

              <CustomPaging
                totalCount={totalCount}
              />
              <VirtualTable
                columnExtensions={tableColumnExtensions}
                cellComponent={this.Cell}
                messages={GRID_TEXT_ES}
              />

              <CurrencyTypeProvider for={currencyColumns} />

              <EstadoTypeProvider
                  for={estadoColumn}
                  estados={estados}
                  todosestados={todosestados}
              />

              <CostoTypeProvider
                  for={costoColumn}
                  estados={tipocostos}
                  todosestados={tipocostos}
              />

              <PerfilTypeProvider
                  for={perfilColumn}
                  perfiles={perfiles}
              />

              <CorreoTypeProvider
                  for={correoColumn}
              />

              <DscNomApeTypeProvider
                  for={dscNomApeColumn}
              />
              

              <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
              <TableHeaderRow showSortingControls />
              <TableEditRow
                cellComponent={EditCell}
              />

              <TableEditColumn
                width={85}
                showAddCommand
                showEditCommand                      
                showDeleteCommand
                commandComponent={Command}
              />
               <TableSummaryRow />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
              /> 
              
            </Grid>
          </Paper>

           
        </GridItem>
      </GridContainer>
    );
  }
}

const mapState = (state) => ({
  loading: state.load.loading,  
  user: state.session.user,
});


const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(usuarioActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    parametroActions:bindActionCreators(parametroActions, dispatch),
    domicilioActions: bindActionCreators(domicilioActions, dispatch),
  };
};

export default withRouter(connect(mapState, mapDispatch)(CostosDomicilioGrid));

