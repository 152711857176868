import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm } from 'redux-form';
import * as loadActions from "actions/LoadActions";
import * as notificationActions from "actions/NotificationActions";
import PerfilDialog from "components/Dialog/PerfilDialog";

class PerfilForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
       perfil: {
           ideperfil: '',
           nomperfil:'',
           dscperfil:'',
           stsperfil:'',
       },
       showPerfilDialog: false,
    }
  }
  componentDidMount() {
  }

  getSelectValue = (valor, name) => {
    var perfil = this.state.perfil
    perfil[name] = valor;
    this.setState({ perfil: perfil });
  }
  limpiarForm = () => {
    this.props.reset();
    var perfil = { 
      ideperfil: '',
      dscperfil:'',
      nomperfil:'',
      stsperfil:''};

    this.setState({ perfil: perfil });
  }


  toggleDialogPerfil = (perfilVal) => {
    var { perfil } = this.state;
    if (typeof perfilVal !== "undefined") {
      perfil = Object.assign(perfil, perfilVal);
    } else {
      perfil.ideperfil = '';
      perfil.dscperfil = '';
      perfil.nomperfil = '';
      perfil.stsperfil = '';
    }
    this.setState({ showPerfilDialog: !this.state.showPerfilDialog })
  };

  render() {
    const { handleSubmit, classes, estados } = this.props;
    const { perfil, showPerfilDialog } = this.state;

    return (
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <Field labelText="Nombre del Perfil"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "nomperfil"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 40)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={perfil.nomperfil}
                  name="nomperfil"
                  id="nomperfil" />
              </GridItem>  
              <GridItem xs={12} sm={12} md={5}>
                <Field labelText="Descripción de Perfil"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "dscperfil"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 250)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={perfil.dscperfil}
                  name="dscperfil"
                  id="dscperfil" />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <Field labelText="Estado"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "stsperfil",
                    name: "stsperfil"
                  }}
                  multiple={false}
                  data={estados}
                  component={CustomSelect}
                  valor={perfil.stsperfil}
                  getValue={this.getSelectValue}
                  val="stsperfil"
                  txt="dscstsperfil"
                  name="stsperfil"
                  id="stsperfil" />
              </GridItem>                                      
            </GridContainer>
          </GridItem>
          <PerfilDialog toggleDialogPerfil={this.toggleDialogPerfil} 
                         showPerfilDialog={showPerfilDialog} 
                         perfil={perfil} 
                         classes={classes}
                         estados={estados} 
                         buscarPerfil={this.props.buscarPerfil}/>
        </GridContainer>
        <div style={{ textAlign: "right" }}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => this.toggleDialogPerfil()}
          >Nuevo</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >Buscar</Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={this.limpiarForm}
          >Limpiar</Button>
        </div>
      </form>
    );
  }
}

PerfilForm = reduxForm({
  form: 'perfilForm'
})(PerfilForm)
const mapState = (state) => ({
  loading: state.load.loading
});
const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(PerfilForm));