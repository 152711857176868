import { APIRestAWS } from "services/common/APIRestAWS";
import { PATH_SEGURIDAD_API } from "commons/config/Path";
import config from "commons/config/AWSConfig";
import { OPC_SEGURIDAD } from 'utils/Constants';
export default class PerfilApi {

    static async listarPerfil(stsperfil) {
        console.log('listarperfil');
        var varQueryParams = {
            opc : OPC_SEGURIDAD.LISTAR_PERFIL,
            stsperfil : stsperfil == undefined ? '' : stsperfil
        };
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_PERFIL, varQueryParams)
            .then(async (response) => {
                return response;
            }); 
    }

    static async paginarPerfil(perfil, page, pagesize) {
        var varQueryParams = {
            opc: OPC_SEGURIDAD.PAGINAR_PERFIL,
            nomperfil: perfil.nomperfil,
            dscperfil: perfil.dscperfil,
            stsperfil: perfil.stsperfil,
            page: page,
            pagesize: pagesize
        }
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_PERFIL, varQueryParams)
            .then(async (response) => {
                return response;
            });
    } 

    static async actualizarPerfil(perfil, user) {

        var varQueryParams = {
            opc : OPC_SEGURIDAD.ACTUALIZAR_PERFIL,
            perfil : {  ideperfil : perfil.ideperfil === undefined ? '' : perfil.ideperfil ,
                        dscperfil : perfil.dscperfil === undefined ? '' : perfil.dscperfil ,
                        nomperfil : perfil.nomperfil === undefined ? '' : perfil.nomperfil ,
                        stsperfil : perfil.stsperfil === undefined ? '' : perfil.stsperfil ,
                        usumodificacion : user.ideusuario
            }
        };
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.put(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_PERFIL, varQueryParams)
            .then(async (response) => {
                return response;
            }); 
    }

    static async insertarPerfil(perfil, user) {
        var varQueryParams = {
            opc : OPC_SEGURIDAD.INSERTAR_PERFIL,
            perfil : {  nomperfil : perfil.nomperfil,
                        dscperfil : perfil.dscperfil,
                        stsperfil : perfil.stsperfil,
                        usucreacion : user.ideusuario,
                        usumodificacion : user.ideusuario
            }
        };
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.post(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_PERFIL, varQueryParams)
            .then(async (response) => {
                return response;
            }); 
    }

    static async listarOpcionesxPerfil(perfil) {

        var varQueryParams = {
            opc : OPC_SEGURIDAD.LISTAR_OPCIONXPERFIL,
            ideperfil : perfil.ideperfil
        };
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_PERFIL, varQueryParams)
            .then(async (response) => {
                return response;
            }); 
    }

    static async AsignarOpcionesxPerfil(lstideopcion, perfil,  user) {
        var varQueryParams = {
            opc : OPC_SEGURIDAD.ACTUALIZAR_OPCIONXPERFIL,
            perfil : {  ideperfil : perfil.ideperfil,
                        lstideopcion : lstideopcion,
                        usucreacion : user.ideusuario,
                        usumodificacion : user.ideusuario
            }
        };
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.put(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_PERFIL, varQueryParams)
            .then(async (response) => {
                return response;
            }); 
    }
    
}


