import domicilioApi from 'services/api/DomicilioApi';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';
import { OPC_BUSQUEDA, OPC_CUESTIONARIO } from 'utils/Constants';
import { copiarJson } from 'utils/Functions';

 
export function registrarDomicilio(documento) {
    return function (dispatch) {
        return domicilioApi.registrarDomicilio( documento ).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function updateCliente(documento) {
    return function (dispatch) {
        return domicilioApi.updateCliente( documento ).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}


export function consultarDocumentoPorOtrosCampos(request) {
    return function (dispatch) {
        return domicilioApi.consultarDocumentoPorOtrosCampos( request ).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}
