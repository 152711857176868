export const LOAD_ACTIONS_TYPE = {
    ADD_LOAD: "ADD_LOAD",
    REMOVE_LOAD: "REMOVE_LOAD",
    ADD_LOAD_POPUP: "ADD_LOAD_POPUP",
    REMOVE_LOAD_POPUP: "REMOVE_LOAD_POPUP"
}

export const NOTIFICATION_ACTIONS_TYPE = {
    SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION"
}

export const FORM_ACTIONS_TYPE = {
    FORM_LOAD: "FORM_LOAD"
}
