import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor
} from "styles/jss/material-dashboard-react.jsx";

const sidebarStyle = theme => ({
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "1",
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition
    }
  },
  drawerPaperClose: {
    [theme.breakpoints.up("md")]: {
      overflowX: "hidden",
      width: theme.spacing.unit * 10,
      ...transition
    }
  },
  logo: {
    position: "relative",
    padding: "15px 15px 11px",
    zIndex: "4",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",

      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      backgroundColor: "rgba(180, 180, 180, 0.3)"
    },
    maxHeight: "40px"
  },
  logoLink: {
    ...defaultFont,
    textTransform: "uppercase",
    display: "block",
    fontSize: "19px",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "40px",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: "#FFFFFF"
    },
    transform: "translateZ(0)",
    opacity: "1"
  },
  logoLinkFactElec: {
    ...defaultFont,
    display: "block",
    textTransform: "uppercase",
    fontSize: "19px",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "25px",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: "#FFFFFF"
    },
    transform: "translateZ(0)",
    opacity: "1"
  },
  logoLinkHidden: {
    lineHeight: "50px",
    [theme.breakpoints.up("md")]: {
      opacity: "0",
    },    
  },
  logoLinkMini: {
    [theme.breakpoints.down("sm")]: {
      opacity: "0"
    },
    opacity: "1",
    transform: "translate3d(0,-120px,0)",
    transition: "all 0.9s cubic-bezier(0.685, 0.0473, 0.346, 1)",
  },
  logoLinkMiniHidden: {
    [theme.breakpoints.up("md")]: {
      opacity: "0",
    }
  },
  logoImage: {
    width: "90px",
    display: "inline-block",
    maxHeight: "30px",
    marginLeft: "10px",
    marginRight: "15px"
  },
  logoImageFactElec: {
    width: "90px",
    display: "inline-block",
    maxHeight: "30px",
    marginRight: "15px",
    float: "left"
  },
  imgMini: {
    width: "30px !important"
  },
  img: {
    width: "100px",
    position: "relative",
    verticalAlign: "baseline",
    border: "0"
  },
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: "#000",
      opacity: ".8"
    }
  },
  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset"
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: "#FFFFFF"
    }
  },
  itemLink: {
    width: "auto",
    transition: "all 300ms linear",
    margin: "10px 15px 0",
    borderRadius: "3px",
    position: "relative",
    display: "flex",
    padding: "10px 15px",
    backgroundColor: "transparent",
    color: "white",
    "&:hover": {
      backgroundColor: dangerColor,
      boxShadow:
        "0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)"
    },
    ...defaultFont
  },
  itemIcon: {
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(255, 255, 255, 0.8)",
    "& > span": {
      paddingTop: "3px"
    }
  },
  listSubMenu: {
    marginTop: "10px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset"
  },
  itemLinkSubMenu: {
    width: "auto",
    transition: "all 300ms linear",
    margin: "5px 15px 0",
    borderRadius: "3px",
    position: "relative",
    display: "flex",
    padding: "5px 15px",
    backgroundColor: "transparent",
    ...defaultFont,
    "&:hover": {
      backgroundColor: dangerColor,
      boxShadow:
        "0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)"
    }
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  },
  itemIconSubMenu: {
    width: "25px",
    height: "30px",
    lineHeight: "25px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(255, 255, 255, 0.8)",
    "& > span": {
      fontSize: "18px",
      overflow: "visible",
      paddingTop: "3px"
    }
  },
  itemText: {
    ...defaultFont,
    margin: "0",
    lineHeight: "30px",
    fontSize: "14px",
    color: "#FFFFFF",
    transform: "translateZ(0)",
    opacity: "1",
    display: "block",
    fontWeight: "400",
    position: "relative",
    maxHeight: "30px",
  },
  itemTextHidden: {
    [theme.breakpoints.up("md")]: {
      opacity: "0",
      transform: "translate3d(-25px, 0, 0)"
    }
  },
  whiteFont: {
    color: "#FFFFFF"
  },
  purple: {
    backgroundColor: primaryColor,
    ...primaryBoxShadow,
    "&:hover": {
      backgroundColor: primaryColor,
      ...primaryBoxShadow
    }
  },
  blue: {
    backgroundColor: infoColor,
    boxShadow:
      "0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)",
    "&:hover": {
      backgroundColor: infoColor,
      boxShadow:
        "0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)"
    }
  },
  green: {
    backgroundColor: successColor,
    boxShadow:
      "0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)",
    "&:hover": {
      backgroundColor: successColor,
      boxShadow:
        "0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)"
    }
  },
  orange: {
    backgroundColor: warningColor,
    boxShadow:
      "0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)",
    "&:hover": {
      backgroundColor: warningColor,
      boxShadow:
        "0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)"
    }
  },
  red: {
    backgroundColor: dangerColor,
    boxShadow:
      "0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)",
    "&:hover": {
      backgroundColor: dangerColor,
      boxShadow:
        "0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)"
    },
    transition: "all 0s linear"
  },
  redLink: {
    borderLeft: "3px solid " + dangerColor
  },
  redSubLink: {
    backgroundColor: dangerColor,
    boxShadow:
      "0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)"
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    zIndex: "4",
    overflow: "auto !important",
    overflowScrolling: "touch"
  },
  overflowHidden: {
    overflow: "hidden !important",
  },
  itemTextSubMenu: {
    paddingLeft: "5px"
  },
  sidebarProfile: {
    position: "relative",
    height: "auto",
    overflow: "auto",
    zIndex: "4",
    overflowScrolling: "touch",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",
      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      backgroundColor: "rgba(180, 180, 180, 0.3)"
    }
  }
});

export default sidebarStyle;
