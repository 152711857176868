import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Tasks from "components/Tasks/Tasks.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import * as documentoActions from "actions/DocumentoActions";
import * as notificationActions from "actions/NotificationActions";
//import * as sessionActions from "actions/SessionActions";
import * as loadActions from "actions/LoadActions";
import { bugs, website, server } from "variables/general";
import documentoStyle from "styles/jss/app/views/documentoStyle.jsx";
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts";
import { Bar } from 'react-chartjs-2';
import { HorizontalBar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2'; 
import {Line} from 'react-chartjs-2';

import dashboardStyle from "styles/jss/material-dashboard-react/views/dashboardStyle.jsx";
var Chartist = require("chartist");

class Dashboard extends React.Component {
   

  constructor(props, context) {
    super(props, context); 
    this.state = {
        taller1: [],
        taller2: [],
        taller3: [],
        taller4: [],
        taller5: [],

        tallerTot1: {},
        tallerTot2: {},
        tallerTot3: {},
        tallerTot4: {},
        tallerTot5: {},

        count: 1,
        rowsUltimos: [], 
        totales:{
            total: 0,
            registrados: 0,
            despachados: 0,
            revisados: 0,
            entregados: 0
        },
        dataestados: {
          labels: ["REGISTRADO", "REPARADO",  "ENTREGADO OK", "REVISADO", "EN REPARACIÓN", "DESPACHADO"],
          series: [[0, 0, 0, 0, 0, 0]]
        },
        optionsEstados: {
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 100, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        databarEstados: {
            labels: ["REGISTRADO", "REPARADO",  "ENTREGADO OK", "REVISADO", "EN REPARACIÓN", "DESPACHADO","REGISTRADO", "REPARADO",  "ENTREGADO OK", "REVISADO", "EN REPARACIÓN", "DESPACHADO"],
            datasets: [
              {
                label: 'RESUMEN DE ESTADOS',
                backgroundColor: ["#f50057", "#16886e", "#f9ab18","#13cff9", "#979899", "##e29b51","#00ff7e", "#c57bc0","#3a115f", "#16886e", "rgb(111 76 56)" ],
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
              } 
          ]
        },
        databarTecnicos: {
            labels: ["EDGAR RUIZ SANDOVAL", "VICTOR DANIEL SANCHEZ CANAYO",  "RAFAEL  SERGIO TAFUR ALBERCA",
             "HUMBERTO PATRICIO   DIAZ AGUILAR", "MIRKO HEREDIA ALVARADO", "JULIO CESAR QUISPE APAZA","CASTILLO DE LA CRUZ  LUIS MIGUEL",
              "CASTILLO DE LA CRUZ  LUIS MIGUEL",  "JANET ASTO ATOCCSA", "LUIS FERNANDO BENDITA YANCHAYLA", "LUIS FERNANDO BENDITA YANCHAYLA", "DESPACHADO"],
            datasets: [
              {
                label: 'ASIGNACIÓN POR TECNICO',
                backgroundColor: ["#16886e", "#f50057", "#f9ab18","#13cff9", "#979899", "##e29b51","#00ff7e", "#c57bc0","#3a115f", "#16886e", "rgb(111 76 56)" ],
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
              } 
          ]
        },
        databarLinea: {
            labels: ["REGISTRADO", "DESPACHADO" ],
            datasets: [
              {
                label: 'LINEA LIMPIEZA',
                backgroundColor: ["#f50057", "#f50057", "#f9ab18","#13cff9", "#979899", "##e29b51","#00ff7e", "#c57bc0","#3a115f", "#16886e", "rgb(111 76 56)" ],
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: [40, 15, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
              },
              {
                label: 'LINEA REFRIGERACIÓN',
                backgroundColor: ["#16886e", "#16886e", "#f9ab18","#13cff9", "#979899", "##e29b51","#00ff7e", "#c57bc0","#3a115f", "#16886e", "rgb(111 76 56)" ],
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: [30, 11, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
              },
              {
                label: 'LINEA ELECTRÓNICA',
                backgroundColor: ["#3a115f", "#3a115f", "#f9ab18","#13cff9", "#979899", "##e29b51","#00ff7e", "#c57bc0","#3a115f", "#16886e", "rgb(111 76 56)" ],
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: [40, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
              },
              {
                label: 'LINEA BLANCA',
                backgroundColor: ["#f9ab18", "#f9ab18", "#f9ab18","#13cff9", "#979899", "##e29b51","#00ff7e", "#c57bc0","#3a115f", "#16886e", "rgb(111 76 56)" ],
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: [4, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
              },
              {
                label: 'LAVANDERIA INDUSTRIAL',
                backgroundColor: ["#979899", "#979899", "#f9ab18","#13cff9", "#979899", "##e29b51","#00ff7e", "#c57bc0","#3a115f", "#16886e", "rgb(111 76 56)" ],
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: [3, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
              } 
          ]
        },
    }
  }

  tick () {
    this.setState({count: (this.state.count + 1)})
    //console.log("el valor del timer es:::" + this.state.count);
    if (this.state.count == 3600){
      window.location.href = window.location.href;
    }
  }
  startTimer () {
    clearInterval(this.timer)
    this.timer = setInterval(this.tick.bind(this), 1000)
  }
  stopTimer () {
    clearInterval(this.timer)
  }



  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  componentDidMount() {
    this.startTimer();
    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    add();
    this.props.actions.consultarDashboard({operacion: "graficos"}).then(response => {
      console.log(response);
      if (response.tipo == "success"){
        let data = response.data;
        let estados  =data.estado;
        let totales  =data.totales;
        let labelsEstados = [];
        let valoresEstados = [];  
       for(var x=0; x<=estados.length -1; x++){
          labelsEstados.push(estados[x].estado);
          valoresEstados.push(estados[x].total);
       }
       console.log(labelsEstados);
       this.state.databarEstados.labels = labelsEstados;
       this.state.databarEstados.datasets[0].data = valoresEstados;

       let tecnicos  =data.tecnicos;
        let labelsTecnicos = [];
        let valoresTecnicos = [];
         for(var x=0; x<=tecnicos.length -1; x++){
            labelsTecnicos.push(tecnicos[x].tecnico);
            valoresTecnicos.push(tecnicos[x].total);
         }
         this.state.databarTecnicos.labels = labelsTecnicos;
        this.state.databarTecnicos.datasets[0].data = valoresTecnicos;
        this.state.totales.total = totales.total;
        this.state.totales.registrados = totales.registrados;
         this.state.totales.despachados = totales.despachados;
          this.state.totales.revisados = totales.revisado;
          this.state.totales.entregados = totales.reparadoentregado

          this.props.actions.consultarDashboard({operacion: "talleres"}).then(responseData => {
              if (responseData.tipo == "success"){
                let dataTa = responseData.data.talleres;
                 let dataTaTot = responseData.data.talleresTot;
                 this.state.taller1 = dataTa.taller1;
                 this.state.taller2 = dataTa.taller2;
                 this.state.taller3 = dataTa.taller3;
                 this.state.taller4 = dataTa.taller4;
                 this.state.taller5 = dataTa.taller5;


                 this.state.tallerTot1 = dataTaTot.tallerTot1;
                 this.state.tallerTot2 = dataTaTot.tallerTot2;
                 this.state.tallerTot3 = dataTaTot.tallerTot3;
                 this.state.tallerTot4 = dataTaTot.tallerTot4;
                 this.state.tallerTot5 = dataTaTot.tallerTot5;


                 this.state.rowsUltimos = responseData.data.ultimo;

              }
              remove();
          })
          
      }else{
        show(MENSAJE.IND_MOSTRAR_MENSAJE, "Ocurrio un error al registrar los datos.", TIPO_MENSAJE.WARNING);
        remove();
      }
       
      
    })
  }
 





  render() {
    const { classes } = this.props;
    const { dataestados,optionsEstados, databarEstados, databarTecnicos,databarLinea, totales, taller1, 
      taller2, taller3, taller4, taller5 , tallerTot1, tallerTot2, tallerTot3, tallerTot4, tallerTot5, rowsUltimos } = this.state;
    
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon>content_copy</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Total</p>
                <h3 className={classes.cardTitle}>
                  {totales.total} <small></small>
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Danger>
                    <Warning />
                  </Danger>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                   SERFAC SAC
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Store />
                </CardIcon>
                <p className={classes.cardCategory}>REGISTRADOS</p>
                <h3 className={classes.cardTitle}>{totales.registrados}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  SERFAC SAC
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Icon>info_outline</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>DESPACHADOS</p>
                <h3 className={classes.cardTitle}>{totales.despachados}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <LocalOffer />
                  SERFAC SAC
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Accessibility />
                </CardIcon>
                <p className={classes.cardCategory}>REV. / REP. </p>
                <h3 className={classes.cardTitle}>{totales.revisados} / {totales.entregados}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  SERFAC SAC
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

       

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title="TALLER LINEA: (R) (D)"
              headerColor="primary"
              tabs={[
                {
                  tabName: "LIMPIEZA  "+tallerTot1.totregistrados+" "+tallerTot1.totdespachados ,
                  tabIcon: BugReport,
                  tabContent: (
                    <Tasks
                      checkedIndexes={[0 ]}
                      tasksIndexes={[0, 1, 2, 3,4 ]}
                      tasks={taller1}
                    />
                  )
                },
                {
                  tabName:  "REFRIGERACION " + tallerTot2.totregistrados + " " + tallerTot2.totdespachados,
                  tabIcon: Code,
                  tabContent: (
                    <Tasks
                      checkedIndexes={[0 ]}
                      tasksIndexes={[0, 1, 2, 3,4 ]}
                      tasks={taller2}
                    />
                  )
                },
                {
                  tabName: "ELECTRONICA  "+ tallerTot3.totregistrados + " " + tallerTot3.totdespachados,
                  tabIcon: Code,
                  tabContent: (
                    <Tasks
                      checkedIndexes={[0 ]}
                      tasksIndexes={[0, 1, 2, 3,4 ]}
                      tasks={taller3}
                    />
                  )
                },
                {
                  tabName: "BLANCA "+ tallerTot4.totregistrados + " " + tallerTot4.totdespachados,
                  tabIcon: Cloud,
                  tabContent: (
                    <Tasks
                      checkedIndexes={[0 ]}
                      tasksIndexes={[0, 1, 2, 3,4 ]}
                      tasks={taller4}
                    />
                  )
                }, 
                {
                  tabName: "LAV. INDUSTRIAL "+ tallerTot5.totregistrados + " " + tallerTot5.totdespachados,
                  tabIcon: Code,
                  tabContent: (
                    <Tasks
                      checkedIndexes={[0 ]}
                      tasksIndexes={[0, 1, 2, 3,4 ]}
                      tasks={taller5}
                    />
                  )
                }
              ]}
            />
          </GridItem> 
        </GridContainer>

        <GridContainer>
           
          <GridItem xs={12} sm={12} md={6}>
            <Card chart>
              <CardHeader >
                <Bar
                  data={databarTecnicos}
                  width={100}
                  height={350}
                  options={{
                    maintainAspectRatio: false
                  }}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>POR TECNICO</h4>
                <p className={classes.cardCategory}>
                  se listan el total por estado
                </p>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> Ultimo Mes
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card chart>
              <CardHeader >
                <Bar
                  data={databarEstados}
                  width={100}
                  height={350}
                  options={{
                    maintainAspectRatio: false
                  }}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>POR ESTADO</h4>
                <p className={classes.cardCategory}>
                  se listan el total por estado
                </p>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> Ultimo Mes
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        

        <GridContainer>
           
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Ultimos Registros</h4>
                <p className={classes.cardCategoryWhite}>
                  Ultimo Nuevos registros 
                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="warning"
                  tableHead={["Ficha", "Artefacto", "Modelo", "Serie", "Ubicación","Taller","Estado"]}
                  tableData={rowsUltimos}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </div>
    );
  }
}
const mapState = (state) => ({
  loading: state.load.loading
});

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(documentoActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};
 
Dashboard = withStyles(dashboardStyle)(Dashboard);
export default withRouter(connect(mapState, mapDispatch)(Dashboard));