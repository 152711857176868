import React from "react";
// @material-ui/core components
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';

import documentoStyle from "styles/jss/app/views/documentoStyle.jsx";
import * as resumenActions from "actions/ResumenActions";

import * as documentoActions from "actions/DocumentoActions";
import * as notificationActions from "actions/NotificationActions";
//import * as sessionActions from "actions/SessionActions";
import * as loadActions from "actions/LoadActions";
import Form from "components/Form/TecnicoDiagnosticoForm"; 

class TecnicoDiagnostico extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.consultarDocumento = this.consultarResumen.bind(this);
    this.state = {
      documento: {
        docuNumero: '',
        tipoDocumento: '',
        clieNumero: '',  //es ruc
        docuFechaIni: '',
        docuFechaFin: '',
        estado: '',
        clieNombre: '', //razon Social
        cdr: '',
        compania: '',
        documentType: ''
      }
    }
  }

  componentDidMount() {
  }

  consultarResumen = (data) => {
   // console.log("datos enviados");
    console.log(data);
    console.log(this.props);
    this.Form.buscarArtefacto(data);
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <SearchIcon className={classes.titleIcon} />
            <Typography className={classes.title} variant="h4"> Diagnostico de Tecnico - Domicilio</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Form onSubmit={this.consultarResumen} classes={classes} consultarResumen={this.consultarResumen} documento={this.state.documento} onRef={ref => (this.Form = ref)} />
          </ExpansionPanelDetails>
          
        </ExpansionPanel> 

      </div>
    )
  }
}
const mapState = (state) => ({
  loading: state.load.loading
});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(documentoActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

TecnicoDiagnostico = withStyles(documentoStyle)(TecnicoDiagnostico);
export default withRouter(connect(mapState, mapDispatch)(TecnicoDiagnostico));