import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  SelectionState, IntegratedSelection, IntegratedSorting, SortingState, PagingState, CustomPaging
} from '@devexpress/dx-react-grid';
import {
  Grid, VirtualTable, Table,
  TableHeaderRow, PagingPanel, TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import * as documentoActions from "actions/DocumentoActions";
import * as notificationActions from "actions/NotificationActions";
//import * as sessionActions from "actions/SessionActions";
import * as loadActions from "actions/LoadActions";

import {
  TIPO_MENSAJE, GRID_TEXT_ES, MENSAJE, MENSAJE_GENERAL
} from 'utils/Constants';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import AnularIcon from '@material-ui/icons/Close';
import List from '@material-ui/icons/List';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/CustomButtons/Button";
import Typography from '@material-ui/core/Typography';
//import { s2ab } from 'utils/Functions';
import CsvCreator from 'react-csv-creator';
import SvgIcon from '@material-ui/core/SvgIcon';
import cdrPng from "images/icons/cdr.png";
import xmlPng from "images/icons/xml.png";
import zipPng from "images/icons/zip.png";
import excelPng from "images/icons/excel.png";
import sunatPng from "images/icons/sunat.png";
import logoPdf from "images/logopdfmin.png";
import { s2ab, getBase64FromFile } from 'utils/Functions';
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { blue, red } from '@material-ui/core/colors';

import RepuestoDialog from "components/Dialog/DetalleFichaDialog";
import logo from "images/logo-rimac-r.png";
import JsBarcode from 'jsbarcode';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done'; 
import { withStyles } from "@material-ui/core/styles";

const getRowId = row => row.id;


const styles = {
  customHeaderCell: {
    '& div': {
      whiteSpace: "normal",
      wordWrap: "break-word" 
    }
    /*your styles here*/
  }
};


const CustomTableHeaderCellBase = ({ classes, ...restProps }) => {

  restProps.value = restProps.column.title || restProps.column.name;
  return <TableHeaderRow.Cell className={classes.customHeaderCell} {...restProps} />
}
export const CustomTableHeaderCell = withStyles(styles)(CustomTableHeaderCellBase);


class BsqProgRutaGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [ 
        { name: 'fechaatencion', title: ' Fecha de Atención' },
        { name: 'id', title: 'Número de Ficha ' }, 
        { name: 'ttrabajo', title: 'Tipo de Trabajo' },
        { name: 'nombre', title: 'Cliente' },
        { name: 'codproducto', title: 'Artefacto' },
        { name: 'marca', title: 'Marca' },
        { name: 'distrito', title: 'Distrito' }, 
        { name: 'tiposervicio', title: 'Tipo de Servicio' },
        { name: 'archivo', title: 'Acción' }
      ],
      tableColumnExtensions: [ 
        { columnName: 'fechaatencion', width: 100, wordWrapEnabled: true },
        { columnName: 'id', width: 120, wordWrapEnabled: true },
        { columnName: 'nombre', width: 200, wordWrapEnabled: true },
        { columnName: 'ttrabajo', width: 120, wordWrapEnabled: true },
        { columnName: 'codproducto', width: 100, wordWrapEnabled: true },
        { columnName: 'marca', width: 100 },
        { columnName: 'distrito', width: 150 , wordWrapEnabled: true}, 
        { columnName: 'tiposervicio', width: 150 },  
        { columnName: 'archivo', width: 100 },
      ], 
      rows: [],
      //sorting: [{ columnName: 'docu_fecha', direction: 'asc' }],
      sorting: [],
      currentPage: 0,
      totalCount: 0,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      showSeguimientoDialog: false,
      seguimientoRows: [],
      selection: [],
      showUsuarioDialog: false,
      repuestos: [],
      documento:{}
    };

    this.changeSorting = sorting => this.setState({ sorting });
    this.changeSelection = selection => {
      const { show } = this.props.notificationActions;
      // console.log(selection);
      //console.log(selection.length);
      if (selection.length > 250){
        show("1", "La cantidad de archivos a descargar debe ser menor a 250", TIPO_MENSAJE.WARNING);
        //console.log("error la cantidad de archivos a descargar debe ser menor a 250");
      }
      this.setState({ selection: selection });
    }
    //this.changeCurrentPage = currentPage => this.setState({ currentPage });
    //this.changePageSize = pageSize => this.setState({ pageSize });

  }

  changeCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage })
    this.buscarDocumentos(currentPage);
  }

  changePageSize = (pageSize) => {
    const { totalCount, currentPage: stateCurrentPage } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(stateCurrentPage, totalPages - 1);

    this.setState({
      pageSize: pageSize,
      currentPage: currentPage
    });
    this.buscarDocumentos(currentPage, pageSize);
  }

  componentDidMount() {
   // this.buscarDocumentos();
    this.state.selection = [];
    this.props.onRef(this);
    //this.buscarDocumentos();
  }

  asignarTecnico = (tecnico) => {
    let selection = this.state.selection;
    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    console.log("data tecnico:::", tecnico);
    if (tecnico.length <= 0 || tecnico.length >= 2 ){
      show("1", "No ha seleccionado ningun tecnico o ha seleccionado  mas de uno ", TIPO_MENSAJE.WARNING);
    }else{
      if (selection.length > 0){
        add();
        this.props.actions.asignarTecnicoDomicilio({
          tecnico: tecnico[0].id,
          fichas: selection 
        }).then(response => {
          console.log("response de dataaaaa", response);
          if (response.status === 200) {
            show("1", "Se asigno con exito al tecnico ", TIPO_MENSAJE.SUCCESS);  
            this.buscarDocumentos();
          } else {
            if (response.data != null && response.data == 'dato invalido'){
              show(response.indMostrarMensaje, "Ingrese información valida para la busqueda", TIPO_MENSAJE.WARNING);
            }else{
              show(response.indMostrarMensaje, response.mensaje, response.tipo);
            }
          }
          remove();
        })
          
      }else{
        show("1", "No ha seleccionado ningun registro ah asignar al tecnico", TIPO_MENSAJE.WARNING);
      }
    }
    
  }

  limpiarForm = () => {
    //this.props.reset();
    var documento = {
      docuNumero: '',
      tipoDocumento: '',
      clieNumero: '',  //es ruc
      docuFechaIni: '',
      docuFechaFin: '',
      estado: '',
      clieNombre: '', //razon Social
      cdr: ''
    };
    
    this.setState({ rows: [] });
    this.setState({ documento: documento });
  }

  buscarDocumentos(page, size) {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    const { currentPage, pageSize } = this.state;
    add();
    page = page ? page : currentPage;
    size = size ? size : pageSize;
    this.state.selection = [];
    if (page === -1) {
      page = 0;
      this.limpiarForm();
    }
    if (page === currentPage) { page = 0; }
    //validamos filtros

    this.props.actions.consultarFichas(this.props.documento, page + 1, size).then(response => {
      console.log("response de dataaaaa", response);
      if (response.status === 200) {
        this.setState({ rows: response.data, totalCount: response.totalCount })
      } else {
        if (response.data != null && response.data == 'dato invalido'){
          show(response.indMostrarMensaje, "Ingrese información valida para la busqueda", TIPO_MENSAJE.WARNING);
        }else{
          show(response.indMostrarMensaje, response.mensaje, response.tipo);
        }
      }
      remove();
    })
  }

 


 

  Cell = (props) => {
    const { column, row } = props;
    
    if (column.name === 'estadoname' &&  row.estado == 0) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="secondary" avatar={<Avatar>R</Avatar>} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 10) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} icon={<DoneIcon  />} color="primary" avatar={<Avatar>E</Avatar>} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 11) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>R</Avatar>} style={{background: "#e29b51", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 2) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>C</Avatar>} style={{background: "#00ff7e" , color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 3) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>A</Avatar>} style={{background: "#c57bc0", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 9) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} icon={<DoneIcon  />} color="yellow" avatar={<Avatar>D</Avatar>} style={{background: "#3a115f", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 13) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>R</Avatar>} style={{background: "#16886e", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 14) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>N</Avatar>} style={{background: "rgb(111 76 56)", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' ) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname}   />      
          
        </TableCell>
      );
     }

    if (column.name === 'archivo' ) {
      return (
        <TableCell style={{ padding: 0 }}>
          <IconButton color="primary" aria-label="row.cdr" style={{ display: 'flex', float: 'left' }}
            onClick={this.toggleDialogRepuesto.bind(this, row)} title="Detalle de Ficha" >
            <List>{row.cdr}</List>
          </IconButton>
          
        </TableCell>
      );
    } 
    return <VirtualTable.Cell {...props} />;
  };

 

  

  toggleDialogRepuesto = (documento) => {
    console.log("valor seleccionado" , documento)

    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    if (documento  != null){
      add();
      let documentoRepuesto = {
        operacion: "repuesto",
        id: documento.id
      }
      this.props.actions.consultarDocumentoPorOtrosCampos(documentoRepuesto).then(response => {
        console.log("respuesta de los repuestos", response); 
        if (response.status == 200) {
          console.log("datos de los repuestos", response.data );
          this.setState({ documento: documento})
          this.setState({ repuestos: response.data})
          this.setState({ showRepuestoDialog: !this.state.showRepuestoDialog })
        } else{
            show("1", "No se encontraron datos a mostrar.", TIPO_MENSAJE.WARNING);
        }
        remove();
      })
    }else{
      this.setState({ showRepuestoDialog: !this.state.showRepuestoDialog })
    }

  };


  toggleDialogRepuestoRegistro = (currentPage, page) => {
     this.buscarDocumentos(currentPage, page);
     this.setState({ showRepuestoDialog: !this.state.showRepuestoDialog }) 
  }; 

  render() {
    const {
      rows,
      columns,
      tableColumnExtensions,
      sorting,
      selection,
      currentPage,
      pageSize,
      pageSizes,
      totalCount, 
      showRepuestoDialog,
      repuestos,
      documento
    } = this.state;

    const {  classes } = this.props;

    return (
      <GridContainer>
        
      <div style={{ display: "none" }}> <img id='itf' /></div>
        <GridItem xs={12} sm={12} md={12}>
           
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ width: "100%" }}>
            <Grid
              rows={rows}
              columns={columns}
              style={{ height: "100%" }}
              getRowId={getRowId}
              className={"ps-child"}
            >

              <SortingState
                sorting={sorting}
                onSortingChange={this.changeSorting}
              />
              <SelectionState
                selection={selection}
                onSelectionChange={this.changeSelection}
              />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={this.changeCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={this.changePageSize}
              />
              <CustomPaging
                totalCount={totalCount}
              />
              {}
              {}
              <VirtualTable
                columnExtensions={tableColumnExtensions}
                cellComponent={this.Cell}
                messages={GRID_TEXT_ES}
              />
              <IntegratedSelection />
              <TableSelection showSelectAll />
              <TableHeaderRow showSortingControls cellComponent={CustomTableHeaderCell} />
              <PagingPanel
                pageSizes={pageSizes}
                messages={GRID_TEXT_ES}
              />
            </Grid>
            <RepuestoDialog currentPage={currentPage} pageSize={pageSize} toggleDialogRepuesto={this.toggleDialogRepuesto} toggleDialogRepuestoRegistro={this.toggleDialogRepuestoRegistro} showRepuestoDialog={showRepuestoDialog} classes={classes} repuestos={repuestos} documento={documento}
               />
          </Paper>
        </GridItem>
      </GridContainer>

    );
  }
}

const mapState = (state) => ({
  loading: state.load.loading,
  user: state.session.user
});

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(documentoActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

export default withRouter(connect(mapState, mapDispatch)(BsqProgRutaGrid));