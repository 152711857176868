import { APIRestAWS } from "services/common/APIRestAWS";
import { PATH_DOCUMENTO_API, PATH_SERFAC_API } from "commons/config/Path";
import config from "commons/config/AWSConfig";
import axios from 'axios';
import { validarResponse } from 'utils/Functions';
import queryString from 'query-string'
export default class DocumentoApi {
    static async obtenerDocumento(opc, documento) {
        var varQueryParams = {
            opc: opc,
            idedocumento: documento.idedocumento
        }
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_DOCUMENTO_API.URL_METHOD, varQueryParams)
            .then(async (response) => {
                return response;
            });
    }

    static async listarParametrosFiltro(idopc) {
        var apiRestAWS = new APIRestAWS();
        var varQueryParams = {
            opc: idopc
        }
        return apiRestAWS.get(config.apiGateway.NAME, PATH_DOCUMENTO_API.URL_METHOD_PARAMETRO, varQueryParams)
            .then(async (response) => {
                return response;
            });
    }

    

    static async listarDocumentos(opc, documento, ideusuario, page, pagesize) {
        
        documento.clieNombre = documento.clieNombre.replace(/\\n/g, "\\n")  
    		               .replace(/\\'/g, "\\'")
    		               .replace(/\\"/g, '\\"')
    		               .replace(/\\&/g, "\\&")
    		               .replace(/\\r/g, "\\r")
    		               .replace(/\\t/g, "\\t")
    		               .replace(/\\b/g, "\\b")
                           .replace(/\\f/g, "\\f");
        var varQueryParams = {
            opc: opc,
           // ideusuario: ideusuario,
            page: page,
            pagesize: pagesize,
            //filtro: {
                tipoDocumento: documento.tipoDocumento?documento.tipoDocumento.join(','):"",
                docuNumero: documento.docuNumero?documento.docuNumero:'',
                clieNumero: documento.clieNumero?documento.clieNumero:'',
                estado: documento.estado?documento.estado:'',
                cdr: documento.cdr?documento.cdr:'',
                clieNombre: documento.clieNombre?documento.clieNombre.trim():'',
                docuFechaIni: documento.docuFechaIni?documento.docuFechaIni:'',
                docuFechaFin:documento.docuFechaFin?documento.docuFechaFin:'',
                tipoDocuCliente: documento.documentTypeClient?documento.documentTypeClient:'',
                codCia: documento.compania?documento.compania:'',
            //}
        };
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_DOCUMENTO_API.URL_METHOD, varQueryParams)
            .then(async (response) => {
                return response;
            });
    }

    static async listarSeguimiento(idDocuCodigo) {
        var apiRestAWS = new APIRestAWS();
        var varQueryParams = {
            docuCodigo: idDocuCodigo
        }
        return apiRestAWS.get(config.apiGateway.NAME, PATH_DOCUMENTO_API.URL_METHOD_SEGUIMIENTO, varQueryParams)
            .then(async (response) => {
                return response;
            });
    }

    static async obtenerArchivo(document, tipo) {
        const data = {
            codcompania: document.codcompania,
            tipdoc: document.tipdoc,
            serie:  document.serie,
            numero: document.numero,
            extension: tipo
          };
          
          const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
            }});
          httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;

          return httpClient.post(config.apiGateway.URL,  data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              //console.log(response);
              //console.log(response.data);
              return response.data;
            })
    }

    static async descargarMasivo(files) {
        var apiRestAWS = new APIRestAWS();
        var varQueryParams = {
            files: files
        }
        return apiRestAWS.post(config.apiGateway.NAME, PATH_DOCUMENTO_API.URL_METHOD_DESCARGA, varQueryParams)
            .then(async (response) => {
                return response;
        });
    }

    

    static async reenviarDocumento(opc, documento, tipo) {
        var apiRestAWS = new APIRestAWS();
        var numDoc = documento.numero?documento.numero:'';
        if (documento.tipdoc =='RT'){
            var str = numDoc;
            var cSearch = '0';
            var posIni = 0;
            var cadenaFinal = '';
            for (var i = 0; i <= str.length-1; i++){
                if (posIni == 0){
                    if (str[i] != cSearch){
                    cadenaFinal = cadenaFinal + str[i];
                    posIni = 1;
                    }
                }else{
                    cadenaFinal = cadenaFinal + str[i];
                }
            }
            numDoc = cadenaFinal;
        }
        var varQueryParams = {
            opc: opc,
            tipoDoc: documento.tipdoc?documento.tipdoc:"",
            numDoc: numDoc,
            serie: documento.serie?documento.serie:'',
            codCia: documento.codcompania?documento.codcompania:'',
        };

        if (tipo == "S"){
            
            varQueryParams.opc = "sunat";
            varQueryParams.id = documento.docu_codigo;
            varQueryParams.rucProveedor = documento.codcompania;
            varQueryParams.tipoDocSunat = documento.tipodocumento.substr(0,2);
            varQueryParams.serieComprobante = documento.serie;
            varQueryParams.numeroComprobante = Number(documento.numero);
            varQueryParams.docu_fecha = documento.docu_fecha;
            varQueryParams.docu_sunat = documento.docu_proce_status;
            //varQueryParams.proceso = "N";
            varQueryParams.proceso = (documento.docu_proce_status == 2 || documento.docu_proce_status == 3) ? "C" : "N";
        }

        return apiRestAWS.post(config.apiGateway.NAME, PATH_DOCUMENTO_API.URL_METHOD_REPROCESAR, varQueryParams)
            .then(async (response) => {
                return response;
        });
    }

    static async reenviarDocumentoErp(opc, documento) {
        var apiRestAWS = new APIRestAWS();
        var varQueryParams = {
            opc: opc,
            tipoDoc: documento.tipdoc?documento.tipdoc:"",
            numDoc: documento.docu_numero?documento.docu_numero.split("-")[1]:'',
            serie: documento.docu_numero?documento.docu_numero.split("-")[0]:'',
            codCia: documento.codcompania?documento.codcompania:'',
        };
        return apiRestAWS.post(config.apiGateway.NAME, PATH_DOCUMENTO_API.URL_METHOD_REPROCESAR_ERP, varQueryParams)
            .then(async (response) => {
                return response;
        });
    }

    static async reporteGraficoDocumento(opc, documento) {
        var varQueryParams = {
            opc: documento.tipReporte,
            tipoDocumento: documento.tipoDocumento?documento.tipoDocumento.join(','):"",
            docuNumero: documento.docuNumero?documento.docuNumero:'',
            clieNumero: documento.clieNumero?documento.clieNumero:'',
            estado: documento.estado?documento.estado.join(','):'',
            cdr: documento.cdr?documento.cdr:'',
            clieNombre: documento.clieNombre?documento.clieNombre:'',
            docuFechaIni: documento.docuFechaIni?documento.docuFechaIni:'',
            docuFechaFin:documento.docuFechaFin?documento.docuFechaFin:'',
            tipoDocuCliente: documento.documentTypeClient?documento.documentTypeClient:'',
            codCia: documento.compania?documento.compania:'',
        };
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_DOCUMENTO_API.URL_METHOD_REPORTE_INDICADOR, varQueryParams)
            .then(async (response) => {
                return response;
            });
    }

    static async procesarCsvReversiones(documentos, opc) {
        var apiRestAWS = new APIRestAWS();
        var varQueryParams = {
            opc: opc,
            documentos: documentos
        }
        return apiRestAWS.post(config.apiGateway.NAME, PATH_DOCUMENTO_API.URL_METHOD_REPROCESAR, varQueryParams)
            .then(async (response) => {
                return response;
        });
    }


    //serfac registrar documentos
    static async registrarFicha(  documento) {
        var apiRestAWS = new APIRestAWS();
        documento.clieNombre = documento.clieNombre.replace(/\\n/g, "\\n")  
                           .replace(/\\'/g, "\\'")
                           .replace(/\\"/g, '\\"')
                           .replace(/\\&/g, "\\&")
                           .replace(/\\r/g, "\\r")
                           .replace(/\\t/g, "\\t")
                           .replace(/\\b/g, "\\b")
                           .replace(/\\f/g, "\\f");
       
        var data = { 
            nombre: documento.clieNombre?documento.clieNombre : "",
            fechaIngreso: documento.docuFechaIni?documento.docuFechaIni : "", 
            apellidos: '',
            tipoDoc: documento.clieTipDoc?documento.clieTipDoc : "",
            numDoc: documento.clieNumero?documento.clieNumero : "",
            direccion: documento.clieDireccion?documento.clieDireccion:'',
            ubigeo: documento.clieUbigeo?documento.clieUbigeo : "",
            email: documento.clieEmail?documento.clieEmail : "",
            telefono: documento.clieTelefono?documento.clieTelefono : "",
            codProd: documento.artefacto?documento.artefacto : "",
            motivo: documento.motivo?documento.motivo : "",
            otroMotivo: "",
            fechaVenta: documento.fechaVenta?documento.fechaVenta : "",
            comprobante: documento.facbolguia?documento.facbolguia : "",
            tipoCom:   "",
            distribuidor: " ",
            modelo: documento.fechaVenta?documento.modelo : "",
            marca: documento.marca?documento.marca : "",
            serie: documento.serie?documento.serie : "",
            tipoServicio: documento.tipoServicio?documento.tipoServicio : "",
            observacion: documento.observacion?documento.observacion : "",
            accesorios: "",
            recomendacion: "",
            ubicacion: documento.ubicacion?documento.ubicacion : "",
            numeroservicio: documento.numeroservicio?documento.numeroservicio : "",
            area: documento.area?documento.area : "",
            linea: documento.linea?documento.linea : "",
            doccaja: documento.doccaja?documento.doccaja : "", 
            idPerfilAud: localStorage.getItem("idperfilAud") ? localStorage.getItem("idperfilAud") : 1,
            userAud: localStorage.getItem("userAud") ? localStorage.getItem("userAud") : "admin",
            idUserAud: localStorage.getItem("idUser") ? localStorage.getItem("idUser") : 1,
            recepciona: documento.recepciona?documento.recepciona : "",
            tienda: documento.tienda?documento.tienda : "",
            clieId: documento.clieId, 
            local: documento.local?documento.local : "",
            
              
        };
       /* return apiRestAWS.post(config.apiGateway.NAME, PATH_SERFAC_API.URL_METHOD_FICHA, varQueryParams)
            .then(async (response) => {
                return response;
        });*/
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.post(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });
    }

    static async consultarDocumentoPorId(id) {
        
        var apiRestAWS = new APIRestAWS();
        
        var data = { 
             id: id,
             otro:"aaaaa"
        };
       /* return apiRestAWS.post(config.apiGateway.NAME, PATH_SERFAC_API.URL_METHOD_FICHA, varQueryParams)
            .then(async (response) => {
                return response;
        });*/
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.get(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA+"?id="+id,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });
    }

    static async consultarDocumentoPorOtrosCampos(request) {
        var apiRestAWS = new APIRestAWS();
        let data = {};
        let queryParams = "?" + queryString.stringify(request);
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.get(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA +  queryParams,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });
    }

    static async consultarFichas(params, page, pagesize) {
        console.log("params dede api222233333:::", params);
        //verificamos si se trata de un tecnico
        let tecnicoId = 0;
        if (localStorage.getItem("tkn") == "serfac-tecnicos"){
            if (localStorage.getItem("userTecnico")){
                let tecnicoData = JSON.parse(localStorage.getItem("userTecnico")); 
               console.log("jsonParseUserTecnico", JSON.parse(localStorage.getItem("userTecnico")));
               tecnicoId = tecnicoData.id;
            }
        }

        var apiRestAWS = new APIRestAWS();
        var data = { 
             page: page,
            pagesize: pagesize,
            estado: params.estado,
            producto: params.producto,
            fechaini: params.docuFechaIni,
            fechafin: params.docuFechaFin,
            modelo: params.modelo,
            serie: params.serie,
            marca: params.marca,
            area: params.area,
            tipoServicio: params.tipoServicio,
            clieTipDoc: params.clieTipDoc,
            clieNumero: params.clieNumero,
            numeroservicio: params.numeroservicio,
            numeroFicha: params.numeroFicha,
            telefono: params.telefono, 
            tipoTrabajo: params.tipoTrabajo?params.tipoTrabajo:'',
            distrito: params.distrito?params.distrito:'',
            operacion: params.operacion?params.operacion:'',
            tiporegistro: params.tiporegistro?params.tiporegistro:'',
            tecnico: tecnicoId ? tecnicoId : (params.tecnico && params.tecnico!=null?params.tecnico:tecnicoId),
        }; 
       /* return apiRestAWS.post(config.apiGateway.NAME, PATH_SERFAC_API.URL_METHOD_FICHA, varQueryParams)
            .then(async (response) => {
                return response;
        });*/
       
        let queryParams = "?" + queryString.stringify(data);
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.get(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA +  queryParams,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          }).then( async (response) => { 
               // console.log("respuesta de servicio");   
              //console.log(response.data);
              return response.data;
        })
    }



    static async consultarFichasByCheck(params, page, pagesize) { 
        //verificamos si se trata de un tecnico
        let tecnicoId = 0;
        if (localStorage.getItem("tkn") == "serfac-tecnicos"){
            if (localStorage.getItem("userTecnico")){
                let tecnicoData = JSON.parse(localStorage.getItem("userTecnico"));  
               tecnicoId = tecnicoData.id;
            }
        }

        //var apiRestAWS = new APIRestAWS();
        var data = { 
             page: page,
            pagesize: pagesize,
            estado: params.estado,
            producto: params.producto,
            fechaini: params.docuFechaIni,
            fechafin: params.docuFechaFin,
            modelo: params.modelo,
            serie: params.serie,
            marca: params.marca,
            linea: params.linea,
            area: params.area,
            tipoServicio: params.tipoServicio,
            clieTipDoc: params.clieTipDoc,
            clieNumero: params.clieNumero,
            numeroservicio: params.numeroservicio,
            numeroFicha: params.numeroFicha,
            telefono: params.telefono, 
            tipoTrabajo: params.tipoTrabajo?params.tipoTrabajo:'',
            distrito: params.distrito?params.distrito:'',
            operacion: params.operacion?params.operacion:'',
            tiporegistro: 'T', 
        }; 
       /* return apiRestAWS.post(config.apiGateway.NAME, PATH_SERFAC_API.URL_METHOD_FICHA, varQueryParams)
            .then(async (response) => {
                return response;
        });*/
       
        let queryParams = "?" + queryString.stringify(data);
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.get(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA +  queryParams,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              //console.log(response);
              //console.log(response.data);
              return response.data;
        });
    }



    static async guardarAsginacionTecnico(  documento) {
        var apiRestAWS = new APIRestAWS();
        console.log("datos enviado en documento tecnico", documento);
        var data = { 
            id: documento.id?documento.id : "",
            ubicacion: documento.ubicacion?documento.ubicacion : "",
            recomendacion: documento.recomendacion,
            tecnico: documento.tecnico,
            repuestos: documento.repuestos,
            linea: documento.linea,
            estado: documento.estado,
            modelo: documento.modelo,
            serie: documento.serie,
            idPerfilAud: localStorage.getItem("idperfilAud") ? localStorage.getItem("idperfilAud") : 1,
            userAud: localStorage.getItem("userAud") ? localStorage.getItem("userAud") : "admin",
            idUserAud: localStorage.getItem("idUser") ? localStorage.getItem("idUser") : 1,
            obscliente: documento.obscliente,
            pasaje: documento.pasaje

        };
       /* return apiRestAWS.post(config.apiGateway.NAME, PATH_SERFAC_API.URL_METHOD_FICHA, varQueryParams)
            .then(async (response) => {
                return response;
        });*/
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.put(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });
    }

    static async guardarRepuestosPrecioCordinadoraAlmacen(  documento) {
        var apiRestAWS = new APIRestAWS();
         
        var data = { 
            id: documento.hId?documento.hId : "",
            estado: documento.estado?documento.estado : "",
            recomendacion: documento.recomendacion,
            seguimiento: documento.seguimiento,
            operacion: documento.operacion,
            repuestos: documento.repuestos,
            modelo: documento.modelo,
            serie: documento.serie,
            //marca: documento.marca,
            numeroservicio: documento.numeroservicio,
            ubicacion: documento.ubicacion?documento.ubicacion : "",

            nombre: documento.nombre?documento.nombre : "",
            direccion: documento.direccion?documento.direccion : "",
            telefono: documento.telefono?documento.telefono : "",
            clieNumero: documento.clieNumero?documento.clieNumero : "",
            email: documento.email?documento.email : "",
            
            clieId: documento.clieId?documento.clieId : "",
            clieUbigeo: documento.clieUbigeo?documento.clieUbigeo : "",
            tipoServicio: documento.tipoServicio?documento.tipoServicio : "",
            moneda: documento.moneda?documento.moneda : "",
            artefacto: documento.artefacto?documento.artefacto : "",
            marca: documento.marca?documento.marca : "",
            linea: documento.linea?documento.linea : "",
            area: documento.area?documento.area : "",
            motivo: documento.motivo?documento.motivo : "",
            horaservicio: documento.horaservicio?documento.horaservicio : "",
            fechaAtencion: documento.hFechaInicio?documento.hFechaInicio : "",
            tipoTrabajo: documento.tipoTrabajo?documento.tipoTrabajo : "",
            observacion: documento.observacion?documento.observacion : "",
            idlocal: documento.idlocal?documento.idlocal : 0,
            celular: documento.celular?documento.celular : '',
            fechacompra: documento.hFechaFin?documento.hFechaFin : '',
            tecnico: documento.tecnico?documento.tecnico : '',
            idPerfilAud: localStorage.getItem("idperfilAud") ? localStorage.getItem("idperfilAud") : 1,
            userAud: localStorage.getItem("userAud") ? localStorage.getItem("userAud") : "admin",
            idUserAud: localStorage.getItem("idUser") ? localStorage.getItem("idUser") : 1,
            doccaja: documento.doccaja?documento.doccaja : '',
            tienda: documento.tienda?documento.tienda : '0',

        };
       /* return apiRestAWS.post(config.apiGateway.NAME, PATH_SERFAC_API.URL_METHOD_FICHA, varQueryParams)
            .then(async (response) => {
                return response;
        });*/
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.put(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });
    }


    static async guardarPasajeById(  documento) {
        var apiRestAWS = new APIRestAWS();
         
        var data = { 
            id: documento.hId?documento.hId : "",
            idficha: documento.idficha?documento.idficha : "",
            importe: documento.importe?documento.importe : 3.75, 
            operacion: documento.operacion
        };
       /* return apiRestAWS.post(config.apiGateway.NAME, PATH_SERFAC_API.URL_METHOD_FICHA, varQueryParams)
            .then(async (response) => {
                return response;
        });*/
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.put(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });
    }



    static async descargarExcel(opc, documento, user, totalCount) {
        var apiRestAWS = new APIRestAWS();
        documento.clieNombre = documento.clieNombre != null ?  documento.clieNombre.replace(/\\n/g, "\\n")  
                           .replace(/\\'/g, "\\'")
                           .replace(/\\"/g, '\\"')
                           .replace(/\\&/g, "\\&")
                           .replace(/\\r/g, "\\r")
                           .replace(/\\t/g, "\\t")
                           .replace(/\\b/g, "\\b")
                           .replace(/\\f/g, "\\f") : ''; 
        let data = documento;
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.post(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_DESCARGA_EXCEL,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });

    }


    static async consultarDashBoard(params ) {
        console.log("params dede api:::", params);
        var apiRestAWS = new APIRestAWS();
        var data = { 
            operacion: params.operacion 
        }; 
       /* return apiRestAWS.post(config.apiGateway.NAME, PATH_SERFAC_API.URL_METHOD_FICHA, varQueryParams)
            .then(async (response) => {
                return response;
        });*/
       
        let queryParams = "?" + queryString.stringify(data);
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.get(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_REPORTE +  queryParams,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });
    }

    static async enviarMail(documento) { 
        let id = (""+documento.data.id).padStart(10,  "0");
        let datosMail = {
                "codgrupo": "000",
                "datosgrupo": [
                    {
                        "campo": "codcompania",
                        "descripcion": "Cod. Compañía",
                        "valor": "01"
                    } 
                ],
                "mail": {
                        "name": "SERFAC SAC",
                        "from": "no-reply@serfac.com.pe",
                        "to": documento.email,
                          envelope: {
                            from: 'SERFAC <atencionalcliente@serfac.com.pe>',
                            to: 'atencionalcliente@serfac.com.pe, Mailer <atencionalcliente@serfac.com.pe>'
                        },
                        "subject": "SERFAC - Ficha - " + documento.data.nombre + " - " + documento.data.codproducto + "-" + documento.data.numeroservicio,
                        "bodyhtml": "Se le adjunta Ficha de Trajo, para mayor información verificar en la ficha de trabajo en nombre, celular y correo de su coordinadora asignada. ",
                        "attachments": [
                     
                    {
                         path: documento.file,
                         filename: "Ficha-de-Trabajo-"+id+".pdf"
                    } 
                ]
            }
        };  
        
        documento.operacion = "mail";
        let data = documento;
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.post(config.apiGateway.URL+"/serfac/mail",  datosMail, {
        //return httpClient.post("https://9oj5qm6rq7.execute-api.us-east-1.amazonaws.com/prod"+"/serfac/mail",  datosMail, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });

    }



    static async asignarTecnicoDomicilio(documento) {
        var apiRestAWS = new APIRestAWS();
        
       
        var data = { 
            fichas: documento.fichas?documento.fichas : "",
            tecnico: documento.tecnico?documento.tecnico : "",
            idPerfilAud: localStorage.getItem("idperfilAud") ? localStorage.getItem("idperfilAud") : 1,
            userAud: localStorage.getItem("userAud") ? localStorage.getItem("userAud") : "admin",
            idUserAud: localStorage.getItem("idUser") ? localStorage.getItem("idUser") : 1,
            operacion: "asignarTecnicoDom"
        };
       /* return apiRestAWS.post(config.apiGateway.NAME, PATH_SERFAC_API.URL_METHOD_FICHA, varQueryParams)
            .then(async (response) => {
                return response;
        });*/
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.put(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });
    }


    static async descargarExcelPasaje(opc, documento, user, totalCount) {
        var apiRestAWS = new APIRestAWS();
        documento.clieNombre = documento.clieNombre != null ?  documento.clieNombre.replace(/\\n/g, "\\n")  
                           .replace(/\\'/g, "\\'")
                           .replace(/\\"/g, '\\"')
                           .replace(/\\&/g, "\\&")
                           .replace(/\\r/g, "\\r")
                           .replace(/\\t/g, "\\t")
                           .replace(/\\b/g, "\\b")
                           .replace(/\\f/g, "\\f") : ''; 
        let data = documento;
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.post(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_DESCARGA_EXCEL,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });

    }




}