import React from "react";
// @material-ui/core components
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';

import documentoStyle from "styles/jss/app/views/documentoStyle.jsx";
import * as documentoActions from "actions/DocumentoActions";
import * as notificationActions from "actions/NotificationActions";
//import * as sessionActions from "actions/SessionActions";
import * as loadActions from "actions/LoadActions";
import Form from "components/Form/RepByCheckForm"; 
import Grid from "components/Grid/BsqByCheckGrid"; 

class ReportByCheck extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.reportByCheck = this.reportByCheck.bind(this);
    this.state = {
      documento: {
        estado: 0,
        docuFechaIni: '',
        docuFechaFin: '', 
        linea: '', 
        numeroFicha: ''
      }
    }
  }

  componentDidMount() {

     

  }

  reportByCheck = (data) => {
   // console.log("datos enviados");
  
    var {documento} = this.state;
    documento.docuNumero = data.txtnumdoc?data.txtnumdoc:'';
    documento.docuFechaIni = data.hFechaInicio?data.hFechaInicio:'';
    documento.docuFechaFin = data.hFechaFin?data.hFechaFin:''; 
    documento.linea = data.linea?data.linea:''; 
    documento.numeroFicha = data.numeroFicha?data.numeroFicha:''; 

    documento.estado = data.estado?data.estado:0;  
    this.setState({documento: documento});    
    this.Grid.buscarDocumentos();
  }
  getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }


  render() {
    const { classes } = this.props;
    return (
      <div>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <SearchIcon className={classes.titleIcon} />
            <Typography className={classes.title} variant="h4"> Criterio de Búsqueda </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Form onSubmit={this.reportByCheck} classes={classes} reportByCheck={this.reportByCheck} documento={this.state.documento} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <ListIcon className={classes.titleIcon} />
            <Typography className={classes.title} variant="h4"> Lista de Fichas </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid documento={this.state.documento} onRef={ref => (this.Grid = ref)}/>
          </ExpansionPanelDetails>
        </ExpansionPanel>

      </div>
    )
  }
}
const mapState = (state) => ({
  loading: state.load.loading
});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(documentoActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

ReportByCheck = withStyles(documentoStyle)(ReportByCheck);
export default withRouter(connect(mapState, mapDispatch)(ReportByCheck));