export const DATA_DISTRITOS = [
        {id: "140101",
          valor: "LIMA"},
        {id: "140102",
                  valor: "ANCON"},
        {id: "140103",
                  valor: "ATE"},
        {id: "140104",
                  valor: "BREÑA"},
        {id: "140105",
                  valor: "CARABAYLLO"},
        {id: "140106",
                  valor: "COMAS"},
        {id: "140107",
                  valor: "CHACLACAYO"},
        {id: "140108",
                  valor: "CHORRILLOS"},
        {id: "140109",
                  valor: "LA VICTORIA"},
        {id: "140110",
                  valor: "LA MOLINA"},
        {id: "140111",
                  valor: "LINCE"},
        {id: "140112",
                  valor: "LURIGANCHO"},
        {id: "140113",
                  valor: "LURIN"},
        {id: "140114",
                  valor: "MAGDALENA DEL MAR"},
        {id: "140115",
                  valor: "MIRAFLORES"},
        {id: "140116",
                  valor: "PACHACAMAC"},
        {id: "140117",
                  valor: "PUEBLO LIBRE"},
        {id: "140118",
                  valor: "PUCUSANA"},
        {id: "140119",
                  valor: "PUENTE PIEDRA"},
        {id: "140120",
                  valor: "PUNTA HERMOSA"},
        {id: "140121",
                  valor: "PUNTA NEGRA"},
        {id: "140122",
                  valor: "RIMAC"},
        {id: "140123",
                  valor: "SAN BARTOLO"},
        {id: "140124",
                  valor: "SAN ISIDRO"},
        {id: "140125",
                  valor: "BARRANCO"},
        {id: "140126",
                  valor: "SAN MARTIN DE PORRES"},
        {id: "140127",
                  valor: "SAN MIGUEL"},
        {id: "140128",
                  valor: "SANTA MARIA DEL MAR"},
        {id: "140129",
                  valor: "SANTA ROSA"},
        {id: "140130",
                  valor: "SANTIAGO DE SURCO"},
        {id: "140131",
                  valor: "SURQUILLO"},
        {id: "140132",
                  valor: "VILLA MARIA DEL TRIUNFO"},
        {id: "140133",
                  valor: "JESUS MARIA"},
        {id: "140134",
                  valor: "INDEPENDENCIA"},
        {id: "140135",
                  valor: "EL AGUSTINO"},
        {id: "140136",
                  valor: "SAN JUAN DE MIRAFLORES"},
        {id: "140137",
                  valor: "SAN JUAN DE LURIGANCHO"},
        {id: "140138",
                  valor: "SAN LUIS"},
        {id: "140139",
                  valor: "CIENEGUILLA"},
        {id: "140140",
                  valor: "SAN BORJA"},
        {id: "140141",
                  valor: "VILLA EL SALVADOR"},
        {id: "140142",
                  valor: "LOS OLIVOS"},
        {id: "140143",
                  valor: "SANTA ANITA"},
        {id: "140203",
                  valor: "CHOSICA"},
        {id: "140301",
                  valor: "CALLAO"},
        {id: "140302",
                  valor: "BELLAVISTA"},
        {id: "140303",
                  valor: "CARMEN DE LA LEGUA"},
        {id: "140304",
                  valor: "LA PERLA"},
        {id: "140305",
                  valor: "LA PUNTA"},
        {id: "140306",
                  valor: "MI PERÚ"},
        {id: "140307",
                  valor: "VENTANILLA"},
        {id: "260101",
                  valor: "PROVINCIA"}
      ];

export const DATA_TIPO_DOCUMENTO = [
            {
                id: "1",
                valor: "DNI"
              },
              {
                id: "2",
                valor: "RUC"
              },
              {
                id: "3",
                valor: "CE"
              }       
];

export const DATA_LINEAS = [
                {
                    id: "1",
                    valor: "TALLER LINEA LIMPIEZA"
                  },
                {
                    id: "2",
                    valor: "TALLER LINEA REFRIGERACIÓN"
                  },
                  {
                    id: "3",
                    valor: "TALLER LINEA ELECTRÓNICA"
                  },
                  {
                    id: "4",
                    valor: "TALLER LINEA BLANCA"
                  },
                  {
                    id: "5",
                    valor: "TALLER LINEA LAVANDERIA INDUSTRIAL"
                  }       
];

export const DATA_RECEPCIONISTA = [
    {
                    id: "1",
                    valor: "SANDRA PAUCAR"
                  },
              {
                    id: "2",
                    valor: "ANA PAULA JURADO"
                  },
                  {
                    id: "3",
                    valor: "GISELA ARADIEL"
                  },
                  {
                    id: "4",
                    valor: "ROSANGELYS HERNANDEZ"
                  },
                  {
                    id: "5",
                    valor: "MILAGROS GASLAC"
                  },
                  {
                    id: "6",
                    valor: "DAVID GASLAC"
                  },
                  {
                    id: "7",
                    valor: "KARIN YUPANQUI"
                  },
                  {
                    id: "8",
                    valor: "ESTER RODRIGUEZ"
                  },
                  {
                    id: "9",
                    valor: "DIANA TELLO"
                  },
                  {
                    id: "10",
                    valor: "OTRO"
                  },
                  {
                    id: "11",
                    valor: "LINARES ALDAZABAL, DAYANA"
                  },
                  {
                    id: "12",
                    valor: "INES QUEREVALU PURIZACA"
                  },
                  {
                    id: "13",
                    valor: "GASLAC GUTIERREZ CAROL"
                  },
                  {
                    id: "14",
                    valor: "GASLAC GUTIERREZ SANDRA"
                  },
                  {
                    id: "15",
                    valor: "MARCA GARCIA YAJAYRA TAINA"
                  },
                  {
                    id: "16",
                    valor: "WINNY TORRES"
                  },
                  {
                    id: "17",
                    valor: "TANYA TORRES"
                  }
];

export const DATA_AREA = [
            {
                id: "1",
                valor: "COBRABLES PRODUCTO PEQUEÑO"
              },
              {
                id: "2",
                valor: "GARANTÍA KITCHENAID"
              },
              {
                id: "3",
                valor: "GARANTIA/COBRABLE LIMPIEZA INDUSTRIAL"
              },
              {
                id: "4",
                valor: "GARANTÍA ELECTROLUX DOMÉSTICO"
              },
              {
                id: "5",
                valor: "GARANTÍA ELECTROLUX ICON"
              },
              {
                id: "6",
                valor: "GARANTÍA WHIRLPOOL LINEA BLANCA"
              },
              {
                id: "7",
                valor: "GARANTÍA UNIVERSAL"
              },
              {
                id: "8",
                valor: "GARANTÍA OSTER"
              },
              {
                id: "9",
                valor: "COBRABLE LINEA BLANCA"
              },
              {
                id: "10",
                valor: "GARANTÍA EXTENDIDA MOK"
              },
              {
                id: "11",
                valor: "GARANTIA WHIRLPOOL COMERCIAL"
              },
              {
                id: "12",
                valor: "GARANTIA / COBRABLE LAVANDERIA INDUSTRIAL"
              },
              {
                id: "13",
                valor: "GARANTIA / COBRABLE WINNIA"
              },
              {
                id: "14",
                valor: "GARANTIA / COBRABLE THOMAS"
              },
              {
                id: "15",
                valor: "GARANTÍA ILUMI"
              },
              {
                id: "16",
                valor: "COBRABLE ILUMI"
              },
              {
                id: "17",
                valor: "GARANTÍA LG COMERCIAL"
              }
];


export const DATA_AREA_ILUMI = [ 
    {
      id: "15",
      valor: "GARANTÍA ILUMI"
    }
];

export const DATA_AREA_INDURAMA = [ 
  {
    id: "18",
    valor: "GARANTÍA INDURAMA"
  },{
    id: "19",
    valor: "COBRABLE INDURAMA"
  }
];


export const DATA_ARTEFACTO = [
              {
                id: "1",
                valor: "AIRE ACONDICIONADO PRUEBA MAL"
              },
              {
                id: "2",
                valor: "ASPIRADORA"
              },
              {
                id: "3",
                valor: "BATIDORA"
              },
              {
                id: "4",
                valor: "BATIDORA DE MANO"
              },
              {
                id: "5",
                valor: "CAFETERA"
              },
              {
                id: "6",
                valor: "CALEFACTOR"
              },
              {
                id: "7",
                valor: "CAMPANA"
              },
              {
                id: "8",
                valor: "CENTRO DE LAVADO"
              },
              {
                id: "9",
                valor: "COCINA"
              },
              {
                id: "10",
                valor: "CONGELADOR"
              },
              {
                id: "11",
                valor: "DESHUMEDECEDOR"
              },
              {
                id: "12",
                valor: "DISPENSADOR DE AGUA"
              },
              {
                id: "13",
                valor: "EXHIBIDORAS"
              },
              {
                id: "14",
                valor: "EXPRIMIDOR DE CITRICOS"
              },
              {
                id: "15",
                valor: "FABRICADOR DE HIELO"
              },
              {
                id: "16",
                valor: "FREIDORA"
              },
              {
                id: "17",
                valor: "FRIGOBAR"
              },
              {
                id: "18",
                valor: "HERVIDOR"
              },
              {
                id: "19",
                valor: "HORNO"
              },
              {
                id: "20",
                valor: "LAVADORA"
              },
              {
                id: "21",
                valor: "LAVAPLATOS"
              },
              {
                id: "22",
                valor: "LAVASECA"
              },
              {
                id: "23",
                valor: "LICUADORA"
              },
              {
                id: "24",
                valor: "LUSTRADORA"
              },
              {
                id: "25",
                valor: "MICROONDAS"
              },
              {
                id: "26",
                valor: "OLLA ARROCERA"
              },
              {
                id: "27",
                valor: "PARRILLA"
              },
              {
                id: "28",
                valor: "PLANCHA"
              },
              {
                id: "29",
                valor: "PROCESADOR DE ALIMENTOS"
              },
              {
                id: "30",
                valor: "REFRIGERADORA"
              },
              {
                id: "31",
                valor: "SANDWICHERA"
              },
              {
                id: "32",
                valor: "SECADORA"
              },
              {
                id: "33",
                valor: "TERMA"
              },
              {
                id: "34",
                valor: "TORRE DE SECADO"
              },
              {
                id: "35",
                valor: "TOSTADORA"
              },
              {
                id: "36",
                valor: "VENTILADOR"
              },
              {
                id: "37",
                valor: "VINERA"
              },
              {
                id: "38",
                valor: "ASPIRADORA INDUSTRIAL"
              },
              {
                id: "39",
                valor: "LUSTRADORA INDUSTRIAL"
              },
              {
                id: "40",
                valor: "LAVAALFOMBRAS INDUSTRIAL"
              },
              {
                id: "41",
                valor: "LAVADORA INDUSTRIAL"
              },
              {
                id: "42",
                valor: "SECADORA INDUSTRIAL"
              },
              {
                id: "43",
                valor: "MESA DE PLANCHAR"
              },
              {
                id: "44",
                valor: "PLANCHA INDUSTRIAL"
              },
              {
                id: "45",
                valor: "CALANDRA"
              },
              {
                id: "46",
                valor: "LAVADORA COMERCIAL"
              },
              {
                id: "47",
                valor: "SECADORA COMERCIAL"
              },
              {
                id: "48",
                valor: "CENTRO DE LAVADO COMERCIAL"
              },
              {
                id: "49",
                valor: "LAVASECA"
              },
              {
                id: "50",
                valor: "ACCESORIO"
              },
              {
                id: "51",
                valor: "REPUESTO"
              },
              {
                id: "52",
                valor: "ESTUFA"
              },
              {
                id: "53",
                valor: "PURIFICADOR DE OSMOSIS"
              },
              {
                id: "54",
                valor: "CALEFACTOR"
              },
              {
                id: "55",
                valor: "WAFLERA"
              },
              {
                id: "56",
                valor: "VAPORIZADORA"
              },
              {
                id: "57",
                valor: "EXTRACTORA"
              },
              {
                id: "58",
                valor: "ADITAMENTO"
              },
              {
                id: "59",
                valor: "BARRERA INDUSTRIAL"
              } 
];

export const DATA_MARCA = [
            {
                id: "1",
                valor: "BOSCH MAL"
              },
              {
                id: "2",
                valor: "COLDEX"
              },
              {
                id: "3",
                valor: "ELECTROLUX"
              },
              {
                id: "4",
                valor: "FRIGIDAIRE"
              },
              {
                id: "5",
                valor: "GENERAL ELECTRIC"
              },
              {
                id: "6",
                valor: "IMACO"
              },
              {
                id: "7",
                valor: "INDURAMA"
              },
              {
                id: "8",
                valor: "KENMORE"
              },
              {
                id: "9",
                valor: "KITCHENAID"
              } ,
              {
                id: "10",
                valor: "LG"
              } ,
              {
                id: "11",
                valor: "MABE"
              } ,
              {
                id: "12",
                valor: "MAYTAG"
              } ,
              {
                id: "13",
                valor: "OSTER"
              } ,
              {
                id: "14",
                valor: "PHILIPS"
              } ,
              {
                id: "15",
                valor: "PRACTIKA"
              } ,
              {
                id: "16",
                valor: "SAMSUNG"
              } ,
              {
                id: "17",
                valor: "THOMAS"
              } ,
              {
                id: "18",
                valor: "UNIVERSAL"
              } ,
              {
                id: "19",
                valor: "WHIRLPOOL"
              } ,
              {
                id: "20",
                valor: "WHITE WESTINGHOUSE"
              } ,
              {
                id: "21",
                valor: "OTRO"
              },
              {
                id: "22",
                valor: "GHIBLI"
              },
              {
                id: "23",
                valor: "NILFISK ADVANCE"
              },
              {
                id: "24",
                valor: "CHASQUY"
              },
              {
                id: "25",
                valor: "DAEWOO"
              }
];


export const DATA_MARCA_ILUMI = [
    {
      id: "41",
      valor: "ILUMI"
    }
];

export const DATA_MARCA_INDURAMA= [
  {
    id: "7",
    valor: "INDURAMA"
  }
];


export const DATA_TIPO_SERVICIO = [
             {
                id: "1",
                valor: "GARANTIA"
              },
              {
                id: "2",
                valor: "COBRABLE"
              }, 
              {
                id: "3",
                valor: "GARANTIA TALLER"
              }
];

export const DATA_TIPO_COMPROBANTE = [
            {
                id: "1",
                valor: "BV"
              },
              {
                id: "2",
                valor: "FT"
              }   
];

export const DATA_TIPO_COSTOS = [
          {
            "id": 1,
            "name": "Repuestos"
          },{
            "id": 2,
            "name": "Mano de Obra"
          },
          {
            "id": 3,
            "name": "Mantenimiento"
          },
          {
            "id": 4,
            "name": "Transporte"
          },
          {
            "id": 5,
            "name": "Pintura"
          },
          {
            "id": 6,
            "name": "Carga de Gas"
          },
          {
            "id": 7,
            "name": "Rep. Tarjeta"
          } 
];


export const DATA_ESTADO_REPUESTO = [
          {
            "id": 0,
            "name": "No hay Stock"
          },
          {
            "id": 1,
            "name": "En stock"
          }
];


export const DATA_TIPO_TRABAJO = [
             {
                id: "1",
                valor: "INSTALACIÓN"
              },
              {
                id: "2",
                valor: "MANTENIMIENTO"
              }, 
              {
                id: "3",
                valor: "SERVICIO TECNICO"
              }, 
              {
                id: "4",
                valor: "MOVILIDAD"
              }, 
              {
                id: "5",
                valor: "CAMBIO REPUESTO"
              }, 
              {
                id: "6",
                valor: "REINCIDENTE"
              }, 
              {
                id: "7",
                valor: "SUPERVISOR"
              }, 
              {
                id: "8",
                valor: "RECOJO"
              }, 
              {
                id: "9",
                valor: "ENTREGA"
              },
              {
                id: "10",
                valor: "PINTURA"
              },
              {
                id: "11",
                valor: "CONVERSIÓN"
              }
];



export const DATA_TECNICOS =  [
          {
                id: "1",
                valor: "RUIZ SANDOVAL EDGAR MAL ERRRO"
              },
              {
                id: "2",
                valor: "SANCHEZ CANAYO VICTOR DANIEL"
              },
              {
                id: "3",
                valor: "TAFUR ALBERCA RAFAEL  SERGIO"
              },
              {
                id: "4",
                valor: "TORRES JAUREGUI CESAR ANIBAL"
              },
              {
                id: "5",
                valor: "DIAZ AGUILAR HUMBERTO PATRICIO"
              },
              {
                id: "6",
                valor: "HEREDIA ALVARADO MIRKO"
              },
              {
                id: "7",
                valor: "QUISPE NAVARRO JOEL RICARDO"
              },
              {
                id: "8",
                valor: "QUISPE APAZA JULIO CESAR"
              },
              {
                id: "9",
                valor: "TELLO ANGULO DIANA ROSARIO"
              },
              {
                id: "10",
                valor: "CASTILLO DE LA CRUZ LUIS MIGUEL"
              },
              {
                id: "11",
                valor: "CASTILLO ROMERO ERDWIN NEIL"
              },
              {
                id: "12",
                valor: "MARIA KATIA GARAY ESPINOZA"
              },
              {
                id: "13",
                valor: "JANET ASTO ATOCCSA "
              },
              {
                id: "14",
                valor: "LUIS FERNANDO BENDITA YANCHAYLA"
              },
              {
                id: "15",
                valor: "TECNICO NUEVO "
              },
              {
                id: "16",
                valor: "TECNICO NUEVO 2"
              },
              {
                id: "17",
                valor: "GASLAC CUBAS HANS"
              },
              {
                id: "18",
                valor: "TRANSPORTE"
              },
              {
                id: "19",
                valor: "JUAN CARLOS DAZA"
              },
              {
                id: "20",
                valor: "GASLAC CULQUI NIXON"
              },
              {
                id: "21",
                valor: "QUISPE PINO, JAIME HERNAN"
              },
              {
                id: "22",
                valor: "GASLAC CULQUI, DAVID"
              }
];

export const DATA_ESTADOS = [
                {
                    id: "0",
                    valor: "REGISTRADO"
                  },
                  {
                    id: "11",
                    valor: "REVISADO"
                  },{
                    id: "12",
                    valor: "EN REPARACIÓN"
                  },{
                    id: "13",
                    valor: "REPARADO"
                  }
                  ,{
                    id: "14",
                    valor: "NO SE PUDO REPARAR"
                  },
                  {
                    id: "1",
                    valor: "PND COTIZACIÓN"
                  },
                  {
                    id: "2",
                    valor: "COTIZADO"
                  },
                  {
                    id: "3",
                    valor: "ACEPTO"
                  },
                  {
                    id: "4",
                    valor: "NO ACEPTO"
                  } ,
                  {
                    id: "5",
                    valor: "ENTREGAR SIN REPARAR"
                  } ,
                  {
                    id: "6",
                    valor: "ENTREGADO SIN REPARAR"
                  } ,
                  {
                    id: "7",
                    valor: "PND DESPACHO RPTOS"
                  } , ,
                  {
                    id: "8",
                    valor: "PND X IMPORTACIÓN"
                  } ,
                  {
                    id: "9",
                    valor: "DESPACHADO"
                  },
                  {
                    id: "10",
                    valor: "ENTREGADO OK"
                  },
                  {
                    id: "19",
                    valor: "EN REVISIÓN"
                  },
                  {
                    id: "17",
                    valor: "EN PRUEBA"
                  },
                  {
                    id: "18",
                    valor: "DESCARTE"
                  }

];


export const DATA_ESTADOS_DIALOG_SEGUIMIENTO_DOMICILIO = [
  {
    id: "0",
    valor: "REGISTRADO"
  },
  {
    id: "1",
    valor: "PND COTIZACIÓN"
  },
  {
    id: "2",
    valor: "COTIZADO"
  },
  {
    id: "3",
    valor: "ACEPTO"
  },
  {
    id: "4",
    valor: "NO ACEPTO"
  } ,
  {
    id: "5",
    valor: "ENTREGAR SIN REPARAR"
  } ,
  {
    id: "7",
    valor: "PND DESPACHO RPTOS"
  } , ,
  {
    id: "8",
    valor: "PND X IMPORTACIÓN"
  } ,
  {
    id: "9",
    valor: "DESPACHADO"
  },
  {
    id: "10",
    valor: "ENTREGADO OK"
  },,
  {
    id: "15",
    valor: "LIQUIDADO"
  },
  {
    id: "21",
    valor: "PROGRAMADO"
  },
  {
    id: "22",
    valor: "RUTA OK"
  }, 
  {
    id: "23",
    valor: "NO CONTESTA"
  },
  {
    id: "24",
    valor: "REPROGRAMAR"
  },
  {
    id: "25",
    valor: "CANCELO SERVICIO"
  },
  {
    id: "26",
    valor: "ENTREGADO OK"
  },
  {
    id: "27",
    valor: "RECOJO OK"
  },
  {
    id: "28",
    valor: "ENTREGADO SIN REPARAR"
  },
  {
    id: "29",
    valor: "CERRADO OK"
  }    
  
];



export const DATA_ESTADOS_RUTA_DIA_DOMICILIO = [
  {
    id: "0",
    valor: "REGISTRADO"
  },
  {
    id: "11",
    valor: "REVISADO"
  },{
    id: "12",
    valor: "EN REPARACIÓN"
  },{
    id: "13",
    valor: "REPARADO"
  }
  ,{
    id: "14",
    valor: "NO SE PUDO REPARAR"
  },
  {
    id: "1",
    valor: "PND COTIZACIÓN"
  },
  {
    id: "2",
    valor: "COTIZADO"
  },
  {
    id: "3",
    valor: "ACEPTO"
  },
  {
    id: "4",
    valor: "NO ACEPTO"
  } ,
  {
    id: "5",
    valor: "ENTREGAR SIN REPARAR"
  } ,
  {
    id: "7",
    valor: "PND DESPACHO RPTOS"
  } , ,
  {
    id: "8",
    valor: "PND X IMPORTACIÓN"
  } ,
  {
    id: "9",
    valor: "DESPACHADO"
  },
  {
    id: "10",
    valor: "ENTREGADO OK"
  },
  {
    id: "19",
    valor: "EN REVISIÓN"
  },
  {
    id: "17",
    valor: "EN PRUEBA"
  },
  {
    id: "18",
    valor: "DESCARTE"
  },
  {
    id: "21",
    valor: "PROGRAMADO"
  },
  {
    id: "22",
    valor: "RUTA OK"
  }, 
  {
    id: "23",
    valor: "NO CONTESTA"
  },
  {
    id: "24",
    valor: "REPROGRAMAR"
  },
  {
    id: "25",
    valor: "CANCELO SERVICIO"
  },
  {
    id: "27",
    valor: "RECOJO OK"
  },
  {
    id: "28",
    valor: "ENTREGADO SIN REPARAR"
  },
  {
    id: "29",
    valor: "CERRADO OK"
  }   
];

export const DATA_ESTADOS_DIAGNOSTICO_DOMICLIO = [
  {
    id: "11",
    valor: "REVISADO"
  } ,
  {
    id: "13",
    valor: "REPARADO"
  },
  {
    id: "22",
    valor: "RUTA OK"
  }, 
  {
    id: "24",
    valor: "REPROGRAMAR"
  },
  {
    id: "26",
    valor: "ENTREGADO OK"
  },
  {
    id: "27",
    valor: "RECOJO OK"
  },
  {
    id: "28",
    valor: "ENTREGADO SIN REPARAR"
  }
];


export const DATA_ESTADOS_DIAGNOSTICO_DOMICLIO_TECNICO = [
  {
    id: "22",
    valor: "RUTA OK"
  },
  {
    id: "24",
    valor: "REPROGRAMAR"
  }
];


export const DATA_ESTADOS_DIALOG_SEGUIMIENTO_TALLER = [
  {
    id: "1",
    valor: "PND COTIZACIÓN"
  },
  {
    id: "2",
    valor: "COTIZADO"
  },
  {
    id: "3",
    valor: "ACEPTO"
  },
  {
    id: "4",
    valor: "NO ACEPTO"
  } ,
  {
    id: "5",
    valor: "ENTREGAR SIN REPARAR"
  } ,
  {
    id: "6",
    valor: "ENTREGADO SIN REPARAR"
  } ,
  {
    id: "7",
    valor: "PND DESPACHO RPTOS"
  } , ,
  {
    id: "8",
    valor: "PND X IMPORTACIÓN"
  } ,
  {
    id: "9",
    valor: "DESPACHADO"
  },
  {
    id: "10",
    valor: "ENTREGADO OK"
  },,
  {
    id: "15",
    valor: "LIQUIDADO"
  }
];

export const DATA_ESTADOS_DIAGNOSTICO_TALLER = [
  {
    id: "19",
    valor: "EN REVISIÓN"
  }, 
  {
      id: "11",
            valor: "REVISADO"
  },
  {
    id: "12",
    valor: "EN REPARACIÓN"
  },
  {
    id: "13",
    valor: "REPARADO"
  },
  {
    id: "14",
    valor: "NO SE PUDO REPARAR"
  },
  {
    id: "17",
    valor: "EN PRUEBA"
  },
  {
    id: "18",
    valor: "DESCARTE"
  }    
];


export const DATA_ESTADOS_PROGRAMACION_DOMICILIO = [ 
  {
    id: "1",
    valor: "PND COTIZACIÓN"
  },
  {
    id: "2",
    valor: "COTIZADO"
  },
  {
    id: "3",
    valor: "ACEPTO"
  },
  {
    id: "4",
    valor: "NO ACEPTO"
  } ,
  {
    id: "5",
    valor: "ENTREGAR SIN REPARAR"
  } ,
  {
    id: "28",
    valor: "ENTREGADO SIN REPARAR"
  } ,
  {
    id: "7",
    valor: "PND DESPACHO RPTOS"
  } , ,
  {
    id: "8",
    valor: "PND X IMPORTACIÓN"
  } ,
  {
    id: "9",
    valor: "DESPACHADO"
  },
  {
    id: "10",
    valor: "ENTREGADO OK"
  },,
  {
    id: "15",
    valor: "LIQUIDADO"
  },
];


export const DATA_TIENDAS = [
  
  {
     id: "1",
     valor: "SAGA FALABELLA "
   },
   {
     id: "2",
     valor: "SODIMAC"
   }, 
   {
     id: "3",
     valor: "TOTTUS"
   }, 
   {
     id: "4",
     valor: "HIRAOKA"
   }, 
   {
     id: "5",
     valor: "CURACAO"
   }, 
   {
     id: "6",
     valor: "CONECTA"
   }, 
   {
     id: "7",
     valor: "RIPLEY"
   }, 
   {
     id: "8",
     valor: "ELECTROLUX"
   }, 
   {
     id: "9",
     valor: "PLAZA VEA"
   },
   {
    id: "0",
    valor: "OTRO"
  }
];



 