import * as React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as perfilActions from "actions/PerfilActions";
import * as notificationActions from "actions/NotificationActions";
import * as parametroActions from "actions/ParametroActions";
import * as documentoActions from "actions/DocumentoActions";
import * as loadActions from "actions/LoadActions";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
//import PublicacionGrid from "components/Grid/PublicacionGrid";
import {TIPO_MENSAJE, GRID_TEXT_ES, MENSAJE, ESTADO, DSC_ESTADO} from 'utils/Constants';
import { email, required} from 'utils/FormValidation';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import OpcionesxPerfilDialog from "components/Dialog/OpcionesxPerfilDialog";

import {
  CustomPaging, SortingState, EditingState, PagingState, DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
  Grid, TableEditColumn,  TableColumnVisibility,
  VirtualTable, TableEditRow, TableHeaderRow, PagingPanel, 
  TableColumnResizing
} from '@devexpress/dx-react-grid-material-ui';


const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Editar" style={{padding: "2px"}}>
    <EditIcon />
  </IconButton>
);

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Guardar" style={{padding: "2px"}}>
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancelar" style={{padding: "2px"}}>
    <CancelIcon />
  </IconButton>
);

const commandComponents = {
  edit: EditButton,
   commit: CommitButton,
   cancel: CancelButton
};

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return (
    <CommandButton style={{ padding: 0 }}
      onExecute={onExecute}
    />
  );
};


const EditCell = (props) => {
  return <TableEditRow.Cell {...props} />;
};

const getRowId = row => row.ideperfil;

//Estado de Perfil
const StsPerfilTypeProvider = props => {
  const BooleanEditor = ({ value, onValueChange }) => {
    return  <Select
              input={<Input />}
              value={value}
              onChange={event => onValueChange(event.target.value)}
              style={{ width: '100%' }}
            >
            {props.estados.map(({stsperfil,dscstsperfil}) => (
            <MenuItem  value={stsperfil} >
              {dscstsperfil}
            </MenuItem>
            ))}           
          </Select>
         
  };
    const BooleanFormatter = ({ value }) => {
      var dscstsperfil = 'PARAMETRO INVALIDO';
      for (var key in props.todosestados) {
        const obj = props.todosestados[key];
        if (obj.stsperfil == value) {
          dscstsperfil = obj.dscstsperfil;
        }
      }  
      return <Chip label={dscstsperfil} />      
    };

  return <DataTypeProvider  formatterComponent={BooleanFormatter}
                            editorComponent={BooleanEditor}
                            {...props}
  />
};
//Fin Estado Perfil

//Nombre Perfil
const NomPerfilEditor = ({ value, onValueChange }) => { 

  var label = required(value) !== undefined ? required(value) : '';
  var error = required(value) ? true : false;

  return   <TextField            
            id="standard-name"
            label={label}
            value={value}
            onChange={event => onValueChange(event.target.value)}
            type="text"
            error={error}
            />
};

const NomPerfilTypeProvider = props => {
  return <DataTypeProvider
          editorComponent={NomPerfilEditor}
          {...props}
        />
};
//Fin

//Descripción Perfil
const DscPerfilEditor = ({ value, onValueChange }) => { 

    var label = required(value) !== undefined ? required(value) : '';
    var error = required(value) ? true : false;
  
    return   <TextField            
              id="standard-name"
              label={label}
              value={value}
              onChange={event => onValueChange(event.target.value)}
              type="text"
              error={error}
              />
  };
  
  const DscPerfilTypeProvider = props => {
    return <DataTypeProvider
            editorComponent={DscPerfilEditor}
            {...props}
          />
  };
  //Fin

class PerfilGrid extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.listarOpcionesxPerfil = this.listarOpcionesxPerfil.bind(this);
    this.state = {
      columns: [
        { name: 'asignar', title: 'edit' },  
        { name: 'tag', title: 'tag' },  
        { name: 'ideperfil', title: 'id' },      
        { name: 'nomperfil', title: 'Nombre del Perfil *' },
        { name: 'dscperfil', title: 'Descripción del Perfil *'},
        { name: 'stsperfil', title: 'Estado *'},
        { name: 'usumodificacion', title: 'Usuario Mod' },
        { name: 'fecmodificacion', title: 'Fecha Mod' }
      ],
      tableColumnExtensions: [   
        { columnName: 'asignar', width: 30 },
        { columnName: 'tag', width: 30 }, 
        { columnName: 'ideperfil', width: 30 },
        { columnName: 'nomperfil', width: 250 },
        { columnName: 'dscperfil', width: 350 },
        { columnName: 'stsperfil', width: 150 },
        { columnName: 'usumodificacion', width: 200 },
        { columnName: 'fecmodificacion', width: 200 }
      ],

      editingStateColumnExtensions: [
        { columnName: 'dscperfil', editingEnabled: true },
        { columnName: 'nomperfil', editingEnabled: true },
        { columnName: 'stsperfil', editingEnabled: true },
        { columnName: 'ideperfil', editingEnabled: false },
        { columnName: 'usumodificacion', editingEnabled: false },
        { columnName: 'fecmodificacion', editingEnabled: false },
        { columnName: 'asignar', editingEnabled: false },
        { columnName: 'tag', editingEnabled: false }
      ],

      hiddenColumnNames: ['ideperfil'],
      rows: [],
      sorting: [],
      currentPage: 0,
      totalCount: 0,
      pageSize: 20,
      pageSizes: [10, 20, 50, 100],
      editingRowIds: [],
      perfil: {
        ideperfil: '',
        nomperfil: '',
        dscperfil: '',
        stsperfil: ''
      },
      stsPerfilColumn: ['stsperfil'],
      dscPerfilColumn: ['dscperfil'],
      nomPerfilColumn:['nomperfil'],
      listaOpcionesxPerfil: [],
      selection:[],
      showOpcionesxPerfilDialog : false,
      todosestados:[]
      
    };

    this.hiddenColumnNamesChange = (hiddenColumnNames) => {
      this.setState({ hiddenColumnNames });
    };

    this.changeSorting = sorting => this.setState({ sorting });
    this.changeEditingRowIds = editingRowIds => this.setState({ editingRowIds });
    this.changeRowChanges = rowChanges => this.setState({ rowChanges });    

    this.commitChanges = ({ added, changed, deleted }) => {
      let { rows } = this.state;
      if (changed) {                  
        const { add, remove } = this.props.loadActions;
        const { show } = this.props.notificationActions;
        var perfil = {};
        var indCambio = true;
        for (var key in changed) {
          perfil = changed[key];
          if (typeof perfil === "undefined"){
            indCambio = false;
          }else{
             perfil.ideperfil = key;
          }
        }
        if (!indCambio) {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "No se realizó ningún cambio.", TIPO_MENSAJE.INFO);
          return;
        } 

        if ((perfil.nomperfil !== undefined && required(perfil.nomperfil)) || (perfil.dscperfil !== undefined && required(perfil.dscperfil))) {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "Corregir el error de validación", TIPO_MENSAJE.INFO);
          return;
        } 

        add();

        this.props.actions.actualizarPerfil(perfil, this.props.user).then(response => {
          if (response.tipo === TIPO_MENSAJE.SUCCESS) {
            this.buscarPerfil();
          }
          show(response.indMostrarMensaje, response.mensaje, response.tipo);
          remove();
        }) 

      }
      this.setState({ rows });
    };    

  }

  
  changeCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage })
    this.buscarPerfil(currentPage);
  }

  changePageSize = (pageSize) => {
    const { totalCount, currentPage: stateCurrentPage } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(stateCurrentPage, totalPages - 1);

    this.setState({
      pageSize: pageSize,
      currentPage: currentPage
    });
    this.buscarPerfil(currentPage, pageSize);
  }

  componentDidMount() {
    this.props.onRef(this);
    this.buscarPerfil();  
    this.listarTodosEstados();
  }

  listarTodosEstados() {
    const { show } = this.props.notificationActions;
    this.props.documentoActions.listarParametrosFiltroDocumento('listparamconsult').then(response => {
      if (response.tipo !== TIPO_MENSAJE.SUCCESS) {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
      } else {
        if (response.data.length === 0) {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "No se encontraron los estados a mostrar.", TIPO_MENSAJE.WARNING);
        } else {
          var todosestados = [];
          var estado;
          /*for (var key in response.data) {
            estado = {};
            estado.dscstsperfil = response.data[key].dscparametro;
            estado.stsperfil = response.data[key].codparametro;
            todosestados.push(estado);
          }*/
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].cod_grup_parm == '6') {
              estado = {};
              estado.dscstsperfil = response.data[i].nom_parm;
              estado.stsperfil = response.data[i].val_parm_nume;
              todosestados.push(estado);
            }
          }
          this.setState({ todosestados: todosestados})         
        }
      }
    })   
  }

  buscarPerfil = (page, size) => {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    const { currentPage, pageSize } = this.state;
    add();
    page = page ? page : currentPage;
    size = size ? size : pageSize;

    if (page === -1) {
      page = 0;
      this.limpiarForm();
    }
    if (page === currentPage) { page = 0; }
    this.props.actions.paginarPerfil(this.props.perfil, page + 1, size).then(response => {
      if (response.tipo === TIPO_MENSAJE.SUCCESS) {
        this.setState({ rows: response.data.rows, totalCount: response.data.totalCount })
      } else {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
      }
      remove();
    })
  }  

  toggleDialogOpcionesxPerfil = (perfilVal) => {
    var { perfil } = this.state;
    if (typeof perfilVal !== "undefined") {
        perfil = Object.assign(perfil, perfilVal);
    } else {
        perfil.ideperfil = '';
        perfil.nomperfil = '';
        perfil.dscperfil = '';
        perfil.stsperfil = '';

    }
    this.listarOpcionesxPerfil(perfil);
    this.setState({ showOpcionesxPerfilDialog: !this.state.showOpcionesxPerfilDialog })
  };

  listarOpcionesxPerfil = (perfil) => {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    var selection = [];
    add();
    this.props.actions.listarOpcionesxPerfil(perfil).then(response => {
      if (response.tipo !== TIPO_MENSAJE.SUCCESS) {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
      } else {
        if (response.data.length === 0) {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "No se encontraron Opciones a mostrar.", TIPO_MENSAJE.WARNING);
        } else {
          for (var key in response.data) {
            if (response.data[key].stssegopperfil == "1") {
              selection.push(response.data[key].ideopcion);
            }
            
          }
          this.setState({ listaOpcionesxPerfil: response.data,
                          selection: selection,
                          perfil : perfil})
        }
      }
    remove();
    })
  };
  

  Cell = (props) => {
    const { column } = props;
    const { classes } = props;

    if (column.name === 'asignar') {
      return (
        <TableCell style={{ padding: 0 }}>
          <IconButton color="primary" aria-label="editIcon" style={{ display: 'flex', float: 'left' }}  
            onClick={this.toggleDialogOpcionesxPerfil.bind(this, props.row)} title="Asignar Opciones" >
            <HowToRegIcon />            
          </IconButton>
        </TableCell>
      );
    }

    return <VirtualTable.Cell {...props} />;
  };

  render() {
    const {
      rows,
      columns,
      tableColumnExtensions,
      editingStateColumnExtensions,
      sorting,
      editingRowIds,
      rowChanges,
      currentPage,
      pageSize,
      pageSizes,
      totalCount,
      hiddenColumnNames,
      stsPerfilColumn,
      nomPerfilColumn,
      dscPerfilColumn,
      showOpcionesxPerfilDialog,
      listaOpcionesxPerfil,
      selection,
      perfil,
      todosestados
    } = this.state;
    const { classes, estados } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ width: "100%" }}>
            <Grid
              rows={rows}
              columns={columns}
              style={{ height: "500px" }}
              getRowId={getRowId}
              className={"ps-child"}
            >
              <SortingState
                sorting={sorting}
                onSortingChange={this.changeSorting}
              />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={this.changeCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={this.changePageSize}
              />

              <EditingState
                editingRowIds={editingRowIds}
                onEditingRowIdsChange={this.changeEditingRowIds}
                rowChanges={rowChanges}
                onRowChangesChange={this.changeRowChanges}
                onCommitChanges={this.commitChanges}
                columnExtensions={editingStateColumnExtensions}
               />

              <CustomPaging
                totalCount={totalCount}
              />
              <VirtualTable
                columnExtensions={tableColumnExtensions}
                cellComponent={this.Cell}
                messages={GRID_TEXT_ES}
              />

              <StsPerfilTypeProvider
                  for={stsPerfilColumn}
                  estados={estados}
                  todosestados={todosestados}
              />

              <DscPerfilTypeProvider
                  for={dscPerfilColumn}
              />

              <NomPerfilTypeProvider
                  for={nomPerfilColumn}
              />
              
              <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
              <TableHeaderRow showSortingControls />
              <TableEditRow
                cellComponent={EditCell}
              />

              <TableEditColumn
                width={85}
                showEditCommand                      
                commandComponent={Command}
              />
              <PagingPanel
                pageSizes={pageSizes}
                messages={GRID_TEXT_ES}
              />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
              />

            </Grid>
          </Paper>
        </GridItem>
        <OpcionesxPerfilDialog toggleDialogOpcionesxPerfil={this.toggleDialogOpcionesxPerfil} 
                        showOpcionesxPerfilDialog={showOpcionesxPerfilDialog} 
                        perfil={perfil} 
                        classes={classes}
                        listaOpcionesxPerfil = {listaOpcionesxPerfil}
                        listarOpcionesxPerfil = {this.listarOpcionesxPerfil}
                        selection = {selection}
                        titulo = {"Asignación de Opciones del Sistema"}/>        
      </GridContainer>
    );
  }
}

const mapState = (state) => ({
    loading: state.load.loading,  
    user: state.session.user,
  });
  

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(perfilActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    parametroActions:bindActionCreators(parametroActions, dispatch),
    documentoActions: bindActionCreators(documentoActions, dispatch)
  };
};

export default withRouter(connect(mapState, mapDispatch)(PerfilGrid));

