import parametroApi from 'services/api/ParametroApi';
import { TIPO_MENSAJE, MENSAJE, OPC_PARAMETROS } from 'utils/Constants';


export function listarParametro(parametro) {
    return function (dispatch) {
        return parametroApi.listarParametro(parametro).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function seleccionarParametro(parametro) {
    return function (dispatch) {
        return parametroApi.seleccionarParametro(parametro).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function insertarTipParametro(parametroCab, user) {
    return function (dispatch) {
        return parametroApi.insertarTipParametro(parametroCab, user, OPC_PARAMETROS.INSERTAR_TIPPARAMETRO).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function listarTipParametro() {
    return function (dispatch) {
        return parametroApi.listarTipParametro(OPC_PARAMETROS.LISTAR_TIPPARAMETRO).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function listarParametroDet(idetipparametro) {
    return function (dispatch) {
        return parametroApi.listarParametroDet(OPC_PARAMETROS.LISTAR_PARAMETRO, idetipparametro).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function insertarParametro(parametro, user) {
    return function (dispatch) {
        return parametroApi.insertarParametro(parametro, user, OPC_PARAMETROS.INSERTAR_PARAMETRO).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function actualizarTipParametro(parametroCab, user) {
    return function (dispatch) {
        return parametroApi.actualizarTipParametro(parametroCab, user, OPC_PARAMETROS.ACTUALIZAR_TIPPARAMETRO).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function actualizarParametro(parametro, codparametroOld, user) {
    return function (dispatch) {
        return parametroApi.actualizarParametro(parametro, codparametroOld, user, OPC_PARAMETROS.ACTUALIZAR_PARAMETRO).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

