import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm } from 'redux-form';
import { required, integer, min0, max3000, maxLength4000 } from 'utils/FormValidation';
import { ESTADO } from 'utils/Constants';

import * as loadActions from "actions/LoadActions";
import * as notificationActions from "actions/NotificationActions";
import * as formActions from "actions/FormActions";

class ParametroDetMantForm extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            parametroDet : {
                idetipparametro: '',
                codparametro: '',
                dscparametro: '',
                txtparametro: '',
                stsparametro: ''
            } 
        }
    }

    componentDidMount() {    
        var { parametroDet } = this.props;    

        if (parametroDet.stsparametro === '') {
          parametroDet.stsparametro = ESTADO.ACTIVO;
        }

        this.props.formActions.loadData(parametroDet);
        this.setState({ parametroDet: parametroDet });
    }

    getSelectValue = (valor, name) => {
        var parametroDet = this.state.parametroDet;
        parametroDet[name] = valor;
        this.setState({ parametroDet: parametroDet });
    }

  render() {
    const { handleSubmit, classes, estadosDet } = this.props;
    const { parametroDet, estadox } = this.state;

    return (
      <form onSubmit={handleSubmit} style={{ width: "100%", margin: 8 * 3 }}>
        <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Field labelText="Identificador"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          type: "text",
                          autoComplete: "idetipparametro",
                          name: "idetipparametro",
                          disabled: true
                        }}
                        component={CustomInput}
                        validate={[required]}
                        value={parametroDet.idetipparametro}
                        name="idetipparametro"
                        id="idetipparametro" />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Field labelText="Orden"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          type: "number",
                          autoComplete: "codparametro",
                          name: "codparametro"
                        }}
                        
                        component={CustomInput}
                        validate={[integer, min0, max3000]}
                        valor={parametroDet.codparametro}
                        name="codparametro"
                        id="codparametro" />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Field labelText="Descripción"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          type: "text",
                          autoComplete: "dscparametro",
                          name: "dscparametro"
                        }}
                        component={CustomInput}
                        
                        value={parametroDet.dscparametro}
                        name="dscparametro"
                        id="dscparametro" />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Field labelText="Texto"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          type: "text",
                          autoComplete: "txtparametro",
                          name: "txtparametro"
                        }}
                        component={CustomInput}
                        
                        value={parametroDet.txtparametro}
                        name="txtparametro"
                        id="txtparametro" />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Field labelText="Estado"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          autoComplete: "stsparametro",
                          name: "stsparametro"
                        }}
                        multiple={false}
                        data={estadosDet}
                        component={CustomSelect}
                        valor={parametroDet.stsparametro}
                        validate={[required]}
                        getValue={this.getSelectValue}
                        val="stsparametro"
                        txt="descparametro"
                        name="stsparametro"
                        id="stsparametro" />
                    </GridItem>                          
                                             
                </GridContainer>
              </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
              {/* <div style={{ textAlign: "right" }}> */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}                
              >Guardar</Button>
              <Button onClick={this.props.toggleDialogMantParamDet} className={classes.submit}>Cerrar</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </form>
    );
  }

}

ParametroDetMantForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

ParametroDetMantForm = reduxForm({
  form: 'parametroDetMantForm',
  enableReinitialize: true
})(ParametroDetMantForm)

const mapState = (state) => ({
  loading: state.load.loading,
  initialValues: state.formApp.data,
  user: state.session.user
});

const mapDispatch = (dispatch) => {
  return {    
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch)
  };
};

export default withRouter(connect(mapState, mapDispatch)(ParametroDetMantForm));