import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Button from "components/CustomButtons/Button";
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Details from '@material-ui/icons/Details';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Settings from '@material-ui/icons/Settings';
import List from '@material-ui/icons/List';
import TableCell from '@material-ui/core/TableCell';
import RootRef from '@material-ui/core/RootRef';
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import PropTypes from 'prop-types';
import {
  TIPO_MENSAJE, GRID_TEXT_ES, MENSAJE
} from 'utils/Constants';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import {
  SelectionState,
  IntegratedSelection,
  FilteringState, SortingState, IntegratedFiltering,
  PagingState, IntegratedPaging, DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable, Table,
  TableHeaderRow,
  TableSelection, TableFilterRow, PagingPanel
} from '@devexpress/dx-react-grid-material-ui';


import * as usuarioActions from "actions/UsuarioActions";
import * as parametroActions from "actions/ParametroActions";
import * as notificationActions from "actions/NotificationActions";
import * as loadActions from "actions/LoadActions";

import withStyles from "@material-ui/core/styles/withStyles";
import cuestionarioStyle from "styles/jss/app/views/cuestionarioStyle.jsx";
import ParametroCabMantForm from "components/Form/ParametroCabMantForm";

var pintaFila = "";

const Row = (props) => {
  const { row } = props;
  let style = {}
  if (row.idetipparametro === pintaFila) {
    style = { backgroundColor: "lightcyan" }
  }
  return <VirtualTable.Row {...props} style={style} />;
};

class ParametroCabGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                { name: 'mnt', title: ' ' },
                { name: 'det', title: ' ' },
                { name: 'idetipparametro', title: 'Código' },
                { name: 'dsctipparametro', title: 'Descripción' },
                { name: 'dscststipparametro', title: 'Estado' }
            ],
            tableColumnExtensions: [
                { columnName: 'mnt', width: 60, align: 'right', wordWrapEnabled: true },
                { columnName: 'det', width: 60, align: 'center', wordWrapEnabled: true },
                { columnName: 'idetipparametro', width: 180, align: 'left', wordWrapEnabled: true },
                { columnName: 'dsctipparametro', width: 350, align: 'left', wordWrapEnabled: true },
                { columnName: 'dscststipparametro', width: 150, align: 'center' }
            ],
            filteringStateColumnExtensions: [
                { columnName: 'mnt', filteringEnabled: false },
                { columnName: 'det', filteringEnabled: false }
            ],
            rows: [],
            currentPage: 0,
            pageSize: 10, 
            pageSizes: [10, 20, 0],
            parametro : {
                idetipparametro: '',
                dsctipparametro: '',
                ststipparametro: ''
            },
            metodo: null,
            showParametroCabActual: false,
            flagDisabledIdetipparametro: false,
            idetipparametroActual: ''
        };

        this.contentRefGrid = element => {

        };

        this.changeCurrentPage = currentPage => this.setState({ currentPage });
        this.changePageSize = pageSize => this.setState({ pageSize });
    }

    Cell = (props) => {
        const { column } = props;

        if (column.name === 'mnt') {
            return (
                <TableCell style={{ padding: 0 }}>
                <IconButton color="primary" aria-label="observacion" style={{ display: 'flex', float: 'left' }}
                    onClick={this.toggleDialogMantParamCab.bind(this, props.row)} title="Mantenimiento" >
                    <EditIcon />
                </IconButton>
                </TableCell>
            );
        }

        if (column.name === 'det') {                              
            return (            
                <TableCell style={{ padding: 0 }}>
                <IconButton color="primary" aria-label="deleted" style={{ display: 'flex', float: 'left' }}
                    onClick={this.listarParametroDetalle.bind(this, props.row)} title="Detalle" >              
                    <Details />            
                </IconButton>
                </TableCell>
            );
        }

      return <VirtualTable.Cell {...props} />;
    }; 


    toggleDialogMantParamCab = (data) => {
        var { parametro } = this.state; 
        if (typeof data !== "undefined") {          
          parametro = Object.assign(parametro, data);            
          this.setState({metodo : 0});
          this.setState({flagDisabledIdetipparametro : true});
        } else {          
            parametro.idetipparametro = '';
            parametro.dsctipparametro = '';
            parametro.ststipparametro = '';
            this.setState({parametro : parametro});   
            this.setState({metodo : 1});
            this.setState({flagDisabledIdetipparametro : false});
        }
    
      this.setState({ showParametroCabActual: !this.state.showParametroCabActual })
    };
    
    componentDidMount() {
      this.listarTipParametro();
    }


    listarTipParametro = () => {
        const { add, remove } = this.props.loadActions;
        const { show } = this.props.notificationActions;
        add();
        this.props.actions.listarTipParametro().then(response => {

        if (response.tipo === TIPO_MENSAJE.SUCCESS) {
            this.setState({ rows: response.data })
        } else {
            this.setState({ rows: [] })
            show(response.indMostrarMensaje, response.mensaje, response.tipo);
        }
        remove();
        })
    }


    listarParametroDetalle = (parametro) => {
        pintaFila =  parametro.idetipparametro;
        this.props.listarParametroDet(parametro.idetipparametro);
    }

    enviarMetodo = (data) =>{
        if(this.state.metodo === 1){
            this.crearParametroCab(data);
        }else{
            this.actualizarParametroCab(data);
        }
    }

    crearParametroCab = (data) => {
      const { add, remove } = this.props.loadActions;
      const { show } = this.props.notificationActions;             
        add();    
        this.props.actions.insertarTipParametro(data, this.props.user).then(response => {       
            if (response.tipo === TIPO_MENSAJE.SUCCESS) {        
                show(response.indMostrarMensaje, response.mensaje, response.tipo);          
                this.listarTipParametro();
                this.toggleDialogMantParamCab();        
            }else {
                show(response.indMostrarMensaje, response.mensaje, response.tipo);      
            }        
            remove();
        });
    }

    actualizarParametroCab = (data) =>{    
      const { add, remove } = this.props.loadActions;
      const { show } = this.props.notificationActions;                 
        add();  
        this.props.actions.actualizarTipParametro(data, this.props.user).then(response => {       
        if (response.tipo === TIPO_MENSAJE.SUCCESS) {        
            show(response.indMostrarMensaje, response.mensaje, response.tipo);          
            this.listarTipParametro();   
        }else{
            show(response.indMostrarMensaje, response.mensaje, response.tipo);
        }        
        remove();
        });
    }

    render() {
        const {rows, columns, currentPage, 
            pageSize, 
            tableColumnExtensions, 
            showParametroCabActual,
            pageSizes,
            parametro,
            flagDisabledIdetipparametro} = this.state;
        const { handleSubmit, classes, estados, pristine } = this.props;
        return (
        <div>
                <Button
                        type="button"
                        variant="contained"
                        color="primary"                      
                        onClick={() => this.toggleDialogMantParamCab()}
                >Nuevo</Button>
            <br/>
            <form className={classes.root} onSubmit={handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <Paper>
                            <RootRef rootRef={this.contentRefGrid}>
                            <Grid
                            rows={rows}
                            columns={columns}
                            style={{ height: "450px" }}
                            className={"ps-child"}
                            getRowId={row => row.idetipparametro}
                            >

                            <FilteringState
                                columnExtensions={this.state.filteringStateColumnExtensions} />
                            <IntegratedFiltering />
                            <SortingState />
                            <PagingState
                                currentPage={currentPage}
                                onCurrentPageChange={this.changeCurrentPage}
                                pageSize={pageSize}
                                onPageSizeChange={this.changePageSize}
                            />
                            <IntegratedPaging />
                            
                            <VirtualTable
                                columnExtensions={tableColumnExtensions}
                                cellComponent={this.Cell}
                                rowComponent={Row}
                                messages={GRID_TEXT_ES}
                            />
                            <TableHeaderRow />
                            <TableFilterRow messages={GRID_TEXT_ES} />
                            <PagingPanel
                                pageSizes={pageSizes}
                                messages={GRID_TEXT_ES}
                            />
                            </Grid>
                            </RootRef>
                        </Paper>
                        </GridItem>
                    </GridContainer>

                    <Dialog                                
                        open={showParametroCabActual}
                        onClose={this.toggleDialogMantParamCab}
                        >                        
                        <DialogTitle>Mantenimiento de Parametro Cabecera</DialogTitle>
                        <DialogContent>
                        {/* */}
                        <ParametroCabMantForm classes={classes} 
                            toggleDialogMantParamCab={this.toggleDialogMantParamCab}
                            onRef={ref => (this.ParametroCabMantForm = ref)}
                            estados={estados} parametro={parametro}
                            onSubmit={this.enviarMetodo}
                            flagDisabledIdetipparametro={flagDisabledIdetipparametro} />
                        </DialogContent>               
                        <DialogActions>
                        {/*  <Button onClick={this.toggleDialogMantDriver}>Cerrar</Button> */}
                        </DialogActions>            
                    </Dialog>
            </form>
        </div>

        );
    }

}

const mapState = (state) => ({
  user: state.session.user,
  loading: state.load.loading
});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(parametroActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    usuarioActions: bindActionCreators(usuarioActions, dispatch)
  };
};

ParametroCabGrid = withStyles(cuestionarioStyle)(ParametroCabGrid);
export default withRouter(connect(mapState, mapDispatch)(ParametroCabGrid));