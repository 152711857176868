import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import ParametroSelect from "components/CustomInput/ParametroSelect";
import CustomSelect from "components/CustomInput/CustomSelect";
import CustomAutocomplete from "components/CustomInput/CustomAutocomplete";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';

import * as documentoActions from "actions/DocumentoActions";
import * as loadActions from "actions/LoadActions";
import * as usuarioActions from "actions/UsuarioActions";
import * as notificationActions from "actions/NotificationActions";
import { s2ab } from 'utils/Functions';
import { getFormatDate, getRestarDias } from 'utils/Functions';
import { DATA_DISTRITOS, DATA_TECNICOS, DATA_ESTADOS, DATA_TIPO_TRABAJO } from 'utils/GeneralData';
import { change } from 'redux-form'

const required = value => value ? undefined : 'Valor Requerido'

const validate = values => {
  const errors = {}
  var fechaActual = getFormatDate(new Date(),  'ymd', '-')

  if (!values.tipReporte) {
    errors.tipReporte = 'Seleccione el tipo de reporte'
  }

  if (!values.typeGraph) {
    errors.typeGraph = 'Seleccione el tipo de grafico'
  }

  

  if (!values.hFechaInicio) {
    errors.hFechaInicio = 'Valor Requerido'
  } else if (values.hFechaInicio > values.hFechaFin) {
    errors.hFechaInicio = ' '
  } 

  if (!values.hFechaFin) {
    errors.hFechaFin = 'Valor Requerido'
  } else if (values.hFechaFin < values.hFechaInicio) {    
    errors.hFechaFin = ' '
  } 

  if (values.typeGraph && values.typeGraph == 'PIE' && !values.compania) {
    errors.compania = 'Valor Requerido para este tipo de consulta'
  }
  
  if (values.hFechaInicio && values.hFechaFin) {
    var fechaInicio = new Date(values.hFechaInicio).getTime();
    var fechaFin    = new Date(values.hFechaFin).getTime();
    var diff = fechaFin - fechaInicio;
    var difDias = diff/(1000*60*60*24);
    //if (difDias > 30 ){
      // errors.hFechaFin = 'La diferencia de fechas no puede ser mayor a 30 dias'
    //}
  }

  return errors
}

/****end new */

class BsqProgRutaForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { 
      statesSunat: [],
      lstCdr: [],
      lstTypeDocument: [],
      graficos: [],
      tipReporte: [],
      tipsTrabajo: [],
      documento: {
        docuNumero: '',
        tipoDocumento: [],
        clieNumero: '',  //es ruc
        docuFechaIni: '',
        docuFechaFin: '',
        estado: [],
        clieNombre: '', //razon Social
        cdr: '',
        documentTypeClient: '',
        compania: '',
        producto: '',
        estado: '',
        marca: '',
        tipoServicio: '',
        area: '',
        modelo: '',
        serie: '',
        clieTipDoc: '',
        numeroservicio: '',
        numeroficha: '',
        telefono: '',
        distrito: '',
        tecnico: '',
        tipoTrabajo: ''
      },
      distritos: [{id: "140101",
          valor: "LIMA"}],
      tecnicos: [{
                id: "1",
                valor: "RUIZ SANDOVAL EDGAR"
              }],
      fecdefault: getFormatDate(new Date(),  'ymd', '-'),
      errorFecha: '',
      errorFechaFin: '',
      estadoArtefactos: [{
                    id: "0",
                    valor: "REGISTRADO"
                  }]
    }
  }
  componentDidMount() {
    //this.buscarParametroFiltro();
    this.props.change('hFechaInicio', getFormatDate(new Date(), 'ymd', '-'))
    this.props.change('hFechaFin', getFormatDate(new Date(), 'ymd', '-'))
    this.state.documento.docuFechaIni = getFormatDate(new Date(), 'ymd', '-')
    this.state.documento.docuFechaFin = getFormatDate(new Date(), 'ymd', '-')
    //para filtros exportar excel
    this.props.documento.docuFechaIni = getFormatDate(new Date(), 'ymd', '-')
    this.props.documento.docuFechaFin = getFormatDate(new Date(), 'ymd', '-')

    this.state.tecnicos = JSON.parse(localStorage.getItem("tecnicos_serfac")); //DATA_TECNICOS;
    this.state.distritos = JSON.parse(localStorage.getItem("ubigeos_serfac")); //DATA_DISTRITOS;
    this.state.estadoArtefactos = DATA_ESTADOS;

    this.state.tipsTrabajo = JSON.parse(localStorage.getItem("tipotrabajos_serfac")); //DATA_TIPO_TRABAJO;
    this.props.onRef(this);
  }  

  getSelectValue = (valor, name) => {
    var documento = this.state.documento
    documento[name] = valor;
    this.setState({ documento: documento });
  }


  obtenerTecnico = () => {
    return this.state.documento.tecnico;
  }

  limpiarForm = () => {
    this.props.reset();
    var documento = {
     docuFechaIni: getFormatDate(new Date(), 'ymd', '-'),
      docuFechaFin: getFormatDate(new Date(), 'ymd', '-'),
      
      numeroficha: '',
      telefono: '' ,
      tecnico: '',
      tipoTrabajo: '',
      distrito: ''
    };
    this.setState({ documento: documento });
    this.props.change('hFechaInicio', getFormatDate(new Date(), 'ymd', '-'))
    this.props.change('hFechaFin', getFormatDate(new Date(), 'ymd', '-'))
    this.setState({ errorFecha: false });
    this.setState({ errorFechaFin: false });

  }

  setFechaIni = (event) => {
    let fecha = event.target.value;
    //console.log("la fecha de fin es: " + this.state.documento.docuFechaFin);
    if ((this.state.documento.docuFechaFin && fecha) && (fecha > this.state.documento.docuFechaFin)) {
      this.state.documento.docuFechaIni = fecha;
      this.props.change('hFechaInicio', fecha)
      this.setState({ errorFecha: "Es mayor a la fecha fin." });
    } else {
      this.setState({ errorFechaFin: false });
      this.setState({ errorFecha: false });
      this.state.documento.docuFechaIni = fecha;
      this.props.change('hFechaInicio', fecha)
    }
  }

  setFechaFin = (event) => {
    let fecha = event.target.value;
    if ((this.state.documento.docuFechaIni && fecha) && (fecha < this.state.documento.docuFechaIni)) {
      this.state.documento.docuFechaFin = fecha;
      this.props.change('hFechaFin', fecha)
      this.setState({ errorFechaFin: "Es menor a la fecha inicio." });
    } else {
      this.setState({ errorFecha: false });
      this.setState({ errorFechaFin: false });
      this.state.documento.docuFechaFin = fecha;
      this.props.change('hFechaFin', fecha)
    }
  }

  render() {
    const { handleSubmit, classes, asignarTecnico } = this.props;
    const { documento, fecdefault, errorFecha, errorFechaFin } = this.state;
    return (
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                 <Field labelText="Distrito"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "distrito",
                    name: "distrito"
                  }}
                  multiple={false}
                  data={this.state.distritos}
                  component={CustomAutocomplete}
                  valor={documento.distrito}
                  getValue={this.getSelectValue}
                  val="id"
                  txt="valor"
                  name="distrito"
                  id="distrito" />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <Field labelText="Número de Ficha"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          type: "text",
                          name: "numeroficha" 
                        }}                    
                        component={CustomInput}
                        value={documento.numeroficha}
                        name="numeroficha"
                        id="numeroficha" />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
              <Field labelText="Tipo de Trabajo"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          autoComplete: "tipoTrabajo",
                          name: "tipoTrabajo"
                        }}
                        multiple={false}
                        data={this.state.tipsTrabajo}
                        component={CustomSelect}
                        valor={documento.tipoTrabajo}
                        getValue={this.getSelectValue}
                        val="id"
                        txt="valor"
                        name="tipoTrabajo"
                        id="tipoTrabajo" />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <CustomInput labelText="Fecha Inicio"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "docuFechaIni",
                    name: "docuFechaIni",
                    type: "date",
                    //defaultValue: fecdefault,
                    onChange: this.setFechaIni,
                    value:  documento.docuFechaIni,
                    format: 'DD-MM-YYYY'
                  }}
                 // validate={[ required ]}
                  meta={{ touched: true, error: errorFecha, warning: false }}
                  //value={documento.docuFechaIni}
                  //name="docuFechaIni"
                 // id="docuFechaIni"
                   />
                  <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "hFechaInicio",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={documento.docuFechaIni}
                  name="hFechaInicio"
                  id="hFechaInicio" />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <CustomInput labelText="Fecha Fin"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "date",
                    name: "txtfechafin",
                    //defaultValue: fecdefault,
                    onChange: this.setFechaFin,
                    value:  documento.docuFechaFin
                  }}
                 // validate={[ required ]}
                  meta={{ touched: true, error: errorFechaFin, warning: false }}
                  value={documento.docuFechaFin}
                  name="txtfechafin"
                  id="txtfechafin" />
                  <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "hFechaFin",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={documento.docuFechaFin}
                  name="hFechaFin"
                  id="hFechaFin" />
              </GridItem>
              </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Tecnico"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "tecnico",
                    name: "tecnico"
                  }}
                  multiple={true}
                  data={this.state.tecnicos}
                  component={CustomAutocomplete}
                  valor={documento.tecnico}
                  getValue={this.getSelectValue}
                  val="id"
                  txt="valor"
                  name="tecnico"
                  id="tecnico" />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={asignarTecnico}
                >Asignar Tecnico</Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >Buscar </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={this.limpiarForm}
                >Limpiar</Button>
              </GridItem>
              
            
            </GridContainer>
          </GridItem>
          
        </GridContainer>
        
      </form>
    );
  }
}
BsqProgRutaForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
BsqProgRutaForm = reduxForm({
  form: 'bsqProgRutaForm',
  validate
})(BsqProgRutaForm)

const selector = formValueSelector('bsqProgRutaForm')
const mapState = (state) => ({
  loading: state.load.loading,
  dscversion: selector(state, 'dscversion')
});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(documentoActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    usuarioActions: bindActionCreators(usuarioActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(BsqProgRutaForm));