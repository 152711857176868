import { NOTIFICATION_ACTIONS_TYPE as ACTIONS } from "utils/ActionTypes";

export const notificationReducer = function reducer(state = {
    message: "",
    place: "bl",
    color: "info",
    open: false
}, action) {
    switch (action.type) {
        case ACTIONS.SHOW_NOTIFICATION: {
            return {
                message: action.message,
                place: state.place,
                color: action.color,
                open: action.open
            };
        }
        case ACTIONS.CLOSE_NOTIFICATION: {
            return {
                message: state.message,
                place: state.place,
                color: state.color,
                open: action.open
            };
        }
        default: {
            return state;
        }
    }

}