import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm } from 'redux-form';
import { required, integer, min1, max3000, maxLength4000 } from 'utils/FormValidation';
import { ESTADO } from 'utils/Constants';

import * as loadActions from "actions/LoadActions";
import * as notificationActions from "actions/NotificationActions";
import * as formActions from "actions/FormActions";

class ParametroCabMantForm extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            parametro : {
                idetipparametro: '',
                dsctipparametro: '',
                ststipparametro: ''
            }  
        }
    }

    componentDidMount() { 
        this.props.onRef(this);
      
        var { parametro } = this.props;    

        if (parametro.ststipparametro === '') {
          parametro.ststipparametro = ESTADO.ACTIVO;
        }
        this.props.formActions.loadData(parametro);
        this.setState({ parametro: parametro });
    }

    getSelectValue = (valor, name) => {
        var parametro = this.state.parametro;
        parametro[name] = valor;
        this.setState({ parametro: parametro });
    }

  render() {
    const { handleSubmit, classes, estados, flagDisabledIdetipparametro } = this.props;
    const { parametro } = this.state;

    return (
      <form onSubmit={handleSubmit} style={{ width: "100%", margin: 8 * 3 }}>
        <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Field labelText="Identificador"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          type: "text",
                          autoComplete: "idetipparametro",
                          name: "idetipparametro",
                          disabled: flagDisabledIdetipparametro
                        }}
                        component={CustomInput}
                        validate={[required]}
                        value={parametro.idetipparametro}
                        name="idetipparametro"
                        id="idetipparametro" />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Field labelText="Descripción"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          type: "text",
                          autoComplete: "dsctipparametro",
                          name: "dsctipparametro"
                        }}
                        component={CustomInput}
                        /*validate={[required]}*/
                        value={parametro.dsctipparametro}
                        name="dsctipparametro"
                        id="dsctipparametro" />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Field labelText="Estado"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          autoComplete: "ststipparametro",
                          name: "ststipparametro"
                        }}
                        multiple={false}
                        data={estados}
                        component={CustomSelect}
                        valor={parametro.ststipparametro}
                        validate={[required]}
                        getValue={this.getSelectValue}
                        val="ststipparametro"
                        txt="descparametro"
                        name="ststipparametro"
                        id="ststipparametro" />
                    </GridItem>                          
                </GridContainer>
              </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
              {/* <div style={{ textAlign: "right" }}> */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}                
              >Guardar</Button>
              <Button onClick={this.props.toggleDialogMantParamCab} className={classes.submit}>Cerrar</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </form>
    );
  }

}

ParametroCabMantForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

ParametroCabMantForm = reduxForm({
  form: 'parametroCabMantForm',
  enableReinitialize: true
})(ParametroCabMantForm)

const mapState = (state) => ({
  loading: state.load.loading,
  initialValues: state.formApp.data,
  user: state.session.user
});

const mapDispatch = (dispatch) => {
  return {    
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch)
  };
};

export default withRouter(connect(mapState, mapDispatch)(ParametroCabMantForm));