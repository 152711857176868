import clienteApi from 'services/api/ClienteApi';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';
import { OPC_BUSQUEDA, OPC_CUESTIONARIO } from 'utils/Constants';
import { copiarJson } from 'utils/Functions';

 
export function registrarCliente(documento) {
    return function (dispatch) {
        return clienteApi.registrarCliente( documento ).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}