import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as perfilActions from "actions/PerfilActions";
import * as notificationActions from "actions/NotificationActions";
import * as loadActions from "actions/LoadActions";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import tabStyle from "styles/jss/app/components/tabStyle.jsx";
import Form from "components/Form/MntPerfilForm";
import { TIPO_MENSAJE } from 'utils/Constants';

function TabContainer(props) {
  return (
    <div style={{ padding: 8 * 3 }}>
      {props.children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class PerfilDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueTab: 0,
      showPerfilialog: '',

    };
  }

  componentDidMount() {
    this.setState({ perfil: this.props.perfil });
  }
  handleChangeTab = (event, valueTab) => {
    this.setState({ valueTab });
  };

  guardaPerfil = (data) => {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    add();
    this.props.actions.insertarPerfil(data, this.props.user).then(response => {
    if (response.tipo === TIPO_MENSAJE.SUCCESS) {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
        this.closeDialog();
        this.props.buscarPerfil(this.props.perfil);
    } else{
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
    }
    remove();
    })

  }

  closeDialog = () => {
    this.setState({ valueTab: 0 });
    this.props.toggleDialogPerfil();
  }

  render() {
    const { classes, showPerfilDialog, estados } = this.props;
    const { valueTab, perfil} = this.state;

    return (
      <Dialog
        open={showPerfilDialog}
        onClose={this.closeDialog}
        fullWidth={true}
        maxWidth={false}
      >
        <DialogContent>
          <AppBar position="static" color="default">
            <Tabs
              value={valueTab}
              onChange={this.handleChangeTab}
              indicatorColor="secondary"
              classes={{ root: classes.tabsRoot }}
              fullWidth
            >
              <Tab
                classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                label="Registro de Perfil" />
            </Tabs>
          </AppBar>
          <Form onSubmit={this.guardaPerfil} 
                classes={classes} 
                perfil={perfil} 
                estados={estados} 
                toggleDialogPerfil={this.closeDialog} />
        </DialogContent>
      </Dialog>
    )
  }
}

const mapState = (state) => ({
  user: state.session.user,
  loading: state.load.loading
});

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(perfilActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

PerfilDialog = withStyles(tabStyle)(PerfilDialog);
export default withRouter(connect(mapState, mapDispatch)(PerfilDialog));