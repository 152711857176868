import {
  //grayColor,
  primaryColor,
  /*infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  purpleColor,*/
  defaultFont
} from "styles/jss/material-dashboard-react.jsx";

const tabStyle = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabRoot: {
    /*textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,*/
    ...defaultFont,
    '&:hover': {
      color: primaryColor,
      opacity: 1,
    },
    '&$tabSelected': {
      color: primaryColor,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: primaryColor
    },
  },
  tabSelected: {}
})

export default tabStyle;