import documentoApi from 'services/api/DocumentoApi';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';
import { OPC_BUSQUEDA, OPC_CUESTIONARIO } from 'utils/Constants';
import { copiarJson } from 'utils/Functions';
import domicilioApi from 'services/api/DomicilioApi';
export function obtenerDocumento(documento, history) {
    return function (dispatch) {
        return documentoApi.obtenerDocumento(OPC_BUSQUEDA.OBTENER, documento).then(data => {
            if (data.tipo !== TIPO_MENSAJE.SUCCESS) {
                history.goBack();
            }
            return data;
        }).catch(error => {
            history.goBack();
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function consultarDocumentoPorId(id) {
    return function (dispatch) {
        return documentoApi.consultarDocumentoPorId( id ).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function consultarDocumentoPorOtrosCampos(request) {
    return function (dispatch) {
        return documentoApi.consultarDocumentoPorOtrosCampos( request ).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function consultarFichas(id, page, pagesize) {
    return function (dispatch) {
        return documentoApi.consultarFichas( id , page, pagesize).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function consultarFichasByCheck(id, page, pagesize) {
    return function (dispatch) {
        return documentoApi.consultarFichasByCheck( id , page, pagesize).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}


export function consultarDashboard(params) {
    return function (dispatch) {
        return documentoApi.consultarDashBoard( params ).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}


export function enviarMail(params) {
    return function (dispatch) {
        return documentoApi.enviarMail( params ).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}



export function registrarFicha(documento) {
    return function (dispatch) {
        return documentoApi.registrarFicha( documento ).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function guardarAsginacionTecnico(documento) {
    return function (dispatch) {
        return documentoApi.guardarAsginacionTecnico( documento ).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function guardarRepuestosPrecioCordinadoraAlmacen(documento) {
    return function (dispatch) {
        return documentoApi.guardarRepuestosPrecioCordinadoraAlmacen( documento ).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}


export function listarParametrosFiltroDocumento(idopc) {
    return function (dispatch) {
        return documentoApi.listarParametrosFiltro(idopc).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}


export function listarDocumentos(usuario, documento) {    
    return function (dispatch) {
        return documentoApi.listarDocumentos(OPC_BUSQUEDA.LISTAR, documento, usuario ? usuario.ideusuario : "", null, null).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}


export function paginarDocumentos(documento, page, pagesize) {
    return function (dispatch) {
        return documentoApi.listarDocumentos(OPC_BUSQUEDA.PAGINAR, documento, documento.ideusuario, page, pagesize).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function listarSeguimiento(idDocuCodigo) {
    return function (dispatch) {
        return documentoApi.listarSeguimiento(idDocuCodigo).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function obtenerArchivo(document, tipo) {
   
    return function (dispatch) {
        return documentoApi.obtenerArchivo(document, tipo ).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function descargarMasivo(files) {
    return function (dispatch) {
        return documentoApi.descargarMasivo(files).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function descargarExcel(documento, user, totalCount) {
    return function (dispatch) {
        return documentoApi.descargarExcel(OPC_BUSQUEDA.EXPORTAR, documento, user, totalCount).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}


export function reenviarDocumento(documento, tipo) {
    return function (dispatch) {
        return documentoApi.reenviarDocumento("reprocesar", documento, tipo).then(data => {
            if (data.codError != null){
                if (data.codError == "0"){
                    return {
                        tipo: TIPO_MENSAJE.SUCCESS,
                        mensaje: MENSAJE.SUCCESS_GENERAL,
                        indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE,
                        mesajeSunat: data.mensaje,
                        code: data.codError
                    }
                }else{
                    if (tipo == "S"){
                        return {
                            tipo: TIPO_MENSAJE.WARNING,
                            mensaje: MENSAJE.ERROR_GENERAL,
                            indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE,
                            mesajeSunat: data.mensaje,
                            code: data.codError
                        }
                    }else{
                        return {
                            tipo: TIPO_MENSAJE.WARNING,
                            mensaje: MENSAJE.ERROR_GENERAL,
                            indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
                        }
                    }
                }
            }else{
                return {
                    tipo: TIPO_MENSAJE.DANGER,
                    mensaje: MENSAJE.ERROR_GENERAL,
                    indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
                }
            }
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}
export function reenviarDocumentoErp(documento) {
    return function (dispatch) {
        return documentoApi.reenviarDocumentoErp(OPC_BUSQUEDA.EXPORTAR, documento).then(data => {
            if (data.codError != null){
                if (data.codError == "0"){
                    return {
                        tipo: TIPO_MENSAJE.SUCCESS,
                        mensaje: MENSAJE.SUCCESS_GENERAL,
                        indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
                    }
                }else{
                    return {
                        tipo: TIPO_MENSAJE.WARNING,
                        mensaje: MENSAJE.ERROR_GENERAL,
                        indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
                    }
                }
            }else{
                return {
                    tipo: TIPO_MENSAJE.DANGER,
                    mensaje: MENSAJE.ERROR_GENERAL,
                    indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
                }
            }
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function reporteGraficoDocumento(documento, history) {
    return function (dispatch) {
        return documentoApi.reporteGraficoDocumento(OPC_BUSQUEDA.OBTENER, documento).then(data => {
            if (data.tipo !== TIPO_MENSAJE.SUCCESS) {
                //history.goBack();
            }
            return data;
        }).catch(error => {
            //history.goBack();
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}


export function asignarTecnicoDomicilio(documento) {
    return function (dispatch) {
        return documentoApi.asignarTecnicoDomicilio( documento ).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}


export function updateCliente(documento) {
    return function (dispatch) {
        return domicilioApi.updateCliente( documento ).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}


export function guardarPasajeById(documento) {
    return function (dispatch) {
        return documentoApi.guardarPasajeById( documento ).then(data => {
            return data;
        }).catch(error => {
             console.log(error);
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}


export function descargarExcelPasaje(documento, user, totalCount) {
    return function (dispatch) {
        return documentoApi.descargarExcelPasaje(OPC_BUSQUEDA.EXPORTAR, documento, user, totalCount).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}