import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { PATH_APP } from "commons/config/Path";


const PublicRoute = ({ component: Component, exact, path, authenticated, props: cProps }) => (
  <Route
    path={path}
    render={props => (
      !authenticated ? (
        <Component {...props} {...cProps} />
      ) : (
          <Redirect push to={PATH_APP.HOME1} />
        )
    )}
  />
);

const { object, bool, string, func } = PropTypes;

PublicRoute.propTypes = {
  component: func.isRequired,
  exact: bool,
  path: string.isRequired,
  authenticated: bool.isRequired,
  location: object
};

export default PublicRoute;