import { NOTIFICATION_ACTIONS_TYPE as ACTIONS } from 'utils/ActionTypes';

export const show = (open, message, color) => ({
    type: ACTIONS.SHOW_NOTIFICATION,
    open: open === "1",
    message: message,
    color: color
})

export const close = (open) => ({
    type: ACTIONS.CLOSE_NOTIFICATION,
    open: open
})
