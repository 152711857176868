import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomTextArea from "components/CustomInput/CustomTextArea";
import CustomSelect from "components/CustomInput/CustomSelect";
import CustomAutocomplete from "components/CustomInput/CustomAutocomplete";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { TIPO_MENSAJE, GRID_TEXT_ES, MENSAJE } from 'utils/Constants';
import * as documentoActions from "actions/DocumentoActions";
import * as resumenActions from "actions/ResumenActions";
import * as loadActions from "actions/LoadActions";
import * as usuarioActions from "actions/UsuarioActions";
import * as notificationActions from "actions/NotificationActions"; 
import { getFormatDate, getRestarDias } from 'utils/Functions';  
 
 import Grid from "components/Grid/RepuestoGrid";
 import UploadImages from "components/File/FileUpload";
 import CanvasDraw from "react-canvas-draw";
 import config from "commons/config/AWSConfig";
 import AWS from 'aws-sdk'
 import { DATA_TECNICOS, DATA_LINEAS, DATA_ESTADOS_DIAGNOSTICO_DOMICLIO } from 'utils/GeneralData';
/********new */
const required = value => value ? undefined : 'Valor Requerido'
const maxLength = max => value =>
  value && value.length > max ? `debe ser ${max} caracteres or menos` : undefined
const maxLength11 = maxLength(11)
const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
const minValueFecha = minValue(18)

 

const validate = values => {
  const errors = {}
  var fechaActual = getFormatDate(new Date(),  'ymd', '-')
  if (!values.txtruc) {
  }

  if (values.txtruc) {
    errors.hFechaFin = '';
  }

  return errors
}  
class TecnicoDiagnosticoForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      saveableCanvas: null,
      showBusqueda: 'None',
      disabledFicha: false,
      tipDocuments: [],
      statesSunat: [],
      lstCdr: [],
      lstTypeDocument: [],
      companias: [],
      documentosReversiones: [],
      estadoArtefactos: [],
      lineas: [],
      estadoCodigos: [ 11,13,22,24,26,27,28], 
      tecnicos: [],
      documento: {
        fechaIngreso:'',
        clieNombre: '', //razon Social
        ubicacion: '',
        clieTipDoc: '',
        clieDireccion:'',
        clieNumero: '',  //es ruc
        clieUbigeo: '',
        motivo: '',
        otro_motivo: '',
        artefacto: '',
        tipoServicio: '',
        modelo: '',
        serie: '',
        marca: '',
        observacion:'',
        telefono: '',
        email:'',
        fechaVenta: '',
        docuNumero: '',
        docuNumeroTo: '',
        tipoDocumento: [],
        docuFechaIni: '',
        docuFechaVenta: '',
        estado: '',
        clieNombre: '', //razon Social
        cdr: '',
        documentTypeClient: '',
        compania: '',
        recomendacion: '',
        tecnico: '',
        respuestos: '',
        numeroFicha: "",
        linea: '',
        obscliente: '',
        pasaje: "3.75",
        adicional: "0",
        importe: 0,
        id: 0
      },
      fecdefault: getFormatDate(new Date(),  'ymd', '-'),
      errorFecha: '',
      errorFechaFin: '',
      rows: [],
      columns: [
        { name: 'id', title: 'Nro.' },      
        { name: 'cantidad', title: 'Cantidad ' },
        { name: 'codigo', title: 'Codigo Repuesto *'},
        { name: 'nombre', title: 'Nombre *' } 
      ],
      tableColumnExtensions: [
        { columnName: 'id', width: 70 },      
        { columnName: 'cantidad', width: 300 },
        { columnName: 'codigo', width: 250 },
        { columnName: 'nombre', width: 180 } 
      ],
      editingRowIds: [],
      sorting: [],
      currentPage: 0, 
      pageSize: 20,
      pageSizes: [10, 20, 50, 100],
      addedRows:[],
      rowChanges:{},
      columnOrder:["id"],
      totalSummaryItems: [ 
        { columnName: 'cantidad', type: 'sum' }
      ],
      currencyColumns : ['amount'],
      percentColumns : ['discount'] , 
      totalCount: 0,
      estadoColumn: ['stsusuario'],
      perfilColumn: ['ideperfil'],
      correoColumn:['email'],
      dscNomApeColumn:['dscnomape'],
      todosestados:[],
       hiddenColumnNames: ['ideusuario', 'id'],

    }
    

    this.changeAddedRows = addedRows => this.setState({ addedRows });    

    this.setColumnOrder = columnOrder => this.setState({ columnOrder });
     this.changeSorting = sorting => this.setState({ sorting });
     this.changeRowChanges = rowChanges => this.setState({ rowChanges });    

 
    this.changeEditingRowIds = editingRowIds => this.setState({ editingRowIds }); 
    this.commitChanges = ({ added, changed, deleted }) => {
      let { rows } = this.state;
      if (changed) {            
        
        const { add, remove } = this.props.loadActions;
        const { show } = this.props.notificationActions;
        

        add();

        this.props.actions.actualizarUsuario(null, this.props.user).then(response => {
          if (response.tipo === TIPO_MENSAJE.SUCCESS) {
            this.buscarUsuario();
          }
          show(response.indMostrarMensaje, response.mensaje, response.tipo);
          remove();
        }) 


      }
      this.setState({ rows });
    };   
      
  }

  changeCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage })
    this.buscarUsuario(currentPage);
  }

  changePageSize = (pageSize) => {
    const { totalCount, currentPage: stateCurrentPage } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(stateCurrentPage, totalPages - 1);

    this.setState({
      pageSize: pageSize,
      currentPage: currentPage
    });
    this.buscarUsuario(currentPage, pageSize);
  }

  componentDidMount() { 
    this.props.onRef(this); 
    this.state.tecnicos = JSON.parse(localStorage.getItem("tecnicos_serfac")); //DATA_TECNICOS
    this.state.lineas = JSON.parse(localStorage.getItem("lineas_serfac")); //DATA_LINEAS
    this.state.estadoArtefactos = DATA_ESTADOS_DIAGNOSTICO_DOMICLIO

    
    let numFicha = this.getParameterByName('ficha');
    if (numFicha!=null){
      console.log("href:::", this.getParameterByName('ficha'));
      this.props.change('numeroFicha', numFicha)
      this.state.documento.numeroFicha = numFicha
      this.buscarArtefacto({numeroFicha: numFicha});
    }

  }

  buscarArtefacto(data){
    
    let id = data.numeroFicha;
    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;



    let rowsRepuestos = this.Grid.extraerRows(); 
    add();
    let tecnico =  data.tecnico != null ? data.tecnico : 0;
    let estado =  data.estado != null ? data.estado : 0;
    let linea =  data.linea != null ? data.linea : 0;
    if(this.state.documento.tecnico != '' &&  tecnico == 0){
      data.tecnico = this.state.documento.tecnico;
      tecnico = data.tecnico != null ? data.tecnico : 0;
    }
    if(this.state.documento.estado != '' &&   estado == 0){
      data.estado = this.state.documento.estado;
    }
    if(this.state.documento.linea != '' &&   linea == 0){
      data.linea = this.state.documento.linea;
    }
    let idtecnicodom = 0;
    if (localStorage.getItem("tkn") == "serfac-tecnicos"){
      let objUser = JSON.parse(localStorage.getItem("userTecnico"));
      idtecnicodom = objUser.id;
    }


    if (tecnico >= 1){
        data.repuestos = rowsRepuestos;
        data.id = data.numeroFicha;
        this.props.actions.guardarAsginacionTecnico(data).then(response => {
          
          if (response.status == 200){
            if (response.data.length > 0 ){
              let info = response.data[0]; 
              this.limpiarForm();
              
              show(MENSAJE.IND_MOSTRAR_MENSAJE, response.message, TIPO_MENSAJE.SUCCESS);
            }else{
              show(MENSAJE.IND_MOSTRAR_MENSAJE, "El artefacto escaneado no existe.", TIPO_MENSAJE.WARNING);
            }
            
          }else{
            show(MENSAJE.IND_MOSTRAR_MENSAJE, "Ocurrio un error al registrar los datos.", TIPO_MENSAJE.WARNING);
          }
          
          remove();
        }) 
    }else{
      this.props.actions.consultarDocumentoPorId(id).then(response => {
        if (response.status == 200){
          if (response.data.length > 0 ){
            let info = response.data[0]; 
            var documento = { 
                  observacion: info.observacion,
                  fechaIngreso: info.fechaingreso,
                  clieNombre:  info.nombre,//razon Social
                  ubicacion: info.ubicacion,
                  clieTipDoc: info.tipodoc,
                  clieDireccion: info.direccion,
                  clieNumero: info.numdoccliente,  //es ruc
                  clieUbigeo: info.ubigeo,
                  motivo: info.motivo,
                  otro_motivo: info.otromotivo,
                  artefacto: info.codproducto,
                  tipoServicio: info.tiposervicio,
                  modelo: info.modelo,
                  serie: info.serie,
                  marca: info.marca,
                  telefono: info.telefono,
                  email:info.email,
                  fechaVenta: info.fechaventa, 
                  recomendacion: info.recomendacion,
                  tecnico: info.idtecnico,
                  linea: info.linea  != null &&  info.linea != 0 ? info.linea : '',
                  obscliente: info.obscliente  != null &&  info.obscliente != 0 ? info.obscliente : '',
                  estado: info.estado != null ? ( !this.state.estadoCodigos.includes(info.estado) ? '' :   info.estado ) : '',
                  importe: info.importe  != null &&  info.importe != 0 ? info.importe : 0,  
                  id: info.id
            };
            this.props.change('fechaIngreso', info.fechaingreso)
            this.props.change('clieNombre',  info.nombre)
            this.props.change('ubicacion', info.ubicacion)
            this.props.change('clieTipDoc', info.tipodoc)
            this.props.change('clieDireccion', info.direccion)
            this.props.change('clieNumero', info.numdoccliente)
            this.props.change('clieUbigeo', info.ubigeo)
            this.props.change('motivo', info.motivo)
            this.props.change('artefacto', info.codproducto)
            this.props.change('tipoServicio', info.tiposervicio)
            this.props.change('modelo', info.modelo)
            this.props.change('serie', info.serie)
            this.props.change('marca', info.marca)
            this.props.change('telefono', info.telefono)
            this.props.change('email', info.email)
            this.props.change('docuFechaVenta', info.fechaventa) 
            this.props.change('recomendacion', info.recomendacion)   
            this.props.change('estado', info.estado)
            this.props.change('obscliente', info.obscliente)
            this.props.change('pasaje', info.pasaje  != null &&  info.pasaje != 0 ? info.pasaje : 3.75)
            this.props.change('importe', info.importe  != null &&  info.importe != 0 ? info.importe : 0)
            


            this.Grid.asignarRows(response.repuestos);
            this.state.disabledFicha = true;
            this.setState({ documento: documento });
            this.state.showBusqueda = '';
            this.UploadImages.listarImagenesPorId(this.state.documento);

            if (idtecnicodom > 0 && (info.idtecnico != idtecnicodom)){
              show(MENSAJE.IND_MOSTRAR_MENSAJE, "Servicio no asignado a tu cargo, Verifica el número de ficha ingresado.", TIPO_MENSAJE.WARNING);
              this.limpiarForm();
            }else{
              show(MENSAJE.IND_MOSTRAR_MENSAJE, response.message, TIPO_MENSAJE.SUCCESS);
            }
          }else{
            show(MENSAJE.IND_MOSTRAR_MENSAJE, "El artefacto escaneado no existe.", TIPO_MENSAJE.WARNING);
          }
          
        }else{
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "Ocurrio un error al registrar los datos.", TIPO_MENSAJE.WARNING);
        }
         
        remove();
      })
    }
  }

 

  getParameterByName = (name, url = window.location.href) => {
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  getSelectValue = (valor, name) => {
    var documento = this.state.documento
    documento[name] = valor;
    this.setState({ documento: documento });
  }

  guardarFirma = async () => {
    localStorage.setItem(
      "savedDrawing",
      this.state.saveableCanvas.getSaveData()
    );
    const { show } = this.props.notificationActions; 
    let canvas = this.state.saveableCanvas.canvas.drawing;
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', 'CanvasAsImage.png'); 
    let dataURL = canvas.toDataURL('image/png'); 

    const toBase64 = dataURI =>  new Promise((resolve, reject) => {
      var binary = atob(dataURI.split(',')[1]);
      var array = [];
      for(var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
      }
      resolve( new Blob([new Uint8Array(array)], {type: 'image/png'}));    
  });
  let file = await toBase64(dataURL); 
    AWS.config.update({
      accessKeyId: config.accessKey,
      secretAccessKey: config.secretKey,
  })
    const myBucket = new AWS.S3({
          params: { Bucket: config.bucket},
        region: config.REGION,
      })

      const params = {
        ACL: 'public-read',
        Body: file, 
        Bucket: config.bucket,
        Key: "IMAGES/"+this.state.documento.fechaIngreso.substr(0,10).replace(/-/g,"/") + "/"+ this.state.documento.id + "/FIRMA/" + this.state.documento.id + ".png"
    }; 
    const { add, remove } = this.props.loadActions;
    add();
    myBucket.putObject(params)
     
    .send((err) => {
        if (err){
          console.log(err) 
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "Intente Nuevamente.", TIPO_MENSAJE.WARNING);
        }else{
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "Subido la firma correctamente", TIPO_MENSAJE.SUCCESS);
          
        }
        remove();
         
    })


   // console.log(dataURL);
    /*let url = dataURL.replace(/^data:image\/png/,'data:application/octet-stream');
    downloadLink.setAttribute('href',url);
    downloadLink.click();*/
  }
  limpiarForm = () => {
    
    this.props.reset();
    var documento = {
      docuNumero: '',
      tipoDocumento: [],
      clieNumero: '',  //es ruc
      docuFechaIni: getFormatDate(new Date(), 'ymd', '-'),
      docuFechaFin: getFormatDate(new Date(), 'ymd', '-'),
      estado: '',
      clieNombre: '', //razon Social
      cdr: '',
      estado: '',
      tecnico: '',
      documentTypeClient: ''
    };
    this.state.showBusqueda = 'None';
    this.state.disabledFicha = false;
    this.setState({ documento: documento });
    this.props.change('hFechaInicio', getFormatDate(new Date(), 'ymd', '-'))
    this.props.change('hFechaFin', getFormatDate(new Date(), 'ymd', '-'))
    this.setState({ errorFecha: false });
    this.setState({ errorFechaFin: false });
    this.state.saveableCanvas.clear();
    this.Grid.limpiarRows();
  }

  setFechaIni = (event) => {
    let fecha = event.target.value;
    this.state.documento.docuFechaIni = fecha;
    this.props.change('hFechaInicio', fecha);
  }

 

  render() {
    const { handleSubmit, classes,
    rows,
      columns,
      tableColumnExtensions,
      editingStateColumnExtensions,
      sorting,currentPage,
      editingRowIds,pageSize,
      pageSizes,
      addedRows,
      rowChanges,
      columnOrder,
      totalSummaryItems,
      currencyColumns,
      percentColumns,
      leftFixedColumns,
      totalCount,
      hiddenColumnNames,
      estadoColumn,
      perfilColumn,
      correoColumn,
      dscNomApeColumn,
      todosestados

       } = this.props;
    const { documento, fecdefault, errorFecha, errorFechaFin, estadoArtefactos, lineas} = this.state;
    //console.log("documento render,");
    return (
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <div style={{ textAlign: "center" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Field labelText="Número de Ficha"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "text",
                      name: "numeroFicha",
                      disabled: this.state.disabledFicha
                    }}
                    normalize={(val, prevVal) => {
                      if (val.length > 10)
                        return prevVal;
                      
                      if (val) {
                        return (!/[^0-9--_/]/.test(val)) ? val : prevVal
                      }
                      return val;
                    }}
                    component={CustomInput}
                    value={documento.numeroFicha}
                    name="numeroFicha"
                    id="numeroFicha" /> 
                </GridItem> 
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={this.state.disabledFicha}
            className={classes.submit}
          >Consultar </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={this.limpiarForm}
          >Limpiar</Button>
        </div>
        <div style={{ display: this.state.showBusqueda }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>

                  <Field labelText="Fecha de Ingreso"
                    formControlProps={{
                      fullWidth: true,
                      maxLength: "10",
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "text",
                      name: "fechaIngreso",
                      min: "1",
                      maxLength: "250",
                      disabled: true
                    }} 
                    component={CustomInput}
                    value={documento.fechaIngreso} 
                    name="fechaIngreso"
                    id="fechaIngreso" />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <Field labelText="Nombres y/o Razón social"
                    formControlProps={{
                      fullWidth: true,
                      maxLength: "10",
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "text",
                      name: "clieNombre",
                      min: "1",
                      maxLength: "250",
                      disabled: true
                    }}
                     
                    component={CustomInput}
                    value={documento.clieNombre} 
                    name="clieNombre"
                    id="clieNombre" />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <Field labelText="Dirección"
                    formControlProps={{
                      fullWidth: true,
                      maxLength: "10",
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "text",
                      name: "clieDireccion",
                      min: "1",
                      disabled: true
                    }}
                    
                    component={CustomInput}
                    value={documento.clieDireccion} 
                    name="clieDireccion"
                    id="clieDireccion" />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <Field labelText="Tipo Documento"
                    formControlProps={{
                      fullWidth: true,
                      maxLength: "10",
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "text",
                      name: "clieTipDoc",
                      min: "1",
                      maxLength: "10",
                      disabled: true
                    }}
                    
                    component={CustomInput}
                    value={documento.clieTipDoc} 
                    name="clieTipDoc"
                    id="clieTipDoc" />  
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                 <Field labelText="Número Documento"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "clieNumero",
                      name: "clieNumero",
                      min: "1",
                      disabled: true
                    }}
                    component={CustomInput}
                    value={documento.clieNumero}
                    name="clieNumero"
                    id="clieNumero" />
                    
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Field labelText="Distrito"
                      formControlProps={{
                        fullWidth: true,
                        margin: "normal",
                        style: { marginTop: "0px" }
                      }}
                      inputProps={{
                        type: "clieUbigeo",
                        name: "clieUbigeo",
                        min: "1",
                        disabled: true
                      }}
                      component={CustomInput}
                      value={documento.clieUbigeo}
                      name="clieUbigeo"
                      id="clieUbigeo" />

                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
               
              <GridItem xs={12} sm={12} md={3}>
                  <Field labelText="Marca"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "text",
                      name: "marca" ,
                      disabled: true
                    }}
                     
                    component={CustomInput}
                    value={documento.marca}
                    name="marca"
                    id="marca" />
                </GridItem>
                <GridItem xs={12} sm={12} md={3} >
                  <Field labelText="Teléfono"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "telefono",
                      name: "telefono",
                      min: "1",
                      disabled: true
                    }}
                    
                    component={CustomInput}
                    value={documento.telefono}
                    name="telefono"
                    id="telefono" />
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  <Field labelText="Email"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "email",
                      name: "email",
                      min: "1",
                      disabled: true
                    }}
                    
                    component={CustomInput}
                    value={documento.email}
                    name="email"
                    id="email" />
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  <Field labelText="Importe"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "importe",
                      name: "importe",
                      min: "1", 
                    }}
                    
                    component={CustomInput}
                    value={documento.importe}
                    name="importe"
                    id="importe" />
                </GridItem>

              </GridContainer>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
               
                <GridItem xs={12} sm={12} md={6}>
                  <Field labelText="Motivo"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "motivo",
                      name: "motivo",
                      min: "1",
                      disabled: true
                    }}
                    
                    component={CustomInput}
                    value={documento.motivo}
                    name="motivo"
                    id="motivo" />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Field labelText="Tipo de Servicio"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "tipoServicio",
                      name: "tipoServicio",
                      min: "1",
                      disabled: true
                    }}
                    
                    component={CustomInput}
                    value={documento.tipoServicio}
                    name="tipoServicio"
                    id="tipoServicio" />

                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <Field labelText="Artefacto"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "artefacto",
                      name: "artefacto",
                      min: "1",
                      disabled: true
                    }}
                    
                    component={CustomInput}
                    value={documento.artefacto}
                    name="artefacto"
                    id="artefacto" />

                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Field labelText="Modelo"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "text",
                      name: "modelo",
                      min: "1"
                    }}
                    normalize={(val, prevVal) => {
                      if (val.length > 20)
                        return prevVal;
                      if (val) {
                        return (!/[^a-zA-Z0-9--_/]/.test(val)) ? val : prevVal
                      }
                      return val;
                    }}
                    component={CustomInput}
                    value={documento.modelo}
                    name="modelo"
                    id="modelo" />
                </GridItem>
                 <GridItem xs={12} sm={12} md={4}>
                  <Field labelText="Serie"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "text",
                      name: "serie",
                      min: "1"
                    }}
                    normalize={(val, prevVal) => {
                      if (val.length > 20)
                        return prevVal;
                      if (val) {
                        return (!/[^a-zA-Z0-9--_/]/.test(val)) ? val : prevVal
                      }
                      return val;
                    }}
                    component={CustomInput}
                    value={documento.serie}
                    name="serie"
                    id="serie" />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                  <Field labelText="Observaciones Cliente"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "text",
                      name: "obscliente"  
                    }}
                     
                    component={CustomInput}
                    value={documento.obscliente}
                    name="obscliente"
                    id="obscliente" />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Field labelText="pasaje"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "text",
                      name: "pasaje"  
                    }}
                    normalize={(val, prevVal) => {
                      if (val.length > 10)
                        return prevVal;
                      
                      if (val) {
                        return (!/[^0-9./]/.test(val)) ? val : prevVal
                      }
                      return val;
                    }}
                    component={CustomInput}
                    value={documento.pasaje}
                    name="pasaje"
                    id="pasaje" />
                </GridItem> 
              </GridContainer>
            </GridItem>  
            <GridItem xs={12} sm={12} md={12}>

              <GridContainer> 
                 <div style={{ padding: "15px" }}>Observaciones</div> 
              </GridContainer>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>

              <GridContainer>  
                 <div style={{ padding: "15px" }}>{ documento.observacion }</div> 
              </GridContainer>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}> 
              <GridContainer> 
              <GridItem xs={12} sm={12} md={4}>
                  <Field labelText="Tecnico"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "tecnico",
                    name: "tecnico"
                  }}
                  
                  multiple={false}
                  data={this.state.tecnicos}
                  component={CustomSelect}
                  valor={documento.tecnico}
                  getValue={this.getSelectValue}
                  val="id"
                  txt="valor"
                  name="tecnico"
                  id="tecnico" />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>

                  <Field labelText="Estado"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      autoComplete: "estado",
                      name: "estado"
                    }}
                    multiple={false}
                    data={estadoArtefactos}
                    component={CustomSelect}
                    valor={documento.estado}
                    getValue={this.getSelectValue} 
                    val="id"
                    txt="valor"
                    name="estado"
                    id="estado" />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer> 
              <div style={{ padding: "15px" }}>Recomendaciones del tecnico</div>
                 <GridItem xs={12} sm={12} md={12}>
                  <Field labelText=""
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "textarea",
                      name: "recomendacion",
                      rows: 9,
                      min: "1"
                    }}
                    component={CustomTextArea}
                    value={documento.recomendacion}
                    name="recomendacion"
                    id="recomendacion" /> 

                    <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "hRepuestos",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={this.state.documento.respuestos}
                  name="hRepuestos"
                  id="hRepuestos" />

                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}> 
              <GridContainer> 
                  <UploadImages documento={this.state.documento}   onRef={ref => (this.UploadImages = ref)}/>
              </GridContainer>
            </GridItem>

          </GridContainer>
        </div>
       
        <div style={{ padding: "15px" }}>  Repuestos</div> 
        <Grid onRef={ref => (this.Grid = ref)}  usuario={this.state.usuario} estados={this.state.estados} perfiles={this.state.perfiles}/> 
         
        <div style={{ display: this.state.showBusqueda }}>Ingrese Firma</div> 
            <GridItem xs={12} sm={12} md={3}> 
              <GridContainer> 
              <div> 
              <CanvasDraw  key="id" ref={canvasDraw => (this.state.saveableCanvas = canvasDraw)} canvasWidth={300} canvasHeight={350} brushRadius={1} lazyRadius={1}/>
              </div>
              <div style={{ display: this.state.showBusqueda }}>
              <Button width="30px" type="button"
            onClick={() => {
              this.state.saveableCanvas.clear();
            }} >Limpiar</Button>
            <Button type="button"
            onClick={this.guardarFirma}
             >Cargar Firma</Button></div>
             
              </GridContainer>
            </GridItem>         
        <div style={{ display: this.state.showBusqueda }}>
          <div style={{ textAlign: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >Guardar </Button> 
          </div>
          </div>
      </form>
    );
  }
}
TecnicoDiagnosticoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
TecnicoDiagnosticoForm = reduxForm({
  form: 'bsqResumenBajaForm',
  validate
})(TecnicoDiagnosticoForm)

const selector = formValueSelector('bsqResumenBajaForm')
const mapState = (state) => ({
  loading: state.load.loading,
  dscversion: selector(state, 'dscversion')
});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(documentoActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    usuarioActions: bindActionCreators(usuarioActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(TecnicoDiagnosticoForm));