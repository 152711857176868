import { LOAD_ACTIONS_TYPE as ACTIONS } from 'utils/ActionTypes';

export const add = () => ({
    type: ACTIONS.ADD_LOAD
})

export const remove = () => ({
    type: ACTIONS.REMOVE_LOAD
})

export const addPopup = () => ({
    type: ACTIONS.ADD_LOAD_POPUP
})

export const removePopup = () => ({
    type: ACTIONS.REMOVE_LOAD_POPUP
})