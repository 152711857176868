import React from "react";
// @material-ui/core components
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import domicilioStyle from "styles/jss/app/views/domicilioStyle.jsx";
import * as domicilioActions from "actions/DomicilioActions";
import * as notificationActions from "actions/NotificationActions";
//import * as sessionActions from "actions/SessionActions";
import * as loadActions from "actions/LoadActions";
import Form from "components/Form/CriterioBusquedaForm";
import Grid from "components/Grid/ConsultaFichasDomicilioGrid";  
import JsBarcode from 'jsbarcode';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';

class ConsultaHistorial extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.buscarFichas = this.buscarFichas.bind(this);
    this.state = {
      documento: { 
        clieNumero: '',   
        docuFechaIni: '',
        docuFechaFin: '',
        estado: [],  
        estado: '', 
        numeroFicha: '',
        telefono: ''
      },
    }
  }

  componentDidMount() {
  }

  buscarFichas = (data) => { 
    console.log("vengo desde el formulario::::", data);
    var {documento} = this.state; 
    documento.telefono = data.telefono?data.telefono:'';  
    this.setState({documento: documento}); 
    
    //this.guardarRegistro();
    this.Grid.buscarFicha(data);
  }

  guardarRegistro() {
    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    add();
    this.props.actions.registrarDomicilio(this.state.documento).then(response => {
      console.log(response);
      if (response.status == 200){
        let id = response.data
        this.Form.limpiarForm();
        
        show(MENSAJE.IND_MOSTRAR_MENSAJE, response.message, TIPO_MENSAJE.SUCCESS);
        //this.generarPdf(id);
      }else{
        show(MENSAJE.IND_MOSTRAR_MENSAJE, "Ocurrio un error al registrar los datos.", TIPO_MENSAJE.WARNING);
      }
       
      remove();
    })
  }
 
  render() {
    const { classes } = this.props;
    return (
       <div>
      <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <SearchIcon className={classes.titleIcon} />
            <Typography className={classes.title} variant="h4"> Criterio de Búsqueda</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Form  onRef={ref => (this.Form = ref)}  onSubmit={this.buscarFichas} classes={classes} buscarFichas={this.buscarFichas} documento={this.state.documento} />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <ListIcon className={classes.titleIcon} />
            <Typography className={classes.title} variant="h4"> Lista de Fichas</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
             <Grid documento={this.state.documento} onRef={ref => (this.Grid = ref)}/>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        </div>
    )
  }
}
const mapState = (state) => ({
  loading: state.load.loading
});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(domicilioActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

ConsultaHistorial = withStyles(domicilioStyle)(ConsultaHistorial);
export default withRouter(connect(mapState, mapDispatch)(ConsultaHistorial));