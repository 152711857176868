import {
  boxShadow,
} from "styles/jss/material-dashboard-react.jsx";
const sidebarStyle = theme => ({
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "1",
    ...boxShadow,
    width: "650px",
    [theme.breakpoints.up(960)]: {
      width: "550px",
      position: "fixed",
      height: "100%"
    },
    [theme.breakpoints.up(1050)]: {
      width: "650px",
      position: "fixed",
      height: "100%"
    },
    [theme.breakpoints.up(1180)]: {
      width: "775px",
      position: "fixed",
      height: "100%"
    }
  },
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      opacity: ".8"
    }
  }
});

export default sidebarStyle;
