import usuarioApi from 'services/api/UsuarioApi';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';

export function listarResponsables() {
    return function (dispatch) {
        var usuario = {
            stsresponsable : 1
        };
        return usuarioApi.listarUsuario(usuario).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function insertarUsuario(usuario, user) {
    return function (dispatch) {

        return usuarioApi.insertarUsuario(usuario, user).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}    

export function paginarUsuario(usuario, page, pagesize) {
    return function (dispatch) {
        return usuarioApi.paginarUsuario(usuario, page, pagesize).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function actualizarUsuario(usuario, user) {
    return function (dispatch) {
        return usuarioApi.actualizarUsuario(usuario, user).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}