import { FORM_ACTIONS_TYPE as ACTIONS } from "utils/ActionTypes";

export const formReducer = (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.FORM_LOAD: {
        return {
          data: action.data
        }
      }
      default:
        return state
    }
  }
