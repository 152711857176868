
import Usuario from  "views/Seguridad/Usuario";
import Parametros from "views/Mantenimiento/Parametros";
import Opcion from "views/Seguridad/Opcion";
import Perfil from "views/Seguridad/Perfil";
import ConsultarDocumento from "views/Documento/ConsultarDocumento";
import ReporteDocumento from "views/Reporte/ReporteDocumento";
import ResumenBaja from "views/Resumen/ResumenBaja";
import Dashboard from "views/Dashboard/Dashboard";
import RegistroDomicilio from "views/Domicilio/RegistroDomicilio";
import Ruta from "views/Programacion/Ruta";
import ConsultaHistorial from "views/Consultas/ConsultaHistorial";
import ConsultaTecnico from "views/Consultas/ConsultaTecnico";
import RutaDia from "views/Domicilio/RutaDia";
import TecnicoDiagnostico from "views/Domicilio/TecnicoDiagnostico";
import ReporteRevisar from "views/Reporte/ReportByCheck";
import ReporteReparar from "views/Reporte/ReportByCheck";
import Pasaje from "views/Gastos/Pasaje";


export const VIEW_COMPONENT = {
    Usuario: Usuario,
    Opcion: Opcion,
    Perfil: Perfil,
    //Parametros: Parametros,
    ConsultarDocumento: ConsultarDocumento,
    ReporteDocumento: ReporteDocumento,
    ResumenBaja: ResumenBaja,
    Dashboard: Dashboard,
    RegistroDomicilio: RegistroDomicilio,
    Ruta: Ruta,
    ConsultaHistorial: ConsultaHistorial,
    ConsultaTecnico: ConsultaTecnico,
    RutaDia: RutaDia,
    TecnicoDiagnostico: TecnicoDiagnostico,
    ReporteRevisar: ReporteRevisar,
    ReporteReparar: ReporteReparar,
    Pasaje: Pasaje
}
