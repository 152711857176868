import { APIRestAWS } from "services/common/APIRestAWS";
import { PATH_DOCUMENTO_API, PATH_SERFAC_API } from "commons/config/Path";
import config from "commons/config/AWSConfig";
import axios from 'axios';
import { validarResponse } from 'utils/Functions';
import queryString from 'query-string'
export default class DomicilioApi {
     

    //serfac registrar documentos
    static async registrarDomicilio(  documento )  {
        var apiRestAWS = new APIRestAWS();
        documento.clieNombre = documento.clieNombre.replace(/\\n/g, "\\n")  
                           .replace(/\\'/g, "\\'")
                           .replace(/\\"/g, '\\"')
                           .replace(/\\&/g, "\\&")
                           .replace(/\\r/g, "\\r")
                           .replace(/\\t/g, "\\t")
                           .replace(/\\b/g, "\\b")
                           .replace(/\\f/g, "\\f");
       
        var data = { 
            // se debe enviar el codigo de Local
            clieId: documento.clieId, 
            local: documento.local?documento.local : "",
            codProd: documento.artefacto?documento.artefacto : "",
            modelo: documento.fechaVenta?documento.modelo : "",
            serie: documento.serie?documento.serie : "",
            marca: documento.marca?documento.marca : "",
            tipoServicio: documento.tipoServicio?documento.tipoServicio : "",
            motivo: documento.motivo?documento.motivo : "", //defecto
            observacion: documento.observacion?documento.observacion : "",

            numeroservicio: documento.numeroservicio?documento.numeroservicio : "",
            fechaAtencion: documento.docuFechaIni?documento.docuFechaIni : "",
            tipoTrabajo: documento.tipoTrabajo?documento.tipoTrabajo : "",
            fechaCompra: documento.fechaVenta?documento.fechaVenta : "", 
            area: documento.area?documento.area : "",  
            costos: documento.costos,
            idPerfilAud: localStorage.getItem("idperfilAud") ? localStorage.getItem("idperfilAud") : 1,
            userAud: localStorage.getItem("userAud") ? localStorage.getItem("userAud") : "admin",
            idUserAud: localStorage.getItem("idUser") ? localStorage.getItem("idUser") : 1,
            doccaja: documento.doccaja?documento.doccaja : "",
            tienda: documento.tienda?documento.tienda : "",
            operacion: "saveDomicilio"
        }; 
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.post(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });
    }

    static async updateCliente(  documento )  {
        var apiRestAWS = new APIRestAWS();
        documento.clieNombre = documento.clieNombre.replace(/\\n/g, "\\n")  
                           .replace(/\\'/g, "\\'")
                           .replace(/\\"/g, '\\"')
                           .replace(/\\&/g, "\\&")
                           .replace(/\\r/g, "\\r")
                           .replace(/\\t/g, "\\t")
                           .replace(/\\b/g, "\\b")
                           .replace(/\\f/g, "\\f");
       
        var data = {
            celular: documento.celular?documento.celular : "",
            clieId: documento.clieId?documento.clieId : 0,
            clieNombre: documento.clieNombre?documento.clieNombre : "",
            clieNumero: documento.clieNumero?documento.clieNumero : "",
            clieTipDoc: documento.clieTipDoc?documento.clieTipDoc : "",
            clieUbigeo: documento.clieUbigeo?documento.clieUbigeo : "",
            direccion: documento.direccion?documento.direccion : "",
            email: documento.email?documento.email : "",
            local: documento.local?documento.local : "",
            referencia: documento.referencia?documento.referencia : "",
            telefono: documento.telefono?documento.telefono : "", 
            nombreLocal: documento.nombreLocal?documento.nombreLocal : "",  
            nombreTemporalLocal: documento.nombreTemporalLocal?documento.nombreTemporalLocal : "",  
            operacion: "saveCliente"
        }; 
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.post(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });
    }


    static async consultarDocumentoPorOtrosCampos(request) {
        var apiRestAWS = new APIRestAWS();
        let data = {};
        let queryParams = "?" + queryString.stringify(request);
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.get(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_FICHA +  queryParams,  data, {
            headers: {
                'Content-Type': 'application/json',
            }
          } )
            .then( async (response) => { 
               // console.log("respuesta de servicio");  
              console.log(response);
              //console.log(response.data);
              return response.data;
        });
    }

     


}