export const TIPO_MENSAJE = {
    DANGER: 'danger',
    INFO: 'info',
    WARNING: 'warning',
    SUCCESS: 'success'
}

export const COLOR_MENSAJE = {
    INFO: "#00d3ee",
    SUCCESS: "#5cb860",
    WARNING: "#ffa21a",
    DANGER: "#f55a4e"
}

export const MENSAJE = {
    SUCCESS_GENERAL: 'Se ejecutó la acción correctamente.',
    ERROR_GENERAL: 'Su sesión ha expirado, debe iniciar sesión nuevamente',
    IND_MOSTRAR_MENSAJE: '1',
    IND_OCULTAR_MENSAJE: '0',
    CERRAR_SESION: 'Su sesión ha expirado, debe iniciar sesión nuevamente'
}

export const ESTADO = {
    ACTIVO: '1',
    INACTIVO: '0',
}

export const DSC_ESTADO = {
    ACTIVO: 'Activo',
    INACTIVO: 'Inactivo'
}

export const DSC_AJUSTE = {
    ACTIVO: 'Sí',
    INACTIVO: 'No'
}

export const ESTADO_SUBPROCESO = {
    PENDIENTE: 1,
    EN_PROCESO: 2,
    PROCESADO: 3
}

export const ESTADO_CUESTIONARIO = {
    PENDIENTE: 1,
    EN_PROCESO: 2,
    TERMINADO: 3,
    APROBADO: 4,
    ANULADO: 5,
    DEVUELTO: 6
}

export const GRID_TEXT_ES = {
    filterPlaceholder: 'Filtro',
    showAll: 'Todo',
    rowsPerPage: 'Registros por página',
    info: '{from}-{to} de {count}',
    noData: 'No se encontraron datos.'
}

export const OPC_BUSQUEDA = {
    OBTENER: 'obtener',
    LISTAR: 'listar',
    PAGINAR: 'paginar',
    EXPORTAR: 'exportar',
    RESUMENBAJA: 'resumenbaja'
}

export const OPC_MTTO = {
    ELIMINAR: 'eliminar',
    INSERTAR: 'insertar',
    EDITAR: 'editar'
}

export const OPC_REPORTE = {
    IMPORTAR: 'importar',
    EXPORTAR: 'exportar'
}

export const OPC_CECOS = {
    LISTAR: 'listar',
    LISTARSINRELACION: 'listarSinRelacion'
}

export const OPC_DRIVER = {
    LISTAR: 'listarDrivers',
    INSERTAR: 'insertarDrivers',
    ACTUALIZAR:'actualizarDrivers',
    ELIMINAR: 'eliminarDrivers',
    DIST_CANALES:'canales',
    DIST_PRODUCTOS:'productos',
    DIST_ACTUALIZAR:'actualizar',
    DIST_PROCESAR:'procesar'
}

export const OPC_MP = {
    LISTAR: 'listar',
    IMPORTAR: 'importar',
    GUARDARLOG: 'guadarDatosLog',
    DESCARGARMAPAPROC: 'descargarMapaProc'
}


export const OPC_CARGAOTROSDOCS = {
    CARGAROTROSDOCS: 'cargarotrosdocs',
    LISTARLOG: 'listarlog',
    INSERTARLOG: 'insertarlog',
    ACTUALIZARLOG: 'actualizarlog',
    ACTUALIZARPROCESADO: 'actualizarprocesado',       
    LISTARDOCENTRADAPROC: 'listardocEntradaProc',
    PAGINAR_DOCENTRADAPROC: 'paginardocEntradaProc',                           
    BUSCAR_DOCENTRADAPROC: 'buscardocEntradaProc',
    INSERTAR_DOCENTRADAPROC: 'insertardocEntradaProc',
    ACTUALIZAR_DOCENTRADAPROC: 'actualizardocEntradaProc'  
}

export const PARAM_APP = {
    NROPERIODOS: '24'
}

export const ESTADO_CARGA_ARCHIVO = {
    INICIADO: 1,
    ENPROCESO: 2,
    PROCESADO: 3, 
    ERROR: 0,

}

export const TIPO_CARGA = {
    HISTORICO: '1',
    MAESTRO: '2'
}

export const EXTENSION_ARCHIVO = {
    XLSX: '.xlsx',
    XLS: '.xls',
    CSV: '.csv'
}
//dscextension:".csv"
//extension:"CSV"
export const TAMANO_ARCHIVO = {
     '1' : '1024',
     '2' : '2048',
     '3' : '3072',
     '4' : '4096',
     '5' : '5120'
}

export const FORMATO_PERIODO = {
    AAAAMM: '%Y%m',
    AAAA: '%Y',
}

export const OPC_SEGURIDAD = {
    LISTAR_PERFIL: 'listarperfil',
    BUSCAR_PERFIL: 'buscarperfil',
    INSERTAR_PERFIL: 'insertarperfil',
    ACTUALIZAR_PERFIL: 'actualizarperfil',
    PAGINAR_PERFIL: 'paginarperfil',
    LISTAR_OPCIONXPERFIL: 'listaropcionxperfil',
    ACTUALIZAR_OPCIONXPERFIL: 'actualizaropcionxperfil',

    LISTAR_USUARIO: 'listarusuario',
    BUSCAR_USUARIO: 'buscarusuario',
    PAGINAR_USUARIO: 'paginarusuario',
    INSERTAR_USUARIO: 'insertarusuario',
    ACTUALIZAR_USUARIO: 'actualizarusuario',

    LISTAR_OPCION: 'listaropcion',
    BUSCAR_OPCION: 'buscaropcion',
    PAGINAR_OPCION: 'paginaropcion',
    INSERTAR_OPCION: 'insertaropcion',
    ACTUALIZAR_OPCION: 'actualizaropcion',  
    BUSCAR_OPCION: 'buscaropcion',
    BUSCAR_PADRE: 'buscarpadre'      

}

export const OPC_CTACONTAB = {
    LISTAR_EMPRESAS: 'listarEmpresas',
    LISTAR_CUENTASCONTAB: 'listarCuentasContables',                           
    LISTAR_AGRUPCUENTA: 'listarAgrupCuenta',
    LISTAR_LINEAGASTO: 'listarLineaGasto',
    LISTAR_TIPOGASTO: 'listarTipoGasto',
    LISTAR_GRILLA: 'listarCCGrilla',
    MODIFICAR_CTACONTAB: 'modificarCtaContable'    
}

export const OPC_CUESTIONARIO = {
    VERIFICAR_ESTADO_FINAL: "verificarEstadoFinal"
}

export const OPC_CANAL = {
    LISTAR_CANALES: "listarCanales",
    INSERTAR_CANAL: "agregarCanal",
    ACTUALIZAR_CANAL: "modificarCanal",
    VALIDAR_GRUPO_CANAL: "validarGrupoOrden",
    LISTAR: "listar"
}

export const OPC_SEGMENTO = {
    LISTAR: "listarsegmento",
    INSERTAR: "insertarsegmento",
    ACTUALIZAR: "actualizarsegmento",
    BUSCAR: "buscarsegmento",
    PAGINAR: "paginarsegmento"
}

export const OPC_PRODUCTO = {
    LISTAR_PRODUCTOS: "paginar",
    INSERTAR_PRODUCTO: "agregarProducto",
    ACTUALIZAR_PRODUCTO: "modificarProducto",
    VALIDAR_GRUPO_PRODUCTO: "valorden",
    LISTAR_CANALESXPRODUCTOS: "canxpro",
    LISTAR_CANALESXPRODUCTOSNR: "canxpronr",
    INSERTAR_CANALXPRODUCTO: "agregarcanalxprod",
    LISTAR_ACTCANALESXPRODUCTOS: "actcanxprod",
    ELIMINAR_CANALXPRODUCTO: "elicanxprod"
}

export const OPC_PARAMETROS = {
    LISTAR : 'listar',
    SELECCIONAR : 'seleccionar',
    LISTAR_TIPPARAMETRO: "listarTipParametro",
    LISTAR_PARAMETRO: "listarParametro",
    INSERTAR_TIPPARAMETRO: "insertarTipParametro",
    INSERTAR_PARAMETRO: "insertarParametro",
    ACTUALIZAR_TIPPARAMETRO: "modificarTipParametro",
    ACTUALIZAR_PARAMETRO: "modificarParametro"
}

export const RESPONSE_STATUS = {
    OK: "200",
}

export const PERIODO_MES = {
    ENERO : '01',
    FEBRERO : '02',
    MARZO : '03',
    ABRIL : '04',
    MAYO : '05',
    JUNIO : '06',
    JULIO : '07',
    AGOSTO : '08',
    SETIEMBRE : '09',
    OCTUBRE : '10',
    NOVIEMBRE : '11',
    DICIEMBRE : '12'
}


export const TIPO_CARGA_PROC = {
    SEGUIMIENTO : '0',
    CIERRE : '1'
}

export const OPC_EJECUCION = {
    PROCESOABC : 'procesoabc'
}

export const MENSAJE_GENERAL = {
    NO_SELECTION: 'No se han seleccionado items'
}