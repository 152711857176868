import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';
import withStyles from "@material-ui/core/styles/withStyles";
import * as parametroActions from "actions/ParametroActions";
import * as notificationActions from "actions/NotificationActions";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import customInputStyle from "styles/jss/material-dashboard-react/components/customInputStyle";
import FormHelperText from '@material-ui/core/FormHelperText';

class ParametroSelect extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      parametros: []
    }
  }
  componentDidMount() {
    const { idetipparametro, dsctipparametro } = this.props;
    const { show } = this.props.notificationActions;
    var parametro = {
      idetipparametro: idetipparametro
    }
    this.props.actions.listarParametro(parametro).then(response => {
      if (response.tipo !== TIPO_MENSAJE.SUCCESS) {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
      } else {
        if (response.data.length === 0) {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "No se encontraron " + dsctipparametro + " a mostrar.", TIPO_MENSAJE.WARNING);
        } else {
          this.setState({ parametros: response.data })
        }
      }
    })
  }
  handleChange = event => {
    //this.setState({ valor: event.target.value });
    this.props.getValue(event.target.value, this.props.id)
  }
  getTextSelect = selected => {
    const { multiple } = this.props;
    const { parametros } = this.state;
    if (parametros.length > 0) {
      if (multiple) {
        var params = [];
        for (var i = 0; i < selected.length; i++) {
          for (var j = 0; j < parametros.length; j++) {
            if (selected[i] === parametros[j].codparametro) {
              params.push(parametros[j].dscparametro)
            }
          }
        }
        return params.join(', ');
      } else {
        var param = parametros.find(o => o.codparametro === selected);
        return param.dscparametro;
      }
    }
  }
  render() {
    const {
      input,
      classes,
      formControlProps,
      labelText,
      id,
      labelProps,
      inputProps,
      requiredLabel,
      meta: { touched, error, warning },
      multiple,
      forceSelect
    } = this.props;
    const labelClasses = classNames({
      [" " + classes.labelRootError]: Boolean(touched && (error || warning)),
      [" " + classes.labelRootSuccess]: Boolean(touched && !(error || warning))
    });
    const underlineClasses = classNames({
      [classes.underlineError]: Boolean(touched && (error || warning)),
      [classes.underlineSuccess]: Boolean(touched && !(error || warning)),
      [classes.underline]: true
    });
    const marginTop = classNames({
      [classes.marginTop]: labelText === undefined
    });
    return (
      <FormControl error={Boolean(touched && (error || warning))} aria-describedby="name-error-text"
        {...formControlProps}
        className={formControlProps.className + " " + classes.formControl}>
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          required={requiredLabel}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
        <Select
          multiple={multiple}
          value={this.props.valor}
          onChange={this.handleChange}
          input={<Input id={id} classes={{ underline: underlineClasses }} {...input} {...inputProps} />}
          classes={{
            root: marginTop,
            disabled: classes.disabled
          }}
          renderValue={this.getTextSelect}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
                fontSize: '14px !important'
              }
            }
          }}
        >
          {!multiple && !forceSelect ? (
            <MenuItem value="" className={classes.itemSelect}>
              <em>SELECCIONAR</em>
            </MenuItem>
          ) : ("")}
          {multiple ? this.state.parametros.map(item => (
            <MenuItem key={item.codparametro} value={item.codparametro} className={classes.menuItemCheck}>
              <Checkbox checked={this.props.valor.indexOf(item.codparametro) > -1} className={classes.sinMargen} />
              <ListItemText primary={item.dscparametro} primaryTypographyProps={{ variant: "subtitle1", className: classes.itemSelect }} />
            </MenuItem>
          )) : this.state.parametros.map(item => (
            <MenuItem key={item.codparametro} value={item.codparametro} className={classes.itemSelect}>
              {item.dscparametro}
            </MenuItem>
          ))}
        </Select>
        {touched && (error || warning) && <FormHelperText id="name-error-text">{error || warning}</FormHelperText>}
      </FormControl>
    );
  }
}
ParametroSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool
};
const mapState = (state) => ({});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(parametroActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};
ParametroSelect = withStyles(customInputStyle)(ParametroSelect);
export default withRouter(connect(mapState, mapDispatch)(ParametroSelect));