import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';
var moment = require('moment');

/**
     * Valida el formato del objeto de respuesta
     * @param {object} response  - La respuesta obtenida por el servidor
     */
export const validarResponse = (response) => {
  var respuesta = {};
  if (typeof response.errorMessage !== "undefined") {
    respuesta.tipo = TIPO_MENSAJE.DANGER;
    respuesta.mensaje = MENSAJE.ERROR_GENERAL;
    respuesta.indMostrarMensaje = MENSAJE.IND_MOSTRAR_MENSAJE;
  } else {
    respuesta = response;
    if (response.tipo === TIPO_MENSAJE.DANGER
      && response.mensaje === '')
      respuesta.mensaje = MENSAJE.ERROR_GENERAL;
    else if (response.tipo === TIPO_MENSAJE.SUCCESS
      && response.mensaje === '' && response.indMostrarMensaje === MENSAJE.IND_MOSTRAR_MENSAJE)
      respuesta.mensaje = MENSAJE.SUCCESS_GENERAL;
  }
  return respuesta;
};
/**
     * Convierte la data de un archivo a buffer para ser generado
     * @param {string} s  - La data a convertir
     */
export const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

export const getBase64FromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const copiarJson = (obj1, obj2) => {
  if (obj2 === null || typeof obj2 !== 'object') {
    return obj2;
  }
  //var temp = obj.constructor();
  for (var key in obj2) {
    obj1[key] = copiarJson(obj1, obj2[key]);
  }
}

export const getFormatDate = (date, format, separator) => {
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }

  if (typeof format === "string" && format.length === 3) {
    var fechastr = {
      d: dd,
      m: mm,
      y: yyyy
    }
    return fechastr[format[0]] + separator + fechastr[format[1]] + separator + fechastr[format[2]];
  } else {
    return dd + "/" + mm + '/' + yyyy;
  }
}

export const getRestarDias = (date, dias, format, separator) => {
  
  let secondsRest = 1000 * 60 * 60 * 24 * dias;
  var dateRestado = date.getTime()  - secondsRest;
  var newDate = new Date(dateRestado);  
  var dd = newDate.getDate();
  var mm = newDate.getMonth() + 1;
  var yyyy = newDate.getFullYear();
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }

  if (typeof format === "string" && format.length === 3) {
    var fechastr = {
      d: dd,
      m: mm,
      y: yyyy
    }
    return fechastr[format[0]] + separator + fechastr[format[1]] + separator + fechastr[format[2]];
  } else {
    return dd + "/" + mm + '/' + yyyy;
  }
}

export const generarListaPeriodo = (formato,nroperiodos) => {
  var listaperiodos  = [];
  var ideperiodo = '';
  var dscperiodo = '';

  for(var i= 0; i < nroperiodos ; i++) {

    if (formato === '%Y') {
      ideperiodo = moment().add(-i, 'year').format('YYYY');
      dscperiodo = moment().add(-i, 'year').format('YYYY');
    } else { // '%Y%m'
      ideperiodo = moment().add(-i, 'month').format('YYYYMM');
      dscperiodo = moment().add(-i, 'month').format('YYYY-MM');
    }

    listaperiodos.push({ 
        "periodomes" : ideperiodo,
        "dscperiodomes"  : dscperiodo,
        "fmtperiodo" : formato
    });

  }    
  //this.setState({ listaperiodos: listaperiodos });
  return listaperiodos;
}

