import { FORM_VALIDATION_MESSAGE } from 'utils/Messages'
export const required = value => {
  return (typeof value === "undefined" || value === '' || value.length === 0 ? FORM_VALIDATION_MESSAGE.REQUIRED : undefined)
}
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? FORM_VALIDATION_MESSAGE.EMAIL
    : undefined
export const integer = value =>
  value && !/^[0-9]*$/i.test(value) ? FORM_VALIDATION_MESSAGE.INTEGER
    : undefined

export const max = max => value => value > max ? FORM_VALIDATION_MESSAGE.MAXNUMBER + max
    : undefined

export const min = min => value => min > value ? FORM_VALIDATION_MESSAGE.MINNUMBER + min
    : undefined

export const maxLength = max => value => value.length > max ? parse(FORM_VALIDATION_MESSAGE.MAXLENGTH, max)
    : undefined

export const min2 = min(2)
export const min1 = min(1)
export const min0 = min(0)
export const max3000 = max(3000)
export const maxLength4000 = maxLength(4000)


function parse(str) {
    var args = [].slice.call(arguments, 1),
        i = 0;
    return str.replace(/%s/g, function() {
        return args[i++];
    });
}