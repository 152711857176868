import { API } from "aws-amplify";
import { validarResponse } from 'utils/Functions';
import sessionApi from 'services/api/SessionApi';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';
import { sessionService } from 'redux-react-session';
import { isArray, isObject } from "util";
import queryString from 'query-string'

export class APIRestAWS {

    buildCanonicalQueryString = function (queryParams) {
        /*if (Object.keys(queryParams).length < 1) {
            return "";
        }

        let sortedQueryParams = [];
        for (let property in queryParams) {
            if (queryParams.hasOwnProperty(property)) {
                sortedQueryParams.push(property);
            }
        }
        sortedQueryParams.sort();

        let canonicalQueryString = "";
        for (let i = 0; i < sortedQueryParams.length; i++) {
            if (isObject(queryParams[sortedQueryParams[i]])){
                let newQueryParams = queryParams[sortedQueryParams[i]];
                let nameNewParam = sortedQueryParams[i];
                let sortedQueryParamsNivel2 = [];
                for (let property in newQueryParams) {
                    if (newQueryParams.hasOwnProperty(property)) {
                        sortedQueryParamsNivel2.push(property);
                    }
                }
                for (let x = 0; x < sortedQueryParamsNivel2.length; x++) {
                    canonicalQueryString +=
                    nameNewParam + "[" + sortedQueryParamsNivel2[x] + "]"+
                        "=" +
                        encodeURIComponent(newQueryParams[sortedQueryParamsNivel2[x]]) +
                        "&";
                }
            }else{
                canonicalQueryString +=
                sortedQueryParams[i] +
                "=" +
                encodeURIComponent(queryParams[sortedQueryParams[i]]) +
                "&";
            }
        }*/
        //console.log("new query string:::", queryString.stringify(queryParams));
        //return canonicalQueryString.substr(0, canonicalQueryString.length - 1);
        return queryString.stringify(queryParams);
    }


    /**
     * Make a GET request
     * @param {string} apiName  - The api name of the request
     * @param {string} path - The path of the request
     * @param {json} queryParams - Request query params
     * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
     */
    get = async function (apiName, path, queryParams) {
        
        let options = {
          //  headers: { user: localStorage.tkn },
            
        };
        let url = path;
        let queryString = this.buildCanonicalQueryString(queryParams);
        if (queryString !== "") {
            url += "?" + queryString;
        }
        try {
            let response = await API.get(apiName, url, options);
            return validarResponse(response);
        } catch (error) {
           // console.log("error al consultar URL", error)
            if( typeof error.response != "undefined" || error.response != null){
                if (error.response.status === 401  || ( typeof error.response === "undefined" || error.response == null)) {
                    localStorage.clear();
                    sessionService.deleteUser();
                    sessionService.deleteSession();
                    return {
                        tipo: TIPO_MENSAJE.WARNING,
                        mensaje: MENSAJE.CERRAR_SESION,
                        indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
                    };
                }else{
                    throw error;
                }
            }else{ //hubo error de network
                localStorage.clear();
                    sessionService.deleteUser();
                    sessionService.deleteSession();
                    return {
                        tipo: TIPO_MENSAJE.WARNING,
                        mensaje: MENSAJE.CERRAR_SESION,
                        indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
                    };
            }
        }
    }

    /**
     * Make a POST request
     * @param {string} apiName  - The api name of the request
     * @param {string} path - The path of the request
     * @param {json} bodyParam - Request body params
     * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
     */
    post = async function (apiName, path, bodyParam) {
        let options = {
            headers: { 
                user: "aaaa"
            },
            body: bodyParam
        }
        try {
            let response = await API.post(apiName, path, options);
            return validarResponse(response);
        } catch (error) {
            console.log("error en el api:::" + error);
            if (error.response.status === 401) {
                localStorage.clear();
                sessionService.deleteUser();
                sessionService.deleteSession();
                return {
                    tipo: TIPO_MENSAJE.WARNING,
                    mensaje: MENSAJE.CERRAR_SESION,
                    indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
                };
            } else {
                throw error;
            }
        }
    }

    /**
     * Make a PUT request
     * @param {string} apiName  - The api name of the request
     * @param {string} path - The path of the request
     * @param {json} bodyParam - Request body params
     * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
     */
    put = async function (apiName, path, bodyParam) {
        let options = {
            headers: { 
            //    user: localStorage.tkn 
            },
            body: bodyParam
        }
        try {
            let response = await API.put(apiName, path, options);
            return validarResponse(response);
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                sessionService.deleteUser();
                sessionService.deleteSession();
                return {
                    tipo: TIPO_MENSAJE.WARNING,
                    mensaje: MENSAJE.CERRAR_SESION,
                    indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
                };
            } else {
                throw error;
            }
        }
    }

    /**
     * Make a PATCH request
     * @param {string} apiName  - The api name of the request
     * @param {string} path - The path of the request
     * @param {json} bodyParam - Request body params
     * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
     */
    patch = async function (apiName, path, bodyParam) {
        let options = {
            headers: { 
            //    user: localStorage.tkn 
            },
            body: bodyParam
        }
        return await API.patch(apiName, path, options);
    }

    /**
     * Make a DEL request
     * @param {string} apiName  - The api name of the request
     * @param {string} path - The path of the request
     * @param {json} queryParams - Request query params
     * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
     */
    del = async function (apiName, path, queryParams) {

        let options = {
            headers: {
                // user: localStorage.tkn 
                }
        };

        let url = path;
        let queryString = this.buildCanonicalQueryString(queryParams);
        if (queryString !== "") {
            url += "?" + queryString;
        }
        try {
            let response = await API.del(apiName, url, options);
            return validarResponse(response);
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                sessionService.deleteUser();
                sessionService.deleteSession();
                return {
                    tipo: TIPO_MENSAJE.WARNING,
                    mensaje: MENSAJE.CERRAR_SESION,
                    indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
                };
            } else {
                throw error;
            }
        }
    }

    /**
     * Make a HEAD request
     * @param {string} apiName  - The api name of the request
     * @param {string} path - The path of the request
     * @param {json} queryParams - Request query params
     * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
     */
    head = async function (apiName, path, queryParams) {

        let options = {
            headers: { user: localStorage.tkn }
        };

        let url = path;
        let queryString = this.buildCanonicalQueryString(queryParams);
        if (queryString !== "") {
            url += "?" + queryString;
        }

        return await API.head(apiName, url, options);
    }

    /**
     * Make a POST sync request
     * @param {string} apiName  - The api name of the request
     * @param {string} path - The path of the request
     * @param {json} bodyParam - Request body params
     * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
     */
    postSync = function (apiName, path, bodyParam) {
        let options = {
            headers: { user: localStorage.tkn },
            response: true,
            body: bodyParam
        }
        API.get(apiName, path, options).then(response => {
            return response;
        }).catch(error => {
            return error.response;
        });
    }

}