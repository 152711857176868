import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { PATH_APP } from "commons/config/Path";

const PrivateRoute = ({ component: Component, authenticated, props: cProps, ...rest }) => (
  <Route {...rest} render={props => (
    authenticated ? (
      <Component {...props} {...cProps} />
    ) : (
        <Redirect push to={PATH_APP.LOGIN} />
      )
  )} />
)

const { object, bool, string, func } = PropTypes;

PrivateRoute.propTypes = {
  component: func.isRequired,
  exact: bool,
  path: string.isRequired,
  authenticated: bool.isRequired,
  location: object
};

export default PrivateRoute;