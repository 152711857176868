import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import * as perfilActions from "actions/PerfilActions";
import * as notificationActions from "actions/NotificationActions";
import * as loadActions from "actions/LoadActions";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import tabStyle from "styles/jss/app/components/tabStyle.jsx";
import Form from "components/Form/OpcionesxPerfilForm";
import { TIPO_MENSAJE, OPC_SEGURIDAD, MENSAJE } from 'utils/Constants';

function TabContainer(props) {
  return (
    <div style={{ padding: 8 * 3 }}>
      {props.children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class OpcionxPerfilDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueTab: 0,
      perfil: {},
      showOpcionxPerfilDialog: '',

    };
  }

  componentDidMount() {
    this.setState({ perfil: this.props.perfil });
    this.setState({ selection: this.props.selection});
  }

  handleChangeTab = (event, valueTab) => {
    this.setState({ valueTab });
  };

  closeDialog = () => {
    this.setState({ valueTab: 0 });
    this.props.toggleDialogOpcionesxPerfil();
  }

  render() {
    const { classes, showOpcionesxPerfilDialog, listaOpcionesxPerfil, selection, perfil} = this.props;

    return (
      <Dialog
        open={showOpcionesxPerfilDialog}
        onClose={this.closeDialog}
        fullWidth={false}
        maxWidth={false}
      >
        <DialogContent>
          <Form onSubmit={this.actualizarOpcionxPerfil} 
                classes={classes} 
                toggleDialogOpcionesxPerfil={this.closeDialog}
                listaOpcionesxPerfil={listaOpcionesxPerfil}
                selection = {selection}
                perfil = {perfil}
                listarOpcionesxPerfil = {this.props.listarOpcionesxPerfil}
           />
        </DialogContent>
      </Dialog>
    )
  }
}

const mapState = (state) => ({
  user: state.session.user,
  loading: state.load.loading
});

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(perfilActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

OpcionxPerfilDialog = withStyles(tabStyle)(OpcionxPerfilDialog);
export default withRouter(connect(mapState, mapDispatch)(OpcionxPerfilDialog));