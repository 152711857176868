import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm } from 'redux-form';

import * as loadActions from "actions/LoadActions";
import * as notificationActions from "actions/NotificationActions";
import * as formActions from "actions/FormActions";
import { email, required} from 'utils/FormValidation';

class MntOpcionForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {

      opcion:  { 
        ideopcion : '',
        nomopcion : '',
        dscopcion : '',
        dsctitopcion : '',
        dscruta : '',
        dscvista : '',
        dscicono : '',
        ideopcionpadre : '',
        dscideopcionpadre : '',
        stssegopcion : '',
        numorden : '',
        valdescendencia : '' 
      }
    }
  }

  componentDidMount() {
    var { opcion } = this.props;   
    this.props.formActions.loadData(opcion);
    this.setState({ opcion: opcion });
  }

  getSelectValue = (valor, name) => {
    var opcion = this.state.opcion
    opcion[name] = valor;
    this.setState({ opcion: opcion });
  }

  render() {
    const { handleSubmit, classes, estados, lstOpcionPadre, toggleDialogOpcion} = this.props;
    const { opcion } = this.state;
    return (
      <form onSubmit={handleSubmit} style={{ width: "100%", margin: 8 * 3 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Field labelText="Nombre de la Opción del Sistema"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "nomopcion"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 30)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={opcion.nomopcion}
                  validate={[required]}
                  name="nomopcion"
                  id="nomopcion" />
              </GridItem>     
              <GridItem xs={12} sm={12} md={6}>
              <Field labelText="Descripción de la Opción del Sistema"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "dscopcion"
                  }}
                  normalize={(val, prevVal) => {
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={opcion.dscopcion}
                  validate={[required]}
                  name="dscopcion"
                  id="dscopcion" />
              </GridItem>                
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>            
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Título de la Opción"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "dsctitopcion"
                  }}
                  normalize={(val, prevVal) => {                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={opcion.dsctitopcion}
                  validate={[required]}
                  name="dsctitopcion"
                  id="dsctitopcion" />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
              <Field labelText="Ruta de la Opción"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "dscruta"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 50)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-Z0-9/]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={opcion.dscruta}
                  validate={[required]}
                  name="dscruta"
                  id="dscruta" />
              </GridItem>                         
              <GridItem xs={12} sm={12} md={3}>
              <Field labelText="Nombre del Objeto"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "dscvista"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 40)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={opcion.dscvista}
                  name="dscvista"
                  id="dscvista" />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
              <Field labelText="Descripción del icono"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "dscicono"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 30)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={opcion.dscicono}
                  validate={[required]}
                  name="dscicono"
                  id="dscicono" />
              </GridItem>
            </GridContainer>            
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>            
            <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
            <Field labelText="Opción Padre"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "ideopcionpadre",
                    name: "ideopcionpadre"
                  }}
                  multiple={false}
                  data={lstOpcionPadre}
                  component={CustomSelect}
                  valor={opcion.ideopcionpadre}
                  getValue={this.getSelectValue}
                  val="ideopcionpadre"
                  txt="dscideopcionpadre"
                  name="ideopcionpadre"
                  id="ideopcionpadre" />
              </GridItem>                   
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Número de Orden"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "numorden"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 30)
                      return prevVal;
                    
                    if (val) {
                      return (/^[0-9]+$/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={opcion.numorden}
                  validate={[required]}
                  name="numorden"
                  id="numorden" />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Valor de Descendencia"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "valdescendencia"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 45)
                      return prevVal;
                    
                    if (val) {
                      return (/^[0-9a-zA-Z]+$/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={opcion.valdescendencia}
                  validate={[required]}
                  name="valdescendencia"
                  id="valdescendencia" />
              </GridItem>                         
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Estado"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      autoComplete: "stssegopcion",
                      name: "stssegopcion"
                    }}
                    multiple={false}
                    data={estados}
                    component={CustomSelect}
                    valor={opcion.stssegopcion}
                    getValue={this.getSelectValue}
                    validate={[required]}
                    val="stssegopcion"
                    txt="dscstssegopcion"
                    name="stssegopcion"
                    id="stssegopcion" />
              </GridItem>            
            </GridContainer>
          </GridItem>          
        </GridContainer>
      
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
              {/* <div style={{ textAlign: "right" }}> */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >Guardar</Button>
              <Button onClick={() => toggleDialogOpcion()}>Cerrar</Button> 
            </GridItem>
          </GridContainer>
        </GridItem>
      </form>
    );
  }
}

MntOpcionForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
MntOpcionForm = reduxForm({
  form: 'mntOpcionForm',
  enableReinitialize: true
})(MntOpcionForm)

const mapState = (state) => ({
  loading: state.load.loading,
  initialValues: state.formApp.data,
  user: state.session.user,
});

const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(MntOpcionForm));
