import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CustomAutocomplete from "components/CustomInput/CustomAutocomplete";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm } from 'redux-form';
import CustomTextArea from "components/CustomInput/CustomTextArea";
import * as loadActions from "actions/LoadActions";
import * as notificationActions from "actions/NotificationActions";
import * as formActions from "actions/FormActions";
import { email, required} from 'utils/FormValidation';
import { ESTADO } from 'utils/Constants';
import Grid from "components/Grid/RepuestoAlmacenDirGrid";
import UploadImages from "components/File/FileUpload";
import { DATA_DISTRITOS, DATA_TECNICOS, DATA_ESTADO_REPUESTO, DATA_LINEAS,DATA_TIENDAS, DATA_ESTADOS_DIALOG_SEGUIMIENTO_TALLER, DATA_AREA, DATA_ARTEFACTO, DATA_MARCA, DATA_TIPO_SERVICIO } from 'utils/GeneralData';
class MntRepuestoForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showGuadar: '',
         documento:  { 
            tecnico: '',
            modelo:'',
            serie:'',
            marca:'',
            producto:'',
            recomendacion:'',
            estado: '',
            precio: '',
            vertodogth: '',
            stsusuario: '',
            id: '',
            clieId: '',
            codestado: '',
            seguimiento: '',
            numeroservicio: '',
            clieNumero: '',
            email: '',
            clieUbigeo: '',
            tipoServicio: '',
            motivo: '',
            linea: '',
            area:'',
            moneda: 'SOL',
            artefacto: '',
            doccaja: '',
            tienda: '',
            observacion: ''
          },
          lineas: [],
          tiendas: [],
          areas: [ ],
          marcas: [],
          estados: [],
          monedas: [{
              "id": "SOL",
              "valor": "SOLES"
            },
            {
              "id": "USD",
              "valor": "DOLARES"
            }
          ],
          companias: [],
          artefactos:[],
          estadoArtefactos: [],
          tipoServicios: [],
          repuestos: [],
          tecnicos: [],
      }
      
  }

  componentDidMount() {
      this.props.onRef(this);
      const {   documento } = this.props;
      console.log("datos que llegaron", documento);
      this.props.change('modelo', documento.modelo)
      this.props.change('serie', documento.serie)
      this.props.change('marca', documento.marcacodigo)
      this.props.change('recomendacion', documento.recomendacion)
      this.props.change('seguimiento', documento.seguimiento)
      
      this.props.change('hId', documento.id)
      this.props.change('estado', documento.estado)
      this.props.change('ubicacion', documento.ubicacion)
      this.props.change('nombre', documento.nombre)
      this.props.change('telefono', documento.telefono)
      this.props.change('direccion', documento.direccion)
      this.props.change('numeroservicio', documento.numeroservicio)
      this.props.change('clieNumero', documento.numdoccliente)
      this.props.change('clieId', documento.clienteid) 
      this.props.change('email', documento.email)
      this.props.change('clieUbigeo', documento.ubigeo) 
      this.props.change('tipoServicio', ""+documento.codtipservicio) 
      this.props.change('moneda',  documento.moneda) 
      this.props.change('doccaja',  documento.doccaja) 

      this.props.change('artefacto',  documento.artefacto)
      this.props.change('motivo', documento.motivo)
      this.props.change('area', documento.area)
      this.props.change('linea', documento.linea)
      this.props.change('observacion',  documento.observacion)
      
      this.state.documento.clieUbigeo = documento.ubigeo;
      this.state.documento.tipoServicio = documento.codtipservicio;
      this.state.documento.moneda = documento.moneda;
      this.state.documento.estado = documento.estado;

      this.state.documento.artefacto = documento.artefacto;
      this.state.documento.area = documento.area;
      this.state.documento.marca = documento.marcacodigo;
      this.state.documento.linea = documento.linea; 
      this.state.documento.tecnico = documento.tecnico != null ? documento.tecnico : ''; 
      this.state.documento.id = documento.id; 
      this.state.documento.observacion = documento.observacion;

      this.state.documento.doccaja = documento.doccaja; 


      console.log("lectura de imagenes") 
      documento.fechaIngreso = documento.fechaingreso
      this.state.documento.fechaIngreso = documento.fechaingreso; 
      this.state.documento.id = documento.id; 
      this.UploadImages.listarImagenesPorId(documento);

      this.state.lineas = JSON.parse(localStorage.getItem("lineas_serfac")); //DATA_LINEAS;
      this.state.areas = JSON.parse(localStorage.getItem("areas_serfac"));  //DATA_AREA;
      this.state.marcas = JSON.parse(localStorage.getItem("marcas_serfac")); //DATA_MARCA;
      this.state.companias = JSON.parse(localStorage.getItem("ubigeos_serfac")); //DATA_DISTRITOS;
      this.state.artefactos = JSON.parse(localStorage.getItem("productos_serfac")); //DATA_ARTEFACTO;
      this.state.tipoServicios = JSON.parse(localStorage.getItem("tiposervicios_serfac")); // DATA_TIPO_SERVICIO;
      this.state.estados = DATA_ESTADO_REPUESTO; 
      this.state.estadoArtefactos = DATA_ESTADOS_DIALOG_SEGUIMIENTO_TALLER;
      this.state.tiendas = JSON.parse(localStorage.getItem("tiendas_serfac")); //DATA_TIENDAS;

      this.state.tecnicos = JSON.parse(localStorage.getItem("tecnicos_serfac")); //DATA_TECNICOS
      this.props.change('tecnico',  documento.idtecnico === 0 ? null : documento.idtecnico)
      this.state.documento.tecnico = documento.idtecnico === 0 ? null : documento.idtecnico
      this.props.change('tienda',  documento.tienda === 0 ? null : documento.tienda)
      this.state.documento.tienda = documento.tienda === 0 ? null : documento.tienda

      if (localStorage.getItem("tkn") == "serfac-ilumi" || localStorage.getItem("tkn") == "serfac-indurama"  ){
        this.state.showGuadar = 'None' 
      }
    /*  this.state.documento.estado = {
        "id": "2",
        "valor": "COTIZADO"
      } */
  }

  extraerRows = ( ) => {
    let rowsRepuestos = this.Grid.extraerRows();
    console.log(rowsRepuestos);
    return rowsRepuestos;
  }  


  


  getSelectValue = (valor, name) => {
    var documento = this.state.documento
    documento[name] = valor;
    this.setState({ documento: documento });
  }

  render() {
    const { handleSubmit, classes, estados, toggleDialogRepuesto, repuestos } = this.props;
    const { documento , estadoArtefactos, monedas, lineas, marcas, areas, artefactos, showGuadar} = this.state;

    var perfilesactivos = [];
    

    return (
      <form onSubmit={handleSubmit} style={{ width: "100%", margin: 8 * 3 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Modelo"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "modelo"
                  }}
                   
                  component={CustomInput}
                  value={documento.modelo} 
                  name="modelo"
                  id="modelo" />
              </GridItem>  
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Serie"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "serie"
                  }}
                   
                  component={CustomInput}
                  value={documento.serie} 
                  name="serie"
                  id="serie" />

                  <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "hId",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={documento.id}
                  name="hId"
                  id="hId" />


                  <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "clieId",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={documento.clieId}
                  name="clieId"
                  id="clieId" />

              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Marca"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "marca",
                    name: "marca"
                  }}
                  multiple={false}
                  data={this.state.marcas}
                  component={CustomSelect}
                  valor={documento.marca}
                  getValue={this.getSelectValue}
                  validate={[required]}
                  val="id"
                  txt="valor"
                  name="marca"
                  id="marca" />
              </GridItem>                         
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              
              <GridItem xs={12} sm={12} md={3}>
                  <Field labelText="Tecnico"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal", 
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "tecnico",
                    name: "tecnico"
                  }}
                  
                  multiple={false}
                  
                  data={this.state.tecnicos}
                  component={CustomSelect}
                  valor={documento.tecnico}
                  getValue={this.getSelectValue}
                  val="id"
                  txt="valor"
                  name="tecnico"
                  id="tecnico" />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>

                <Field labelText="Estado"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "estado",
                    name: "estado"
                  }}
                  multiple={false}
                  data={estadoArtefactos}
                  component={CustomAutocomplete}
                  valor={documento.estado}
                  getValue={this.getSelectValue}
                  validate={[required]}
                  val="id"
                  txt="valor"
                  name="estado"
                  id="estado" />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Ubicación"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "ubicacion"
                  }}
                  component={CustomInput}
                  value={documento.ubicacion} 
                  name="ubicacion"
                  id="ubicacion" />
              </GridItem>           
              <GridItem xs={12} sm={12} md={3}>
                 <Field labelText="Número de O/S"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "text",
                    name: "numeroservicio" 
                  }}                    
                  component={CustomInput}
                  value={documento.numeroservicio}
                  name="numeroservicio"
                  id="numeroservicio" />
              </GridItem>
              
            </GridContainer>
          </GridItem>



          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
                        
              <GridItem xs={12} sm={12} md={3}>
             
                   <Field labelText="Distrito"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      autoComplete: "clieUbigeo",
                      name: "clieUbigeo"
                    }}
                    multiple={false}
                    data={this.state.companias}
                    component={CustomSelect}
                    validate={[required]}
                    valor={documento.clieUbigeo}
                    getValue={this.getSelectValue}
                    val="id"
                    txt="valor"
                    name="clieUbigeo"
                    id="clieUbigeo" /> 
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                 <Field labelText="Email"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "email",
                    name: "email",
                    min: "1"
                  }}
                  
                  component={CustomInput}
                  value={documento.email}
                  name="email"
                  id="email" />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Tipo de Servicio"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "tipoServicio",
                    name: "tipoServicio"
                  }}
                  multiple={false}
                  data={this.state.tipoServicios}
                  component={CustomSelect}
                  valor={documento.tipoServicio}
                  getValue={this.getSelectValue}
                  validate={[required]}
                  val="id"
                  txt="valor"
                  name="tipoServicio"
                  id="tipoServicio" />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Moneda"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "moneda",
                    name: "moneda"
                  }}
                  multiple={false}
                  data={this.state.monedas}
                  component={CustomSelect}
                  valor={documento.moneda}
                  getValue={this.getSelectValue}
                  validate={[required]}
                  val="id"
                  txt="valor"
                  name="moneda"
                  id="moneda" />
              </GridItem>

            </GridContainer>
          </GridItem>







          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Nombre del Cliente"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "nombre"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 50)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={documento.nombre} 
                  name="nombre"
                  id="nombre" />
              </GridItem>  
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Dirección"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "direccion"
                  }}
                 
                  component={CustomInput}
                  value={documento.direccion} 
                  name="direccion"
                  id="direccion" />
 
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Telefono"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "telefono"
                  }}
                  
                  component={CustomInput}
                  value={documento.telefono} 
                  name="telefono"
                  id="telefono" />
              </GridItem>  
                <GridItem xs={12} sm={12} md={3}>
               <Field labelText="Número Documento"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "clieNumero",
                    name: "clieNumero",
                    min: "1"
                  }}
                  component={CustomInput}
                  validate={[required]}
                  value={documento.clieNumero}
                  name="clieNumero"
                  id="clieNumero" /> 
              </GridItem>                         
            </GridContainer>
          </GridItem>

          
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
                        
              <GridItem xs={12} sm={12} md={3}>
             
                   <Field labelText="Motivo"
                      formControlProps={{
                        fullWidth: true,
                        margin: "normal",
                        style: { marginTop: "0px" }
                      }}
                      inputProps={{
                        type: "motivo",
                        name: "motivo",
                        min: "1"
                      }}
                      
                      component={CustomInput}
                      value={documento.motivo}
                      name="motivo"
                      id="motivo" /> 
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                 <Field labelText="Area Asignada"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "area",
                    name: "area"
                  }}
                  multiple={false}
                  data={this.state.areas}
                  component={CustomSelect}
                  valor={documento.area}
                  getValue={this.getSelectValue}
                  validate={[required]}
                  val="id"
                  txt="valor"
                  name="area"
                  id="area" />

             

                <Field
                      formControlProps={{
                        fullWidth: false,
                        margin: "normal",
                        style: { marginTop: "0px" }
                      }}
                      inputProps={{
                        type: "hidden",
                        name: "observacion",
                        min: "1"
                      }}
                      component={CustomInput}
                      value={documento.observacion}
                      name="observacion"
                      id="observacion" /> 


              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Taller Linea"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      autoComplete: "linea",
                      name: "linea"
                    }}
                    multiple={false}
                    data={lineas}
                    validate={[required]}
                    component={CustomSelect}
                    valor={documento.linea}
                    getValue={this.getSelectValue} 
                    val="id"
                    txt="valor"
                    name="linea"
                    id="linea" />
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Artefacto"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "artefacto",
                    name: "artefacto"
                  }}
                  multiple={false}
                  data={this.state.artefactos}
                  component={CustomSelect}
                  valor={documento.artefacto}
                  getValue={this.getSelectValue}
                  val="id"
                  validate={[required]}
                  txt="valor"
                  name="artefacto"
                  id="artefacto" />
              </GridItem>
                
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                 <Field labelText="Documentos Caja"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "text",
                    name: "doccaja" 
                  }}                    
                  component={CustomInput}
                  value={documento.doccaja}
                  name="doccaja"
                  id="doccaja" />
              </GridItem>   
              <GridItem xs={12} sm={12} md={6}>
                      <Field labelText="Tienda"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          autoComplete: "tienda",
                          name: "tienda"
                        }}
                        multiple={false}
                        data={this.state.tiendas}
                        component={CustomSelect}
                        valor={documento.tienda}
                        getValue={this.getSelectValue}
                        validate={[required]}
                        val="id"
                        txt="valor"
                        name="tienda"
                        id="tienda" />
                    </GridItem>

            </GridContainer>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
            <div style={{ padding: "15px" }}>  Recomendaciones del tecnico: {documento.tecnico} </div>
            <GridItem xs={12} sm={12} md={12}>
                <Field labelText=""
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "textarea",
                      name: "recomendacion",
                      rows: 6,
                      min: "1"
                    }}
                    component={CustomTextArea}
                    value={documento.recomendacion}
                    name="recomendacion"
                    id="recomendacion" /> 
              </GridItem>                
            </GridContainer>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
            <div style={{ padding: "15px" }}> Seguimiento Coordinadora </div>
            <GridItem xs={12} sm={12} md={12}>
                <Field labelText=""
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "textarea",
                      name: "seguimiento",
                      rows: 6,
                      min: "1"
                    }}
                    component={CustomTextArea}
                    value={documento.seguimiento}
                    name="seguimiento"
                    id="seguimiento" /> 
              </GridItem>                
            </GridContainer>
          </GridItem>
 
          
        </GridContainer>
        <div style={{ padding: "15px" }}>  Repuestos</div>
        <GridItem xs={12} sm={12} md={12} >
              <GridContainer> 
                <GridItem xs={12} sm={12} md={12}>
                  <Grid onRef={ref => (this.Grid = ref)}  usuario={this.state.usuario} estados={this.state.estados} repuestos={repuestos}/> 
                </GridItem>
              </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} >
              <GridContainer> 
                <GridItem xs={12} sm={12} md={12}>
                <UploadImages documento={this.state.documento}   onRef={ref => (this.UploadImages = ref)}/>
                </GridItem>
              </GridContainer>
          </GridItem>

        
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
              {/* <div style={{ textAlign: "right" }}> */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ display: showGuadar }}
                className={classes.submit}
              >Guardar</Button>
              <Button onClick={() => toggleDialogRepuesto()}>Cerrar</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </form>
    );
  }
}

MntRepuestoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
MntRepuestoForm = reduxForm({
  form: 'mntRepuestoForm',
  enableReinitialize: true
})(MntRepuestoForm)
const mapState = (state) => ({
  loading: state.load.loading,
  initialValues: state.formApp.data
});
const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(MntRepuestoForm));
