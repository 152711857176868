import React from "react";
// @material-ui/core components
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import DatosIcon from '@material-ui/icons/LibraryBooks';
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import cuestionarioStyle from "styles/jss/app/views/cuestionarioStyle.jsx";
import * as parametroActions from "actions/ParametroActions";
import * as usuarioActions from "actions/UsuarioActions";
import * as notificationActions from "actions/NotificationActions";
import * as loadActions from "actions/LoadActions";
import { TIPO_MENSAJE, MENSAJE, ESTADO } from 'utils/Constants';

import ParametroCabGrid from "components/Grid/ParametroCabGrid";
import ParametroDetGrid from "components/Grid/ParametroDetGrid";


class Parametros extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      estados: [],
      estadosDet:[]
    }
  }

  componentDidMount() {
    this.buscarEstados();
  }

  listarParametroDet = (idetipparametro) =>{
    this.ParametroDetGrid.listarParametroDet(idetipparametro);
  }

  buscarEstados() {
    var estados = [];
    var estado;
    for (var key in ESTADO) {
      estado = {};
      estado.descparametro = key;
      estado.ststipparametro = ESTADO[key];
      estados.push(estado);
    }
    this.setState({ estados: estados});

    var estados1 = [];
    var estado1;
    for (var key in ESTADO) {
      estado1 = {};
      estado1.descparametro = key;
      estado1.stsparametro = ESTADO[key];
      estados1.push(estado1);
    }
    this.setState({ estadosDet: estados1});
  }

  render() {
    const { estados } = this.state;
    const { classes } = this.props;
    return (
        <div>
            <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <DatosIcon className={classes.titleIcon} />
                    <Typography variant="h4" className={classes.title}> Parametros</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                          <ParametroCabGrid  classes={classes}
                            listarParametroDet={this.listarParametroDet} 
                            estados={estados} />
                      </GridItem>
                    </GridContainer>
                </ExpansionPanelDetails>                
            </ExpansionPanel>

            <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <DatosIcon className={classes.titleIcon} />
                    <Typography variant="h4" className={classes.title}> Detalle Parametros</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                          <ParametroDetGrid classes={classes} onRef={ref => (this.ParametroDetGrid = ref)}
                          estadosDet={this.state.estadosDet} />
                      </GridItem>
                    </GridContainer>
                </ExpansionPanelDetails>               
            </ExpansionPanel>            
      
        </div>
    );
  }
}

const mapState = (state) => ({
  loading: state.load.loading
});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(parametroActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    usuarioActions: bindActionCreators(usuarioActions, dispatch)
  };
};

Parametros = withStyles(cuestionarioStyle)(Parametros);
export default withRouter(connect(mapState, mapDispatch)(Parametros));
