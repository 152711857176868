import React from "react";
// @material-ui/core components
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import domicilioStyle from "styles/jss/app/views/domicilioStyle.jsx";
import * as domicilioActions from "actions/DomicilioActions";
import * as notificationActions from "actions/NotificationActions";
//import * as sessionActions from "actions/SessionActions";
import * as loadActions from "actions/LoadActions";
import Form from "components/Form/RegDomicilioForm";
import Grid from "components/Grid/BsqDocumentoGrid"; 
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logo from "images/logo-rimac-r.png";
import JsBarcode from 'jsbarcode';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';

class RegistroDomicilio extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.registroDomicilio = this.registroDomicilio.bind(this);
    this.state = {
      documento: {
        clieNombre: '', //razon Social
        ubicacion: '',
        clieTipDoc: '',
        clieDireccion:'',
        clieNumero: '',  //es ruc
        clieTelefono: '',
        clieEmail: '',
        clieUbigeo: '',
        motivo: '',
        otro_motivo: '',
        artefacto: '',
        modelo: '',
        serie: '',
        marca: '',
        tipoServicio: '',
        fechaVenta: '',
        docuNumero: '',
        docuNumeroTo: '',
        observacion: '',
        tipoDocumento: [],
        docuFechaIni: '',
        docuFechaVenta: '',
        estado: '', 
        cdr: '',
        documentTypeClient: '',
        compania: '' ,
        imagen: '',
        tienda: ''
      },
    }
  }

  componentDidMount() {
    
  }

  registroDomicilio = (data) => {
   // console.log("datos enviados");
    console.log("data Enviada:::", data);
    var {documento} = this.state; 
    documento.docuFechaIni = data.hFechaInicio?data.hFechaInicio:'';
    documento.clieNombre = data.clieNombre?data.clieNombre:'';
    documento.ubicacion = data.ubicacion?data.ubicacion:'';
    documento.clieTipDoc = data.clieTipDoc?data.clieTipDoc:'';
    documento.clieDireccion = data.txtnumdocto?data.txtnumdocto:'';
    documento.clieNumero = data.clieNumero?data.clieNumero:'';
    documento.clieTelefono = data.telefono?data.telefono:'';
    documento.clieEmail = data.email?data.email:'';
    documento.clieUbigeo = data.clieUbigeo?data.clieUbigeo:'';
    documento.motivo = data.motivo?data.motivo:'';
    documento.otro_motivo = data.compania?data.compania:'';
    documento.artefacto = data.artefacto?data.artefacto:'';
    documento.modelo = data.modelo?data.modelo:'';
    documento.serie = data.serie?data.serie:'';
    documento.marca = data.marca?data.marca:'';
    documento.tipoServicio = data.tipoServicio?data.tipoServicio:'';
    documento.fechaVenta = data.hFechaFin?data.hFechaFin:'';
    documento.observacion = data.observacion?data.observacion:'';
    documento.facbolguia = data.facbolguia?data.facbolguia:'';
    documento.doccaja = data.doccaja?data.doccaja:'';
    documento.area = data.area?data.area:''; 
    documento.numeroservicio = data.numeroservicio?data.numeroservicio:'';
    documento.linea = data.linea?data.linea:''; 
    documento.recepciona = data.recepciona?data.recepciona:''; 
    documento.tipoTrabajo = data.tipoTrabajo?data.tipoTrabajo:''; 
    documento.tienda = data.tienda?data.tienda:''; 
    
    this.setState({documento: documento}); 
    let rowCostos = this.Form.extraerRows();
    documento.costos = rowCostos.costos;
    documento.clieId = rowCostos.clieId;
    documento.local = rowCostos.idlocal;
    console.log("RowsCostos::" , rowCostos);
    console.log("documento::" , documento);
    this.guardarRegistro(documento);
  }

  guardarRegistro(documento) {
    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    add();
    this.props.actions.registrarDomicilio(documento).then(response => {
      console.log(response);
      if (response.status == 200){
        let id = response.data
        this.Form.limpiarForm();
        
        show(MENSAJE.IND_MOSTRAR_MENSAJE, response.message, TIPO_MENSAJE.SUCCESS);
        //this.generarPdf(id);
      }else{
        show(MENSAJE.IND_MOSTRAR_MENSAJE, "Ocurrio un error al registrar los datos.", TIPO_MENSAJE.WARNING);
      }
       
      remove();
    })
  }

  generarPdf = (id) => {
     var doc = new jsPDF("landscape", "mm", "a4");
     var {documento} = this.state;


  
     JsBarcode("#itf", id, {format: "code39", fontSize: 150 , width:4,  height:150});
          const img = document.querySelector('img#itf');
           doc.addImage(img, 50, 20 );
 
 
           var columns = [
            { title: "", dataKey: "id" },
          ]; 
 
          columns = [
            { title: "", dataKey: "id" },
            { title: "", dataKey: "name" },
          ];
          doc.setFontSize(30);
        
          let rows = [
            { "id": "CLIENTE: ", "name": documento.clieNombre},
            { "id": "MODELO: ", "name": documento.modelo},
            { "id": "SERIE: ", "name": documento.serie},
          ];
          
          doc.text(30, 120, "NOMBRE :  "+ documento.clieNombre);
          doc.text(30, 135, "MODELO : "+documento.modelo);
          doc.text(30, 150, "SERIE      : "+ documento.serie);

          doc.setFontSize(24);
          var lugar = 'SERFAC SAC - RUC: 20295833743 - Av. Aviación 2618 San Borja Lima Perú   ';
          var lugar2 = ' Telf. 012243261, Cel. 998121831 - Correo: atencionalcliente@serfac.com.pe  ';
 
          var y = 175;
          var textWidth = doc.getStringUnitWidth(lugar) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.width - textWidth) / 2;

          var textWidth2 = doc.getStringUnitWidth(lugar2) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          var textOffset2 = (doc.internal.pageSize.width - textWidth2) / 2;

          

          doc.text(textOffset, y, lugar);
          y = 185;
           doc.text(textOffset2, y, lugar2);
          //doc.save("codigo-de-barras-"+id+".pdf"); 
          doc.autoPrint();
          window.open(doc.output('bloburl'), '_blank');

  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div style={{ display: "none" }}> <img id='itf' /></div>
         
            <Form  onRef={ref => (this.Form = ref)}  onSubmit={this.registroDomicilio} classes={classes} registroDomicilio={this.registroDomicilio} documento={this.state.documento} />
           
      </div>
    )
  }
}
const mapState = (state) => ({
  loading: state.load.loading
});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(domicilioActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

RegistroDomicilio = withStyles(domicilioStyle)(RegistroDomicilio);
export default withRouter(connect(mapState, mapDispatch)(RegistroDomicilio));