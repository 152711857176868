import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as opcionActions from "actions/OpcionActions";
import * as notificationActions from "actions/NotificationActions";
import * as loadActions from "actions/LoadActions";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import {TIPO_MENSAJE, GRID_TEXT_ES, MENSAJE,  OPC_SEGURIDAD} from 'utils/Constants';
import { email, required} from 'utils/FormValidation';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import TableCell from '@material-ui/core/TableCell';
import OpcionDialog from "components/Dialog/OpcionDialog";

import {
  CustomPaging, SortingState, PagingState
} from '@devexpress/dx-react-grid';
import {
  Grid, TableColumnVisibility,
  VirtualTable, TableHeaderRow, PagingPanel, 
  TableColumnResizing
} from '@devexpress/dx-react-grid-material-ui';


const getRowId = row => row.ideopcion;

class OpcionGrid extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      columns: [
        { name: 'tag', title: ' ' }, 
        { name: 'ideopcion', title: '' },   
        { name: 'edit', title: ' ' },        
        { name: 'nomopcion', title: 'Nombre de la opción' },
        { name: 'dscopcion', title: 'Descripción de la Opción'},
        { name: 'dsctitopcion', title: 'Titulo de la Opción' },
        { name: 'dscruta', title: 'Ruta de la Opción'},
        { name: 'dscvista', title: 'Nombre del Objeto'},
        { name: 'dscicono', title: 'Nombre icono'},
        { name: 'ideopcionpadre', title: ''},
        { name: 'dscideopcionpadre', title: 'Opción Padre'},        
        { name: 'numorden', title: 'Nro. Orden' },
        { name: 'valdescendencia', title: 'Valor desc.' },
        { name: 'stssegopcion', title: '' },        
        { name: 'dscstssegopcion', title: 'Estado' },
        { name: 'usumodificacion', title: 'Usu. Mod' },
        { name: 'fecmodificacion', title: 'Fec. Mod' }

      ],
      tableColumnExtensions: [    
        { columnName: 'tag', width: 20 },           
        { columnName: 'ideopcion', width: 100 }, 
        { columnName: 'edit', width: 80, align: 'center' },
        { columnName: 'nomopcion', width: 200, wordWrapEnabled: true },
        { columnName: 'dscopcion', width: 400, wordWrapEnabled: true },
        { columnName: 'dsctitopcion', width: 300 },
        { columnName: 'dscruta', width: 250 },
        { columnName: 'dscvista', width: 200 },
        { columnName: 'dscicono', width: 130 },
        { columnName: 'ideopcionpadre', width: 130 },      
        { columnName: 'dscideopcionpadre', width: 150 },              
        { columnName: 'numorden', width: 120, wordWrapEnabled: true },
        { columnName: 'valdescendencia', width: 120 , wordWrapEnabled: true },
        { columnName: 'stssegopcion', width: 100 },
        { columnName: 'dscstssegopcion', width: 100 },        
        { columnName: 'usumodificacion', width: 100 },
        { columnName: 'fecmodificacion', width: 150 }
      ],
      hiddenColumnNames: ['ideopcion','ideopcionpadre','stssegopcion'],
      rows: [],
      sorting: [],
      currentPage: 0,
      totalCount: 0,
      pageSize: 20,
      pageSizes: [10, 20, 50, 100],
      editingRowIds: [],
      opcion:  { 
        ideopcion:'',
        nomopcion : '',
        dscopcion : '',
        dsctitopcion : '',
        dscruta : '',
        dscvista : '',
        dscicono : '',
        ideopcionpadre : '',
        dscideopcionpadre : '',
        stssegopcion : '',
        numorden : '',
        valdescendencia : ''
      },
      showOpcionDialog: false,
    };

    this.changeSorting = sorting => this.setState({ sorting });

  }


  Cell = (props) => {
    const { column } = props;
    if (column.name === 'edit') {
      return (
        <TableCell style={{ padding: 0 }}>
          <IconButton color="primary" aria-label="editIcon" style={{ display: 'flex', float: 'left' }}
            onClick={this.toggleDialogOpcion.bind(this, props.row)} title="Editar" >
            <EditIcon />            
          </IconButton>
        </TableCell>
      );
    }

    return <VirtualTable.Cell {...props} />;
  };

  changeCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage })
    this.buscarOpcion(currentPage);
  }

  changePageSize = (pageSize) => {
    const { totalCount, currentPage: stateCurrentPage } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(stateCurrentPage, totalPages - 1);

    this.setState({
      pageSize: pageSize,
      currentPage: currentPage
    });
    this.buscarOpcion(currentPage, pageSize);
  }

  componentDidMount() {
    this.props.onRef(this);
    this.buscarOpcion();
  }

  toggleDialogOpcion = (opcionVal) => {
    var { opcion } = this.state;
    if (typeof opcionVal !== "undefined") {
        opcion = Object.assign(opcion, opcionVal);
    } else {
        opcion.nomopcion = '';
        opcion.dscopcion = '';
        opcion.dsctitopcion = '';
        opcion.dscruta = '';
        opcion.dscvista = '';
        opcion.dscicono = '';
        opcion.ideopcionpadre = '';
        opcion.stssegopcion = '';
        opcion.numorden = '';
        opcion.valdescendencia = '';

    }
    this.setState({ showOpcionDialog: !this.state.showOpcionDialog })
  };

  limpiarForm = () => {
    this.props.reset();
    var opcion = {
        ideopcion:'',
        nomopcion : '',
        dscopcion : '',
        dsctipopcion : '',
        dscruta : '',
        dscvista : '',
        dscicono : '',
        ideopcionpadre : '',
        stssegopcion : '',
        numorden : '',
        valdescendencia : ''
        };

    this.setState({ opcion: opcion });
  }

  buscarOpcion = (page, size) => {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    const { currentPage, pageSize } = this.state;
    add();
    page = page ? page : currentPage;
    size = size ? size : pageSize;

    if (page === -1) {
      page = 0;
      this.limpiarForm();
    }
    if (page === currentPage) { page = 0; }
    this.props.actions.paginarOpcion(this.props.opcion, page + 1, size).then(response => {
      if (response.tipo === TIPO_MENSAJE.SUCCESS) {
        this.setState({ rows: response.data.rows, totalCount: response.data.totalCount })
      } else {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
      }
      remove();
    })
  }  

  actualizarOpcion = (data) => {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    add();
    this.props.actions.actualizarOpcion(data, this.props.user).then(response => {
    if (response.tipo === TIPO_MENSAJE.SUCCESS) {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
        this.buscarOpcion();
        this.toggleDialogOpcion();
    } else{
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
    }
    remove();
    })

  }

  render() {
    const {
      rows,
      columns,
      tableColumnExtensions,
      sorting,
      currentPage,
      pageSize,
      pageSizes,
      totalCount,
      hiddenColumnNames,

    } = this.state;

    const { handleSubmit, classes, estados, lstOpcionPadre, buscarOpcion } = this.props;
    const { opcion, showOpcionDialog } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ width: "100%" }}>
            <Grid
              rows={rows}
              columns={columns}
              style={{ height: "500px" }}
              getRowId={getRowId}
              className={"ps-child"}
            >
              <SortingState
                sorting={sorting}
                onSortingChange={this.changeSorting}
              />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={this.changeCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={this.changePageSize}
              />

              <CustomPaging
                totalCount={totalCount}
              />
              <VirtualTable
                columnExtensions={tableColumnExtensions}
                cellComponent={this.Cell}     
                messages={GRID_TEXT_ES}
              />

              <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
              <TableHeaderRow showSortingControls />
              <PagingPanel
                pageSizes={pageSizes}
                messages={GRID_TEXT_ES}
              />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
              />



            </Grid>
          </Paper>
        </GridItem>
        <OpcionDialog toggleDialogOpcion={this.toggleDialogOpcion} 
                        showOpcionDialog={showOpcionDialog} 
                        opcion={opcion} 
                        classes={classes}
                        estados={estados} 
                        lstOpcionPadre={lstOpcionPadre} 
                        buscarOpcion={buscarOpcion}
                        titulo = {"Modificación de Opciones del Sistema"}
                        handleSubmit = {this.actualizarOpcion}
                        accion = {OPC_SEGURIDAD.ACTUALIZAR_OPCION}/>
      </GridContainer>
    );
  }
}

const mapState = (state) => ({
  loading: state.load.loading,  
  user: state.session.user,
});


const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(opcionActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

export default withRouter(connect(mapState, mapDispatch)(OpcionGrid));

