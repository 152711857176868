import React from "react";
// @material-ui/core components
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';

import cuestionarioStyle from "styles/jss/app/views/cuestionarioStyle.jsx";
import * as parametroActions from "actions/ParametroActions";
import * as perfilActions from "actions/PerfilActions";
import * as documentoActions from "actions/DocumentoActions";
import * as notificationActions from "actions/NotificationActions";
import * as loadActions from "actions/LoadActions";
import { TIPO_MENSAJE, MENSAJE, ESTADO } from 'utils/Constants';
import Form from "components/Form/PerfilForm";
import Grid from "components/Grid/PerfilGrid";

class Perfil extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.buscarPerfil = this.buscarPerfil.bind(this);
    this.state = {
      estados: [],
      perfil: {
        ideperfil: '',
        nomperfil: '',
        dscperfil: '',
        stsperfil:'',

      }
    }
  }

  componentDidMount() {
    this.listarEstados();
  }

  listarEstados() {
    const { show } = this.props.notificationActions;
    this.props.documentoActions.listarParametrosFiltroDocumento('listparamconsult').then(response => {
      if (response.tipo !== TIPO_MENSAJE.SUCCESS) {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
      } else {
        if (response.data.length === 0) {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "No se encontraron los estados a mostrar.", TIPO_MENSAJE.WARNING);
        } else {
          var estados = [];
          var estado;
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].cod_grup_parm == '6') {
              estado = {};
              estado.dscstsperfil = response.data[i].nom_parm;
              estado.stsperfil = response.data[i].val_parm_nume;
              estados.push(estado);
            }
          }
          this.setState({ estados: estados})         
        }
      }
    })    
  }

  buscarPerfil = (data) => {
    var { perfil } = this.state;
    perfil.dscperfil = data.dscperfil ? data.dscperfil : '';
    perfil.nomperfil = data.nomperfil ? data.nomperfil : '';
    perfil.stsperfil = data.stsperfil ? data.stsperfil : '';

    this.setState({ perfil: perfil });
    this.Grid.buscarPerfil();
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <SearchIcon className={classes.titleIcon} />
            <Typography className={classes.title} variant="h4"> Criterio de Búsqueda</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Form onSubmit={this.buscarPerfil} classes={classes} buscarPerfil={this.buscarPerfil} perfil={this.state.perfil} 
               estados={this.state.estados}  />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <ListIcon className={classes.titleIcon} />
            <Typography className={classes.title} variant="h4"> Lista de Perfiles del Sistema</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid onRef={ref => (this.Grid = ref)}  perfil={this.state.perfil} estados={this.state.estados}/> 
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }
}
const mapState = (state) => ({
  loading: state.load.loading
});
const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    parametroActions: bindActionCreators(parametroActions, dispatch),
    perfilActions: bindActionCreators(perfilActions, dispatch),
    documentoActions: bindActionCreators(documentoActions, dispatch)
  };
};

Perfil = withStyles(cuestionarioStyle)(Perfil);
export default withRouter(connect(mapState, mapDispatch)(Perfil));