import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { reducer as reduxFormReducer } from "redux-form";
import { sessionService, sessionReducer } from "redux-react-session";
import thunkMiddleware from "redux-thunk";

import { loadReducer } from "reducers/LoadReducer";
import { notificationReducer } from "reducers/NotificationReducer";
import { formReducer } from "reducers/FormReducer";
import { createBrowserHistory } from "history";
import { routerMiddleware } from 'react-router-redux';

const middlewareHistory = routerMiddleware(createBrowserHistory())

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};

const reducers = combineReducers({
    session: sessionReducer,
    form: reduxFormReducer,
    formApp: formReducer,
    load: loadReducer,
    notification: notificationReducer
})

//const pReducer = persistReducer(persistConfig, reducers);

const store = applyMiddleware(middlewareHistory, thunkMiddleware)(createStore)(reducers);

// Init the session service
sessionService.initSessionService(store);

//export default store;
export default store;
export const persistor = persistStore(store);
