import { APIRestAWS } from "services/common/APIRestAWS";
import { PATH_USUARIO_API, PATH_SEGURIDAD_API, PATH_SERFAC_API } from "commons/config/Path"; 
import config from "commons/config/AWSConfig";
import { OPC_BUSQUEDA, OPC_SEGURIDAD } from 'utils/Constants';
import queryString from 'query-string'
import axios from 'axios';
export default class UsuarioApi {
    
    static async obtenerUsuario(usuario, clave) { 
        var data = { 
            usuario: usuario,
            clave: clave,
            operacion: 'auth'
        };   
        let queryParams = "?" + queryString.stringify(data);
        const httpClient = axios.create({headers: {
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('aws_token')    
        }});
        httpClient.defaults.timeout = 1000 * config.apiGateway.URL_TIMEOUT;
        return httpClient.get(config.apiGateway.URL+PATH_SERFAC_API.URL_METHOD_LOGIN +  queryParams,  data, {
            headers: {
                'Content-Type': 'application/json', 
            }
          } )
            .then( async (response) => {  
              return response.data;
        });
    }

    static async listarUsuario(usuario) {
        var varQueryParams = {
            opc : OPC_BUSQUEDA.LISTAR,
            stsresponsable : usuario.stsresponsable
        };
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_USUARIO_API.URL_METHOD_GET, varQueryParams)
            .then(async (response) => {
                return response;
            }); 
    }

    static async insertarUsuario(usuario, user) {
        var varQueryParams = {
            opc : OPC_SEGURIDAD.INSERTAR_USUARIO,
            usuario : { dscnomape : usuario.dscnom + ' ' + usuario.dscape,
                        email : usuario.email,
                        ideperfil : usuario.ideperfil,
                        vertodogth : '',
                        stsusuario : usuario.stsusuario,
                        usucreacion : user.ideusuario,
                        usumodif : user.ideusuario
            }
        };
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.post(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_USUARIO, varQueryParams)
            .then(async (response) => {
                return response;
            }); 
    }

    static async paginarUsuario(usuario, page, pagesize) {
        var varQueryParams = {
            opc: OPC_SEGURIDAD.PAGINAR_USUARIO,
            dscnomape: usuario.dscnomape,
            email: usuario.email,
            ideperfil: usuario.ideperfil,
            stsusuario: usuario.stsusuario,
            page: page,
            pagesize: pagesize
        }
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.get(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_USUARIO, varQueryParams)
            .then(async (response) => {
                return response;
            });
    } 
    
    static async actualizarUsuario(usuario, user) {
        var varQueryParams = {
            opc : OPC_SEGURIDAD.ACTUALIZAR_USUARIO,
            usuario : { dscnomape : usuario.dscnomape === undefined ? '' : usuario.dscnomape ,
                        email : usuario.email === undefined ? '' : usuario.email ,
                        ideperfil : usuario.ideperfil === undefined ? '' : usuario.ideperfil ,
                        vertodogth : '' === undefined ? '' : usuario.vertodogth ,
                        stsusuario : usuario.stsusuario === undefined ? '' : usuario.stsusuario ,
                        usucreacion : user.ideusuario === undefined ? '' : user.ideusuario ,
                        usumodif : user.ideusuario === undefined ? '' : user.ideusuario ,
                        ideusuario : usuario.ideusuario === undefined ? '' : usuario.ideusuario 
            }
        };
        var apiRestAWS = new APIRestAWS();
        return apiRestAWS.put(config.apiGateway.NAME, PATH_SEGURIDAD_API.URL_METHOD_USUARIO, varQueryParams)
            .then(async (response) => {
                return response;
            }); 
    }

}