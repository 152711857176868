import perfilApi from 'services/api/PerfilApi';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';

export function listarPerfil(stsperfil) {
    return function (dispatch) {

        return perfilApi.listarPerfil(stsperfil).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function paginarPerfil(perfil, page, pagesize) {
    return function (dispatch) {
        return perfilApi.paginarPerfil(perfil, page, pagesize).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function actualizarPerfil(perfil, user) {
    return function (dispatch) {
        return perfilApi.actualizarPerfil(perfil, user).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function insertarPerfil(perfil, user) {
    return function (dispatch) {
        return perfilApi.insertarPerfil(perfil, user).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}

export function listarOpcionesxPerfil(perfil) {
    return function (dispatch) {

        return perfilApi.listarOpcionesxPerfil(perfil).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}


export function AsignarOpcionesxPerfil(lstideopcion, perfil,  user) {
    return function (dispatch) {
        return perfilApi.AsignarOpcionesxPerfil(lstideopcion, perfil,  user).then(data => {
            return data;
        }).catch(error => {
            return {
                tipo: TIPO_MENSAJE.DANGER,
                mensaje: MENSAJE.ERROR_GENERAL,
                indMostrarMensaje: MENSAJE.IND_MOSTRAR_MENSAJE
            }
        });
    }
}
