import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CustomAutocomplete from "components/CustomInput/CustomAutocomplete";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm } from 'redux-form';

import * as loadActions from "actions/LoadActions";
import * as notificationActions from "actions/NotificationActions";
import * as formActions from "actions/FormActions";
import { email, required} from 'utils/FormValidation';
import { ESTADO } from 'utils/Constants';

class MntUsuarioForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      usuario:  { 
        dscnomape:'',
        dscnom:'',
        dscape:'',
        stsresponsable:'',
        email:'',
        ideperfil: '',
        vertodogth: '',
        stsusuario: '' }
    }
  }

  componentDidMount() {
  }

  getSelectValue = (valor, name) => {
    var usuario = this.state.usuario
    usuario[name] = valor;
    this.setState({ usuario: usuario });
  }

  render() {
    const { handleSubmit, classes, estados, toggleDialogUsuario, perfiles } = this.props;
    const { usuario } = this.state;

    var perfilesactivos = [];
    perfiles.map((perfil) => {
        if (perfil.stsperfil == ESTADO.ACTIVO){
          perfilesactivos.push(perfil);
        }
    });

    return (
      <form onSubmit={handleSubmit} style={{ width: "100%", margin: 8 * 3 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Field labelText="Nombres"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "dscnom"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 50)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={usuario.dscnom}
                  validate={[required]}
                  name="dscnom"
                  id="dscnom" />
              </GridItem>   
              <GridItem xs={12} sm={12} md={6}>
                <Field labelText="Apellidos"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "dscape"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 50)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={usuario.dscape}
                  validate={[required]}
                  name="dscape"
                  id="dscape" />
              </GridItem>                         
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Field labelText="Correo"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "email"
                  }}
                  component={CustomInput}
                  value={usuario.email}
                  validate={[required, email]}
                  name="email"
                  id="email" />
              </GridItem>                
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Field labelText="Perfil"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "ideperfil",
                    name: "ideperfil"
                  }}
                  multiple={false}
                  data={perfilesactivos}
                  component={CustomAutocomplete}
                  valor={usuario.ideperfil}
                  getValue={this.getSelectValue}
                  validate={[required]}
                  val="ideperfil"
                  txt="nomperfil"
                  name="ideperfil"
                  id="ideperfil" />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>

                <Field labelText="Estado"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "stsusuario",
                    name: "stsusuario"
                  }}
                  multiple={false}
                  data={estados}
                  component={CustomAutocomplete}
                  valor={usuario.stsusuario}
                  getValue={this.getSelectValue}
                  validate={[required]}
                  val="stsusuario"
                  txt="dscstsusuario"
                  name="stsusuario"
                  id="stsusuario" />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>

        
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
              {/* <div style={{ textAlign: "right" }}> */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >Guardar</Button>
              <Button onClick={() => toggleDialogUsuario()}>Cerrar</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </form>
    );
  }
}

MntUsuarioForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
MntUsuarioForm = reduxForm({
  form: 'mntUsuarioForm',
  enableReinitialize: true
})(MntUsuarioForm)
const mapState = (state) => ({
  loading: state.load.loading,
  initialValues: state.formApp.data
});
const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(MntUsuarioForm));
